import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Anchor from 'ls-common-client/src/components/Anchor';
import Clipboard from 'clipboard/dist/clipboard.min';

const Share = ({ url, title, onClose, show, ...props }) => {
  const [copied, setCopied] = useState();

  useEffect(() => {
    if (show) {
      setCopied(false);
      const clipboard = new Clipboard('.Share__copy');
      clipboard.on('success', () => setCopied(true));

      return () => {
        clipboard.destroy();
      };
    }
    return undefined;
  }, [show]);

  return (
    <Dialog maxWidth="380px" show={show} {...props}>
      <Container
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10px 5px 10px 20px"
      >
        <Text fontSize="16px" fontWeight="600">
          {title}
        </Text>
        <EmptyButton
          onClick={onClose}
          height="40px"
          width="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon color="normal" className="ls-icon icon-generalxlarge" />
        </EmptyButton>
      </Container>
      <Container padding="0 20px 20px 20px">
        <Anchor
          href={`https://www.facebook.com/sharer/sharer.php?t=${title}&u=${url}`}
          target="_blank"
          display="flex"
          alignItems="center"
          padding="5px 0"
        >
          <Container
            marginRight="20px"
            width="34px"
            height="34px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              className="ls-icon icon-facebook"
              color="#1877F2"
              fontSize="38px"
            />
          </Container>
          <Text fontSize="16px" color="normal">
            Share on Facebook
          </Text>
        </Anchor>

        <Anchor
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
          target="_blank"
          display="flex"
          alignItems="center"
          padding="5px 0"
        >
          <Container
            marginRight="20px"
            width="34px"
            height="34px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              className="ls-icon icon-linkedin-with-circle"
              color="#117BB8"
              fontSize="34px"
            />
          </Container>
          <Text fontSize="16px" color="normal">
            Share on Linkedin
          </Text>
        </Anchor>

        <Anchor
          href={`mailto:?body=${url}`}
          target="_blank"
          display="flex"
          alignItems="center"
          padding="5px 0"
        >
          <Container
            marginRight="20px"
            width="34px"
            height="34px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              className="ls-icon icon-envelopeOutline"
              fontSize="21px"
              color="normal"
            />
          </Container>
          <Text fontSize="16px" color="normal">
            Share via Email
          </Text>
        </Anchor>
        <EmptyButton
          className="Share__copy"
          data-clipboard-text={url}
          display="flex"
          alignItems="center"
          padding="5px 0"
        >
          <Container
            marginRight="20px"
            width="34px"
            height="34px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              className="ls-icon icon-documentsFolded"
              fontSize="33px"
              color="normal"
            />
          </Container>
          <Text fontSize="16px" color="normal">
            {copied ? 'Copied' : 'Copy Link'}
          </Text>
        </EmptyButton>
      </Container>
    </Dialog>
  );
};

Share.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

Share.defaultProps = {
  show: false,
};

export default Share;
