import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { quoteListings as quoteListingsQuery } from '../graphql/queries';

const useQuoteListings = ({ variables = {} } = {}) => {
  const { profileId } = useParams();

  const {
    data: { quoteListings } = {},
    previousData: { reviews: previousQuoteListings } = {},
    loading,
    fetchMore,
  } = useQuery(quoteListingsQuery, {
    variables: {
      listingId: profileId,
      first: 9,
      ...variables,
    },
    errorPolicy: 'all',
  });

  return {
    quoteListings,
    previousQuoteListings,
    loading,
    fetchMore,
  };
};

export default useQuoteListings;
