import React, { useState, useContext } from 'react';
import Dialog from 'ls-common-client/src/components/Dialog';
import toolTipLocalStorage from '../../../../lib/toolTipLocalStorage';
import DialogContent from './DialogContent';
import { Context } from '../../../../context/AppContext';

const VisualTour = () => {
  const {
    media: { mobile },
  } = useContext(Context);
  const [show, setShow] = useState(!toolTipLocalStorage.get('visualTour'));

  const onClose = () => {
    setShow(false);
    toolTipLocalStorage.set('visualTour', true);
  };

  return (
    <Dialog mode={mobile ? 'mobile' : 'desktop'} show={show} maxWidth="490px">
      <DialogContent onClose={onClose} />
    </Dialog>
  );
};

export default VisualTour;
