import React from 'react';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';

const RemoveButton = props => (
  <EmptyButton
    width="18px"
    height="18px"
    borderRadius="100%"
    border="1.5px solid #ff5a5f"
    display="flex"
    alignItems="center"
    justifyContent="center"
    background="white"
    {...props}
  >
    <Icon
      fontSize="9px"
      color="#ff5a5f"
      className="ls-icon icon-generalxlarge"
    />
  </EmptyButton>
);

export default RemoveButton;
