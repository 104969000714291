import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Input from 'ls-common-client/src/components/Input';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import Text from 'ls-common-client/src/components/Text';
import { profileSuggest } from '../../../../graphql/queries';
import client from '../../../../services/client';
import { Context } from '../../../../context/AppContext';
import { addressJoin } from '../../../../lib/util';
import ToolTip from '../../../UI/molecules/ToolTip';

const fetchResults = async query => {
  const { data } = await client.query({
    query: profileSuggest,
    variables: {
      query,
    },
  });

  const {
    profileSuggest: { edges },
  } = data;

  return edges;
};

const StyledEmptyButton = styled(EmptyButton)`
  &:focus {
    .ProfileSearchResultsButton {
      background: ${props => props.theme.background.background300};
    }
  }
`;

const ProfileSearch = props => {
  const {
    profile: { profile },
    sideBar,
    media: { desktop },
  } = useContext(Context);

  const { displayName } = profile || {};

  const navigate = useNavigate();
  const { profileId } = useParams();
  const { pathname } = useLocation();

  const timeout = useRef();
  const [value, setValue] = useState(displayName);
  const [results, setResults] = useState();
  const [loading, setLoading] = useState();
  const [toolTipDisabled, setToolTipDisabled] = useState();
  const autoSuggestRef = useRef();

  const onChange = ({ target }) => {
    setValue(target.value);
    clearTimeout(timeout.current);

    if (target.value) {
      setLoading(true);
      timeout.current = setTimeout(async () => {
        setResults(await fetchResults(target.value));
        setLoading(false);
      }, 500);
    } else {
      setLoading(false);
      setResults(null);
    }
  };

  const onKeyDown = e => {
    const { which } = e;

    if (which === 40) {
      e.preventDefault();
      autoSuggestRef?.current?.firstChild.focus();
    }
  };

  const onSelect = selected => {
    const newPath = profileId
      ? pathname.replace(profileId, selected.id)
      : `/${selected.id}`;

    navigate(newPath);
    setValue(selected.displayName);
    setResults(null);
    sideBar.close();
  };

  const onFocus = () => {
    setValue('');
    setToolTipDisabled(true);
  };

  const onBlur = () => {
    setValue(displayName);
  };

  return (
    <Container {...props}>
      <Container position="relative">
        <Icon
          className="ls-icon icon-generalsearchlarge"
          pointerEvents="none"
          left="14px"
          top="12px"
          position="absolute"
          color="text300"
          zIndex="1"
        />
        <ToolTip
          anchor={desktop ? 'left' : 'top'}
          render={() => (
            <Text
              fontSize="12px"
              fontWeight="600"
              display="flex"
              alignItems="center"
            >
              <Icon
                color={theme => theme.error.normal}
                fontSize="18px"
                className="ls-icon icon-warning"
                marginRight="5px"
              />
              Search a business to start
            </Text>
          )}
          show={!profile && !toolTipDisabled}
          marginBottom="5px"
          popupProps={{
            width: '200px',
          }}
        >
          <Input
            onChange={onChange}
            value={value}
            borderRadius="30px"
            height="40px"
            placeholder="Business Search"
            fontSize="14px"
            paddingLeft="40px"
            paddingRight="10px"
            border="1px solid #eaefff"
            textOverflow="ellipsis"
            autoComplete="new-password"
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            _placeholderShown={{
              borderColor: theme => theme.border.border300,
            }}
            _placeholder={{
              color: theme => theme.text.text300,
            }}
          />
        </ToolTip>
        <AutoSuggest
          ref={autoSuggestRef}
          data={results}
          loading={loading}
          boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.15)"
          border="none"
          width="300px"
          zIndex="9"
          onSelect={({ node }) => onSelect(node)}
          render={({ node, onKeyDown: onKeyDownRender }) => (
            <StyledEmptyButton
              key={node.id}
              onKeyDown={onKeyDownRender}
              onClick={() => onSelect(node)}
              width="100%"
            >
              <Text
                margin="0 10px"
                height="1px"
                backgroundColor="background300"
                display="block"
              />
              <Text padding="3px" display="block">
                <Text
                  className="ProfileSearchResultsButton"
                  key={node.id}
                  textAlign="left"
                  padding="15px"
                  borderRadius="11px"
                  display="block"
                  _hover={{
                    backgroundColor: 'background200',
                  }}
                >
                  <Text
                    display="block"
                    color="normal"
                    fontSize="14px"
                    lineHeight="1"
                    marginBottom="3px"
                  >
                    {node.displayName}
                  </Text>
                  <Text
                    display="block"
                    color="text400"
                    fontSize="10px"
                    lineHeight="1.1"
                  >
                    {addressJoin(
                      node.street,
                      node.suburb,
                      node.stateCode,
                      node.postcode
                    )}
                  </Text>
                </Text>
              </Text>
            </StyledEmptyButton>
          )}
        />
      </Container>
    </Container>
  );
};

export default ProfileSearch;
