import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../../UI/molecules/FormDialogHeader';
import ShareDialogContents from './ShareDialogContents';

const ShareDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="420px" {...props}>
      <FormDialogHeader
        header="Get more reviews"
        subHeader="Click on the link below and share with customers to review your business on Localsearch."
        onClose={onClose}
      />
      <ShareDialogContents />
    </FormDialog>
  );
};

ShareDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ShareDialog;
