import { useMutation } from '@apollo/client';
import client from '../services/client';
import { addQuoteListingResponse as addQuoteListingResponseMutation } from '../graphql/mutations';

const useQuoteListingResponse = () => {
  const [
    addQuoteListingResponse,
    {
      loading: addQuoteListingResponseLoading,
      error: addQuoteListingResponseError,
    },
  ] = useMutation(addQuoteListingResponseMutation);

  const add = async input => {
    const { quoteListingId, message } = input;

    await addQuoteListingResponse({ variables: { input } });

    client.cache.modify({
      id: `QuoteListing:${quoteListingId}`,
      fields: {
        quoteListingResponse: () => ({
          message,
        }),
      },
    });
  };

  return {
    add: {
      add,
      loading: addQuoteListingResponseLoading,
      error: addQuoteListingResponseError,
    },
  };
};

export default useQuoteListingResponse;
