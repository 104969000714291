import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { isTouch } from 'ls-common-client/src/util';
import { Context } from '../../../../../context/AppContext';
import PostCard from './PostCard';

let timeout;
let translateX = 0;
let prevPageX = 0;
let prevPageY = 0;
let distanceX = 0;
let dataLength = 0;
let direction = 'left';
let lastSlide;
let nextPage;

const slideDuration = 10000;

const StyledProgressBar = styled(Container)`
  background: linear-gradient(
    90deg,
    rgba(45, 96, 251, 1) 0%,
    rgba(152, 176, 253, 1) 100%
  );
`;

const StyledPostCard = styled(PostCard)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Slider = ({
  data,
  onClose,
  onLastSlide,
  hasNextPage,
  loading,
  paused,
  hideShare,
  initialSlide,
  onSlideChange,
  onClick,
  className,
}) => {
  const {
    media: { mobile },
  } = useContext(Context);

  const containerRef = useRef();
  const progressBarRef = useRef();

  const [activeSlide, setActiveSlide] = useState(initialSlide || 0);
  const [shareActive, setShareActive] = useState();

  const translate = x => {
    if (!containerRef.current) {
      return;
    }

    const { style } = containerRef.current;

    translateX = x;

    window.requestAnimationFrame(() => {
      style.transform = `translateX(${x}px)`;
    });
  };

  const stopProgress = () => {
    if (!progressBarRef.current) {
      return;
    }

    const { style } = progressBarRef.current;

    style.transition = `none`;
    style.width = '0';
  };

  const startProgress = () => {
    if (!progressBarRef.current) {
      return;
    }

    const { style } = progressBarRef.current;

    stopProgress();

    setTimeout(() => {
      style.transition = `all linear ${slideDuration}ms`;
      style.width = '100%';
    });
  };

  const slideRight = () => {
    if (!containerRef.current) {
      return;
    }

    const { clientWidth, style } = containerRef.current;

    style.transition = 'all 0.5s ease';

    setActiveSlide(val => {
      if (val === dataLength - 2) {
        lastSlide();
      }

      if (val < dataLength - 1 || nextPage) {
        startProgress();
        translate(translateX - clientWidth);
        return val + 1;
      }

      setTimeout(onClose);

      return val;
    });

    clearTimeout(timeout);

    if (!paused) {
      timeout = setTimeout(() => {
        slideRight();
      }, slideDuration);
    }
  };

  const slideLeft = () => {
    if (!containerRef.current) {
      return;
    }

    const { clientWidth, style } = containerRef.current;

    style.transition = 'all 0.5s ease';

    setActiveSlide(val => val - 1);
    startProgress();
    translate(translateX + clientWidth);
    clearTimeout(timeout);

    if (!paused) {
      timeout = setTimeout(() => {
        slideRight();
      }, slideDuration);
    }
  };

  const pause = () => {
    stopProgress();
    clearTimeout(timeout);
  };

  const play = () => {
    startProgress();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      slideRight();
    }, slideDuration);
  };

  const init = useCallback(() => {
    const {
      current: { clientWidth },
    } = containerRef;

    translateX = 0;
    if (initialSlide) {
      translate(-(clientWidth * initialSlide));
      setActiveSlide(initialSlide);
    }
  }, [initialSlide]);

  useEffect(() => {
    const resize = () => {
      init();
      if (!paused) {
        play();
      }
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  // fix for ipad Safari -
  // when Safari comes back from running in the background, slider needs to be re-initialized
  useEffect(() => {
    const onFocus = () => {
      init();
    };

    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  useEffect(() => {
    dataLength = data.length;
  }, [data]);

  useEffect(() => {
    lastSlide = onLastSlide;
  }, [onLastSlide]);

  useEffect(() => {
    nextPage = hasNextPage;
  }, [hasNextPage]);

  useEffect(() => {
    onSlideChange(activeSlide);
  }, [activeSlide]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (paused) {
      pause();
    } else {
      play();
    }
  }, [paused]);

  const lockSlide = () => {
    if (!containerRef.current) {
      return;
    }

    const { clientWidth } = containerRef.current;

    const isFirstSlide = activeSlide === 0;
    const isLastSlide = activeSlide === dataLength - 1;

    if (isFirstSlide && direction === 'left') {
      translate(0);
      return;
    }

    if (isLastSlide && direction === 'right') {
      if (nextPage) {
        translate(-(clientWidth * dataLength));
        lastSlide();
      } else {
        onClose();
      }
      return;
    }

    let newActiveSlide = activeSlide;

    if (['left', 'right'].includes(direction) && Math.abs(distanceX) > 5) {
      newActiveSlide =
        direction === 'right' ? activeSlide + 1 : activeSlide - 1;
    }

    setActiveSlide(newActiveSlide);
    translate(-(clientWidth * newActiveSlide));
    direction = null;
  };

  const onTouchStart = () => {
    if (!containerRef.current || paused || shareActive) {
      return;
    }

    const { style } = containerRef.current;
    style.transition = 'none';
    pause();
  };

  const onTouchMove = e => {
    const {
      touches: [touch],
    } = e;

    const { pageX, pageY } = touch;

    if (paused || shareActive) {
      return;
    }

    if (prevPageX) {
      distanceX = pageX - prevPageX;
      const distanceY = pageY - prevPageY;

      const isHorizontal = Math.abs(distanceX) > Math.abs(distanceY);

      if (isHorizontal) {
        direction = distanceX < 0 ? 'right' : 'left';
        translate(translateX + distanceX);
      } else {
        direction = distanceY < 0 ? 'down' : 'up';
      }
    }

    prevPageX = pageX;
    prevPageY = pageY;
  };

  const onTouchEnd = () => {
    if (!containerRef.current || paused || shareActive) {
      return;
    }
    const { style } = containerRef.current;
    style.transition = 'all 0.5s ease';
    prevPageX = 0;
    prevPageY = 0;
    lockSlide();
    play();
  };

  const onShareShow = () => {
    setShareActive(true);
    pause();
  };

  const onShareClose = () => {
    setShareActive(false);
    if (!paused) {
      play();
    }
  };

  if (!data.length) {
    return null;
  }

  return (
    <>
      <Container padding="10px 45px">
        <Container position="relative">
          <Container
            backgroundColor="background500"
            borderRadius="10px"
            height="5px"
          />
          <StyledProgressBar
            ref={progressBarRef}
            position="absolute"
            top="0"
            left="0"
            height="5px"
            borderRadius="10px"
          />
        </Container>
      </Container>
      <Container position="relative">
        <EmptyButton
          onClick={onClose}
          position="absolute"
          right={mobile ? '4px' : '6px'}
          top={mobile ? '19px' : '-12px'}
          height="40px"
          width="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="2"
        >
          <Icon color="normal" className="ls-icon icon-generalxlarge" />
        </EmptyButton>
      </Container>
      <Container
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onClick={() => {
          if (shareActive) {
            return;
          }
          onClick();
        }}
        display="flex"
        ref={containerRef}
        flex="1"
        minHeight="0"
      >
        {data.map(({ node }, index) => (
          <StyledPostCard
            className={className}
            data-active={activeSlide === index}
            data-post-id={node.id}
            key={node.id}
            data={node}
            flex="0 0 100%"
            onShareShow={onShareShow}
            onShareClose={onShareClose}
            hideShare={hideShare}
          />
        ))}
        {loading && (
          <Container
            height="100%"
            flex="0 0 100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Loader width="200px" />
          </Container>
        )}
      </Container>

      {!isTouch() && (
        <>
          {!!activeSlide && (
            <EmptyButton
              onClick={slideLeft}
              position="absolute"
              left="-15px"
              top="50%"
              transform="translateY(-50%)"
              backgroundColor="white"
              borderRadius="100%"
              width="44px"
              height="44px"
              border="1px solid"
              borderColor={theme => theme.primary.primary200}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                className="ls-icon icon-generalarrowwlarge"
                iconColor={theme => theme.primary.normal}
                iconSize="18px"
              />
            </EmptyButton>
          )}

          {activeSlide < data.length - 1 || hasNextPage ? (
            <EmptyButton
              onClick={slideRight}
              position="absolute"
              right="-15px"
              top="50%"
              transform="translateY(-50%)"
              backgroundColor="white"
              borderRadius="100%"
              width="44px"
              height="44px"
              border="1px solid"
              borderColor={theme => theme.primary.primary200}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                className="ls-icon icon-generalarrowelarge"
                iconColor={theme => theme.primary.normal}
                iconSize="18px"
              />
            </EmptyButton>
          ) : null}
        </>
      )}
    </>
  );
};

Slider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  hideShare: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onLastSlide: PropTypes.func,
  onSlideChange: PropTypes.func,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  paused: PropTypes.bool,
  initialSlide: PropTypes.number,
};

Slider.defaultProps = {
  className: '',
  hideShare: false,
  data: [],
  onClose: () => {},
  onLastSlide: () => {},
  onSlideChange: () => {},
  onClick: () => {},
  loading: false,
  hasNextPage: false,
  paused: false,
  initialSlide: 0,
};

export default Slider;
