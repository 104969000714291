import React from 'react';
import PropTypes from 'prop-types';
import CommonAlertDialog from 'ls-common-client/src/components/AlertDialog';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Text from 'ls-common-client/src/components/Text';
import alert from '../../../images/alert.svg';

const AlertDialog = ({ heading, text, ...props }) => (
  <CommonAlertDialog
    padding="20px 35px"
    heading={
      <Container textAlign="center">
        <Container marginBottom="10px">
          <Image src={alert} />
        </Container>
        <Text>{heading}</Text>
      </Container>
    }
    text={
      <Text textAlign="center" display="block">
        {text}
      </Text>
    }
    {...props}
  />
);

AlertDialog.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

AlertDialog.defaultProps = {
  heading: null,
  text: null,
};

export default AlertDialog;
