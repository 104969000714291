import { useMutation } from '@apollo/client';
import client from '../services/client';
import {
  addReviewResponse as addReviewResponseMutation,
  updateReviewResponse as updateReviewResponseMutation,
  removeReviewResponse as removeReviewResponseMutation,
} from '../graphql/mutations';

const useReviewResponse = () => {
  const [
    addReviewResponse,
    { loading: addReviewResponseLoading, error: addReviewResponseError },
  ] = useMutation(addReviewResponseMutation);

  const [
    updateReviewResponse,
    { loading: updateReviewResponseLoading, error: updateReviewResponseError },
  ] = useMutation(updateReviewResponseMutation);

  const [
    removeReviewResponse,
    { loading: removeReviewResponseLoading, error: removeReviewResponseError },
  ] = useMutation(removeReviewResponseMutation);

  const add = async input => {
    const { reviewId, message } = input;

    await addReviewResponse({ variables: { input } });

    client.cache.modify({
      id: `Review:${reviewId}`,
      fields: {
        reviewResponse: () => ({
          message,
        }),
      },
    });
  };

  const update = async input => {
    const { reviewId, message } = input;

    await updateReviewResponse({ variables: { input } });

    client.cache.modify({
      id: `Review:${reviewId}`,
      fields: {
        reviewResponse: () => ({
          message,
        }),
      },
    });
  };

  const remove = async ({ reviewId }) => {
    await removeReviewResponse({ variables: { reviewId } });

    client.cache.modify({
      id: `Review:${reviewId}`,
      fields: {
        reviewResponse: () => null,
      },
    });
  };

  return {
    add: {
      add,
      loading: addReviewResponseLoading,
      error: addReviewResponseError,
    },
    update: {
      update,
      loading: updateReviewResponseLoading,
      error: updateReviewResponseError,
    },
    remove: {
      remove,
      loading: removeReviewResponseLoading,
      error: removeReviewResponseError,
    },
  };
};

export default useReviewResponse;
