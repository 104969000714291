import moment from 'moment';
import { formatGeneric } from 'ls-common-client/src/phone';
import { LISTING_POST_STATES, CONTACT_TYPES } from './constants';
import config from '../config';

const { DISABLED } = LISTING_POST_STATES;
const { PHONE } = CONTACT_TYPES;
const { lsOnlineUrlRoot } = config;

const addressJoin = (street, ...rest) => {
  const st = street ? `${street},` : '';
  return [st, ...rest].filter(Boolean).join(' ');
};

const listingPostDisabled = ({ state, startDate, endDate }) => {
  const now = moment().startOf('day');
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');

  return (
    (state === DISABLED && !startDate && !endDate) || start > now || end < now
  );
};

const sortCategories = categories => {
  const packageCategories = categories.filter(category => category.package);
  const otherCategories = categories.filter(category => !category.package);

  const sortedPackageCategories = packageCategories.sort((a, b) => {
    const { sort: sortA = 0 } = a.package || {};
    const { sort: sortB = 0 } = b.package || {};
    return sortA - sortB;
  });

  return [...sortedPackageCategories, ...otherCategories];
};

const getPackageGradient = packageId => {
  const packages = [
    {
      package: 'Gold',
      ids: ['2'],
      gradient: {
        from: '#9F7433',
        to: '#F5DC54',
      },
    },
    {
      package: 'Silver',
      ids: ['3'],
      gradient: {
        from: '#A0A0A0',
        to: '#ECECEC',
      },
    },
    {
      package: 'Platinum',
      ids: ['1'],
      gradient: {
        from: '#0E0E0E',
        to: '#0E0E0E',
      },
    },
    {
      package: 'Bronze',
      ids: ['4', '9', '10'],
      gradient: {
        from: '#92613B',
        to: '#CFA681',
      },
    },
  ];

  const match = packages.find(({ ids }) => ids.includes(packageId));

  return match ? match.gradient : null;
};

const createProfileUrl = vanityUrlPath =>
  `${lsOnlineUrlRoot}/profile/${vanityUrlPath}`;

const getUpgradeLink = profile => {
  if (!profile) {
    return '';
  }

  const {
    contacts,
    street,
    suburb,
    stateCode,
    postcode,
    displayName,
    vanityUrlPath,
  } = profile;

  const address = addressJoin(street, suburb, stateCode, postcode);
  const { value: phone } = contacts.find(({ type }) => type === PHONE) || {};

  const profileInfo = [
    { label: 'Business Name', value: displayName },
    { label: 'Address', value: address },
    { label: 'Phone', value: formatGeneric(phone) },
    { label: 'Profile URL', value: createProfileUrl(vanityUrlPath) },
  ];

  const profileText = profileInfo
    .map(({ label, value }) => `${label}: ${value}`)
    .join('%0D%0A');

  return `mailto:leads@localsearch.com.au?subject=Upgrade My Business Profile&body=I would like to upgrade my Localsearch business profile.%0D%0A%0D%0A${profileText}`;
};

export {
  addressJoin,
  listingPostDisabled,
  sortCategories,
  getPackageGradient,
  getUpgradeLink,
  createProfileUrl,
};
