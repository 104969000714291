import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import mapboxgl from 'mapbox-gl';
import mapConfig from 'ls-map/src/map/config';
import { wktToGeoJSON, geojsonToWKT } from '@terraformer/wkt';
import poboxRegex from 'pobox-regex';
import Icon from 'ls-common-client/src/components/Icon';
import Container from 'ls-common-client/src/components/Container';
import Form from 'ls-common-client/src/components/Form';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Switch from 'ls-common-client/src/components/Switch';
import Validator from '../../../../UI/atoms/Validator';
import Selector from '../../../../UI/molecules/Selector';
import Label from '../../../../UI/atoms/Label';
import Input from '../../../../UI/atoms/Input';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../../context/AppContext';
import GooglePlaceAutocomplete from './GooglePlacesAutocomplete';
import { STATES } from '../../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

const { style } = mapConfig;

const states = [
  {
    value: '',
    label: 'Please Select',
  },
  ...Object.keys(STATES).map(key => ({
    value: STATES[key],
    label: STATES[key],
  })),
];

const schema = Joi.object({
  street: Joi.string()
    .custom((value, { error }) => {
      if (poboxRegex().test(value)) {
        return error('pobox');
      }
      return value;
    })
    .messages({
      'string.empty': 'You forgot your street',
      pobox: 'PO Boxes are not allowed',
    }),
  suburb: Joi.string(),
  stateCode: Joi.string().required().messages({
    'string.empty': 'You forgot your state',
  }),
  postcode: Joi.string().required().messages({
    'string.empty': 'You forgot your postcode',
  }),
  coordinates: Joi.object({
    lat: Joi.number(),
    lng: Joi.number(),
  }),
  settings: Joi.object({
    showAddress: Joi.bool(),
  }),
});

const getAddressParts = addressComponents => {
  const placesAddressMap = {
    subpremise: { map: 'street', key: 'long_name' },
    street_number: { map: 'street', key: 'long_name' },
    route: { map: 'street', key: 'long_name' },
    locality: { map: 'suburb', key: 'long_name' },
    administrative_area_level_1: { map: 'stateCode', key: 'short_name' },
    postal_code: { map: 'postcode', key: 'long_name' },
  };

  let parts = {};

  addressComponents.forEach(component => {
    const { types } = component;
    const matchingType = types.find(type => placesAddressMap[type]);
    const { map, key } = placesAddressMap[matchingType] || {};

    if (!map) {
      return;
    }

    if (parts[map]) {
      parts = {
        ...parts,
        [map]: `${parts[map]} ${component[key]}`,
      };
    } else {
      parts = {
        ...parts,
        [map]: component[key],
      };
    }
  });

  return parts;
};

const parseCoordinatesIn = coordinates => {
  if (!coordinates) {
    return {};
  }

  const {
    coordinates: [lg, lt],
  } = wktToGeoJSON(coordinates);

  return { lat: lt, lng: lg };
};

const parseCoordinatesOut = ({ lng, lat }) => {
  if (lng && lat) {
    return geojsonToWKT({
      type: 'Point',
      coordinates: [lng, lat],
    });
  }
  return undefined;
};

const LocationForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
      error,
    },
    media: { mobile },
  } = useContext(Context);

  const { street, stateCode, postcode, suburb, coordinates, settings } =
    profile;
  const { showAddress } = settings;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const markerRef = useRef();
  const [showStates, setShowStates] = useState();

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      street,
      stateCode,
      postcode,
      suburb,
      coordinates: parseCoordinatesIn(coordinates),
      settings: { showAddress: !!showAddress },
    },
    resolver: joiResolver(schema),
  });

  const submit = async values => {
    await update({
      ...values,
      countryCode: 'AU',
      coordinates: parseCoordinatesOut(values.coordinates),
    });
    successNotificationSlider.open();
    onClose();
  };

  const onAddressSelect = place => {
    const {
      address_components: addressComponents,
      geometry: {
        location: { lat, lng },
      },
    } = place;

    const parts = {
      ...getAddressParts(addressComponents),
      coordinates: {
        lat: lat(),
        lng: lng(),
      },
    };

    Object.keys(parts).forEach(key => {
      setValue(key, parts[key], { shouldDirty: true });
    });

    mapRef.current.setCenter([lng(), lat()]);
    markerRef.current.setLngLat([lng(), lat()]);
  };

  const onSuburbSelect = place => {
    const {
      address_components: addressComponents,
      geometry: {
        location: { lat, lng },
      },
    } = place;

    const parts = {
      postcode: '',
      coordinates: {
        lat: lat(),
        lng: lng(),
      },
      ...getAddressParts(addressComponents),
    };

    Object.keys(parts).forEach(key => {
      setValue(key, parts[key], { shouldDirty: true });
    });

    clearErrors('suburb');
  };

  const { lat = -25.734968, lng = 134.489563 } = watch('coordinates');

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style,
      zoom: 15,
      center: [lng, lat],
    });

    map.once('load', () => {
      map.setLayoutProperty('Selected', 'visibility', 'none');
    });

    const marker = new mapboxgl.Marker({ draggable: true, color: '#F16159' })
      .setLngLat([lng, lat])
      .addTo(map);

    marker.on('dragend', () => {
      const coords = marker.getLngLat();
      setValue(
        'coordinates',
        { lat: coords.lat, lng: coords.lng },
        { shouldDirty: true }
      );
    });

    mapRef.current = map;
    markerRef.current = marker;

    return () => map.remove();
  }, []);

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container
        display="flex"
        flexWrap="wrap"
        margin="0 -10px"
        flexDirection={mobile ? 'column' : 'row'}
      >
        <Container
          flex="1"
          padding="10px"
          minWidth={mobile ? 'unset' : '300px'}
        >
          <Container marginBottom="10px" position="relative">
            <Icon
              position="absolute"
              left="13px"
              top="50%"
              transform="translateY(-50%)"
              color="text300"
              className="ls-icon icon-generalsearchlarge"
            />
            <GooglePlaceAutocomplete
              types={['address']}
              onSelect={onAddressSelect}
              placeholder="Search Address..."
              paddingLeft="38px"
            />
          </Container>
          <Container marginBottom="10px">
            <Label>Street</Label>
            <Input
              {...register('street')}
              marginBottom="5px"
              placeholder="Street"
            />
            <Validator>{errors.street?.message}</Validator>
          </Container>
          <Container marginBottom="10px">
            <Label>Suburb</Label>
            <Controller
              control={control}
              name="suburb"
              render={({ field: { onChange, value } }) => (
                <GooglePlaceAutocomplete
                  types={['(regions)']}
                  value={value}
                  placeholder="Suburb"
                  onChange={e => {
                    setError('suburb', {
                      type: 'notSelected',
                      message: 'You must select a suburb from the suggestions',
                    });
                    onChange(e);
                  }}
                  onSelect={onSuburbSelect}
                />
              )}
            />
            <Validator>{errors.suburb?.message}</Validator>
          </Container>
          <Container
            display="flex"
            margin="0 -5px"
            flexDirection={mobile ? 'column' : 'row'}
          >
            <Container padding="0 5px" flex="1" marginBottom="10px">
              <Label>State</Label>
              <Controller
                control={control}
                name="stateCode"
                render={({ field: { onChange, value } }) => (
                  <Selector
                    value={value}
                    onChange={val => {
                      onChange(val);
                      setShowStates(false);
                    }}
                    onClose={() => setShowStates(false)}
                    data={states}
                    show={showStates}
                    width="100%"
                  >
                    {({ label, ref }) => (
                      <EmptyButton
                        ref={ref}
                        onClick={() => setShowStates(!showStates)}
                        display="flex"
                        alignItems="center"
                        width="100%"
                        padding="0 16px"
                        height="48px"
                        fontSize="16px"
                        borderRadius="7px"
                        backgroundColor="#f8f8f8"
                        whiteSpace="nowrap"
                        cursor="pointer"
                        color="normal"
                        _focus={{
                          border: '1px solid #97AEFF',
                        }}
                      >
                        {label}
                      </EmptyButton>
                    )}
                  </Selector>
                )}
              />
              <Validator>{errors.stateCode?.message}</Validator>
            </Container>
            <Container padding="0 5px" flex="1" marginBottom="15px">
              <Label>Postcode</Label>
              <Input
                {...register('postcode')}
                marginBottom="5px"
                placeholder="Postcode"
              />
              <Validator>{errors.postcode?.message}</Validator>
            </Container>
          </Container>
          <Container display="flex">
            <Container paddingRight="50px">
              <Text
                display="block"
                marginBottom="5px"
                fontSize="14px"
                color="text300"
                whiteSpace="nowrap"
              >
                Hide Address
              </Text>
              <Controller
                control={control}
                name="settings.showAddress"
                render={({ field: { value, onChange } }) => (
                  <Switch checked={!value} onChange={() => onChange(!value)} />
                )}
              />
            </Container>
            <Container display="flex" alignItems="center">
              <Text
                display="block"
                lineHeight="1.3"
                fontSize="12px"
                color="text300"
                fontWeight="600"
              >
                Hide your address and only display{' '}
                <Text color="text500">
                  {watch('suburb') ? watch('suburb') : 'your suburb'}
                </Text>{' '}
                as your location.
              </Text>
            </Container>
          </Container>
        </Container>
        <Container
          flex="1"
          padding="10px"
          minWidth={mobile ? 'unset' : '300px'}
        >
          <Container ref={mapContainerRef} width="100%" height="380px" />
        </Container>
      </Container>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        marginTop="auto"
      >
        <Validator marginBottom="10px">{error?.message}</Validator>
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

LocationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LocationForm;
