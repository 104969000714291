import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Avatar from 'ls-common-client/src/components/Avatar';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import RatingStars from 'ls-common-client/src/components/RatingStars';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import TextReveal from 'ls-common-client/src/components/TextReveal';
import Anchor from 'ls-common-client/src/components/Anchor';
import MoreMenu from '../../../../UI/molecules/MoreMenu';
import Panel from '../../../../UI/atoms/Panel';
import ReviewResponseDialog from './ReviewResponseDialog';
import useReviewResponse from '../../../../../hooks/useReviewResponse';
import imageResizer from '../../../../../services/imageResizer';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';
import useDeleteNotificationSlider from '../../../../../hooks/useDeleteNotificationSlider';

const Review = ({ data, ...props }) => {
  const {
    id,
    displayName,
    user,
    rating,
    createdAt,
    message,
    reviewResponse,
    reviewAssets,
  } = data;
  const { edges: assets } = reviewAssets;
  const { avatar } = user || {};

  const successNotificationSlider = useSuccessNotificationSlider();
  const deleteNotificationSlider = useDeleteNotificationSlider();

  const {
    add: { add, loading: addLoading },
    update: { update, loading: updateLoading },
    remove: { remove, loading: removeLoading },
  } = useReviewResponse();

  const [showMoreMenu, setShowMoreMenu] = useState();
  const [showDialog, setShowDialog] = useState();

  const showAddSuccess = () => {
    successNotificationSlider.open({ heading: 'Your reply was sent.' });
  };

  const showUpdateSuccess = () => {
    successNotificationSlider.open({ heading: 'Your reply was updated.' });
  };

  const showDeleteSuccess = () => {
    deleteNotificationSlider.open({
      heading: 'Your reply was deleted.',
    });
  };

  const onAddSubmit = async values => {
    await add({ reviewId: id, ...values });
    setShowDialog(null);
    showAddSuccess();
  };

  const onUpdateSubmit = async values => {
    await update({ reviewId: id, ...values });
    setShowDialog(null);
    showUpdateSuccess();
  };

  const onRemoveSubmit = async () => {
    await remove({ reviewId: id });
    setShowMoreMenu(false);
    showDeleteSuccess();
  };

  const menuItems = [
    // {
    //   text: 'Report Review',
    //   onClick: () => {},
    //   icon: 'icon-generalalert',
    // },
    {
      text: 'Edit Reply',
      disabled: !reviewResponse,
      onClick: () => setShowDialog('update'),
      icon: 'icon-generaledit',
    },
    {
      text: 'Delete Reply',
      loading: removeLoading,
      disabled: !reviewResponse,
      onClick: onRemoveSubmit,
      icon: 'icon-categorysanitarydisposal',
    },
  ];

  return (
    <>
      <Panel {...props}>
        <Container display="flex" alignItems="center" marginBottom="20px">
          <Container marginRight="10px">
            {avatar ? (
              <Avatar width="38px" height="38px" src={avatar} />
            ) : (
              <Icon fontSize="38px" className="ls-icon icon-avatar" />
            )}
          </Container>
          <Container display="flex" flexDirection="column" marginRight="10px">
            <Text fontSize="18px" fontWeight="bold" lineHeight="1.1">
              {displayName}
            </Text>
            <Container display="flex" flexWrap="wrap">
              <RatingStars marginRight="5px" rating={rating} size="16px" />
              <Text
                fontSize="14px"
                color="text400"
                lineHeight="1"
                whiteSpace="nowrap"
              >
                {moment(createdAt).fromNow()}
              </Text>
            </Container>
          </Container>
          <Container marginLeft="auto" position="relative">
            <MoreMenu
              show={showMoreMenu}
              onClick={() => setShowMoreMenu(true)}
              onClose={() => setShowMoreMenu(false)}
              menuItems={menuItems}
              anchor="topRight"
            />
          </Container>
        </Container>
        <Container marginBottom="15px">
          <TextReveal
            fontSize="16px"
            lineHeight="1.5"
            marginBottom="10px"
            lines={5}
            whiteSpace="pre-wrap"
            wordBreak="break-word"
            renderShowMore={({ onClick, show }) => (
              <EmptyButton onClick={onClick} fontSize="16px" color="text300">
                See {show ? 'less' : 'more'}
                <Icon
                  className={`ls-icon icon-${
                    show ? 'generalcaretupmedium' : 'generalcaretdownmedium'
                  }`}
                  fontSize="10px"
                  marginLeft="8px"
                />
              </EmptyButton>
            )}
          >
            {message}
          </TextReveal>
        </Container>

        {!!assets.length && (
          <Container marginBottom="15px">
            <Container
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
              display="inline-block"
            >
              <Icon
                className="ls-icon icon-categoryphotography"
                marginRight="10px"
                fontSize="17px"
              />
              Image{assets.length > 1 && 's'} attached
            </Container>
            <Container display="flex" flexWrap="wrap" margin="0 -8px">
              {assets.map(({ node }) => (
                <Anchor
                  href={node.url}
                  key={node.id}
                  target="_blank"
                  width="106px"
                  height="106px"
                  margin="8px"
                  borderRadius="14px"
                  overflow="hidden"
                >
                  <Image
                    src={imageResizer.resize(node.url, {
                      width: 212,
                      height: 212,
                    })}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                  />
                </Anchor>
              ))}
            </Container>
          </Container>
        )}

        {reviewResponse?.message ? (
          <Container
            marginBottom="10px"
            padding="20px"
            backgroundColor="#eaefff"
            borderRadius="15px"
          >
            <TextReveal
              lines={3}
              fontSize="16px"
              lineHeight="1.5"
              marginBottom="10px"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
              renderShowMore={({ onClick, show }) => (
                <EmptyButton onClick={onClick} fontSize="16px" color="text300">
                  See {show ? 'less' : 'more'}
                  <Icon
                    className={`ls-icon icon-${
                      show ? 'generalcaretupmedium' : 'generalcaretdownmedium'
                    }`}
                    fontSize="10px"
                    marginLeft="8px"
                  />
                </EmptyButton>
              )}
            >
              {reviewResponse?.message}
            </TextReveal>
          </Container>
        ) : (
          <Container>
            <EmptyButton
              onClick={() => setShowDialog('add')}
              color="text700"
              borderRadius="30px"
              fontSize="14px"
              fontWeight="600"
              padding="5px 10px"
              marginLeft="-10px"
              _hover={{
                backgroundColor: 'background200',
              }}
            >
              Reply as owner
            </EmptyButton>
          </Container>
        )}
      </Panel>
      <ReviewResponseDialog
        message={reviewResponse?.message}
        show={showDialog === 'add'}
        onClose={() => setShowDialog(null)}
        onSubmit={onAddSubmit}
        loading={addLoading}
      />
      <ReviewResponseDialog
        message={reviewResponse?.message}
        show={showDialog === 'update'}
        onClose={() => setShowDialog(null)}
        onSubmit={onUpdateSubmit}
        loading={updateLoading}
      />
    </>
  );
};

Review.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    user: PropTypes.shape({
      avatar: PropTypes.string,
    }),
    rating: PropTypes.number,
    createdAt: PropTypes.string,
    message: PropTypes.string,
    reviewResponse: PropTypes.shape({
      message: PropTypes.string,
    }),
    reviewAssets: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

Review.defaultProps = {
  data: null,
};

export default Review;
