import React from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import ToolTip from '../../../../UI/molecules/ToolTip';

const PrimaryToolTip = ({ children, onClose, ...props }) => (
  <ToolTip
    anchor="topLeft"
    render={() => (
      <>
        <Text
          display="block"
          fontSize="14px"
          fontWeight="600"
          marginBottom="5px"
        >
          Main Action Button
        </Text>
        <Text
          display="block"
          fontSize="14px"
          color="text400"
          lineHeight="1.2"
          marginBottom="5px"
        >
          This contact will appear as your main contact at the bottom of the
          screen on mobile.
        </Text>
        <EmptyButton onClick={onClose} color="text700" fontSize="14px">
          Got it
        </EmptyButton>
      </>
    )}
    popupProps={{
      width: '210px',
      minWidth: 'unset',
      left: '-10px',
      padding: '15px',
    }}
    arrowProps={{
      left: '5px',
    }}
    {...props}
  >
    {children}
  </ToolTip>
);

PrimaryToolTip.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

PrimaryToolTip.defaultProps = {
  children: null,
  onClose: () => {},
};

export default PrimaryToolTip;
