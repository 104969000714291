import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import ProfileDetailsForm from './ProfileDetailsForm';

const ProfileDetailsDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog
      allowTouchMoveQuerySelectors={[
        '.establishedAtSelector',
        '.aboutTextArea',
      ]}
      maxWidth="795px"
      onClose={onClose}
      {...props}
    >
      <FormDialogHeader
        header="Profile Details"
        subHeader="Let's add some basic information to your profile."
        onClose={onClose}
      />
      <ProfileDetailsForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

ProfileDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ProfileDetailsDialog;
