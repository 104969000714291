import React, { useMemo, useContext, useState } from 'react';
import { Outlet, useParams, useLocation, matchPath } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Text from 'ls-common-client/src/components/Text';
import { Context } from '../../../context/AppContext';
import PageHeader from '../../UI/molecules/PageHeader';
import PaidFeatureToolTip from '../../UI/molecules/PaidFeatureToolTip';
import AlertDialog from '../../UI/molecules/AlertDialog';
import config from '../../../config';

const { b2bRoot } = config;

const menuItems = [
  {
    text: 'Logo',
    path: profileId => `/media/${profileId}`,
  },
  {
    text: 'Images',
    path: profileId => `/media/${profileId}/images`,
  },
  {
    text: 'Videos',
    path: profileId => `/media/${profileId}/videos`,
  },
  {
    text: 'Documents',
    path: profileId => `/media/${profileId}/documents`,
  },
];

const Media = props => {
  const { profileId } = useParams();
  const { pathname } = useLocation();
  const {
    media: { mobile },
    profile: { profile },
  } = useContext(Context);

  const { name: packageName } = profile.package || {};

  const [showUnpaidDialog, setShowUnpaidDialog] = useState(!packageName);

  const activeMenuItem = useMemo(
    () =>
      menuItems.find(({ path }) => matchPath(path(profileId), pathname)) || {},
    [profileId, pathname]
  );

  return (
    <>
      <PageHeader>
        <Container display="flex">
          Media {!packageName && <PaidFeatureToolTip marginLeft="10px" />}
        </Container>
      </PageHeader>
      <Container
        padding="0 20px 20px 20px"
        height="100%"
        display="flex"
        flexDirection="column"
        {...props}
      >
        <Container
          display="flex"
          margin="10px 0"
          justifyContent={mobile ? 'space-around' : 'flex-start'}
        >
          {menuItems.map(({ text, path }) => (
            <AnchorLink
              key={text}
              to={path(profileId)}
              fontSize="14px"
              fontWeight="600"
              color="normal"
              padding={mobile ? '7px 10px' : '7px 17px'}
              whiteSpace="nowrap"
              borderRadius="40px"
              backgroundColor={
                activeMenuItem.text === text ? '#ececec' : 'transparent'
              }
            >
              {text}
            </AnchorLink>
          ))}
        </Container>
        <Outlet />
      </Container>
      <AlertDialog
        show={showUnpaidDialog}
        heading="Woah there — this is a paid feature."
        text={
          <>
            <Text display="block" marginBottom="10px">
              Unlock the media feature on your business profile from less than
              $2 a day.
            </Text>
            <Text display="block">
              <Text color="text600" fontWeight="600">
                Please note:
              </Text>{' '}
              While you can upload your media now, it won&apos;t appear until
              you have upgraded.
            </Text>
          </>
        }
        buttons={[
          {
            children: 'OK',
            props: {
              rounded: true,
              onClick: () => setShowUnpaidDialog(false),
            },
          },
          {
            children: 'Upgrade',
            props: {
              primary: true,
              rounded: true,
              onClick: () => {
                window.open(`${b2bRoot}/contact-us`, '_blank');
                setShowUnpaidDialog(false);
              },
            },
          },
        ]}
        maxWidth="370px"
      />
    </>
  );
};

export default Media;
