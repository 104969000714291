import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../../UI/molecules/FormDialogHeader';
import AddVideosForm from './AddVideosForm';

const AddVideosDialog = ({ onClose, onSubmit, ...props }) => {
  return (
    <FormDialog maxWidth="420px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Add Videos"
        subHeader="Add your YouTube or Vimeo link below."
        onClose={onClose}
      />
      <AddVideosForm onSubmit={onSubmit} flex="1" />
    </FormDialog>
  );
};

AddVideosDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddVideosDialog;
