import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import SocialAccountsForm from './SocialAccountsForm';

const SocialAccountsDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="610px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Social Accounts"
        subHeader="Link your business's social media accounts by selecting the relevant platform below."
        onClose={onClose}
      />
      <SocialAccountsForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

SocialAccountsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SocialAccountsDialog;
