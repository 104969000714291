import { create } from '../lib/api';
import config from '../config';

const { b2bApi } = config;

const { fetchApi } = create({ baseUrl: b2bApi });

const getPosts = query => fetchApi('/v1/posts', { query });

export { getPosts };
