import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import Menu from './Menu';

const MoreMenuDesktop = ({ show, ...props }) => {
  if (!show) return null;

  return (
    <Panel
      boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.2)"
      maxWidth="670px"
      position="fixed"
      top="120px"
      left="220px"
      zIndex="10"
      width="670px"
    >
      <Menu {...props} />
    </Panel>
  );
};

MoreMenuDesktop.propTypes = {
  show: PropTypes.bool,
};

MoreMenuDesktop.defaultProps = {
  show: false,
};

export default MoreMenuDesktop;
