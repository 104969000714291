import React, { useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Loader from 'ls-common-client/src/components/Loader';
import Container from 'ls-common-client/src/components/Container';
import Button from 'ls-common-client/src/components/Button';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Cropper from './Cropper';
import { Context } from '../../../../context/AppContext';

// let scrollPos;

const StyledCropper = styled(Cropper)`
  & .cropper-modal {
    background-color: #c6c6c6;
  }
`;

const CropperDialog = ({
  src,
  options,
  onCrop,
  show,
  onClose,
  onReady,
  ...props
}) => {
  const {
    media: { desktop },
  } = useContext(Context);

  const cropperRef = useRef();
  const [loading, setLoading] = useState(true);

  const ready = () => {
    setLoading(false);
    onReady(cropperRef);
  };

  // fix for ios safari to stop the address bar from bouncing around when the user is touching and dragging the crop box
  // useEffect(() => {
  //   if (show) {
  //     scrollPos = document.documentElement.scrollTop;
  //     document.documentElement.style.overflow = 'auto';
  //     document.body.style.overflow = 'auto';
  //   } else {
  //     setLoading(true);
  //     document.documentElement.style.overflow = 'initial';
  //     document.body.style.overflow = 'initial';
  //     window.scrollTo(0, scrollPos);
  //   }
  //   return () => {
  //     document.documentElement.style.overflow = 'initial';
  //     document.body.style.overflow = 'initial';
  //   };
  // }, [show]);

  useEffect(() => {
    if (!show) {
      setLoading(true);
    }
  }, [show]);

  const onClick = async () => {
    onCrop(cropperRef.current.getCroppedCanvas().toDataURL());
    onClose();
  };

  return (
    <Dialog
      header="Edit Image"
      mode={desktop ? 'desktop' : 'mobile'}
      show={show}
      onClose={onClose}
      maxWidth="1000px"
      overflow="hidden"
      render={({ children }) => children}
      {...props}
    >
      <Container
        position="relative"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Container display="flex">
          <Text
            width="120px"
            flex="1 0 auto"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            padding="20px"
            fontSize="18px"
          >
            Edit Image
          </Text>
          <EmptyButton
            onClick={onClose}
            display="flex"
            alignItems="center"
            overflow="hidden"
            padding="20px"
          >
            <Icon
              className="ls-icon icon-generalxlarge"
              color="normal"
              fontSize="20px"
            />
          </EmptyButton>
        </Container>
        {loading && (
          <Container
            position="absolute"
            left="0"
            top="0"
            right="0"
            bottom="0"
            backgroundColor="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="3"
            overflow="hidden"
          >
            <Loader width="170px" />
          </Container>
        )}
        <StyledCropper
          cropperRef={ref => {
            cropperRef.current = ref;
          }}
          src={src}
          imageHeight="calc(100vh - 119px)"
          options={{
            ready,
            background: false,
            ...options,
          }}
          flex="1"
          minHeight="0"
        />
        <Container padding="10px" display="flex" justifyContent="flex-end">
          <Button onClick={onClick} secondary rounded width="auto">
            Done
          </Button>
        </Container>
      </Container>
    </Dialog>
  );
};

CropperDialog.propTypes = {
  src: PropTypes.string,
  options: PropTypes.shape({}),
  onCrop: PropTypes.func,
  onClose: PropTypes.func,
  onReady: PropTypes.func,
  show: PropTypes.bool,
};

CropperDialog.defaultProps = {
  src: null,
  options: {},
  onCrop: () => null,
  onClose: () => null,
  onReady: () => null,
  show: false,
};

export default CropperDialog;
