import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../../../context/AppContext';

const RoleAccessFilter = ({ children, menuItems }) => {
  const {
    user: { hasNoRouteAccess },
  } = useContext(Context);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const noAccess = hasNoRouteAccess(pathname);
    const [{ path } = {}] = menuItems;

    if (noAccess && path) {
      navigate(path);
    }
  }, [pathname, hasNoRouteAccess]);

  return children;
};

RoleAccessFilter.propTypes = {
  children: PropTypes.node,
};

RoleAccessFilter.defaultProps = {
  children: null,
};

export default RoleAccessFilter;
