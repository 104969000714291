import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { reviews as reviewsQuery } from '../graphql/queries';

const useReviews = ({ variables = {} } = {}) => {
  const { profileId } = useParams();

  const {
    data: { reviews } = {},
    previousData: { reviews: previousReviews } = {},
    loading,
    fetchMore,
  } = useQuery(reviewsQuery, {
    errorPolicy: 'all',
    variables: {
      listingId: profileId,
      first: 9,
      reviewState: 'approved',
      ...variables,
    },
  });

  return {
    reviews,
    previousReviews,
    loading,
    fetchMore,
  };
};

export default useReviews;
