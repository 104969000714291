import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'ls-common-client/src/components/Form';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Image from 'ls-common-client/src/components/Image';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import FormDialogSubmit from '../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../context/AppContext';
import afterpay from '../../../../images/paymentMethods/afterpay.png';
import amex from '../../../../images/paymentMethods/amex.png';
import applePay from '../../../../images/paymentMethods/applePay.png';
import bartercard from '../../../../images/paymentMethods/bartercard.png';
import bitcoin from '../../../../images/paymentMethods/bitcoin.png';
import bpay from '../../../../images/paymentMethods/bpay.png';
import bulkBilling from '../../../../images/paymentMethods/bulkBilling.png';
import googlePay from '../../../../images/paymentMethods/googlePay.png';
import hicaps from '../../../../images/paymentMethods/hicaps.png';
import humm from '../../../../images/paymentMethods/humm.png';
import mastercard from '../../../../images/paymentMethods/mastercard.png';
import openpay from '../../../../images/paymentMethods/openpay.png';
import paypal from '../../../../images/paymentMethods/paypal.png';
import visa from '../../../../images/paymentMethods/visa.png';
import zipPay from '../../../../images/paymentMethods/zipPay.png';
import { PAYMENT_TYPES } from '../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';

const {
  ACCOUNT,
  AFTERPAY,
  AMEX,
  APPLE_PAY,
  BARTERCARD,
  BITCOIN,
  BPAY,
  BULK_BILLING,
  CASH,
  CHEQUE,
  DIRECT_DEBIT,
  EFT,
  EFTPOS,
  FULLY_INSURED,
  GIFT_VOUCHER,
  GOOGLE_PAY,
  HICAPS,
  HUMM,
  INTEREST_FREE_PLANS,
  MASTERCARD,
  OPENPAY,
  PAYPAL,
  SENIORS_DISCOUNT,
  VISA,
  ZIP_PAY,
} = PAYMENT_TYPES;

const allPaymentTypes = [
  {
    label: 'Account',
    name: ACCOUNT,
    image: selected => (
      <Icon
        fontSize="23px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-generalwhatsonbrowse"
      />
    ),
  },
  {
    label: 'Afterpay',
    name: AFTERPAY,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={afterpay}
      />
    ),
  },
  {
    label: 'Amex',
    name: AMEX,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={amex}
      />
    ),
  },
  {
    label: 'Apple Pay',
    name: APPLE_PAY,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={applePay}
      />
    ),
  },
  {
    label: 'Bartercard',
    name: BARTERCARD,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={bartercard}
      />
    ),
  },
  {
    label: 'Bitcoin',
    name: BITCOIN,
    image: () => (
      <Image
        maxWidth="55px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={bitcoin}
      />
    ),
  },
  {
    label: 'Bpay',
    name: BPAY,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={bpay}
      />
    ),
  },
  {
    label: 'Bulk Billing',
    name: BULK_BILLING,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={bulkBilling}
      />
    ),
  },
  {
    label: 'Cash',
    name: CASH,
    image: selected => (
      <Icon
        fontSize="23px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-categoryfinancialservices"
      />
    ),
  },
  {
    label: 'Cheque',
    name: CHEQUE,
    image: selected => (
      <Icon
        fontSize="23px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-categoryatmdepositcheque"
      />
    ),
  },
  {
    label: 'Direct Debit',
    name: DIRECT_DEBIT,
    image: selected => (
      <Icon
        fontSize="20px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-generalbook"
      />
    ),
  },
  {
    label: 'EFT',
    name: EFT,
    image: selected => (
      <Icon
        fontSize="18px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-generalsort"
      />
    ),
  },
  {
    label: 'EFTPOS',
    name: EFTPOS,
    image: selected => (
      <Icon
        fontSize="23px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-categorypaymenttypecards"
      />
    ),
  },
  {
    label: 'Fully Insured',
    name: FULLY_INSURED,
    image: selected => (
      <Icon
        fontSize="20px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-categoryinsurance"
      />
    ),
  },
  {
    label: 'Gift Vouchers',
    name: GIFT_VOUCHER,
    image: selected => (
      <Icon
        fontSize="23px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-categoryartsandentertainment"
      />
    ),
  },
  {
    label: 'Google Pay',
    name: GOOGLE_PAY,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={googlePay}
      />
    ),
  },
  {
    label: 'Hicaps',
    name: HICAPS,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={hicaps}
      />
    ),
  },
  {
    label: 'Humm',
    name: HUMM,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={humm}
      />
    ),
  },
  {
    label: 'Interest Free Plans',
    name: INTEREST_FREE_PLANS,
    image: selected => (
      <Icon
        fontSize="20px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-generalquote"
      />
    ),
  },
  {
    label: 'Mastercard',
    name: MASTERCARD,
    image: () => (
      <Image
        maxWidth="45px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={mastercard}
      />
    ),
  },
  {
    label: 'Openpay',
    name: OPENPAY,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={openpay}
      />
    ),
  },
  {
    label: 'PayPal',
    name: PAYPAL,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={paypal}
      />
    ),
  },
  {
    label: 'Seniors Discounts',
    name: SENIORS_DISCOUNT,
    image: selected => (
      <Icon
        fontSize="23px"
        color={selected ? 'text700' : 'text400'}
        className="ls-icon icon-categoryclubs"
      />
    ),
  },
  {
    label: 'Visa',
    name: VISA,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={visa}
      />
    ),
  },
  {
    label: 'Zip Pay',
    name: ZIP_PAY,
    image: () => (
      <Image
        maxWidth="50px"
        width="100%"
        height="100%"
        objectFit="contain"
        src={zipPay}
      />
    ),
  },
];

const PaymentMethodsForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
    media: { device },
  } = useContext(Context);

  const { paymentTypes } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const [isDirty, setIsDirty] = useState();
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState(
    paymentTypes.map(({ name }) => ({ name }))
  );

  const submit = async e => {
    e.preventDefault();
    await update({ paymentTypes: selectedPaymentTypes });
    successNotificationSlider.open();
    onClose();
  };

  const selected = name =>
    selectedPaymentTypes.some(item => item.name === name);

  const onClick = name => {
    setIsDirty(true);
    if (selected(name)) {
      setSelectedPaymentTypes(
        selectedPaymentTypes.filter(item => item.name !== name)
      );
    } else {
      setSelectedPaymentTypes([...selectedPaymentTypes, { name }]);
    }
  };

  return (
    <Form
      onSubmit={submit}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container>
        <Container
          marginBottom="30px"
          display="grid"
          gridTemplateColumns={
            {
              desktop: 'repeat(7, 1fr)',
              tablet: 'repeat(6, 1fr)',
              mobile: 'repeat(3, 1fr)',
            }[device]
          }
          columnGap="12px"
          rowGap="20px"
        >
          {allPaymentTypes.map(({ label, name, image }) => {
            const isSelected = selected(name);
            return (
              <EmptyButton
                key={name}
                onClick={() => onClick(name)}
                width="100"
                display="flex"
                alignItems="center"
                flexDirection="column"
                padding="10px"
                borderRadius="11px"
                position="relative"
                _hover={{
                  backgroundColor: 'background300',
                }}
              >
                {isSelected && (
                  <Container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="20px"
                    height="20px"
                    borderRadius="100%"
                    backgroundColor="#44d7b6"
                    position="absolute"
                    top="2px"
                    right="2px"
                    border="1px solid white"
                  >
                    <Icon
                      className="ls-icon icon-generalticksmall"
                      color="white"
                      fontSize="12px"
                      marginBottom="-2px"
                    />
                  </Container>
                )}
                <Text
                  width="100%"
                  height="42px"
                  boxShadow="0 0 4px 0 rgba(0, 0, 0, 0.15)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="5px"
                  marginBottom="8px"
                  background="white"
                  borderRadius="3px"
                >
                  {image(isSelected)}
                </Text>
                <Text
                  display="block"
                  fontSize="10px"
                  color={isSelected ? 'text700' : 'text400'}
                  lineHeight="1.1"
                >
                  {label}
                </Text>
              </EmptyButton>
            );
          })}
        </Container>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

PaymentMethodsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PaymentMethodsForm;
