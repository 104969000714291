import { useMemo, useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams, matchPath } from 'react-router-dom';
import { user as userQuery } from '../../../graphql/queries';
import config from '../../../config';

const hasRoles = (userRoles, roles) =>
  userRoles.some(({ node: { roleName } }) => roles.includes(roleName));

const { noAccessRoutes } = config;

const useUser = () => {
  const {
    data: { currentUser: user } = {},
    loading,
    refetch,
  } = useQuery(userQuery);

  const { userRoles } = user || {};

  const navigate = useNavigate();
  const { profileId } = useParams();
  const [isAuthorised, setIsAuthorised] = useState();

  const isStaff = useMemo(() => {
    const { edges = [] } = userRoles || {};
    return hasRoles(edges, ['staff']);
  }, [userRoles]);

  const isPersoniv = useMemo(() => {
    const { edges = [] } = userRoles || {};
    return hasRoles(edges, ['personiv']);
  }, [userRoles]);

  useEffect(() => {
    if (!userRoles) {
      return;
    }

    const { edges } = userRoles;
    const accessRoles = ['staff', 'personiv', `L_${profileId}`];

    let authorised = edges.some(({ node }) =>
      accessRoles.includes(node.roleName)
    );

    const profileRole = edges.find(({ node }) => node.roleName.includes('L_'));

    if (!authorised && profileRole) {
      const {
        node: { roleName },
      } = profileRole;

      navigate(roleName.replace('L_', ''));
      authorised = true;
    }

    setIsAuthorised(authorised);
  }, [userRoles, profileId]);

  const hasNoRouteAccess = useCallback(
    route => {
      const { edges = [] } = userRoles || {};

      return noAccessRoutes.some(
        ({ path, noAccessRoles }) =>
          hasRoles(edges, noAccessRoles) && matchPath(path, route)
      );
    },
    [userRoles]
  );

  return {
    user,
    loading,
    refetch,
    isAuthorised,
    isStaff,
    isPersoniv,
    hasNoRouteAccess,
  };
};

export default useUser;
