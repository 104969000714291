import React, { useState, useRef, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import Text from 'ls-common-client/src/components/Text';
import Anchor from 'ls-common-client/src/components/Anchor';
import Input from '../../../../UI/atoms/Input';
import RemoveButton from '../../../../UI/molecules/RemoveButton';
import client from '../../../../../services/client';
import { categorySuggest } from '../../../../../graphql/queries';
import { getPackageGradient } from '../../../../../lib/util';
import ToolTip from '../../../../UI/molecules/ToolTip';

const fetchResults = async query => {
  const { data } = await client.query({
    query: categorySuggest,
    variables: {
      query,
    },
  });

  const {
    categorySuggest: { edges },
  } = data;

  return edges.map(({ node }) => node);
};

const SearchInput = forwardRef(
  (
    { onChange, onSelect, onRemove, value, packageId, index, ...props },
    ref
  ) => {
    const autoSuggestRef = useRef();
    const timeout = useRef();
    const [results, setResults] = useState();
    const [loading, setLoading] = useState();
    const [inputValue, setInputValue] = useState(value);
    const [showToolTip, setShowToolTip] = useState();

    const search = ({ target }) => {
      setInputValue(target.value);
      clearTimeout(timeout.current);

      if (target.value) {
        setLoading(true);
        timeout.current = setTimeout(async () => {
          setResults(await fetchResults(target.value));
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
        setResults(null);
      }
    };

    const onKeyDown = e => {
      const { which } = e;

      if (which === 40) {
        e.preventDefault();
        autoSuggestRef?.current?.firstChild.focus();
      }
    };

    const gradient = useMemo(() => getPackageGradient(packageId), [packageId]);

    return (
      <Container position="relative" {...props}>
        {packageId ? (
          <ToolTip
            show={showToolTip}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            anchor="topLeft"
            marginBottom="5px"
            render={() => (
              <>
                <Text
                  display="block"
                  fontSize="14px"
                  color="normal"
                  fontWeight="600"
                >
                  Category not editable
                </Text>
                <Text
                  color="text400"
                  display="block"
                  fontSize="14px"
                  lineHeight="1.3"
                >
                  To make changes to these categories please contact us on{' '}
                  <Anchor href="tel:1300 360 867">1300 360 867</Anchor>.
                </Text>
              </>
            )}
            width="100%"
            popupProps={{
              width: '200px',
              minWidth: 'unset',
              padding: '15px',
            }}
          >
            <Input disabled ref={ref} value={inputValue} paddingLeft="35px" />
            {gradient && (
              <Icon
                position="absolute"
                left="12px"
                top="50%"
                transform="translateY(-50%)"
                marginRight="8px"
                fontSize="22px"
                className="ls-icon icon-ribbonoutline"
                _before={{
                  backgroundImage: `linear-gradient(${gradient.from}, ${gradient.to})`,
                  backgroundClip: 'text',
                  color: 'transparent',
                }}
              />
            )}
          </ToolTip>
        ) : (
          <Container marginBottom="5px">
            <Input
              ref={ref}
              value={inputValue}
              onChange={e => {
                onChange(e);
                search(e);
              }}
              onKeyDown={onKeyDown}
              placeholder="Search categories"
            />
            <RemoveButton
              onClick={onRemove}
              position="absolute"
              right="-8px"
              top="16px"
            />
          </Container>
        )}
        <AutoSuggest
          ref={autoSuggestRef}
          data={results}
          loading={loading}
          itemDisplayText="name"
          itemKey="id"
          onSelect={selected => {
            onSelect(selected);
            setInputValue(selected.name);
            setResults(null);
          }}
          top="calc(100% + 10px)"
        />
      </Container>
    );
  }
);

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  value: PropTypes.string,
  packageId: PropTypes.string,
  index: PropTypes.number.isRequired,
};

SearchInput.defaultProps = {
  value: '',
  packageId: null,
};

export default SearchInput;
