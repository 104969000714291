import { gql } from '@apollo/client';

const updateListingPost = gql`
  mutation updateListingPost($input: UpdateListingPostInput!) {
    updateListingPost(input: $input) {
      listingPost {
        id
        image
        theme
        title
        description
        url
        email
        phone
        file
        text
        startDate
        endDate
        state
      }
    }
  }
`;

export default updateListingPost;
