import React, { useEffect, useMemo, useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import FancyInput from 'ls-common-client/src/components/FancyInput';
import TextArea from 'ls-common-client/src/components/TextArea';
import Validator from 'ls-common-client/src/components/Validator';
import SelectButton from 'ls-common-client/src/components/SelectButton';
import Button from 'ls-common-client/src/components/Button';
import Switch from 'ls-common-client/src/components/Switch';
import Form from 'ls-common-client/src/components/Form';
import { toE164, validate as validatePhone } from 'ls-common-client/src/phone';
import ImageUploader from './ImageUploader';
import ThemeSelector from './ThemeSelector';
import FileUploader from './FileUploader';
import DatePicker from './DatePicker';
import PostModal from '../../PostModal';
import { LISTING_POST_THEMES } from '../../../../../lib/constants';

const { LS_GRADIENT } = LISTING_POST_THEMES;

const schema = Joi.object({
  image: Joi.string().uri().allow(null),
  theme: Joi.string().allow(null),
  title: Joi.string().min(5).max(75).messages({
    'string.empty': 'Oops, please provide a post title.',
    'string.min': 'Oops! Please provide more detail.',
  }),
  description: Joi.string().allow(''),
  text: Joi.when('actionType', {
    is: Joi.valid(null),
    then: Joi.allow(''),
    otherwise: Joi.string(),
  }).messages({
    'string.empty': 'Oops, you forgot to add your Call-to-Action button text.',
    'string.base': 'Oops, you forgot to add your Call-to-Action button text.',
  }),
  action: Joi.when('text', {
    is: Joi.valid(''),
    then: Joi.allow(null),
    otherwise: Joi.string(),
  })
    .when('actionType', {
      switch: [
        {
          is: Joi.valid('url'),
          then: Joi.string().uri(),
        },
        {
          is: Joi.valid('email'),
          then: Joi.string().email({ tlds: false }),
        },
        {
          is: Joi.valid('phone'),
          then: Joi.string().custom((value, { error }) =>
            validatePhone(value) ? toE164(value) : error('string.phone')
          ),
        },
      ],
    })
    .messages({
      'any.required': 'Please select a Call-to-Action type.',
      'string.base': 'Please select a Call-to-Action type.',
      'string.uri': "Oops, that isn't a valid web address.",
      'string.email': "Oops, that isn't a valid email.",
      'string.phone': "Oops, that isn't a valid phone",
      'string.empty': "Oops, you haven't finished your Call-to-Action.",
    }),
  actionType: Joi.string().allow(null),
  duration: Joi.boolean(),
  startDate: Joi.when('duration', {
    is: Joi.valid(true),
    then: Joi.date().max(Joi.ref('endDate')),
    otherwise: Joi.allow(null),
  }).messages({
    'date.base': 'Oops, you forgot to enter your date.',
    'date.max': 'Oops, your start date must be before your end date',
  }),
  endDate: Joi.when('duration', {
    is: Joi.valid(true),
    then: Joi.date().min(Joi.ref('startDate')),
    otherwise: Joi.allow(null),
  }).messages({
    'date.base': 'Oops, you forgot to enter your date.',
    'date.min': 'Oops, your end date must be after your start date',
  }),
});

const actionTypes = [
  {
    text: 'Web Address',
    value: 'url',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Phone',
    value: 'phone',
  },
  {
    text: 'File',
    value: 'file',
  },
];

const StyledButton = styled(Button)`
  &:hover {
    &:enabled {
      background-color: #eaefff;
      border-color: ${props => props.theme.primary.normal};
    }
  }
`;

const parseValuesOut = values => {
  const {
    actionType,
    action,
    duration,
    text,
    description,
    startDate,
    endDate,
    url,
    email,
    phone,
    file,
    ...restValues
  } = values;

  return {
    ...restValues,
    text: text || null,
    description: description || null,
    startDate: startDate && moment(startDate).startOf('day'),
    endDate: endDate && moment(endDate).endOf('day'),
    url: url || null,
    email: email || null,
    phone: phone || null,
    file: file || null,
    ...(actionType ? { [actionType]: action } : {}),
  };
};

const CreatePostForm = ({
  data,
  onSubmit,
  submitText,
  hideShare,
  onIsDirty,
}) => {
  const { id } = data;

  const [showStartDatePicker, setShowStartDatePicker] = useState();
  const [showEndDatePicker, setShowEndDatePicker] = useState();
  const [showPostModal, setShowPostModal] = useState();
  const [formError, setFormError] = useState();
  const [loading, setLoading] = useState();

  const actionType = useMemo(
    () => actionTypes.map(({ value }) => value).find(value => data[value]),
    [data]
  );

  const {
    register,
    formState: { errors, isDirty, isSubmitted },
    handleSubmit,
    watch,
    getValues,
    setValue,
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      title: data.title || '',
      description: data.description || '',
      text: data.text || '',
      image: data.image || null,
      theme: data.theme || LS_GRADIENT,
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.startDate ? new Date(data.endDate) : null,
      duration: !!data.startDate && !!data.endDate,
      actionType: actionType || null,
      action: data[actionType] || null,
    },
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    if (isDirty) {
      onIsDirty();
    }
  }, [isDirty]);

  const submit = async values => {
    try {
      setLoading(true);
      await onSubmit(parseValuesOut(values));
    } catch (e) {
      setFormError(e);
    } finally {
      setLoading(false);
    }
  };

  const onActionTypeClick = value => {
    setValue('action', '');
    if (value !== 'file') {
      setTimeout(() => {
        setFocus('action');
      });
    }
  };

  const onDurationChange = value => {
    if (value) {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  };

  const previewPost = () => {
    handleSubmit(() => {
      setShowPostModal(true);
    })();
  };

  return (
    <>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit(submit)}>
        <Container marginBottom="30px">
          <Controller
            name="image"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ImageUploader
                onUploadSuccess={url => onChange(url)}
                onRemove={() => onChange(null)}
                value={value}
                marginBottom="20px"
                zIndex="1"
                position="relative"
              />
            )}
          />
          <Controller
            name="theme"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ThemeSelector
                disabled={!!watch('image')}
                onSelected={theme => onChange(theme)}
                value={value}
              />
            )}
          />
        </Container>
        <Container marginBottom="30px">
          <Text fontSize="16px" fontWeight="600">
            Post Title
          </Text>
          <FancyInput
            {...register('title')}
            showClear={false}
            icon={
              <Icon
                fontSize="20px"
                className="ls-icon icon-categorycoachesandtrainers"
                color="#757575"
                marginRight="5px"
                marginBottom="4px"
              />
            }
            placeholder="Tell users what your post is about."
            required
            maxLength={75}
            minLength={5}
          />
          <Validator marginTop="10px">{errors.title?.message}</Validator>
        </Container>
        <Container marginBottom="25px">
          <Text
            display="block"
            fontSize="16px"
            fontWeight="600"
            marginBottom="15px"
          >
            Post Description
          </Text>
          <TextArea
            {...register('description')}
            placeholder="Add up to 600 characters to share event or offer details, new product or updated business information and more!"
            name="description"
            lineHeight="1.2"
            maxLength={600}
          />
          <Container display="flex" justifyContent="flex-end">
            <Text fontSize="12px">{watch('description').length} / 600</Text>
          </Container>
        </Container>
        <Container marginBottom="15px">
          <Text fontSize="16px" fontWeight="600">
            Add a Call-to-Action Button
          </Text>
          <FancyInput
            {...register('text')}
            showClear={false}
            icon={
              <Icon
                fontSize="20px"
                className="ls-icon icon-categorycoachesandtrainers"
                color="#757575"
                marginRight="5px"
                marginBottom="4px"
              />
            }
            placeholder="Button Text"
          />
          <Validator marginTop="10px">{errors.text?.message}</Validator>
        </Container>
        <Container marginBottom="30px">
          <Container display="flex" flexWrap="wrap">
            <Controller
              name="actionType"
              control={control}
              render={({ field: { value, onChange } }) =>
                actionTypes.map(type => (
                  <SelectButton
                    key={type.value}
                    onClick={() => {
                      onChange(type.value === value ? null : type.value);
                      onActionTypeClick(value);
                    }}
                    selected={type.value === value}
                    minWidth="unset"
                    paddingHorizontal="20px"
                  >
                    {type.text}
                  </SelectButton>
                ))
              }
            />
          </Container>

          {watch('actionType') === 'url' && (
            <>
              <FancyInput
                {...register('action')}
                type="url"
                showClear={false}
                icon={
                  <Icon
                    className="ls-icon icon-generalwebsite"
                    color="#757575"
                    fontSize="18px"
                    marginRight="5px"
                    marginBottom="5px"
                  />
                }
                placeholder="Page URL"
              />
              <Validator marginTop="5px">{errors.url?.message}</Validator>
            </>
          )}

          {watch('actionType') === 'email' && (
            <>
              <FancyInput
                {...register('action')}
                type="email"
                showClear={false}
                icon={
                  <Icon
                    className="ls-icon icon-categoryclubs"
                    color="#757575"
                    fontSize="18px"
                    marginRight="5px"
                    marginBottom="5px"
                  />
                }
                placeholder="Send Email To"
              />
              <Validator marginTop="5px">{errors.email?.message}</Validator>
            </>
          )}

          {watch('actionType') === 'phone' && (
            <>
              <FancyInput
                {...register('action')}
                showClear={false}
                icon={
                  <Icon
                    className="ls-icon icon-generalcalllarge"
                    color="#757575"
                    fontSize="20px"
                    marginRight="5px"
                    marginBottom="5px"
                  />
                }
                placeholder="Phone Number"
              />
              <Validator marginTop="5px">{errors.phone?.message}</Validator>
            </>
          )}

          {watch('actionType') === 'file' && (
            <Controller
              name="action"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FileUploader
                  value={value}
                  onUploadSuccess={src => onChange(src)}
                  onRemove={() => onChange(null)}
                  marginTop="10px"
                />
              )}
            />
          )}

          <Validator marginTop="5px">{errors.action?.message}</Validator>
        </Container>

        <Container display="flex" marginBottom="20px" alignItems="center">
          <Controller
            name="duration"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onChange={() => {
                  onChange(!value);
                  onDurationChange(value);
                }}
              />
            )}
          />
          <Text fontSize="16px" marginLeft="10px">
            Add Duration
          </Text>
        </Container>

        {watch('duration') && (
          <Container marginBottom="30px">
            <Container display="flex" alignItems="center">
              <Text fontSize="16px" fontWeight="600" width="110px">
                Start Date
              </Text>
              <EmptyButton
                onClick={() => setShowStartDatePicker(true)}
                display="flex"
                alignItems="center"
                border="1px solid"
                borderColor="border400"
                borderRadius="30px"
                flex="1"
                justifyContent="space-between"
                padding="3px 10px 3px 20px"
                height="40px"
              >
                <Text
                  fontSize="14px"
                  color={getValues('startDate') ? 'text500' : 'text300'}
                >
                  {getValues('startDate')
                    ? moment(getValues('startDate')).format('DD/MM/YYYY')
                    : 'dd/mm/yyyy'}
                </Text>
                <Icon
                  className="ls-icon icon-categoryeventsandweddings"
                  fontSize="20px"
                  color="normal"
                />
              </EmptyButton>
            </Container>
            <Validator marginBottom="10px" textAlign="right" display="block">
              {errors.startDate?.message}
            </Validator>
            <Container display="flex" alignItems="center">
              <Text fontSize="16px" fontWeight="600" width="110px">
                End Date
              </Text>
              <EmptyButton
                onClick={() => setShowEndDatePicker(true)}
                display="flex"
                alignItems="center"
                border="1px solid"
                borderColor="border400"
                borderRadius="30px"
                flex="1"
                justifyContent="space-between"
                padding="3px 10px 3px 20px"
                height="40px"
              >
                <Text
                  fontSize="14px"
                  color={getValues('endDate') ? 'text500' : 'text300'}
                >
                  {getValues('endDate')
                    ? moment(getValues('endDate')).format('DD/MM/YYYY')
                    : 'dd/mm/yyyy'}
                </Text>
                <Icon
                  className="ls-icon icon-categoryeventsandweddings"
                  fontSize="20px"
                  color="normal"
                />
              </EmptyButton>
            </Container>
            <Validator marginBottom="10px" textAlign="right" display="block">
              {errors.endDate?.message}
            </Validator>
          </Container>
        )}
        <Container
          backgroundColor="#ecf7f3"
          borderRadius="9px"
          display="flex"
          padding="18px 15px 18px 10px"
          justifyContent="center"
          alignItems="center"
          marginBottom="20px"
        >
          <Text
            color="#00a699"
            flex="0 0 110px"
            display="flex"
            alignItems="center"
            fontWeight="600"
            fontSize="16px"
          >
            <Icon className="icon-categoryboatlighting" />
            Pro Tip
          </Text>
          <Text
            fontSize="15px"
            fontWeight="600"
            color="#00a699"
            lineHeight="1.3"
          >
            Adding a post duration will allow you to keep your post online for
            an amount of time you specify, such as a week, month or more!
          </Text>
        </Container>
        <Container display="flex" justifyContent="flex-end">
          <Validator
            show={isSubmitted && Object.keys(errors).length}
            margin="15px 0"
          >
            Oops, it looks like you forgot something.
          </Validator>
          <Validator show={formError} margin="15px 0">
            {formError?.message}
          </Validator>
        </Container>
        <Container display="flex" justifyContent="flex-end">
          <StyledButton
            type="button"
            onClick={previewPost}
            border
            rounded
            primary
            width="auto"
            maxWidth="135px"
            flex="1"
          >
            Preview Post
          </StyledButton>
          <Button
            type="submit"
            rounded
            primary
            width="auto"
            marginLeft="20px"
            flex="1"
            maxWidth="185px"
            loading={loading}
            disabled={!isDirty}
          >
            {submitText}
          </Button>
        </Container>
      </Form>
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            show={showStartDatePicker}
            onClose={() => setShowStartDatePicker(false)}
            onDateSelect={date => {
              onChange(date);
              setShowStartDatePicker(false);
            }}
            value={value}
            minDate={new Date()}
          />
        )}
      />
      <Controller
        name="endDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            show={showEndDatePicker}
            onClose={() => setShowEndDatePicker(false)}
            onDateSelect={date => {
              onChange(date);
              setShowEndDatePicker(false);
            }}
            value={value}
            minDate={new Date()}
          />
        )}
      />
      <PostModal
        show={showPostModal}
        onClose={() => setShowPostModal(false)}
        data={[
          {
            node: {
              id: id || 'preview',
              ...data,
              ...getValues(),
              [getValues('actionType')]: getValues('action'),
            },
          },
        ]}
        paused
        hideShare={hideShare}
      />
    </>
  );
};

CreatePostForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    theme: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  hideShare: PropTypes.bool,
  onIsDirty: PropTypes.func,
};

CreatePostForm.defaultProps = {
  data: {
    id: null,
    startDate: null,
    endDate: null,
  },
  onSubmit: () => {},
  onIsDirty: () => {},
  submitText: '',
  hideShare: false,
};

export default CreatePostForm;
