import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import Doughnut from 'ls-common-client/src/components/charts/Doughnut';
import Panel from '../../../UI/atoms/Panel';

const ProfileProgress = ({ dataCompleted, ...props }) => {
  const percentageRef = useRef();

  const chartData = useMemo(() => {
    const maxTotal = Object.values(dataCompleted).length;
    const total = Object.values(dataCompleted).filter(value => value).length;
    const percentage = Math.round((total / maxTotal) * 100);

    let backgroundColor = '#f9c70a';

    if (percentage > 65) {
      backgroundColor = '#83cb79';
    }

    if (percentage < 35) {
      backgroundColor = '#ff585b';
    }

    const datasets = [
      {
        data: [total, maxTotal - total],
        backgroundColor: [backgroundColor, '#ececec'],
        borderRadius: [10, 0],
      },
    ];

    return {
      datasets,
      percentage,
    };
  }, [dataCompleted]);

  const { percentage } = chartData || {};

  useEffect(() => {
    const animatePercentage = () => {
      let amount = 0;

      const interval = setInterval(() => {
        if (amount >= percentage) {
          clearInterval(interval);
        } else {
          amount += 1;
          percentageRef.current.textContent = `${amount}%`;
        }
      }, 10);
    };

    if (percentage) {
      animatePercentage();
    }
  }, [percentage]);

  return (
    <Panel
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      position="relative"
      {...props}
    >
      <Container pointerEvents="none">
        <Doughnut
          width="200px"
          height="200px"
          data={chartData}
          options={{
            borderWidth: 0,
            cutout: '89%',
            animation: { animateRotate: true },
            plugins: {
              tooltip: {
                enabled: false,
              },
            },
          }}
        />
      </Container>
      <Container width="100px" textAlign="center" position="absolute">
        <Text
          ref={percentageRef}
          fontSize="30px"
          color="text500"
          display="block"
          fontWeight="bold"
          lineHeight="1"
          marginBottom="5px"
        />
        <Text fontSize="16px" color="text300" fontWeight="600" lineHeight="1.1">
          Profile Completed
        </Text>
      </Container>
    </Panel>
  );
};

ProfileProgress.propTypes = {
  dataCompleted: PropTypes.shape({}).isRequired,
};

export default ProfileProgress;
