import React, { useContext } from 'react';
import Animation from 'ls-common-client/src/components/Animation';
import { Context } from '../context/AppContext';
import tick from '../animations/tick.json';

const useSuccessNotificationSlider = (options = {}) => {
  const { notificationSlider } = useContext(Context);

  const open = (optionOverrides = {}) => {
    notificationSlider.open({
      icon: (
        <Animation
          width="100px"
          display="flex"
          alignItems="center"
          data={tick}
          options={{
            rendererSettings: {
              preserveAspectRatio: 'none',
            },
          }}
        />
      ),
      ...options,
      ...optionOverrides,
    });
  };

  return {
    open,
  };
};

export default useSuccessNotificationSlider;
