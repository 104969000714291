import { gql } from '@apollo/client';

const quoteListings = gql`
  query quoteListings($listingId: String, $first: Int, $after: String) {
    quoteListings(listingId: $listingId, first: $first, after: $after) {
      edges {
        node {
          id
          quote {
            message
            market
            region
            state
            suburbName
            requiredBy
            quoteQuoteServices {
              edges {
                node {
                  id
                  quoteServiceName
                }
              }
            }
            quoteContact {
              name
              email
              phone
            }
            quoteAssets {
              edges {
                node {
                  id
                  url
                }
              }
            }
            user {
              avatar
              displayName
            }
          }
          quoteListingResponse {
            message
          }
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default quoteListings;
