import React from 'react';
import Text from 'ls-common-client/src/components/Text';
import PropTypes from 'prop-types';

const Validator = ({ children, ...props }) => {
  if (!children) {
    return null;
  }
  return (
    <Text
      color="#ff585b"
      fontSize="12px"
      lineHeight="1.1"
      display="block"
      {...props}
    >
      {children}
    </Text>
  );
};

Validator.propTypes = {
  children: PropTypes.node,
};

Validator.defaultProps = {
  children: null,
};

export default Validator;
