import { gql } from '@apollo/client';

const setProfileContacts = gql`
  mutation setProfileContacts($input: SetProfileContactsInput) {
    setProfileContacts(input: $input) {
      contacts {
        type
        label
        value
        primary
      }
    }
  }
`;

export default setProfileContacts;
