import React from 'react';
import styled from 'styled-components';
import Icon from 'ls-common-client/src/components/Icon';

const StyledIcon = styled(Icon)`
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: radial-gradient(circle at 4% 39%, #9f7433, #f5dc54 86%);
`;

const Ribbon = props => (
  <StyledIcon className="ls-icon icon-ribbon" fontSize="16px" {...props} />
);

export default Ribbon;
