import React, { useMemo, useContext, useState } from 'react';
import cuid from 'cuid';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import PageHeader from '../../UI/molecules/PageHeader';
import useEnquiries from '../../../hooks/useEnquiries';
import Enquiry from './Enquiry';
import { Context } from '../../../context/AppContext';
import noEnquiries from '../../../images/noEnquiries.svg';

const Enquiries = props => {
  const [fetchMoreLoading, setFetchMoreLoading] = useState();

  const { enquiries, previousEnquiries, loading, fetchMore } = useEnquiries();
  const { edges = [], pageInfo: { endCursor, hasNextPage } = {} } =
    enquiries || {};

  const {
    media: { device },
  } = useContext(Context);

  const columns = useMemo(() => {
    if (!edges.length) {
      return null;
    }

    const numberOfColumns = {
      mobile: 1,
      tablet: 2,
      desktop: 3,
    }[device];

    const cols = [...Array(numberOfColumns)].map(() => ({
      id: cuid(),
      column: [],
    }));

    edges.forEach((edge, i) => {
      const {
        node: { id },
      } = edge;

      const iteration = i % numberOfColumns;

      if (cols[iteration].column.length) {
        cols[iteration].column.push(edge);
      } else {
        cols[iteration] = { id, column: [edge] };
      }
    });

    return cols;
  }, [edges, device]);

  const onLoadMoreClick = async () => {
    setFetchMoreLoading(true);
    await fetchMore({ variables: { after: endCursor } });
    setFetchMoreLoading(false);
  };

  if (loading && !previousEnquiries) {
    return (
      <>
        <PageHeader>Enquiries</PageHeader>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
          width="100%"
          height="100%"
          {...props}
        >
          <Loader width="200px" />
        </Container>
      </>
    );
  }

  if (!columns && !loading) {
    return (
      <>
        <PageHeader>Enquiries</PageHeader>
        <Container padding="20px" height="100%" {...props}>
          <Container
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Container>
              <Image width="100%" src={noEnquiries} />
            </Container>
            <Text
              fontSize="25px"
              fontWeight="bold"
              lineHeight="1"
              textAlign="center"
            >
              You have no enquiries yet
            </Text>
          </Container>
        </Container>
      </>
    );
  }

  return (
    <>
      <PageHeader>Enquiries</PageHeader>
      <Container padding="0 20px 20px 20px" height="100%" {...props}>
        {loading ? (
          <Container
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="20px"
            width="100%"
            height="100%"
            {...props}
          >
            <Loader width="200px" />
          </Container>
        ) : (
          <Container display="flex" flexWrap="wrap" margin="0 -10px 30px -10px">
            {columns.map(({ id, column }) => (
              <Container
                key={id}
                display="flex"
                flexDirection="column"
                flex="1"
              >
                {column.map(({ node }) => (
                  <Enquiry key={node.id} data={node} />
                ))}
              </Container>
            ))}
          </Container>
        )}
        {hasNextPage && (
          <Container
            display="flex"
            justifyContent="center"
            position="relative"
            minHeight="50px"
          >
            {fetchMoreLoading ? (
              <Loader
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%,-50%)"
                width="150px"
              />
            ) : (
              <EmptyButton
                backgroundColor="white"
                border={theme => `1px solid ${theme.border.border300}`}
                height="35px"
                padding="0 18px"
                borderRadius="30px"
                fontSize="14px"
                fontWeight="600"
                color="normal"
                onClick={onLoadMoreClick}
              >
                Load More
              </EmptyButton>
            )}
          </Container>
        )}
      </Container>
    </>
  );
};

export default Enquiries;
