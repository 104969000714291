import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import LocationForm from './LocationForm';

const LocationDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="840px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Primary Location"
        subHeader="Let people know where you're situated."
        onClose={onClose}
      />
      <LocationForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

LocationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LocationDialog;
