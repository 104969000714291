import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import cuid from 'cuid';
import moment from 'moment';
import PropTypes from 'prop-types';
import Form from 'ls-common-client/src/components/Form';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Switch from 'ls-common-client/src/components/Switch';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Validator from '../../../../UI/atoms/Validator';
import SelectButton from '../../../../UI/atoms/SelectButton';
import { Context } from '../../../../../context/AppContext';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import TypeSelector from './TypeSelector';
import TimeSelector from './TimeSelector';
import {
  DAYS_OF_THE_WEEK,
  TRADING_HOURS_TYPES,
} from '../../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } =
  DAYS_OF_THE_WEEK;

const { OPEN, CLOSED, TWENTY_FOUR_HOURS, OPEN_BY_APPOINTMENT } =
  TRADING_HOURS_TYPES;

const times = (() => {
  const am = [];
  const pm = [];
  [...Array(12)].forEach((item, i) => {
    const hour = i === 0 ? 12 : i;
    am.push({ label: `${hour}:00 am`, value: `${i}:00` });
    am.push({ label: `${hour}:15 am`, value: `${i}:15` });
    am.push({ label: `${hour}:30 am`, value: `${i}:30` });
    am.push({ label: `${hour}:45 am`, value: `${i}:45` });
    pm.push({ label: `${hour}:00 pm`, value: `${i + 12}:00` });
    pm.push({ label: `${hour}:15 pm`, value: `${i + 12}:15` });
    pm.push({ label: `${hour}:30 pm`, value: `${i + 12}:30` });
    pm.push({ label: `${hour}:45 pm`, value: `${i + 12}:45` });
  });
  return [
    { label: '--:--', value: '' },
    { label: '24 hrs', value: TWENTY_FOUR_HOURS },
    ...am,
    ...pm,
  ];
})();

const additionalIntervalTimes = times.filter(
  ({ value }) => value !== TWENTY_FOUR_HOURS
);

const types = [
  {
    value: OPEN,
    label: 'Open',
    borderColor: '#d3f0ed',
    focusBorderColor: '#00a699',
    color: '#00a699',
  },
  {
    value: TWENTY_FOUR_HOURS,
    label: '24 hrs',
    borderColor: '#d3f0ed',
    focusBorderColor: '#00a699',
    color: '#00a699',
  },
  {
    value: OPEN_BY_APPOINTMENT,
    label: 'By Appointment',
    borderColor: '#d3f0ed',
    focusBorderColor: '#00a699',
    color: '#00a699',
  },
  {
    value: CLOSED,
    label: 'Closed',
    borderColor: '#ffd8d6',
    focusBorderColor: '#f16159',
    color: '#f16159',
  },
];

const additionalIntervalTypes = types.filter(
  ({ value }) => ![TWENTY_FOUR_HOURS, CLOSED].includes(value)
);

const days = [
  { value: MONDAY, label: 'Mon', fullLabel: 'Monday' },
  { value: TUESDAY, label: 'Tue', fullLabel: 'Tuesday' },
  { value: WEDNESDAY, label: 'Wed', fullLabel: 'Wednesday' },
  { value: THURSDAY, label: 'Thu', fullLabel: 'Thursday' },
  { value: FRIDAY, label: 'Fri', fullLabel: 'Friday' },
  { value: SATURDAY, label: 'Sat', fullLabel: 'Saturday' },
  { value: SUNDAY, label: 'Sun', fullLabel: 'Sunday' },
];

const minutesInDay = 1440;
const isOpen = type => type !== CLOSED;
const isClosed = type => type === CLOSED;
const is24hrs = time => time === TWENTY_FOUR_HOURS;

const timeToMinutes = time => moment.duration(time).asMinutes();

const getInterval = (startTime, endTime) => {
  let interval;
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);

  if (endMinutes > startMinutes) {
    interval = endMinutes - startMinutes;
  } else {
    const minutesToEndOfDay = minutesInDay - startMinutes;
    interval = minutesToEndOfDay + endMinutes;
  }

  return interval;
};

const dayHasOverlap = intervals =>
  intervals.some(({ key, startTime, endTime }) => {
    if (!startTime || !endTime) {
      return false;
    }
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = getInterval(startTime, endTime) + startMinutes;
    const otherIntervals = intervals.filter(interval => interval.key !== key);

    return otherIntervals.some(other => {
      if (!other.startTime || !other.endTime) {
        return false;
      }
      const otherStartMinutes = timeToMinutes(other.startTime);
      return otherStartMinutes > startMinutes && otherStartMinutes < endMinutes;
    });
  });

const getOverlappingDays = tradingHours => {
  const overlappingDays = days.reduce((acc, { value, fullLabel }) => {
    const intervals = tradingHours.filter(({ day }) => value === day);

    if (dayHasOverlap(intervals)) {
      acc.push(fullLabel);
    }

    return acc;
  }, []);

  if (overlappingDays.length > 1) {
    const lastDay = overlappingDays.pop();
    return `${overlappingDays.join(', ')} and ${lastDay}`;
  }

  return overlappingDays.join();
};

const schema = Joi.object({
  tradingHours: Joi.array()
    .items(
      Joi.object({
        day: Joi.number(),
        type: Joi.string(),
        startTime: Joi.string().allow(null).allow(''),
        endTime: Joi.string().allow(null).allow(''),
        startTimeShow: Joi.bool().optional(),
        endTimeShow: Joi.bool().optional(),
        key: Joi.string(),
      }).custom((hours, { message }) => {
        const { startTime, endTime, type } = hours;

        if (isOpen(type) && !is24hrs(startTime) && !endTime) {
          return message('You forgot your closing time');
        }

        if (isOpen(type) && !is24hrs(startTime) && !startTime) {
          return message('You forgot your opening time');
        }

        return hours;
      })
    )
    .custom((tradingHours, { message }) => {
      const overlappingDays = getOverlappingDays(tradingHours);

      if (overlappingDays) {
        return message(`Your times on ${overlappingDays} are overlapping.`);
      }

      return tradingHours;
    }),
});

const getDayLabel = day => {
  const { label } = days.find(({ value }) => day === value);
  return label;
};

const parseTime = time => {
  if (typeof time !== 'number') return null;
  const milliseconds = time * 60 * 1000;
  return moment.utc(milliseconds).format('H:mm');
};

const parseIntervals = intervals =>
  intervals.map(interval => {
    const { start, interval: duration, day } = interval;
    let { type } = interval;
    const is24Hours = !start && duration === minutesInDay;

    if (type !== OPEN_BY_APPOINTMENT) {
      type = OPEN;
    }

    if (is24Hours) {
      type = TWENTY_FOUR_HOURS;
    }

    return {
      day,
      type,
      startTime: is24Hours ? TWENTY_FOUR_HOURS : parseTime(start),
      endTime: is24Hours ? null : parseTime(start + duration),
      key: cuid(),
    };
  });

const parseTradingHoursIn = tradingHours =>
  days.reduce((acc, { value: day }) => {
    const intervals = tradingHours.filter(interval => day === interval.day);
    const parsed = parseIntervals(intervals);

    if (parsed.length) {
      return [...acc, ...parsed];
    }

    return [
      ...acc,
      { day, startTime: null, endTime: null, type: CLOSED, key: cuid() },
    ];
  }, []);

const parseTradingHoursOut = tradingHours => {
  const hours = [];

  tradingHours.forEach(({ day, type, startTime, endTime }) => {
    if (isOpen(type)) {
      hours.push({
        day,
        type: is24hrs(type) ? OPEN : type,
        start: timeToMinutes(startTime),
        interval: getInterval(startTime, endTime),
      });
    }
  });

  return hours;
};

const TradingHoursForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
    media: { mobile },
  } = useContext(Context);

  const { tradingHours } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const [sameHours, setSameHours] = useState(true);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      tradingHours: parseTradingHoursIn(tradingHours),
    },
    resolver: joiResolver(schema),
  });

  const {
    fields,
    remove,
    insert,
    update: updateField,
  } = useFieldArray({
    control,
    name: 'tradingHours',
  });

  const allValues = useMemo(
    () => fields.find(({ type }) => isOpen(type)),
    [fields]
  );

  const {
    startTime: startTimeAll,
    endTime: endTimeAll,
    type: typeAll,
    startTimeShow: startTimeAllShow,
    endTimeShow: endTimeAllShow,
  } = allValues || fields[0];

  useEffect(() => {
    if (tradingHours.length === 1) {
      setSameHours(false);
      return;
    }

    setSameHours(
      tradingHours.every(({ start, interval, type }, i) => {
        if (i === 0) return true;

        const {
          start: prevStart,
          interval: prevInterval,
          type: prevType,
        } = tradingHours[i - 1];

        return (
          start === prevStart && interval === prevInterval && type === prevType
        );
      })
    );
  }, [tradingHours]);

  const setAllDays = values => {
    getValues('tradingHours').forEach((field, i) => {
      updateField(i, {
        ...field,
        ...values,
      });
    });
  };

  const setAllSelectedDays = (values, selectedDays = []) => {
    getValues('tradingHours').forEach((field, i) => {
      const { type, day } = field;

      if (selectedDays.includes(day) || isOpen(type)) {
        updateField(i, {
          ...field,
          ...values,
        });
      }
    });
  };

  const submit = async input => {
    await update({
      tradingHours: parseTradingHoursOut(input.tradingHours),
    });
    successNotificationSlider.open();
    onClose();
  };

  const removeIntervals = day => {
    let foundFirst = false;

    const indexes = getValues('tradingHours').reduce((acc, field, i) => {
      if (day === field.day && foundFirst) {
        return [...acc, i];
      }

      foundFirst = day === field.day;

      return acc;
    }, []);

    remove(indexes);
  };

  const removeAllIntervals = () => {
    const values = getValues('tradingHours');

    const indexes = values.reduce((acc, field, i) => {
      const prev = values[i - 1] || {};
      const isInterval = prev.day === field.day;
      if (isInterval) {
        return [...acc, i];
      }
      return acc;
    }, []);

    remove(indexes);
  };

  const onSameHoursChange = () => {
    if (!sameHours) {
      removeAllIntervals();
      setAllSelectedDays({
        startTime: startTimeAll,
        endTime: endTimeAll,
        type: typeAll,
      });
    }
    setSameHours(!sameHours);
  };

  const onDayAllClick = i => {
    const { day, type, key } = getValues(`tradingHours.${i}`);

    if (isOpen(type)) {
      updateField(i, {
        key,
        day,
        startTime: null,
        endTime: null,
        type: CLOSED,
      });
      return;
    }

    updateField(i, {
      key,
      day,
      startTime: startTimeAll,
      endTime: endTimeAll,
      type: OPEN,
      startTimeShow: !allValues,
    });
  };

  const onStartTimeAllClick = () => {
    setAllDays({
      startTimeShow: !startTimeAllShow,
    });
  };

  const onEndTimeAllClick = () => {
    setAllDays({
      endTimeShow: !endTimeAllShow,
    });
  };

  const onStartTimeAllClose = () => {
    setAllDays({
      startTimeShow: false,
    });
  };

  const onEndTimeAllClose = () => {
    setAllDays({
      endTimeShow: false,
    });
  };

  const onStartTimeAllChange = (startTime, ref) => {
    const selectedDays = isClosed(typeAll)
      ? [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY]
      : undefined;

    let type = typeAll;

    if (is24hrs(startTime)) {
      type = TWENTY_FOUR_HOURS;
    }

    if ((isClosed(typeAll) || is24hrs(typeAll)) && !is24hrs(startTime)) {
      type = OPEN;
    }

    setAllSelectedDays(
      {
        type,
        startTime,
        endTime: is24hrs(startTime) ? null : endTimeAll,
        endTimeShow: is24hrs(startTime) ? false : !endTimeAll,
      },
      selectedDays
    );

    onStartTimeAllClose();

    if (endTimeAll && ref) {
      ref.current.focus();
    }
  };

  const onEndTimeAllChange = (endTime, ref) => {
    const selectedDays = isClosed(typeAll)
      ? [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY]
      : undefined;

    let type = typeAll;

    if (is24hrs(endTime)) {
      type = TWENTY_FOUR_HOURS;
    }

    if (isClosed(typeAll) && !is24hrs(endTime)) {
      type = OPEN;
    }

    setAllSelectedDays(
      {
        type,
        endTime: is24hrs(endTime) ? null : endTime,
        startTime: is24hrs(endTime) ? endTime : startTimeAll,
        startTimeShow: !startTimeAll && !is24hrs(endTime),
      },
      selectedDays
    );

    onEndTimeAllClose();

    if (startTimeAll && ref) {
      ref.current.focus();
    }
  };

  const onTypeAllChange = (type, ref) => {
    const selectedDays = isClosed(typeAll)
      ? [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY]
      : undefined;

    let startTime = startTimeAll;
    let endTime = endTimeAll;

    if (is24hrs(type)) {
      startTime = TWENTY_FOUR_HOURS;
      endTime = null;
    }

    if (isClosed(type)) {
      startTime = null;
      endTime = null;
    }

    if (is24hrs(typeAll) && !is24hrs(type)) {
      startTime = null;
    }

    setAllSelectedDays(
      {
        startTime,
        endTime,
        type,
        startTimeShow: isOpen(type) && !is24hrs(type) && !startTime,
      },
      selectedDays
    );

    ref.current.focus();
  };

  const onDayClick = i => {
    const { type, day, ...values } = getValues(`tradingHours.${i}`);

    if (isOpen(type)) {
      removeIntervals(day);
    }

    updateField(i, {
      ...values,
      day,
      type: CLOSED,
      startTime: null,
      endTime: null,
      startTimeShow: isClosed(type),
    });
  };

  const onTypeChange = (type, ref, i) => {
    const values = getValues(`tradingHours.${i}`);
    const { day, type: prevType } = values;
    let { startTime, endTime } = values;
    let startTimeShow = false;

    if (isClosed(type)) {
      removeIntervals(day);
      startTime = null;
      endTime = null;
    }

    if (is24hrs(type)) {
      startTime = TWENTY_FOUR_HOURS;
      endTime = null;
      removeIntervals(day);
    }

    if (
      (is24hrs(prevType) || isClosed(prevType)) &&
      isOpen(type) &&
      !is24hrs(type)
    ) {
      startTimeShow = true;
      startTime = null;
    }

    updateField(i, {
      ...values,
      day,
      startTime,
      endTime,
      type,
      startTimeShow,
    });

    ref.current.focus();
  };

  const onStartTimeClick = i => {
    const values = getValues(`tradingHours.${i}`);
    const { startTimeShow } = values;

    updateField(i, {
      ...values,
      startTimeShow: !startTimeShow,
    });
  };

  const onEndTimeClick = i => {
    const values = getValues(`tradingHours.${i}`);
    const { endTimeShow } = values;

    updateField(i, {
      ...values,
      endTimeShow: !endTimeShow,
    });
  };

  const onStartTimeClose = i => {
    const values = getValues(`tradingHours.${i}`);

    updateField(i, {
      ...values,
      startTimeShow: false,
    });
  };

  const onEndTimeClose = i => {
    const values = getValues(`tradingHours.${i}`);

    updateField(i, {
      ...values,
      endTimeShow: false,
    });
  };

  const onStartTimeChange = (startTime, ref, i) => {
    const values = getValues(`tradingHours.${i}`);
    const { endTime, day, startTime: prevStartTime } = values;
    let { type } = values;

    if ((is24hrs(prevStartTime) && !is24hrs(startTime)) || isClosed(type)) {
      type = OPEN;
    }

    if (is24hrs(startTime)) {
      type = TWENTY_FOUR_HOURS;
      removeIntervals(day);
    }

    updateField(i, {
      ...values,
      type,
      startTime,
      endTime: is24hrs(startTime) ? null : endTime,
      endTimeShow: !endTime,
    });

    if (endTime && ref) {
      ref.current.focus();
    }
  };

  const onEndTimeChange = (endTime, ref, i) => {
    const values = getValues(`tradingHours.${i}`);
    const { startTime, day } = values;
    let { type } = values;

    if (isClosed(type)) {
      type = OPEN;
    }

    if (is24hrs(endTime)) {
      type = TWENTY_FOUR_HOURS;
      removeIntervals(day);
    }

    updateField(i, {
      ...values,
      type,
      startTime: is24hrs(endTime) ? TWENTY_FOUR_HOURS : startTime,
      endTime: is24hrs(endTime) ? null : endTime,
      startTimeShow: !startTime && !is24hrs(endTime),
    });

    if (startTime && ref) {
      ref.current.focus();
    }
  };

  const isAdditionalInterval = i => {
    const field = fields[i];
    const prevField = fields[i - 1] || {};

    return field.day === prevField.day;
  };

  const addInterval = i => {
    const { day } = getValues(`tradingHours.${i}`);
    const intervals = fields.filter(interval => interval.day === day);

    if (intervals.length > 4) {
      return;
    }

    insert(i + intervals.length, {
      day,
      type: OPEN,
      startTimeShow: true,
      key: cuid(),
    });
  };

  const onIntervalClick = i => {
    if (isAdditionalInterval(i)) {
      remove(i);
    } else {
      addInterval(i);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      noValidate
      display="flex"
      flexDirection="column"
      {...props}
    >
      <Container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="25px"
      >
        <Container display="flex" alignItems="center">
          <Switch checked={sameHours} onChange={onSameHoursChange} />
          <Text
            marginLeft="10px"
            fontSize={mobile ? '16px' : '14px'}
            color="text300"
            lineHeight="1.2"
          >
            Same hours
          </Text>
        </Container>
      </Container>

      {sameHours ? (
        <>
          <Container
            display="flex"
            justifyContent={mobile ? 'flex-start' : 'space-between'}
            flexWrap="wrap"
            marginBottom="30px"
          >
            {fields.map(({ type, day, key }, i) => (
              <Container marginBottom="5px" padding="0 4px" key={key}>
                <SelectButton
                  selected={isOpen(type)}
                  onClick={() => onDayAllClick(i)}
                  fontSize={mobile ? '15px' : '14px'}
                >
                  {getDayLabel(day)}
                </SelectButton>
              </Container>
            ))}
          </Container>

          <Container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <TimeSelector
              data={times}
              onChange={onStartTimeAllChange}
              onClick={onStartTimeAllClick}
              onClose={onStartTimeAllClose}
              show={startTimeAllShow}
              value={startTimeAll || ''}
              error={!!errors?.tradingHours?.[0] && !startTimeAll}
              flex="2"
            />
            <Container flex="1" display="flex" justifyContent="center">
              <Container
                backgroundColor="background600"
                width="18px"
                height="2px"
                margin="0 10px"
                visibility={is24hrs(startTimeAll) ? 'hidden' : 'visible'}
              />
            </Container>
            <TimeSelector
              data={times}
              onChange={onEndTimeAllChange}
              onClick={onEndTimeAllClick}
              onClose={onEndTimeAllClose}
              show={endTimeAllShow}
              value={endTimeAll || ''}
              error={!!errors?.tradingHours?.[0] && !endTimeAll}
              flex="2"
              visibility={is24hrs(startTimeAll) ? 'hidden' : 'visible'}
            />
            <Container marginLeft="auto" paddingLeft="10px">
              <TypeSelector
                data={types}
                onChange={(type, ref) => onTypeAllChange(type, ref)}
                value={typeAll}
              />
            </Container>
          </Container>
        </>
      ) : (
        fields.map(
          (
            { key, day, startTime, startTimeShow, endTime, endTimeShow, type },
            i
          ) => (
            <Container
              key={key}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="20px"
            >
              <Container flex="0 0 70px">
                {!isAdditionalInterval(i) && (
                  <SelectButton
                    onClick={() => onDayClick(i)}
                    selected={isOpen(type)}
                  >
                    {getDayLabel(day)}
                  </SelectButton>
                )}
              </Container>
              <TimeSelector
                data={isAdditionalInterval(i) ? additionalIntervalTimes : times}
                onChange={(value, ref) => onStartTimeChange(value, ref, i)}
                onClick={() => onStartTimeClick(i)}
                onClose={() => onStartTimeClose(i)}
                show={startTimeShow}
                error={!!errors?.tradingHours?.[i] && !startTime}
                value={startTime || ''}
                flex="2"
              />
              <Container flex="1" display="flex" justifyContent="center">
                <Container
                  backgroundColor="background600"
                  width={mobile ? '12px' : '18px'}
                  height="2px"
                  visibility={is24hrs(startTime) ? 'hidden' : 'visible'}
                />
              </Container>
              <TimeSelector
                data={isAdditionalInterval(i) ? additionalIntervalTimes : times}
                onChange={(value, ref) => onEndTimeChange(value, ref, i)}
                onClick={() => onEndTimeClick(i)}
                onClose={() => onEndTimeClose(i)}
                show={endTimeShow}
                error={!!errors?.tradingHours?.[i] && !endTime}
                visibility={is24hrs(startTime) ? 'hidden' : 'visible'}
                value={endTime || ''}
                flex="2"
              />
              <Container
                flex={mobile ? '2' : '0 0 135px'}
                maxWidth="135px"
                marginLeft="auto"
                paddingLeft={mobile ? '5px' : '10px'}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                minWidth="0"
              >
                <TypeSelector
                  data={
                    isAdditionalInterval(i) ? additionalIntervalTypes : types
                  }
                  onChange={(value, ref) => onTypeChange(value, ref, i)}
                  value={type}
                  maxWidth="90px"
                  minWidth="0"
                />
                <EmptyButton
                  onClick={() => onIntervalClick(i)}
                  position="relative"
                  marginLeft={mobile ? '5px' : '10px'}
                  width={mobile ? '22px' : '18px'}
                  height={mobile ? '22px' : '18px'}
                  borderRadius="100%"
                  border={theme => `1px solid ${theme.border.border300}`}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  visibility={
                    isClosed(type) || is24hrs(startTime) ? 'hidden' : 'visible'
                  }
                  _focus={{
                    border: theme => `1px solid ${theme.border.border400}`,
                  }}
                >
                  {!isAdditionalInterval(i) && (
                    <Container
                      width="2px"
                      height="8px"
                      backgroundColor="background600"
                    />
                  )}
                  <Container
                    width="2px"
                    height="8px"
                    top="50%"
                    marginTop="-4px"
                    backgroundColor="background600"
                    position="absolute"
                    transform="rotate(90deg)"
                  />
                </EmptyButton>
              </Container>
            </Container>
          )
        )
      )}
      <Validator marginBottom="15px">{errors?.tradingHours?.message}</Validator>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

TradingHoursForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TradingHoursForm;
