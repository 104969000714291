import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Form from 'ls-common-client/src/components/Form';
import Validator from '../../../UI/atoms/Validator';
import Label from '../../../UI/atoms/Label';
import Input from '../../../UI/atoms/Input';
import TextArea from '../../../UI/atoms/TextArea';
import Selector from '../../../UI/molecules/Selector';
import RemoveButton from '../../../UI/molecules/RemoveButton';
import FormDialogSubmit from '../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../context/AppContext';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';

const schema = Joi.object({
  displayName: Joi.string().required().messages({
    'string.empty': 'Business Name is required',
  }),
  about: Joi.string().allow(''),
  establishedAt: Joi.string().allow(''),
  frequentlyAskedQuestions: Joi.array().items(
    Joi.object({
      question: Joi.string().required().messages({
        'string.empty': 'You forgot your question',
      }),
      answer: Joi.string().required().messages({
        'string.empty': 'You forgot your answer',
      }),
    })
  ),
});

const years = (() => {
  const yearsArray = [{ label: 'Year Established', value: '' }];
  let year = new Date().getFullYear();

  while (year > 1800) {
    yearsArray.push({
      label: year.toString(),
      value: year.toString(),
    });
    year -= 1;
  }

  return yearsArray;
})();

const ProfileDetailsForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
    media: { mobile },
  } = useContext(Context);

  const [showEstablishedAt, setShowEstablishedAt] = useState();

  const { displayName, establishedAt, about, frequentlyAskedQuestions } =
    profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    control,
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      displayName,
      establishedAt: establishedAt ? moment(establishedAt).format('Y') : '',
      about: about || '',
      frequentlyAskedQuestions,
    },
    resolver: joiResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'frequentlyAskedQuestions',
  });

  const addFaq = () => {
    append({
      question: '',
      answer: '',
    });
  };

  const removeFaq = i => {
    remove(i);
  };

  const submit = async input => {
    await update({
      ...input,
      about: input.about || null,
      establishedAt: input.establishedAt
        ? new Date(`${input.establishedAt}-01-01`)
        : null,
    });
    successNotificationSlider.open();
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      noValidate
      display="flex"
      flexDirection="column"
      {...props}
    >
      <Container
        display="flex"
        flexDirection={mobile ? 'column' : 'row'}
        margin="0 -15px"
      >
        <Container flex="1" padding="0 15px">
          <Container marginBottom="15px">
            <Label>Business Name</Label>
            <Input
              {...register('displayName')}
              marginBottom="5px"
              placeholder="Business Name*"
            />
            <Validator>{errors.displayName?.message}</Validator>
          </Container>
          <Container marginBottom="15px">
            <Label marginBottom="10px">Year Established</Label>
            <Container position="relative" width="100%">
              <Controller
                name="establishedAt"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Selector
                    onChange={(val, ref) => {
                      ref.current.focus();
                      onChange(val);
                    }}
                    onClose={() => setShowEstablishedAt(false)}
                    value={value}
                    data={years}
                    show={showEstablishedAt}
                    width="100%"
                    popupProps={{
                      maxHeight: '250px',
                      // required for Dialog component to allow touch/scroll on ios devices... class name is used to target the element
                      className: 'establishedAtSelector',
                    }}
                  >
                    {({ label, ref }) => (
                      <EmptyButton
                        ref={ref}
                        backgroundColor="#f8f8f8"
                        border="none"
                        borderRadius="7px"
                        fontSize="16px"
                        fontFamily="Circular"
                        height="48px"
                        padding="0 16px"
                        width="100%"
                        color={!value ? 'text300' : 'normal'}
                        textAlign="left"
                        onClick={() => setShowEstablishedAt(!showEstablishedAt)}
                        _focus={{
                          border: '1px solid #97AEFF',
                        }}
                      >
                        {label}
                      </EmptyButton>
                    )}
                  </Selector>
                )}
              />
              <Icon
                position="absolute"
                right="10px"
                top="13px"
                fontSize="20px"
                pointerEvents="none"
                className="ls-icon icon-categoryeventsandweddings"
              />
            </Container>
          </Container>
          <Container>
            <Label>About</Label>
            <TextArea
              {...register('about')}
              placeholder="About"
              maxLength={1000}
              height="300px"
              resize="none"
              // required for Dialog component to allow touch/scroll on ios devices... class name is used to target the element
              className="aboutTextArea"
            />
            <Text display="block" textAlign="right" fontSize="12px">
              {watch('about').length} / 1000
            </Text>
          </Container>
        </Container>
        <Container
          flex="1"
          padding="0 15px"
          display="flex"
          flexDirection="column"
        >
          <Text
            fontWeight="600"
            lineHeight="1.2"
            display="block"
            marginBottom="20px"
          >
            Frequently Asked Questions
          </Text>
          <Container marginBottom="20px">
            {fields.map(({ id }, i) => (
              <Container key={id} marginBottom="20px">
                <Container marginBottom="5px" position="relative">
                  <RemoveButton
                    onClick={() => removeFaq(i)}
                    position="absolute"
                    right="-8px"
                    top="16px"
                  />
                  <Input
                    {...register(`frequentlyAskedQuestions.${i}.question`)}
                    placeholder={`Question ${i + 1}`}
                  />
                  <Validator>
                    {errors.frequentlyAskedQuestions?.[i]?.question?.message}
                  </Validator>
                </Container>
                <Container>
                  <Input
                    {...register(`frequentlyAskedQuestions.${i}.answer`)}
                    placeholder="Answer"
                  />
                  <Validator>
                    {errors.frequentlyAskedQuestions?.[i]?.answer?.message}
                  </Validator>
                </Container>
              </Container>
            ))}
            <Container>
              <EmptyButton
                onClick={addFaq}
                color="text700"
                fontWeight="600"
                fontSize="14px"
              >
                {fields.length ? 'Add another' : 'Add FAQ'}
              </EmptyButton>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

ProfileDetailsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ProfileDetailsForm;
