import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import Animation from 'ls-common-client/src/components/Animation';
import imageResizer from '../../../services/imageResizer';
import Validator from '../../UI/atoms/Validator';
import DragDropScreen from '../../UI/molecules/DragDropScreen';
import MediaPanel from '../../UI/molecules/MediaPanel';
import uploader from '../../../services/uploader';
import { Context } from '../../../context/AppContext';
import uploading from '../../../animations/uploading.json';
import mediaBackground from '../../../images/mediaBackground.svg';
import { MEDIA_TYPES } from '../../../lib/constants';
import useSuccessNotificationSlider from '../../../hooks/useSuccessNotificationSlider';
import useDeleteNotificationSlider from '../../../hooks/useDeleteNotificationSlider';

const { DOCUMENT } = MEDIA_TYPES;

const validateFile = ({ type, size }) => {
  const maxSize = 1024 * 1024 * 10;
  let message;

  if (size > maxSize) {
    message =
      'Oops, your file size is too big. Please try again ensuring the file is a maximum of 10MB.';
  }

  if (!type.includes('pdf')) {
    message =
      "Oops, you've selected an invalid file format. You can try again with a pdf. ";
  }

  if (message) {
    throw new Error(message);
  }
};

const getOtherMedia = media =>
  media
    .filter(({ type }) => type !== DOCUMENT)
    .map(({ url, type, background, description }) => ({
      url,
      type,
      background,
      description,
    }));

const validateFiles = files =>
  [...files].reduce((acc, file) => {
    const errorMessage = validateFile(file);
    return errorMessage || acc;
  }, null);

const uploadFiles = files =>
  Promise.all([...files].map(file => uploader.upload(file)));

const Documents = () => {
  const {
    media: { mobile, desktop },
    profile: {
      profile,
      update: { update },
    },
  } = useContext(Context);

  const { media } = profile;

  const successNotificationSlider = useSuccessNotificationSlider();
  const deleteNotificationSlider = useDeleteNotificationSlider();

  const documents = useMemo(
    () =>
      media
        .filter(({ type }) => type === DOCUMENT)
        .map(({ url, description, type, background }) => ({
          url,
          description,
          type,
          background,
        })),
    [media]
  );

  const [showDragDrop, setShowDragDrop] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [uploadNewDocumentIndex, setUploadNewDocumentIndex] = useState();

  const fileUploadRef = useRef();

  const showUploadSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your document upload was successful!',
    });
  };

  const showDescriptionSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your document description was successfully updated!',
    });
  };

  const showDeleteSuccess = () => {
    deleteNotificationSlider.open({
      heading: 'Your document has been deleted.',
    });
  };

  const onDragEnter = () => {
    setShowDragDrop(true);
  };

  const onDragLeave = () => {
    setShowDragDrop(false);
  };

  const onSelected = async files => {
    setShowDragDrop(false);
    setLoading(true);

    try {
      validateFiles(files);
      const urls = await uploadFiles(files);
      const newMedia = urls.map(url => ({
        url,
        type: DOCUMENT,
        background: false,
        description: null,
      }));
      const existingMedia = media.map(
        ({ url, type, background, description }) => ({
          url,
          type,
          background,
          description,
        })
      );
      await update({
        media: [...newMedia, ...existingMedia],
      });
      setError(null);
      showUploadSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onUploadNewDocument = i => {
    setUploadNewDocumentIndex(i);
    fileUploadRef.current.click();
  };

  const onUploadNewDocumentSelected = async files => {
    const [file] = files;

    setLoading(true);

    try {
      validateFile(file);

      const url = await uploader.upload(file);

      const documentMedia = documents.map((document, index) =>
        uploadNewDocumentIndex === index
          ? {
              url,
              type: document.type,
              background: document.background,
              description: document.description,
            }
          : document
      );

      const otherMedia = getOtherMedia(media);

      await update({ media: [...documentMedia, ...otherMedia] });
      setError(null);
      showUploadSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async ({ description }, i) => {
    setLoading(true);

    const documentMedia = documents.map((document, index) =>
      i === index ? { ...document, description: description || null } : document
    );

    const otherMedia = getOtherMedia(media);

    try {
      await update({ media: [...documentMedia, ...otherMedia] });
      setError(null);
      showDescriptionSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async i => {
    const documentMedia = documents.filter((document, index) => i !== index);
    const otherMedia = getOtherMedia(media);

    setLoading(true);

    try {
      await update({ media: [...documentMedia, ...otherMedia] });
      setError(null);
      showDeleteSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
    };
  }, []);

  return (
    <>
      <FileUpload
        accept=".pdf"
        ref={fileUploadRef}
        onSelected={onUploadNewDocumentSelected}
        display="none"
      />

      {error && !mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {((!documents.length && !mobile) || mobile) && (
        <Container
          flex={mobile ? '0 0 190px' : '1'}
          background={mobile ? `url(${mediaBackground}) no-repeat` : 'none'}
          margin="0 -20px 30px -20px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundSize="cover"
          backgroundPosition="bottom"
          position="relative"
        >
          <Container
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Text
              fontSize="25px"
              color="normal"
              fontWeight="bold"
              marginBottom="15px"
            >
              Upload Documents
            </Text>
            <Text
              fontSize="16px"
              color="text400"
              marginBottom="25px"
              maxWidth="280px"
            >
              These documents appear on your profile page
            </Text>
            <FileUpload
              onSelected={onSelected}
              position={mobile ? 'absolute' : 'relative'}
              bottom={mobile ? '-20px' : 0}
              accept=".pdf"
              fontSize="16px"
              color="text700"
              fontWeight="600"
              boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
              backgroundColor="white"
              borderRadius="30px"
              height="40px"
              padding="0 25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              multiple
            >
              <Icon
                className="ls-icon icon-pluscircle"
                marginRight="10px"
                fontSize="20px"
              />
              Upload File
            </FileUpload>
          </Container>
        </Container>
      )}

      {error && mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {!!documents.length && (
        <Container display="flex" margin="0 -10px" flexWrap="wrap">
          {!mobile && (
            <Container flex={desktop ? '0 0 25%' : '0 0 50%'} display="flex">
              <FileUpload
                onSelected={onSelected}
                accept=".pdf"
                flex="1"
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="transparent"
                border={theme => `3px dashed ${theme.border.border300}`}
                borderRadius="20px"
                margin="10px"
                multiple
              >
                <Container
                  fontSize="16px"
                  color="text700"
                  fontWeight="600"
                  boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
                  backgroundColor="white"
                  borderRadius="30px"
                  height="40px"
                  padding="0 25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position={mobile ? 'absolute' : 'relative'}
                  bottom={mobile ? '-15px' : '0'}
                >
                  <Icon
                    className="ls-icon icon-pluscircle"
                    marginRight="10px"
                    fontSize="20px"
                  />
                  Upload File
                </Container>
              </FileUpload>
            </Container>
          )}

          {documents.map((document, i) => (
            <Container
              key={document.url}
              flex={desktop ? '0 0 25%' : '0 0 50%'}
            >
              <MediaPanel
                type="Document"
                data={document}
                onUpload={() => onUploadNewDocument(i)}
                onSubmit={values => onSubmit(values, i)}
                onDelete={() => onDelete(i)}
                anchor={mobile && i % 2 === 0 ? 'topLeft' : 'topRight'}
                imgSrc={url =>
                  imageResizer.resize(url, {
                    width: '420px',
                    height: '420px',
                  })
                }
              />
            </Container>
          ))}
        </Container>
      )}

      <DragDropScreen
        onSelected={onSelected}
        onDragLeave={onDragLeave}
        accept=".pdf"
        pointerEvents={showDragDrop ? 'default' : 'none'}
        opacity={showDragDrop ? 1 : 0}
        transition="opacity 0.3s ease"
        position="fixed"
        left="0"
        top="0"
        width="100%"
        height="100%"
        zIndex="999"
      />

      {loading && (
        <Container
          position="fixed"
          left="0"
          top="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.3)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          padding="0 30px"
        >
          <Animation
            width="450px"
            display="flex"
            alignItems="center"
            data={uploading}
            options={{
              rendererSettings: {
                preserveAspectRatio: 'none',
              },
            }}
          />
        </Container>
      )}
    </>
  );
};

export default Documents;
