import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'ls-common-client/src/components/Anchor';
import FormDialog from '../../../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../../../UI/molecules/FormDialogHeader';
import ReviewResponseForm from './ReviewResponseForm';

const ReviewResponseDialog = ({
  onClose,
  onSubmit,
  message,
  loading,
  ...props
}) => {
  return (
    <FormDialog onClose={onClose} maxWidth="420px" {...props}>
      <FormDialogHeader
        header="Reply as Owner"
        subHeader={
          <>
            Check out our{' '}
            <Anchor
              href="https://business.localsearch.com.au/content-integrity/"
              target="_blank"
            >
              review guidelines
            </Anchor>{' '}
            for more information about review regulations in Australia.
          </>
        }
        onClose={onClose}
      />
      <ReviewResponseForm
        message={message}
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </FormDialog>
  );
};

ReviewResponseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  loading: PropTypes.bool,
};

ReviewResponseDialog.defaultProps = {
  message: null,
  loading: false,
};

export default ReviewResponseDialog;
