import { gql } from '@apollo/client';

const setProfileProducts = gql`
  mutation setProfileProducts($input: SetProfileProductsInput) {
    setProfileProducts(input: $input) {
      products {
        name
        type
        highlight
      }
    }
  }
`;

export default setProfileProducts;
