import { gql } from '@apollo/client';

const createListingPost = gql`
  mutation createListingPost($input: CreateListingPostInput!) {
    createListingPost(input: $input) {
      listingPost {
        id
        image
        theme
        title
        description
        url
        email
        phone
        file
        text
        startDate
        endDate
        state
      }
    }
  }
`;

export default createListingPost;
