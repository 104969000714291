import React, { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Anchor from 'ls-common-client/src/components/Anchor';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Image from 'ls-common-client/src/components/Image';
import { Context } from '../../../../context/AppContext';
import ProfileSelector from './ProfileSelector';
import ProfileSearch from './ProfileSearch';
import MoreMenu from './MoreMenu';
import SettingsDialog from './SettingsDialog';
import InvitationDialog from './InvitationDialog';
import Ribbon from '../../../UI/atoms/Ribbon';
import appStore from '../../../../images/appStore.png';
import googlePlay from '../../../../images/googlePlay.png';
import businessProfileLogo from '../../../../images/businessProfileLogo.svg';
import config from '../../../../config';

const { b2bRoot } = config;

const StyledIcon = styled(Icon)`
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: radial-gradient(circle at 4% 39%, #ecb000, #ffca00 86%);
`;

const SideBar = ({ menuItems, ...props }) => {
  const {
    media: { desktop },
    user: { isStaff, isPersoniv },
    profile: { profile },
    sideBar,
  } = useContext(Context);

  const { name: packageName } = profile?.package || {};

  const { pathname } = useLocation();

  const [showSettingsDialog, setShowSettingsDialog] = useState();
  const [showInvitationDialog, setShowInvitationDialog] = useState();
  const [showMoreMenu, setShowMoreMenu] = useState();

  const activeMenuItem = useMemo(
    () => menuItems.find(({ match }) => matchPath(match, pathname)),
    [pathname, menuItems]
  );

  return (
    <>
      <Container
        position={desktop ? 'sticky' : 'fixed'}
        top="0"
        height="100vh"
        width={desktop ? '210px' : '310px'}
        zIndex={showSettingsDialog && desktop ? '100' : '10'}
        transition="transform 0.3s ease"
        transform={
          sideBar.show || desktop ? 'translateX(0)' : 'translateX(-350px)'
        }
        {...props}
      >
        <Container
          backgroundColor="white"
          borderRadius="0 27px 27px 0"
          boxShadow="0 2px 20px 3px rgba(0, 0, 0, 0.1)"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Container padding={desktop ? '15px 15px 0 15px' : '15px 0 0 15px'}>
            <Container
              display="flex"
              justifyContent="flex-end"
              borderBottom={theme => `1px solid ${theme.border.border300}`}
            >
              <Container
                width="100%"
                marginBottom="7px"
                borderRight={
                  desktop
                    ? 'none'
                    : theme => `1px solid ${theme.border.border300}`
                }
              >
                {isStaff || isPersoniv ? (
                  <ProfileSearch marginRight={desktop ? '0' : '10px'} />
                ) : (
                  <ProfileSelector />
                )}
              </Container>
              {!desktop && (
                <EmptyButton
                  onClick={sideBar.close}
                  padding="10px 20px"
                  marginBottom="7px"
                  color="normal"
                >
                  <Icon
                    fontSize="15px"
                    className="ls-icon icon-generalxlarge"
                  />
                </EmptyButton>
              )}
            </Container>
          </Container>
          <Container
            display="flex"
            flexDirection="column"
            overflowY="auto"
            height="100%"
          >
            <Container
              padding={desktop ? '10px 0 10px 10px' : '10px 0 10px 20px'}
              margin="10px 0"
            >
              {menuItems.map(({ text, icon, path }) => {
                const active = text === activeMenuItem?.text;
                return (
                  <AnchorLink
                    onClick={sideBar.close}
                    to={path}
                    key={text}
                    display="flex"
                    alignItems="center"
                    padding="12px"
                    borderRadius="50px 0 0 50px"
                    transition="all ease 0.3s"
                    fontSize={desktop ? '14px' : '16px'}
                    color={
                      active && !showSettingsDialog ? 'text700' : 'text300'
                    }
                    backgroundColor={
                      active && !showSettingsDialog ? 'white' : 'transparent'
                    }
                    boxShadow={
                      active && !showSettingsDialog
                        ? '-20px 0 20px 0 #ececec'
                        : 'none'
                    }
                    _hover={{
                      backgroundColor:
                        active && !showSettingsDialog
                          ? 'none'
                          : 'background300',
                    }}
                  >
                    <Text
                      width="25px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      marginRight="5px"
                    >
                      {icon}
                    </Text>
                    <Text fontWeight="600">{text}</Text>
                  </AnchorLink>
                );
              })}

              {profile && (
                <EmptyButton
                  onClick={() => setShowSettingsDialog(true)}
                  display="flex"
                  width="100%"
                  alignItems="center"
                  padding="12px"
                  borderRadius="50px 0 0 50px"
                  transition="all ease 0.3s"
                  fontSize={desktop ? '14px' : '16px'}
                  color={showSettingsDialog ? 'text700' : 'text300'}
                  backgroundColor={showSettingsDialog ? 'white' : 'transparent'}
                  boxShadow={
                    showSettingsDialog ? '-20px 0 20px 0 #ececec' : 'none'
                  }
                  _hover={{
                    backgroundColor: showSettingsDialog
                      ? 'none'
                      : 'background300',
                  }}
                >
                  <Text
                    width="25px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginRight="5px"
                  >
                    <Icon className="ls-icon icon-cog" />
                  </Text>
                  <Text fontWeight="600">Settings</Text>
                </EmptyButton>
              )}

              <ClickOutside
                onClickOutside={
                  desktop ? () => setShowMoreMenu(false) : undefined
                }
                display="flex"
              >
                <EmptyButton
                  onClick={() => {
                    setShowSettingsDialog(false);
                    setShowMoreMenu(!showMoreMenu);
                  }}
                  display="flex"
                  flex="1"
                  alignItems="center"
                  fontWeight="600"
                  padding="12px 12px 12px 43px"
                  borderRadius="50px 0 0 50px"
                  fontSize={desktop ? '14px' : '16px'}
                  backgroundColor={showMoreMenu ? 'background300' : 'none'}
                  color={showMoreMenu ? 'text700' : 'text300'}
                  _hover={{
                    backgroundColor: 'background300',
                  }}
                >
                  More
                  <Icon
                    fontSize="10px"
                    marginLeft="8px"
                    marginBottom="-3px"
                    className={`ls-icon ${
                      showMoreMenu
                        ? 'icon-generalcaretleftmedium'
                        : 'icon-generalcaretrightmedium'
                    }`}
                  />
                </EmptyButton>
                <MoreMenu
                  show={showMoreMenu}
                  onClose={() => setShowMoreMenu(false)}
                />
              </ClickOutside>
            </Container>
            <Container padding="0 15px" width="100%" marginBottom="18px">
              <Container height="1px" backgroundColor="background400" />
            </Container>
            {isStaff && (
              <Container
                padding="0 30px"
                display="flex"
                justifyContent="center"
              >
                <EmptyButton
                  onClick={() => setShowInvitationDialog(true)}
                  backgroundColor="background300"
                  padding="8px 33px"
                  borderRadius="20px"
                  fontSize="14px"
                  color="normal"
                  fontWeight="600"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StyledIcon
                    className="ls-icon icon-envelope"
                    fontSize="12px"
                    marginRight="7px"
                  />
                  <Text>Invite</Text>
                </EmptyButton>
              </Container>
            )}
            {!isStaff && !packageName && (
              <Container
                textAlign="center"
                lineHeight="1"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                marginBottom="20px"
                padding="0 30px"
                fontSize="14px"
              >
                <Text fontSize="14px" marginBottom="15px">
                  Access exclusive tools and insights
                </Text>
                <Anchor
                  href={`${b2bRoot}/contact-us`}
                  target="_blank"
                  borderRadius="50px"
                  fontWeight="600"
                  backgroundColor="background300"
                  color="normal"
                  height="32px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin="0 10px"
                  fontSize={desktop ? '14px' : '16px'}
                  _hover={{
                    color: theme => theme.text.text700,
                  }}
                >
                  <Ribbon fontSize="16px" marginRight="8px" />
                  Upgrade
                </Anchor>
              </Container>
            )}
            <Container
              padding={desktop ? '30px' : '30px 80px'}
              marginTop="auto"
            >
              <Anchor
                href="https://itunes.apple.com/au/app/localsearch/id1471245997"
                target="_blank"
                marginBottom="5px"
                display="block"
              >
                <Image width="100%" src={appStore} />
              </Anchor>
              <Anchor
                href="https://play.google.com/store/apps/details?id=com.ls.localsearch"
                target="_blank"
                marginBottom="28px"
                display="block"
              >
                <Image width="100%" src={googlePlay} />
              </Anchor>
              <AnchorLink to="/" marginBottom="20px">
                <Image width="100%" src={businessProfileLogo} />
              </AnchorLink>
            </Container>
          </Container>
        </Container>
      </Container>
      {showMoreMenu && desktop && (
        <Container
          position="fixed"
          backgroundColor="rgba(0,0,0,0.3)"
          left="0"
          top="0"
          width="100%"
          height="100%"
          zIndex="9"
        />
      )}
      <SettingsDialog
        show={showSettingsDialog}
        onClose={() => setShowSettingsDialog(false)}
      />
      <InvitationDialog
        show={showInvitationDialog}
        onClose={() => setShowInvitationDialog(false)}
      />
    </>
  );
};

SideBar.propTypes = {
  pageTitle: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SideBar.defaultProps = {
  pageTitle: '',
};

export default SideBar;
