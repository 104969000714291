import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Calendar from 'ls-common-client/src/components/Calendar';

const DatePicker = ({ show, onClose, ...props }) => (
  <Dialog show={show} maxWidth="330px" clickOutside onClose={onClose}>
    <Container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="20px 20px 10px 20px"
    >
      <Text fontSize="16px" fontWeight="bold">
        Select a date
      </Text>
      <EmptyButton onClick={onClose}>
        <Icon
          className="ls-icon icon-generalxlarge"
          color="normal"
          marginTop="5px"
        />
      </EmptyButton>
    </Container>
    <Container padding="10px">
      <Calendar {...props} />
    </Container>
  </Dialog>
);

DatePicker.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  show: false,
};

export default DatePicker;
