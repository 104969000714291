import React from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import noProfile from '../../../images/noProfile.svg';
import PageHeader from '../../UI/molecules/PageHeader';

const NoProfile = () => (
  <Container display="flex" flexDirection="column" height="100%">
    <PageHeader />
    <Container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      padding="20px"
      flex="1"
    >
      <Container>
        <Image src={noProfile} width="100%" />
      </Container>
      <Text
        fontSize="25px"
        fontWeight="bold"
        lineHeight="1.2"
        textAlign="center"
      >
        Please select a business to start.
      </Text>
    </Container>
  </Container>
);

export default NoProfile;
