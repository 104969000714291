import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Icon from 'ls-common-client/src/components/Icon';
import TruncateText from 'ls-common-client/src/components/TruncateText';
import Panel from '../../UI/atoms/Panel';
import MoreMenu from '../../UI/molecules/MoreMenu';
import themes from '../../../images/listingPostThemes';

const Post = ({ data, menuItems, ...props }) => {
  const { image, theme, title, description, startDate, endDate, text } = data;

  const [showMoreMenu, setShowMoreMenu] = useState();

  const actionType = useMemo(() => {
    const actions = ['email', 'phone', 'url', 'file'];
    return actions.find(action => data[action]);
  }, [data]);

  return (
    <Panel display="flex" flexDirection="column" {...props}>
      <Container position="relative">
        <MoreMenu
          show={showMoreMenu}
          onClick={e => {
            e.stopPropagation();
            setShowMoreMenu(true);
          }}
          onClose={() => setShowMoreMenu(false)}
          menuItems={menuItems}
          anchor="topRight"
          position="absolute"
          right="0"
          top="0"
        />
        {image ? (
          <Container
            height="135px"
            borderRadius="20px"
            overflow="hidden"
            margin="-8px -8px 20px -8px"
          >
            <Image objectFit="cover" width="100%" height="100%" src={image} />
          </Container>
        ) : (
          <Container marginBottom="10px">
            <Image
              src={themes[theme] || themes.lsGradient}
              width="38px"
              height="38px"
              borderRadius="100%"
            />
          </Container>
        )}
      </Container>
      {!!startDate && !!endDate && (
        <Container
          display="flex"
          justifyContent="flex-start"
          marginBottom="10px"
        >
          <Container
            borderRadius="20px"
            color="text700"
            backgroundColor="#eaefff"
            fontWeight="600"
            fontSize="14px"
            padding="5px 20px"
          >
            Valid {moment(startDate).format('D MMM YYYY')} -{' '}
            {moment(endDate).format('D MMM YYYY')}
          </Container>
        </Container>
      )}
      {title && (
        <Text
          fontSize="16px"
          fontWeight="600"
          lineHeight="1.2"
          marginBottom="10px"
        >
          {title}
        </Text>
      )}
      {description && (
        <TruncateText
          lines="5"
          fontSize="14px"
          lineHeight="1.35"
          color="text300"
          whiteSpace="pre-wrap"
          wordBreak="break-word"
        >
          {description}
        </TruncateText>
      )}
      {!!actionType && (
        <Container>
          <Text
            display="flex"
            alignItems="center"
            fontSize="14px"
            marginTop="10px"
            lineHeight="1.2"
            color="text700"
            target="_blank"
          >
            {text}{' '}
            <Icon
              marginLeft="8px"
              marginBottom="-2px"
              fontSize="10px"
              className="ls-icon icon-generalcaretrightmedium"
            />
          </Text>
        </Container>
      )}
    </Panel>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    theme: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    text: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Post;
