import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { isTouch } from 'ls-common-client/src/util';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Anchor from 'ls-common-client/src/components/Anchor';
import { formatLS } from 'ls-common-client/src/phone';
import Panel from '../../UI/atoms/Panel';
import { Context } from '../../../context/AppContext';
import ContactInformationDialog from '../Info/ContactInformationDialog';
import LocationDialog from '../Info/LocationDialog';
import imageResizer from '../../../services/imageResizer';
import { addressJoin, createProfileUrl } from '../../../lib/util';
import ToolTip from '../../UI/molecules/ToolTip';
import PaidFeatureToolTip from '../../UI/molecules/PaidFeatureToolTip';

const StyledContainer = styled(Container)`
  opacity: 0;
  transition: all ease 0.3s;
`;

const StyledAnchorLink = styled(AnchorLink)`
  &:hover ${StyledContainer} {
    opacity: 1;
  }
`;

const ProfileInfo = props => {
  const {
    profile: { profile = {} },
  } = useContext(Context);

  const {
    logo,
    contacts,
    street,
    suburb,
    stateCode,
    postcode,
    updatedAt,
    vanityUrlPath,
  } = profile;

  const { name: packageName } = profile.package || {};

  const [showLocationDialog, setShowLocationDialog] = useState();
  const [showContactInformationDialog, setShowContactInformationDialog] =
    useState();
  const [showToolTip, setShowToolTip] = useState();

  const address = useMemo(
    () => addressJoin(street, suburb, stateCode, postcode),
    [street, suburb, stateCode, postcode]
  );

  const { profileId } = useParams();

  const { phone, url, email } = useMemo(() => {
    if (!contacts) {
      return {};
    }

    return contacts.reduce((acc, { type, value }) => {
      if (!acc[type]) {
        return {
          ...acc,
          [type]: value,
        };
      }
      return acc;
    }, {});
  }, [contacts]);

  return (
    <>
      <Panel display="flex" flexDirection="column" {...props}>
        <Container>
          <Container
            display="flex"
            marginBottom="15px"
            justifyContent="space-between"
          >
            <Text fontSize="16px" fontWeight="600">
              Profile Info
            </Text>
            <ToolTip
              show={isTouch() ? false : showToolTip}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
              popupProps={{
                width: '100px',
              }}
              render={() => (
                <Text
                  fontSize="12px"
                  color="text400"
                  textAlign="center"
                  display="block"
                  fontWeight="600"
                >
                  View Profile
                </Text>
              )}
            >
              <Anchor
                href={createProfileUrl(vanityUrlPath)}
                target="_blank"
                display="flex"
                borderRadius="100%"
                width="35px"
                height="35px"
                alignItems="center"
                justifyContent="center"
                color="normal"
                margin="-8px -8px 0 0"
                _hover={{
                  backgroundColor: 'background300',
                  color: 'text700',
                }}
              >
                <Icon fontSize="15px" className="ls-icon icon-external-link" />
              </Anchor>
            </ToolTip>
          </Container>
          <Container display="flex" marginBottom="20px">
            <Container marginRight="15px">
              <Container
                width="112px"
                height="112px"
                borderRadius="7px"
                border={theme => `1px solid ${theme.border.border300}`}
                position="relative"
              >
                <StyledAnchorLink
                  to={`/media/${profileId}`}
                  position="relative"
                  fontSize="14px"
                  color="text300"
                  width="100%"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  overflow="hidden"
                >
                  {logo ? (
                    <Image
                      src={imageResizer.resize(logo, {
                        width: '230px',
                        height: '230px',
                      })}
                      width="100%"
                      height="100%"
                      objectFit="contain"
                    />
                  ) : (
                    <>
                      <Icon
                        fontSize="22px"
                        className="ls-icon icon-categoryphotography"
                        marginBottom="5px"
                      />
                      <Text display="block" fontWeight="600">
                        Upload logo
                      </Text>
                    </>
                  )}
                  <StyledContainer
                    position="absolute"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Container
                      background="white"
                      padding="6px 22px"
                      color="text700"
                      fontWeight="600"
                      boxShadow="0 0 15px 0 rgba(0, 29, 125, 0.16)"
                      borderRadius="30px"
                    >
                      Edit
                    </Container>
                  </StyledContainer>
                </StyledAnchorLink>
                {!packageName && (
                  <PaidFeatureToolTip
                    showOnLoad
                    position="absolute"
                    right="5px"
                    top="7px"
                  />
                )}
              </Container>
            </Container>
            <Container flex="1">
              <Container
                marginBottom="15px"
                display="flex"
                fontSize="14px"
                wordBreak="break-word"
                lineHeight="1.1"
              >
                <Container flex="0 0 25px">
                  <Icon
                    fontSize="17px"
                    className="ls-icon icon-generalcalllarge"
                  />
                </Container>
                {phone ? (
                  formatLS(phone)
                ) : (
                  <EmptyButton
                    onClick={() => setShowContactInformationDialog(true)}
                    color="text300"
                    textAlign="left"
                    fontSize="14px"
                  >
                    Add phone
                  </EmptyButton>
                )}
              </Container>

              <Container
                marginBottom="15px"
                display="flex"
                fontSize="14px"
                wordBreak="break-word"
                lineHeight="1.1"
              >
                <Container flex="0 0 25px">
                  <Icon
                    fontSize="17px"
                    className="ls-icon icon-generalwebsite"
                  />
                </Container>
                {url || (
                  <EmptyButton
                    onClick={() => setShowContactInformationDialog(true)}
                    color="text300"
                    textAlign="left"
                    fontSize="14px"
                  >
                    Add website
                  </EmptyButton>
                )}
              </Container>

              <Container
                display="flex"
                marginBottom="15px"
                fontSize="14px"
                wordBreak="break-word"
                lineHeight="1.1"
              >
                <Container flex="0 0 25px">
                  <Icon
                    fontSize="17px"
                    className="ls-icon icon-categorypostoffices"
                  />
                </Container>
                {email || (
                  <EmptyButton
                    onClick={() => setShowContactInformationDialog(true)}
                    color="text300"
                    textAlign="left"
                    fontSize="14px"
                  >
                    Add Email
                  </EmptyButton>
                )}
              </Container>

              <Container
                display="flex"
                marginBottom="15px"
                fontSize="14px"
                wordBreak="break-word"
                lineHeight="1.1"
              >
                <Container flex="0 0 25px">
                  <Icon
                    fontSize="17px"
                    className="ls-icon icon-categorynearme"
                  />
                </Container>
                {address || (
                  <EmptyButton
                    onClick={() => setShowLocationDialog(true)}
                    color="text300"
                    textAlign="left"
                  >
                    Add Address
                  </EmptyButton>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
        <Container
          paddingTop="11px"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          borderTop={theme => `1px solid ${theme.border.border300}`}
          marginTop="auto"
        >
          <Text
            fontWeight="600"
            fontSize="14px"
            color="text300"
            textAlign="left"
            marginTop="3px"
            marginRight="10px"
          >
            Last updated {moment(updatedAt).fromNow()}
          </Text>
          <AnchorLink
            to={`/info/${profileId}`}
            color="text700"
            fontSize="14px"
            fontWeight="600"
            marginTop="3px"
          >
            View Business Info
          </AnchorLink>
        </Container>
      </Panel>
      <ContactInformationDialog
        show={showContactInformationDialog}
        onClose={() => setShowContactInformationDialog(false)}
      />
      <LocationDialog
        show={showLocationDialog}
        onClose={() => setShowLocationDialog(false)}
      />
    </>
  );
};

export default ProfileInfo;
