import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'ls-common-client/src/components/Icon';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Loader from 'ls-common-client/src/components/Loader';
import Panel from '../../UI/atoms/Panel';
import profileViewsBackground from '../../../images/profileViewsBackground.svg';

const ProfileViews = ({ count, loading, ...props }) => {
  return (
    <Panel
      background={`url(${profileViewsBackground}) no-repeat`}
      backgroundPosition="center -144px"
      backgroundSize="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="18px 18px 0 18px"
      {...props}
    >
      {loading ? (
        <Container
          minHeight="150px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loader width="150px" />
        </Container>
      ) : (
        <>
          <Container
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              color="transparent"
              fontSize="30px"
              className="ls-icon icon-generalsearchlarge"
              marginBottom="8px"
            />
            <Text
              color="text500"
              fontSize="60px"
              fontWeight="bold"
              lineHeight="1.2"
              marginBottom="8px"
            >
              {count.toLocaleString()}
            </Text>
            <Text
              fontSize="16px"
              fontWeight="600"
              lineHeight="1.1"
              whiteSpace="nowrap"
              width="100%"
              textAlign="center"
              paddingBottom="10px"
              marginBottom="8px"
            >
              Profile Views
            </Text>
          </Container>
          <Container
            borderTop={theme => `1px solid ${theme.border.border300}`}
            width="100%"
            textAlign="center"
            marginTop="auto"
            height="53px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="14px" color="text300">
              Last 365 days
            </Text>
          </Container>
        </>
      )}
    </Panel>
  );
};

ProfileViews.propTypes = {
  count: PropTypes.number,
  loading: PropTypes.bool,
};

ProfileViews.defaultProps = {
  count: 0,
  loading: false,
};

export default ProfileViews;
