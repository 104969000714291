import { ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';

const typePolicies = {
  Profile: {
    fields: {
      contacts: {
        merge: (existing, incoming) => incoming,
      },
      media: {
        merge: (existing, incoming) => incoming,
      },
      tradingHours: {
        merge: (existing, incoming) => incoming,
      },
      amenities: {
        merge: (existing, incoming) => incoming,
      },
      products: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Query: {
    fields: {
      reviews: relayStylePagination(['sort', 'sortDirection', 'listingId']),
      listingPosts: relayStylePagination(['listingId', 'state']),
      enquiries: relayStylePagination(['listingId']),
      quoteListings: relayStylePagination(['listingId']),
    },
  },
};

const create = ({ api, auth, logger }) => {
  const batchLink = new BatchHttpLink({
    uri: `${api}/graphql`,
    batchMax: 50,
  });

  const authentication = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `bearer ${auth.token()}`,
      },
    });
    return forward(operation);
  });

  const authError = onError(err => {
    const { graphQLErrors } = err;

    if (graphQLErrors) {
      for (let index = 0; index < graphQLErrors.length; index += 1) {
        const {
          extensions: { exception },
        } = graphQLErrors[index];
        const error = exception || {};
        const { status } = error;
        if ([401, 403].includes(status)) {
          auth.login({
            redirectUrl: window.location.pathname,
          });
          break;
        }
        if (status === 400) {
          logger.warn(error);
        } else {
          logger.error(error);
        }
      }
    }
  });

  const client = new ApolloClient({
    link: from([authError, authentication, batchLink]),
    cache: new InMemoryCache({
      typePolicies,
    }),
  });
  return client;
};

export { create };
