import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import Popup from 'ls-common-client/src/components/Popup';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';

const MoreMenu = ({
  menuItems,
  onClick,
  onClose,
  show,
  popupWidth,
  anchor,
  ...props
}) => {
  const [mouseOver, setMouseOver] = useState();

  return (
    <ClickOutside onClickOutside={onClose} {...props}>
      <EmptyButton
        onClick={onClick}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        width="34px"
        height="34px"
        borderRadius="100%"
        boxShadow="0 0 3px 0 rgba(0, 0, 0, 0.3)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="white"
      >
        <Text>
          <Text
            width="4px"
            height="4px"
            borderRadius="100%"
            backgroundColor={mouseOver ? '#285aff' : '#9b9b9b'}
            margin="2px"
            display="block"
          />
          <Text
            width="4px"
            height="4px"
            borderRadius="100%"
            backgroundColor={mouseOver ? '#285aff' : '#9b9b9b'}
            margin="2px"
            display="block"
          />
          <Text
            width="4px"
            height="4px"
            borderRadius="100%"
            backgroundColor={mouseOver ? '#285aff' : '#9b9b9b'}
            margin="2px"
            display="block"
          />
        </Text>
      </EmptyButton>
      <Popup
        show={show}
        width={popupWidth}
        anchor={anchor}
        display="flex"
        flexDirection="column"
        padding="0 18px"
      >
        {menuItems.map(
          ({ text, icon, onClick: onItemClick, disabled, loading }) => (
            <EmptyButton
              key={text}
              onClick={onItemClick}
              disabled={disabled || loading}
              fontSize="14px"
              fontWeight="600"
              color={loading ? 'transparent' : 'normal'}
              textAlign="left"
              borderBottom={theme => `1px solid ${theme.border.border300}`}
              padding="15px 0"
              position="relative"
              _hover={{
                color: loading ? 'transparent' : 'text700',
              }}
              _disabled={{
                color: loading ? 'transparent' : 'text200',
              }}
            >
              {loading && (
                <Container
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Loader width="120px" />
                </Container>
              )}
              <Icon
                visibility={loading ? 'hidden' : 'visible'}
                marginRight="10px"
                className={`ls-icon ${icon}`}
              />
              {text}
            </EmptyButton>
          )
        )}
      </Popup>
    </ClickOutside>
  );
};

MoreMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      loading: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  popupWidth: PropTypes.string,
  anchor: PropTypes.string,
};

MoreMenu.defaultProps = {
  onClick: () => null,
  onClose: () => null,
  show: false,
  popupWidth: '160px',
  anchor: 'right',
};

export default MoreMenu;
