import React, { useState, useRef, useEffect, useContext } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import Anchor from 'ls-common-client/src/components/Anchor';
import Image from 'ls-common-client/src/components/Image';
import Text from 'ls-common-client/src/components/Text';
import Loader from 'ls-common-client/src/components/Loader';
import Input from '../../../atoms/Input';
import useB2bPosts from '../../../../../hooks/useB2bPosts';
import { Context } from '../../../../../context/AppContext';

const HelpSearch = () => {
  const {
    media: { mobile },
  } = useContext(Context);

  const { fetch, b2bPosts } = useB2bPosts({ size: 4 });
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState();
  const timeout = useRef();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetch();
      setLoading(false);
    })();
  }, []);

  const onChange = ({ target }) => {
    setLoading(true);
    setValue(target.value);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      await fetch({ search: target.value });
      setLoading(false);
    }, 500);
  };
  return (
    <>
      <Container position="relative" marginBottom="20px">
        <Icon
          className="ls-icon icon-generalsearchlarge"
          pointerEvents="none"
          left="14px"
          top="12px"
          position="absolute"
          color="text300"
          zIndex="1"
        />
        <Input
          onChange={onChange}
          value={value}
          borderRadius="30px"
          height="40px"
          placeholder="Search Articles"
          fontSize="14px"
          paddingLeft="40px"
          paddingRight="10px"
          border="1px solid #eaefff"
          backgroundColor="white"
          textOverflow="ellipsis"
          autoComplete="new-password"
          _placeholderShown={{
            borderColor: theme => theme.border.border300,
          }}
          _placeholder={{
            color: theme => theme.text.text300,
          }}
        />
      </Container>
      {loading ? (
        <Container display="flex" justifyContent="center" height="221px">
          <Loader width="150px" />
        </Container>
      ) : (
        <Container>
          {b2bPosts ? (
            <Container display="flex" flexWrap="wrap">
              {b2bPosts.map(({ id, url, image, title }, i) => (
                <Anchor
                  key={id}
                  href={url}
                  target="_blank"
                  flex={mobile ? '0 0 100%' : '0 0 50%'}
                  padding="5px"
                  borderRight={theme =>
                    i % 2 === 0 && !mobile
                      ? `1px solid ${theme.border.border300}`
                      : 'none'
                  }
                  borderBottom={theme =>
                    [0, 1].includes(i) || mobile
                      ? `1px solid ${theme.border.border300}`
                      : 'none'
                  }
                >
                  <Container
                    borderRadius="11px"
                    _hover={{
                      backgroundColor: 'background300',
                    }}
                    display="flex"
                    padding="10px"
                  >
                    <Container
                      height="80px"
                      flex="0 0 80px"
                      borderRadius="11px"
                      overflow="hidden"
                      marginRight="15px"
                    >
                      <Image
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        src={image}
                      />
                    </Container>
                    <Text
                      color="normal"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="1.2"
                    >
                      {title}
                    </Text>
                  </Container>
                </Anchor>
              ))}
            </Container>
          ) : (
            <Container
              padding="20px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="16px" fontWeight="600">
                No Results
              </Text>
            </Container>
          )}
        </Container>
      )}
    </>
  );
};

export default HelpSearch;
