import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import CommonInput from 'ls-common-client/src/components/Input';

const Input = forwardRef((props, ref) => {
  const { type } = props;

  if (type === 'radio') {
    return (
      <CommonInput
        ref={ref}
        appearance="auto"
        width="auto"
        height="auto"
        margin="0"
        _before={{
          content: '',
          width: '20px',
          height: '20px',
          backgroundColor: 'black',
        }}
        {...props}
      />
    );
  }

  return (
    <CommonInput
      ref={ref}
      backgroundColor="#f8f8f8"
      border="none"
      borderRadius="7px"
      fontSize="16px"
      textOverflow="ellipsis"
      _placeholder={{
        color: 'text300',
      }}
      _focus={{
        border: '1px solid #97AEFF',
      }}
      _disabled={{
        color: 'text300',
      }}
      {...props}
    />
  );
});

Input.propTypes = {
  type: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
