import { gql } from '@apollo/client';

const reviews = gql`
  query reviews(
    $listingId: String
    $reviewState: ReviewStateRegex
    $sort: ReviewSortRegex
    $sortDirection: SortDirectionRegex
    $first: Int
    $after: String
  ) {
    reviews(
      listingId: $listingId
      reviewState: $reviewState
      sort: $sort
      sortDirection: $sortDirection
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          rating
          message
          displayName
          user {
            avatar
          }
          reviewResponse {
            message
          }
          reviewAssets {
            edges {
              node {
                id
                url
              }
            }
          }
          updatedAt
          createdAt
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export default reviews;
