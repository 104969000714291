import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { enquiries as enquiriesQuery } from '../graphql/queries';

const useEnquiries = ({ variables = {} } = {}) => {
  const { profileId } = useParams();

  const {
    data: { enquiries } = {},
    previousData: { reviews: previousEnquiries } = {},
    loading,
    fetchMore,
  } = useQuery(enquiriesQuery, {
    variables: {
      listingId: profileId,
      first: 9,
      ...variables,
    },
    errorPolicy: 'all',
  });

  return {
    enquiries,
    previousEnquiries,
    loading,
    fetchMore,
  };
};

export default useEnquiries;
