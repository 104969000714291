import React from 'react';
import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';

const SelectButton = ({ children, selected, ...props }) => (
  <EmptyButton
    borderRadius="100px"
    border={
      selected ? `1px solid rgba(151, 174, 255, 0.5)` : `1px solid #ececec`
    }
    color={selected ? 'text700' : 'normal'}
    backgroundColor={selected ? '#eaefff' : 'white'}
    fontWeight="600"
    fontSize="14px"
    padding="6px 12px"
    whiteSpace="nowrap"
    _focus={{
      border: selected
        ? `1px solid rgba(151, 174, 255, 1)`
        : `1px solid #a5a5a5`,
    }}
    _hover={{
      color: 'text700',
    }}
    _disabled={{
      opacity: 0.5,
      color: 'normal',
      border: '1px solid #ececec',
    }}
    {...props}
  >
    {children}
  </EmptyButton>
);

export default SelectButton;

SelectButton.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
};

SelectButton.defaultProps = {
  children: null,
  selected: false,
};
