import React from 'react';
import ReactDom from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { hot } from 'react-hot-loader/root';
import { HelmetProvider } from 'react-helmet-async';
import mapboxgl from 'mapbox-gl';
import mapConfig from 'ls-map/src/map/config';
import App from './components/App';
import logger from './services/logger';
import auth from './services/auth';
import client from './services/client';
import config from './config';
import 'mapbox-gl/dist/mapbox-gl.css';

const { accessToken } = mapConfig;

mapboxgl.accessToken = accessToken;

logger.info(config);

const Root = () => (
  <ApolloProvider client={client}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </ApolloProvider>
);

const render = async () => {
  const token = await auth.init();
  if (token) {
    ReactDom.render(<Root />, document.getElementById('root'));
  }
};

render();

export default hot(render);
