import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Doughnut from 'ls-common-client/src/components/charts/Doughnut';
import Panel from '../../UI/atoms/Panel';
import ToolTip from '../../UI/molecules/ToolTip';

const gradients = [
  {
    from: '#ff7f00',
    to: '#ffbe00',
  },
  {
    from: '#98b0fd',
    to: '#2d60fb',
  },
  {
    from: '#40a499',
    to: '#61e7d9',
  },
];

const setBackgroundColor = ({ chart: { ctx } }, i) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
  gradient.addColorStop(0, gradients[i].from);
  gradient.addColorStop(1, gradients[i].to);

  return ['#ececec', gradient];
};

const chartProps = [
  {
    width: '260px',
    height: '260px',
    options: {
      cutout: '98%',
    },
  },
  {
    width: '225px',
    height: '225px',
    options: {
      cutout: '98%',
    },
  },
  {
    width: '187px',
    height: '187px',
    options: {
      cutout: '98%',
    },
  },
];

const getFontSize = length => {
  let fontSize = '60px';

  if (length > 5) {
    fontSize = '30px';
  }

  if (length === 5) {
    fontSize = '40px';
  }

  if (length === 4) {
    fontSize = '45px';
  }

  return fontSize;
};

const Devices = ({ data, ...props }) => {
  const [showToolTip, setShowToolTip] = useState();

  const total = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.reduce((acc, { count }) => acc + count, 0);
  }, [data]);

  const charts = useMemo(() => {
    if (!data || !data.length) {
      return [...Array(3)].map((item, i) => ({
        data: {
          datasets: [
            {
              data: [1],
              borderColor: ['#ececec'],
            },
          ],
        },
        device: i,
        ...(chartProps[i] || {}),
      }));
    }

    return data.map(({ count, device }, i) => ({
      data: {
        datasets: [
          {
            data: [count ? total - count : 1, count],
            backgroundColor: context => setBackgroundColor(context, i),
            borderColor: context => setBackgroundColor(context, i),
            rotation: i * 75,
          },
        ],
      },
      device,
      ...(chartProps[i] || {}),
    }));
  }, [data, total]);

  const legendData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map(({ count, device }, i) => ({
      background: `linear-gradient(90deg, ${gradients[i].from} 0%, ${gradients[i].to} 100%)`,
      label: device,
      value: `${Math.round((count / total) * 100)}%`,
    }));
  }, [data, total]);

  return (
    <Panel display="flex" flexDirection="column" {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="10px"
      >
        <Text fontWeight="600" lineHeight="1.2" marginRight="20px">
          Devices Used to View Your Business Profile
        </Text>
        <ToolTip
          show={showToolTip}
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
          anchor="topRight"
          arrowAnchor="top"
          render={() => (
            <Text
              display="block"
              fontSize="14px"
              lineHeight="1.2"
              color="text400"
            >
              This data shows you the devices people are using when they view
              your profile.
            </Text>
          )}
          popupProps={{
            right: '-15px',
            width: '210px',
            padding: '15px',
          }}
        >
          <Icon className="ls-icon icon-generalabout" fontSize="20px" />
        </ToolTip>
      </Container>
      <Container
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          height="300px"
        >
          {charts.map(({ width, height, options, device, ...chart }) => (
            <Container key={device} position="absolute" pointerEvents="none">
              <Doughnut
                width={width}
                height={height}
                data={chart.data}
                options={{
                  ...options,
                  borderRadius: 10,
                  borderWidth: 10,
                  hoverBorderColor: 'transparent',
                  hoverOffset: 0,
                  plugins: {
                    tooltip: {
                      enabled: false,
                    },
                  },
                }}
              />
            </Container>
          ))}
          <Container
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Text
              fontSize={getFontSize(total.toString().length)}
              fontWeight="bold"
              color="#505d6f"
              lineHeight="1"
            >
              {total.toLocaleString()}
            </Text>
            <Text color="text300" fontSize="16px" fontWeight="600">
              Total
            </Text>
          </Container>
        </Container>
        <Container
          display="flex"
          justifyContent="space-around"
          flexWrap="wrap"
          width="100%"
        >
          {legendData.map(({ background, label, value }) => (
            <Container
              key={label}
              display="flex"
              alignItems="center"
              margin="0 5px 5px 5px"
            >
              <Container
                width="14px"
                height="14px"
                borderRadius="100%"
                marginRight="8px"
                background={background}
              />
              <Text
                fontSize="14px"
                color="text500"
                fontWeight="bold"
                marginRight="5px"
              >
                {value}
              </Text>
              <Text color="text400" fontSize="14px">
                {label}
              </Text>
            </Container>
          ))}
        </Container>
      </Container>
    </Panel>
  );
};

Devices.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

Devices.defaultProps = {
  data: null,
};

export default Devices;
