import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Anchor from 'ls-common-client/src/components/Anchor';
import Ribbon from '../atoms/Ribbon';
import ToolTip from './ToolTip';
import config from '../../../config';

const { b2bRoot } = config;

const PaidFeatureToolTip = ({ popupProps, anchor, heading, ...props }) => {
  const [show, setShow] = useState();
  return (
    <ToolTip
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      show={show}
      arrowAnchor="top"
      anchor="topRight"
      render={() => (
        <>
          <Container display="flex" alignItems="center" marginBottom="5px">
            <Ribbon />
            <Text
              marginLeft="10px"
              fontWeight="600"
              lineHeight="1.1"
              fontSize="14px"
            >
              {heading}
            </Text>
          </Container>
          <Text
            color="text300"
            fontWeight="normal"
            lineHeight="1.2"
            fontSize="14px"
          >
            <Anchor
              href={`${b2bRoot}/contact-us`}
              target="_blank"
              color="text700"
            >
              Upgrade
            </Anchor>{' '}
            now to unlock this feature.
          </Text>
        </>
      )}
      popupProps={{
        width: '260px',
        left: '-18px',
        minWidth: 'unset',
        padding: '15px',
        ...popupProps,
      }}
      {...props}
    >
      <Ribbon fontSize="20px" />
    </ToolTip>
  );
};

PaidFeatureToolTip.propTypes = {
  popupProps: PropTypes.shape({}),
  anchor: PropTypes.string,
  heading: PropTypes.string,
};

PaidFeatureToolTip.defaultProps = {
  popupProps: {},
  anchor: 'topRight',
  heading: 'This is a paid feature',
};

export default PaidFeatureToolTip;
