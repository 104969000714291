import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Form from 'ls-common-client/src/components/Form';
import Radio from '../../../UI/atoms/Radio';
import FormDialogSubmit from '../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../context/AppContext';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';
import { PROFILE_STATUSES } from '../../../../lib/constants';

const { DISABLED } = PROFILE_STATUSES;

const StatusForm = ({ onClose, statusValues, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
  } = useContext(Context);

  const { status } = profile;

  const { name: packageName } = profile.package || {};

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Your update to your Profile Status was successful!',
  });

  const {
    formState: { isDirty },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      status,
    },
  });

  const submit = async values => {
    await update(values);
    successNotificationSlider.open();
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container>
        <Controller
          name="status"
          control={control}
          render={({ field }) =>
            statusValues.map(({ text, value, color }) => (
              <EmptyButton
                onClick={() => field.onChange(value)}
                disabled={packageName && value === DISABLED}
                key={value}
                marginBottom="10px"
                display="flex"
                alignItems="center"
                _disabled={{
                  opacity: 0.5,
                }}
              >
                <Radio
                  active={field.value === value}
                  activeColor={color}
                  marginRight="20px"
                />
                <Text fontSize="16px" color="normal">
                  {text}
                </Text>
              </EmptyButton>
            ))
          }
        />
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

StatusForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  statusValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StatusForm;
