import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import { Context } from '../../../context/AppContext';

const FormDialog = ({ children, ...props }) => {
  const {
    media: { desktop, mobile },
  } = useContext(Context);

  return (
    <Dialog
      mode={desktop ? 'desktop' : 'mobile'}
      padding={mobile ? '30px' : '35px 35px 25px 35px'}
      display="flex"
      render={({ children: renderChildren }) => (
        <Container display="flex" flexDirection="column" flex="1">
          {renderChildren}
        </Container>
      )}
      {...props}
    >
      {children}
    </Dialog>
  );
};

FormDialog.propTypes = {
  children: PropTypes.node,
};

FormDialog.defaultProps = {
  children: null,
};

export default FormDialog;
