import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../../UI/molecules/FormDialogHeader';
import QuoteResponseForm from './QuoteResponseForm';

const QuoteResponseDialog = ({
  onClose,
  onSubmit,
  message,
  loading,
  ...props
}) => {
  return (
    <FormDialog onClose={onClose} maxWidth="420px" {...props}>
      <FormDialogHeader
        header="Reply as Owner"
        subHeader="Remember, even if you can't provide a quote, you should still respond to the user to provide a good customer experience."
        onClose={onClose}
      />
      <QuoteResponseForm
        message={message}
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </FormDialog>
  );
};

QuoteResponseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  loading: PropTypes.bool,
};

QuoteResponseDialog.defaultProps = {
  message: null,
  loading: false,
};

export default QuoteResponseDialog;
