import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { searchLocalmetrics as searchLocalmetricsQuery } from '../graphql/queries';

const useSearchLocalmetrics = () => {
  const { profileId } = useParams();

  const [
    fetchLocalMetrics,
    { data: { searchLocalmetrics } = {}, loading, error },
  ] = useLazyQuery(searchLocalmetricsQuery);

  useEffect(() => {
    if (Number(profileId)) {
      fetchLocalMetrics({
        variables: {
          params: { listing_id: profileId },
        },
      });
    }
  }, [profileId]);

  return {
    searchLocalmetrics,
    loading,
    error,
  };
};

export default useSearchLocalmetrics;
