import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Image from 'ls-common-client/src/components/Image';
import themes from '../../../../../images/listingPostThemes';

const StyledEmptyButton = styled(EmptyButton)`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const ThemeSelector = ({ onSelected, value, disabled, ...props }) => (
  <Container {...props}>
    <Text fontSize="16px" fontWeight="600" display="block" marginBottom="5px">
      OR, Add a Background Colour
    </Text>
    <Text
      opacity={disabled ? '0.5' : '1'}
      display="block"
      color="text300"
      fontSize="16px"
      lineHeight="1.2"
      marginBottom="15px"
    >
      No post image? No problem! Add a background colour instead.
    </Text>
    <Container display="flex" justifyContent="space-between">
      {Object.keys(themes).map(key => (
        <StyledEmptyButton
          disabled={disabled}
          key={key}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="50px"
          height="50px"
          borderRadius="100px"
          border="2px solid"
          borderColor={value === key && !disabled ? '#9b9b9b' : 'border300'}
          onClick={() => {
            if (!disabled) {
              onSelected(key);
            }
          }}
        >
          <Container
            width="42px"
            height="42px"
            borderRadius="100px"
            overflow="hidden"
          >
            {disabled ? (
              <Container
                backgroundColor="background300"
                width="100%"
                height="100%"
              />
            ) : (
              <Image width="42px" height="42px" src={themes[key]} />
            )}
          </Container>
        </StyledEmptyButton>
      ))}
    </Container>
  </Container>
);

ThemeSelector.propTypes = {
  onSelected: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

ThemeSelector.defaultProps = {
  onSelected: () => {},
  value: null,
  disabled: false,
};

export default ThemeSelector;
