const storageKey = 'businessProfile.toolTips';

const get = key => {
  const data = JSON.parse(localStorage.getItem(storageKey) || '{}');
  return key ? data[key] : data;
};

const set = (key, value) => {
  localStorage.setItem(storageKey, JSON.stringify({ ...get(), [key]: value }));
};

export default { set, get };
