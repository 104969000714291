import { useState } from 'react';
import config from '../../../config';

const { googleMapsApiKey } = config;
let initialised;

const useGoogleMaps = () => {
  const [isLoaded, setIsLoaded] = useState();

  const init = () => {
    if (initialised || isLoaded) {
      return;
    }

    initialised = true;

    window.googleMapsCallback = () => {
      setIsLoaded(true);
      window.googleInit = false;
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?callback=googleMapsCallback&key=${googleMapsApiKey}&libraries=places`;
    document.head.appendChild(script);
  };

  return {
    isLoaded,
    init,
  };
};

export default useGoogleMaps;
