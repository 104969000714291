import React, { useMemo, useContext, useState } from 'react';
import cuid from 'cuid';
import { useSearchParams } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Selector from '../../../UI/molecules/Selector';
import useReviews from '../../../../hooks/useReviews';
import Review from './Review';
import { Context } from '../../../../context/AppContext';
import noReviews from '../../../../images/noReviews.svg';
import PageHeader from '../../../UI/molecules/PageHeader';

const sortItems = [
  {
    label: 'Sort by date',
    value: 'datedesc',
  },
  {
    label: 'Sort by highest rating',
    value: 'ratingdesc',
  },
  {
    label: 'Sort by lowest rating',
    value: 'ratingasc',
  },
];

const sortVariables = {
  datedesc: {
    sort: 'date',
    sortDirection: 'desc',
  },
  ratingdesc: {
    sort: 'rating',
    sortDirection: 'desc',
  },
  ratingasc: {
    sort: 'rating',
    sortDirection: 'asc',
  },
};

const Reviews = props => {
  const [showSort, setShowSort] = useState();
  const [fetchMoreLoading, setFetchMoreLoading] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') || 'date';
  const sortDirection = searchParams.get('sortDirection') || 'desc';

  const variables = useMemo(
    () => ({ sort, sortDirection }),
    [sort, sortDirection]
  );

  const { reviews, previousReviews, loading, fetchMore } = useReviews({
    variables,
  });
  const { edges = [], pageInfo: { endCursor, hasNextPage } = {} } =
    reviews || {};

  const {
    media: { device, desktop },
  } = useContext(Context);

  const columns = useMemo(() => {
    if (!edges.length) {
      return null;
    }

    const numberOfColumns = {
      mobile: 1,
      tablet: 2,
      desktop: 3,
    }[device];

    const cols = [...Array(numberOfColumns)].map(() => ({
      id: cuid(),
      column: [],
    }));

    edges.forEach((edge, i) => {
      const {
        node: { id },
      } = edge;

      const iteration = i % numberOfColumns;

      if (cols[iteration].column.length) {
        cols[iteration].column.push(edge);
      } else {
        cols[iteration] = { id, column: [edge] };
      }
    });

    return cols;
  }, [edges, device]);

  const onLoadMoreClick = async () => {
    setFetchMoreLoading(true);
    await fetchMore({ variables: { after: endCursor } });
    setFetchMoreLoading(false);
  };

  if (loading && !previousReviews) {
    return (
      <>
        <PageHeader>Reviews</PageHeader>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
          width="100%"
          height="100%"
          {...props}
        >
          <Loader width="200px" />
        </Container>
      </>
    );
  }

  if (!columns && !loading) {
    return (
      <>
        <PageHeader>Reviews</PageHeader>
        <Container padding="20px" height="100%" {...props}>
          <Container
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Container>
              <Image width="100%" src={noReviews} />
            </Container>
            <Container maxWidth="400px">
              <Text
                fontSize="25px"
                fontWeight="bold"
                lineHeight="1"
                textAlign="center"
                display="block"
                marginBottom="20px"
              >
                Oh no, you have no reviews!
              </Text>
              <Text
                fontSize="16px"
                lineHeight="1.4"
                color="text400"
                display="block"
                marginBottom="20px"
                textAlign="center"
              >
                86% of Aussies say they read reviews before visiting a
                restaurant or business. The easiest way to get more reviews is
                to simply ask!
              </Text>
            </Container>
          </Container>
        </Container>
      </>
    );
  }

  return (
    <>
      <PageHeader>Reviews</PageHeader>
      <Container
        padding={desktop ? '0 20px 20px 20px' : '20px'}
        height="100%"
        {...props}
      >
        <Container display="flex" justifyContent="flex-end" marginBottom="10px">
          <Selector
            data={sortItems}
            show={showSort}
            onClose={() => setShowSort(false)}
            onChange={value => setSearchParams(sortVariables[value])}
            value={`${sort}${sortDirection}`}
            anchor="topRight"
            popupProps={{
              width: '230px',
            }}
          >
            {({ ref }) => (
              <EmptyButton
                ref={ref}
                onClick={() => setShowSort(true)}
                backgroundColor="white"
                border={theme => `1px solid ${theme.border.border300}`}
                height="32px"
                padding="0 13px"
                borderRadius="30px"
                fontSize="14px"
                fontWeight="600"
                whiteSpace="nowrap"
                color="normal"
                _hover={{
                  color: 'text700',
                }}
              >
                <Icon
                  className="ls-icon icon-sliders"
                  fontSize="10px"
                  marginRight="5px"
                />
                Sort
              </EmptyButton>
            )}
          </Selector>
        </Container>
        {loading ? (
          <Container
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="20px"
            width="100%"
            height="100%"
            {...props}
          >
            <Loader width="200px" />
          </Container>
        ) : (
          <Container display="flex" flexWrap="wrap" margin="0 -10px 30px -10px">
            {columns.map(({ id, column }) => (
              <Container
                key={id}
                display="flex"
                flexDirection="column"
                flex="1"
              >
                {column.map(({ node }) => (
                  <Review key={node.id} data={node} />
                ))}
              </Container>
            ))}
          </Container>
        )}
        {hasNextPage && (
          <Container
            display="flex"
            justifyContent="center"
            position="relative"
            minHeight="50px"
          >
            {fetchMoreLoading ? (
              <Loader
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%,-50%)"
                width="150px"
              />
            ) : (
              <EmptyButton
                backgroundColor="white"
                border={theme => `1px solid ${theme.border.border300}`}
                height="35px"
                padding="0 18px"
                borderRadius="30px"
                fontSize="14px"
                fontWeight="600"
                color="normal"
                onClick={onLoadMoreClick}
              >
                Load More
              </EmptyButton>
            )}
          </Container>
        )}
      </Container>
    </>
  );
};

export default Reviews;
