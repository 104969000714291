import React from 'react';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Text from 'ls-common-client/src/components/Text';
import Anchor from 'ls-common-client/src/components/Anchor';
import PageHeader from '../../UI/molecules/PageHeader';
import noReviews from '../../../images/noReviews.svg';

const HideReviews = props => (
  <>
    <PageHeader>Reviews</PageHeader>
    <Container padding="20px" height="100%" {...props}>
      <Container
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container maxWidth="455px" textAlign="center">
          <Container>
            <Image width="100%" src={noReviews} />
          </Container>
          <Text
            fontSize="25px"
            fontWeight="bold"
            lineHeight="1"
            textAlign="center"
            display="block"
            marginBottom="20px"
          >
            Oh, your reviews are turned off.
          </Text>
          <Text
            fontSize="16px"
            lineHeight="1.4"
            color="text400"
            display="block"
            marginBottom="20px"
          >
            This means you can&apos;t show, receive or respond to reviews on
            your Localsearch.com.au business profile. Want to turn your review
            feature back on? Of course! Simply email{' '}
            <Anchor href="mailto:reviews@localsearch.com.au">
              reviews@localsearch.com.au
            </Anchor>
          </Text>
          <Text
            display="block"
            lineHeight="1.4"
            fontSize="14px"
            color="text400"
          >
            Note: By turning your review feature back on, any reviews you had
            when you turned off the feature will come back online.
          </Text>
        </Container>
      </Container>
    </Container>
  </>
);

export default HideReviews;
