import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../../UI/molecules/FormDialogHeader';
import SettingsForm from './SettingsForm';

const SettingsDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="420px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Email Settings"
        subHeader="Update or add email addresses below to choose where you would like us to send emails for your reviews, quotes and reports."
        onClose={onClose}
      />
      <SettingsForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SettingsDialog;
