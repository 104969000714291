import React from 'react';
import PropTypes from 'prop-types';
import CommonLabel from 'ls-common-client/src/components/Label';

const Label = ({ children, ...props }) => (
  <CommonLabel
    fontSize="16px"
    fontWeight="600"
    color="text500"
    display="block"
    marginBottom="10px"
    lineHeight="1.2"
    {...props}
  >
    {children}
  </CommonLabel>
);

Label.propTypes = {
  children: PropTypes.node,
};

Label.defaultProps = {
  children: null,
};

export default Label;
