import { useMemo, useState } from 'react';
import cuid from 'cuid';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import client from '../services/client';
import { listingPosts as listingPostsQuery } from '../graphql/queries';
import {
  createListingPost as createListingPostMutation,
  updateListingPost as updateListingPostMutation,
  deleteListingPost as deleteListingPostMutation,
} from '../graphql/mutations';
import { LISTING_POST_STATES } from '../lib/constants';
import { listingPostDisabled } from '../lib/util';

const { DISABLED, ENABLED } = LISTING_POST_STATES;

const filterPost = (edges, id) => edges.filter(({ node }) => node.id !== id);

const useListingPosts = ({ variables = {} } = {}) => {
  const { first = 12 } = variables;

  const { profileId } = useParams();
  const [pageLimitEnabled, setPageLimitEnabled] = useState(first);
  const [pageLimitDisabled, setPageLimitDisabled] = useState(first);

  const defaultVariables = useMemo(
    () => ({
      listingId: profileId,
      first,
      startDate: moment().startOf('day'),
      endDate: moment().startOf('day'),
    }),
    [profileId]
  );

  const {
    data: { listingPosts } = {},
    previousData: { listingPosts: previousListingPosts } = {},
    loading,
    fetchMore,
  } = useQuery(listingPostsQuery, {
    variables: {
      ...defaultVariables,
      ...variables,
    },
  });

  const [
    createListingPost,
    { loading: createListingPostLoading, error: createListingPostError },
  ] = useMutation(createListingPostMutation);

  const [
    updateListingPost,
    { loading: updateListingPostLoading, error: updateListingPostError },
  ] = useMutation(updateListingPostMutation);

  const [
    deleteListingPost,
    { loading: deleteListingPostLoading, error: deleteListingPostError },
  ] = useMutation(deleteListingPostMutation);

  const fetchNextPost = async () => {
    const {
      pageInfo: { endCursor },
    } = listingPosts;

    const { data } = await client.query({
      query: listingPostsQuery,
      variables: {
        ...defaultVariables,
        ...variables,
        first: 1,
        after: endCursor,
      },
      fetchPolicy: 'no-cache',
    });

    const {
      listingPosts: {
        edges: [nextPost],
      },
    } = data;

    return nextPost;
  };

  const readPostFromCache = id =>
    client.readFragment({
      id: `ListingPost:${id}`,
      fragment: gql`
        fragment ReadListingPost on ListingPost {
          id
          image
          theme
          title
          description
          url
          email
          phone
          file
          text
          startDate
          endDate
          state
        }
      `,
    });

  const removePost = async (edges, id, state) => {
    const nextPost = await fetchNextPost(state);
    return filterPost([...edges, ...(nextPost ? [nextPost] : [])], id);
  };

  const addPost = (edges, data, pageLimit) => {
    const { id } = data;
    return [{ node: data }, ...filterPost(edges, id)].slice(0, pageLimit);
  };

  const readCache = state =>
    client.readQuery({
      query: listingPostsQuery,
      variables: {
        ...defaultVariables,
        ...variables,
        state,
      },
    });

  const fetchCache = async state => {
    const { data } = await client.query({
      query: listingPostsQuery,
      variables: {
        ...defaultVariables,
        ...variables,
        state,
      },
    });
    return data;
  };

  const removeFromCache = async (id, state) => {
    const pageLimit = state === ENABLED ? pageLimitEnabled : pageLimitDisabled;
    let cache = readCache(state);

    if (!cache) {
      cache = await fetchCache(state);
    }

    const {
      listingPosts: { edges: cachedEdges },
    } = cache;

    const existsInCache = cachedEdges.some(({ node }) => node.id === id);

    if (!existsInCache) {
      return;
    }

    const edges = await removePost(cachedEdges, id, state);
    const endCursor = window.btoa(edges.length - 1);
    const hasNextPage =
      cachedEdges.length === edges.length && cachedEdges.length === pageLimit;

    client.writeQuery({
      query: listingPostsQuery,
      data: {
        listingPosts: {
          edges,
          pageInfo: {
            endCursor,
            hasNextPage,
          },
        },
      },
      variables: {
        ...defaultVariables,
        ...variables,
        state,
      },
    });
  };

  const addToCache = async (data, state) => {
    const pageLimit = state === ENABLED ? pageLimitEnabled : pageLimitDisabled;
    let cache = readCache(state);

    if (!cache) {
      cache = await fetchCache(state);
    }

    const {
      listingPosts: {
        edges: cachedEdges,
        pageInfo: { hasNextPage: cachedHasNextPage },
      },
    } = cache;

    const isUpdate = cachedEdges.some(({ node }) => node.id === data.id);

    const edges = addPost(cachedEdges, data, pageLimit);
    const endCursor = window.btoa(edges.length - 1);
    const hasNextPage = isUpdate
      ? cachedHasNextPage
      : cachedEdges.length === pageLimit;

    client.writeQuery({
      query: listingPostsQuery,
      data: {
        listingPosts: {
          edges,
          pageInfo: {
            endCursor,
            hasNextPage,
          },
        },
      },
      variables: {
        ...defaultVariables,
        ...variables,
        state,
      },
    });
  };

  const writeToCache = async data => {
    const { id } = data;

    if (listingPostDisabled(data)) {
      await removeFromCache(id, ENABLED);
      await addToCache(data, DISABLED);
    } else {
      await removeFromCache(id, DISABLED);
      await addToCache(data, ENABLED);
    }
  };

  const create = async input => {
    const tempId = cuid();

    await writeToCache({ __typename: 'ListingPost', id: tempId, ...input });

    const {
      data: {
        createListingPost: { listingPost },
      },
    } = await createListingPost({ variables: { input } });

    const { id } = listingPost;

    client.cache.modify({
      id: `ListingPost:${tempId}`,
      fields: {
        id: () => id,
      },
    });
  };

  const update = async input => {
    const { id } = input;
    const post = readPostFromCache(id);

    await writeToCache({ ...post, ...input });
    await updateListingPost({ variables: { input } });
  };

  const remove = async data => {
    const { id } = data;

    await removeFromCache(id, listingPostDisabled(data) ? DISABLED : ENABLED);
    await deleteListingPost({ variables: { id } });
  };

  return {
    listingPosts,
    previousListingPosts,
    loading,
    fetchMore: async (args, state) => {
      await fetchMore(args);

      if (state === ENABLED) {
        setPageLimitEnabled(pageLimitEnabled + first);
      } else {
        setPageLimitDisabled(pageLimitDisabled + first);
      }
    },
    create: {
      create,
      loading: createListingPostLoading,
      error: createListingPostError,
    },
    update: {
      update,
      loading: updateListingPostLoading,
      error: updateListingPostError,
    },
    remove: {
      remove,
      loading: deleteListingPostLoading,
      error: deleteListingPostError,
    },
  };
};

export default useListingPosts;
