import React, { useContext } from 'react';
import { Context } from '../../../../../context/AppContext';
import MoreMenuDesktop from './MoreMenuDesktop';
import MoreMenuMobile from './MoreMenuMobile';

const MoreMenu = props => {
  const {
    media: { desktop },
  } = useContext(Context);

  return desktop ? (
    <MoreMenuDesktop {...props} />
  ) : (
    <MoreMenuMobile {...props} />
  );
};

export default MoreMenu;
