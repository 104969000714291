import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import getVideoId from 'get-video-id';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Form from 'ls-common-client/src/components/Form';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Validator from '../../../../UI/atoms/Validator';
import Label from '../../../../UI/atoms/Label';
import Input from '../../../../UI/atoms/Input';
import RemoveButton from '../../../../UI/molecules/RemoveButton';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import { MEDIA_TYPES } from '../../../../../lib/constants';

const { YOUTUBE, VIMEO } = MEDIA_TYPES;

const schema = Joi.object({
  videos: Joi.array()
    .items(
      Joi.object({
        url: Joi.string()
          .custom((url, { message }) => {
            const { id, service } = getVideoId(url);

            const valid = id && ['youtube', 'vimeo'].includes(service);

            if (!valid) {
              return message('Invalid Vimeo or YouTube URL');
            }

            return url;
          })
          .messages({
            'string.empty': 'You forgot your video link',
          }),
      })
    )
    .unique('url')
    .messages({
      'array.unique': 'You have duplicate videos',
    }),
});

const addType = videos =>
  videos.map(({ url }) => {
    const { service } = getVideoId(url);

    let type;

    if (service === 'youtube') {
      type = YOUTUBE;
    }

    if (service === 'vimeo') {
      type = VIMEO;
    }

    return {
      url,
      type,
    };
  });

const AddVideosForm = ({ onSubmit, ...props }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      videos: [
        {
          url: '',
        },
      ],
    },
    resolver: joiResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'videos',
  });

  return (
    <Form
      onSubmit={handleSubmit(({ videos }) => onSubmit(addType(videos)))}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container>
        <Label>Video Link</Label>
        {fields.map(({ id }, i) => (
          <Container key={id} marginBottom="10px">
            <Container marginBottom="5px" position="relative">
              <RemoveButton
                position="absolute"
                right="-8px"
                top="16px"
                onClick={() => remove(i)}
              />
              <Input
                {...register(`videos.${i}.url`)}
                placeholder="Paste URL here..."
              />
              <Validator>{errors.videos?.[i]?.url?.message}</Validator>
              <Validator>{errors.videos?.[i]?.message}</Validator>
            </Container>
          </Container>
        ))}
        <Container>
          <EmptyButton
            onClick={() => append('')}
            color="text700"
            fontWeight="600"
            fontSize="14px"
          >
            {fields.length ? 'Add additional' : 'Add Video'}
          </EmptyButton>
        </Container>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit>Add</FormDialogSubmit>
      </Container>
    </Form>
  );
};

AddVideosForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddVideosForm;
