import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import * as theme from 'ls-common-client/src/themes/default';
import { Provider } from '../context/AppContext';
import Layout from './templates/Layout';
import Home from './pages/Home';
import Info from './pages/Info';
import Posts from './pages/Posts';
import Reviews from './pages/Reviews';
import Enquiries from './pages/Enquiries';
import Quotes from './pages/Quotes';
import Media from './pages/Media';
import Logo from './pages/Media/Logo';
import Images from './pages/Media/Images';
import Videos from './pages/Media/Videos';
import Documents from './pages/Media/Documents';
import Insights from './pages/Insights';
import NotFound from './pages/NotFound';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.text.normal};
    height: 1px;
    min-height: 100%;
    background-color: ${props => props.theme.background.normal};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    min-height: 100vh;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  button {
    font-family: Circular;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
`;

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route
          path="/"
          element={
            <Provider>
              <Layout />
            </Provider>
          }
        >
          <Route path=":profileId" element={<Home />} />
          <Route path="info" element={<Info />}>
            <Route path=":profileId" element={<Info />} />
          </Route>
          <Route path="posts" element={<Posts />}>
            <Route path=":profileId" element={<Posts />} />
          </Route>
          <Route path="reviews" element={<Reviews />}>
            <Route path=":profileId" element={<Reviews />} />
          </Route>
          <Route path="enquiries" element={<Enquiries />}>
            <Route path=":profileId" element={<Enquiries />} />
          </Route>
          <Route path="quotes" element={<Quotes />}>
            <Route path=":profileId" element={<Quotes />} />
          </Route>
          <Route path="media" element={<Media />}>
            <Route path=":profileId" element={<Logo />} />
            <Route path=":profileId/images" element={<Images />} />
            <Route path=":profileId/videos" element={<Videos />} />
            <Route path=":profileId/documents" element={<Documents />} />
          </Route>
          <Route path="insights" element={<Insights />}>
            <Route path=":profileId" element={<Insights />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
