import React from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';

const Radio = ({ active, activeColor, ...props }) => (
  <Text
    width="18px"
    height="18px"
    position="relative"
    display="block"
    borderRadius="100%"
    border={theme => `1px solid ${theme.border.border400}`}
    _before={{
      display: active ? 'block' : 'none',
      content: "''",
      backgroundColor: activeColor,
      width: '11px',
      height: '11px',
      position: 'absolute',
      borderRadius: '100%',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    }}
    {...props}
  />
);

Radio.propTypes = {
  active: PropTypes.bool,
  activeColor: PropTypes.string,
};

Radio.defaultProps = {
  active: false,
  activeColor: '#4bc0c0',
};

export default Radio;
