import React, { useContext, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import { Context } from '../../../../context/AppContext';
import Selector from '../../../UI/molecules/Selector';
import { profile as profileQuery } from '../../../../graphql/queries';
import client from '../../../../services/client';
import { addressJoin } from '../../../../lib/util';

const fetchProfile = id =>
  client.query({
    query: profileQuery,
    variables: {
      id,
    },
    errorPolicy: 'all',
  });

const parseProfile = profile => {
  if (!profile) {
    return null;
  }

  const { id, displayName, address, suburb, stateCode, postcode } = profile;

  return {
    value: id,
    label: (
      <Container>
        <Text display="block" fontSize="14px" lineHeight="1">
          {displayName}
        </Text>
        <Text fontSize="10px" color="text400" display="block">
          {addressJoin(address, suburb, stateCode, postcode)}
        </Text>
      </Container>
    ),
  };
};

const ProfileSelector = props => {
  const {
    user: { user = {} },
    profile: { profile = {} },
    sideBar,
  } = useContext(Context);

  const { userRoles: { edges: userRoles = [] } = {} } = user;

  const { displayName, address, suburb, stateCode, postcode } = profile;

  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState();
  const [showProfiles, setShowProfiles] = useState();

  const { pathname } = useLocation();
  const { profileId } = useParams();
  const navigate = useNavigate();

  const profileRoles = useMemo(
    () =>
      userRoles.reduce((acc, { node }) => {
        const isProfileRole = node.roleName.includes('L_');

        if (isProfileRole) {
          return [...acc, node.roleName.replace('L_', '')];
        }

        return acc;
      }, []),
    [userRoles]
  );
  const fetchProfiles = async () => {
    setLoading(true);
    const responses = await Promise.all(
      profileRoles.map(id => fetchProfile(id))
    );
    setProfiles(
      responses.map(({ data }) => parseProfile(data.profile)).filter(Boolean)
    );
    setLoading(false);
  };

  const onClick = () => {
    fetchProfiles();
    setShowProfiles(!showProfiles);
  };

  const onChange = selected => {
    sideBar.close();
    const newPath = profileId
      ? pathname.replace(profileId, selected)
      : `${pathname}${selected}`;
    navigate(newPath);
  };

  return (
    <Container width="100%" {...props}>
      {profileRoles.length > 1 ? (
        <Selector
          data={profiles}
          show={showProfiles}
          onClose={() => setShowProfiles(false)}
          onChange={onChange}
          value={profileId}
          loading={loading}
          width="100%"
          anchor="topLeft"
          popupProps={{
            width: '300px',
            maxHeight: '258px',
          }}
        >
          {({ ref }) => (
            <EmptyButton
              ref={ref}
              onClick={onClick}
              display="flex"
              alignItems="center"
              padding="10px"
              width="100%"
              textAlign="left"
            >
              <Text
                flex="1"
                overflow="hidden"
                display="flex"
                flexDirection="column"
              >
                <Text
                  display="block"
                  color="normal"
                  fontSize="14px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  lineHeight="1.1"
                >
                  {displayName}
                </Text>
                <Text
                  fontSize="10px"
                  color="text300"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {addressJoin(address, suburb, stateCode, postcode)}
                </Text>
              </Text>

              <Icon
                padding="11px"
                fontSize="10px"
                className="ls-icon icon-generalcaretdownmedium"
              />
            </EmptyButton>
          )}
        </Selector>
      ) : (
        <Container
          flex="1"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          padding="10px"
        >
          <Text
            display="block"
            color="normal"
            fontSize="14px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            lineHeight="1.1"
          >
            {displayName}
          </Text>
          <Text
            fontSize="10px"
            color="text300"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {addressJoin(address, suburb, stateCode, postcode)}
          </Text>
        </Container>
      )}
    </Container>
  );
};

export default ProfileSelector;
