import React, { useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Select from 'ls-common-client/src/components/Select';
import { isTouch } from 'ls-common-client/src/util';
import Selector from '../../../../UI/molecules/Selector';

const TimeSelector = ({ show, error, onClick, onChange, data, ...props }) => {
  const selectRef = useRef();

  const border = useMemo(() => {
    let val = 'none';

    if (show) {
      val = '1px solid #97AEFF';
    }

    if (error) {
      val = '1px solid #f16159';
    }

    return val;
  }, [show, error]);

  useEffect(() => {
    if (show && selectRef.current) {
      selectRef.current.focus();
    }
  }, [show]);

  if (isTouch()) {
    return (
      <Select
        ref={selectRef}
        border={error ? '1px solid #f16159' : 'none'}
        onChange={({ target: { value } }) => onChange(value)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        padding="0 5px"
        height="43px"
        fontSize="16px"
        borderRadius="7px"
        backgroundColor="#f8f8f8"
        whiteSpace="nowrap"
        cursor="pointer"
        textAlign="center"
        fontFamily="circular"
        color="normal"
        _focusVisible={{
          outline: '1px solid #97AEFF',
        }}
        {...props}
      >
        {data.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    );
  }

  return (
    <Selector
      show={show}
      popupProps={{
        width: '135px',
      }}
      data={data}
      onChange={onChange}
      renderItem={({ value, label, focused, ...itemProps }) => (
        <EmptyButton
          {...itemProps}
          key={value}
          tabIndex="-1"
          padding="0 7px"
          display="block"
          width="100%"
        >
          <Text
            display="block"
            padding="7px 0"
            borderBottom={theme => `1px solid ${theme.border.border300}`}
          >
            <Text
              fontSize="14px"
              height="40px"
              width="100%"
              textAlign="center"
              backgroundColor={focused ? 'background300' : 'white'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="11px"
            >
              {label}
            </Text>
          </Text>
        </EmptyButton>
      )}
      {...props}
    >
      {({ label, ref }) => (
        <EmptyButton
          ref={ref}
          onClick={onClick}
          border={border}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          padding="0 5px"
          height="43px"
          fontSize="16px"
          borderRadius="7px"
          backgroundColor="#f8f8f8"
          whiteSpace="nowrap"
          cursor="pointer"
          color="normal"
          _focus={{
            border: '1px solid #97AEFF',
          }}
        >
          {label}
        </EmptyButton>
      )}
    </Selector>
  );
};

TimeSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string,
  show: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

TimeSelector.defaultProps = {
  value: null,
  show: false,
  error: false,
  onClick: () => {},
  onChange: () => {},
};

export default TimeSelector;
