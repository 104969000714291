import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import Form from 'ls-common-client/src/components/Form';
import Container from 'ls-common-client/src/components/Container';
import FancyInput from 'ls-common-client/src/components/FancyInput';
import Button from 'ls-common-client/src/components/Button';
import {
  toE164,
  fromE164,
  validate as validatePhone,
} from 'ls-common-client/src/phone';
import Validator from '../../atoms/Validator';

const schema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name is required',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Invalid email',
    }),
  phoneNumber: Joi.string()
    .custom((value, { error }) => {
      return validatePhone(value) ? toE164(value) : error('phone.invalid');
    })
    .required()
    .messages({
      'string.empty': 'Phone number is required',
      'phone.invalid': 'Invalid phone number',
    }),
});

const UpgradeForm = ({ user: { user } }) => {
  const {
    account: { firstName, lastName, email, phoneNumber },
  } = user;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: `${firstName || ''} ${lastName || ''}`.trim(),
      email: email || '',
      phoneNumber: fromE164(phoneNumber),
    },
    resolver: joiResolver(schema),
  });

  const submit = values => {
    console.log(values);
  };

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Container marginBottom="5px">
        <FancyInput placeholder="Name" {...register('name')} />
        <Validator>{errors.name?.message}</Validator>
      </Container>
      <Container marginBottom="5px">
        <FancyInput placeholder="Email" {...register('email')} />
        <Validator>{errors.email?.message}</Validator>
      </Container>
      <Container marginBottom="50px">
        <FancyInput placeholder="Phone Number" {...register('phoneNumber')} />
        <Validator>{errors.phoneNumber?.message}</Validator>
      </Container>
      <Button type="submit" primary rounded height="40px">
        Get Started
      </Button>
    </Form>
  );
};

UpgradeForm.propTypes = {
  user: PropTypes.shape({
    user: PropTypes.shape({
      account: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
      }),
    }),
  }),
};

UpgradeForm.defaultProps = {
  user: {
    user: {
      account: {},
    },
  },
};

export default UpgradeForm;
