import React, { useState, useMemo, useContext, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Anchor from 'ls-common-client/src/components/Anchor';
import RatingStars from 'ls-common-client/src/components/RatingStars';
import { isTouch } from 'ls-common-client/src/util';
import moment from 'moment';
import imageResizer from '../../../../../../services/imageResizer';
import Share from './Share';
import config from '../../../../../../config';
import { Context } from '../../../../../../context/AppContext';
import placeholderLogo from '../../../../../../images/placeholderLogo.png';
import themes from '../../../../../../images/listingPostThemes';
import useReviewAggregates from '../../../../../../hooks/useReviewAggregates';

const { lsOnlineUrlRoot } = config;

let prevPageY = 0;
let prevPageX = 0;
let distanceY = 0;
let distanceX = 0;
let direction = 'down';
let translateY = 0;

const StyledText = styled(Text)`
  break-word: break-word;
  white-space: pre-line;
`;

const actionTypes = {
  email: 'mailto:',
  phone: 'tel:',
};

const titleSizes = [
  {
    min: 38,
    max: 75,
    withImage: false,
    value: '30px',
  },
  {
    min: 38,
    max: 75,
    withImage: true,
    value: '22px',
  },
  {
    min: 1,
    max: 38,
    value: '35px',
  },
];

const descriptionSizes = [
  {
    min: 200,
    max: 800,
    value: '16px',
  },
  {
    min: 100,
    max: 200,
    value: '20px',
  },
  {
    min: 1,
    max: 100,
    value: '24px',
  },
];

const PostCard = ({ data, onShareShow, onShareClose, hideShare, ...props }) => {
  const {
    media: { mobile },
    profile: { profile },
  } = useContext(Context);

  const { reviewAggregates } = useReviewAggregates();

  const [showShare, setShowShare] = useState();
  const containerRef = useRef();
  const parentRef = useRef();

  const { id, title, description, image, theme, text, startDate, endDate } =
    data || {};

  const { logo, displayName } = profile || {};
  const { averageRating } = reviewAggregates || {};

  const actionType = useMemo(() => {
    const actions = ['email', 'phone', 'url', 'file'];
    return actions.find(action => data[action]);
  }, [data]);

  const titleSize = useMemo(() => {
    if (!title) {
      return null;
    }

    const size = titleSizes.find(
      ({ min, max, withImage }) =>
        title.length > min &&
        title.length <= max &&
        (!!image === withImage || withImage === undefined)
    );

    return size && size.value;
  }, [title]);

  const descriptionSize = useMemo(() => {
    if (!description) {
      return null;
    }

    const size = descriptionSizes.find(
      ({ min, max }) => description.length > min && description.length <= max
    );

    return size && size.value;
  }, [description]);

  const translate = y => {
    if (!containerRef.current) {
      return;
    }

    const { style } = containerRef.current;

    translateY = y;

    window.requestAnimationFrame(() => {
      style.transform = `translateY(${y}px)`;
    });
  };

  const onTouchStart = () => {
    const { style } = containerRef.current;

    const currentY = style.transform
      .replace('translateY(', '')
      .replace('px)', '');

    prevPageY = 0;
    prevPageX = 0;
    distanceY = 0;
    distanceX = 0;
    translateY = Number(currentY);
  };

  const onTouchMove = e => {
    if (!containerRef.current || !parentRef.current) {
      return;
    }

    const {
      current: { clientHeight: containerHeight },
    } = containerRef;

    const {
      current: { clientHeight: parentHeight },
    } = parentRef;

    if (containerHeight < parentHeight) {
      return;
    }

    const {
      touches: [touch],
    } = e;

    const { pageY, pageX } = touch;

    if (prevPageY) {
      distanceY = pageY - prevPageY;
      distanceX = pageX - prevPageX;

      const isHorizontal = Math.abs(distanceX) > Math.abs(distanceY);

      if (!isHorizontal) {
        const distancePastTop = translateY > 0 ? translateY : null;
        const distancePastBottom =
          containerHeight + translateY < parentHeight
            ? Math.abs(containerHeight + translateY - parentHeight)
            : null;

        const throttle = distancePastTop || distancePastBottom;

        const newTranslateY = throttle
          ? translateY + distanceY / (throttle / 8)
          : translateY + distanceY;

        direction = distanceY < 0 ? 'down' : 'up';
        translate(newTranslateY);
      } else {
        direction = distanceX < 0 ? 'right' : 'left';
      }
    }

    prevPageY = pageY;
    prevPageX = pageX;
  };

  const bounce = y => {
    const {
      current: { style },
    } = containerRef;

    style.transition = 'all ease 0.5s';
    translate(y);
    setTimeout(() => {
      style.transition = 'none';
    }, 500);
  };

  const momentum = () => {
    const {
      current: { style, clientHeight: containerHeight },
    } = containerRef;
    const {
      current: { clientHeight: parentHeight },
    } = parentRef;

    const force = Math.abs(distanceY) * 5;
    const y = direction === 'up' ? translateY + force : translateY - force;
    const pastTop = y > 0;
    const pastBottom = containerHeight + y < parentHeight;
    const containerBottom = -(containerHeight - parentHeight);

    if (pastTop || pastBottom) {
      bounce(pastBottom ? containerBottom : 0);
      return;
    }

    style.transition = 'all ease 0.5s';
    translate(y);

    setTimeout(() => {
      style.transition = 'none';
    }, 500);
  };

  const onTouchEnd = () => {
    if (!containerRef.current || !parentRef.current) {
      return;
    }

    const {
      current: { clientHeight: containerHeight },
    } = containerRef;

    const {
      current: { clientHeight: parentHeight },
    } = parentRef;

    if (containerHeight < parentHeight) {
      return;
    }

    const pastTop = translateY > 0;
    const pastBottom = containerHeight + translateY < parentHeight;
    const containerBottom = -(containerHeight - parentHeight);

    if (pastTop || pastBottom) {
      bounce(pastBottom ? containerBottom : 0);
    } else {
      momentum();
    }

    prevPageY = 0;
    prevPageX = 0;
    distanceY = 0;
    distanceX = 0;
  };

  if (!data) return null;

  return (
    <Container
      display="flex"
      flexDirection="column"
      padding="20px 20px 0 20px"
      minWidth="0"
      {...props}
    >
      <Container display="flex" flexWrap="wrap" marginBottom="20px">
        <Container
          display="flex"
          marginBottom="10px"
          marginRight="10px"
          flex="1"
        >
          <Container
            padding="3px"
            boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.2)"
            borderRadius="100%"
            height="42px"
            flex="0 0 42px"
            marginRight="12px"
            overflow="hidden"
          >
            <Image
              width="100%"
              height="100%"
              objectFit="contain"
              src={
                logo
                  ? imageResizer.resize(logo, { width: 100, height: 100 })
                  : placeholderLogo
              }
            />
          </Container>
          <Container>
            <Text
              display="block"
              fontSize="16px"
              fontWeight="600"
              lineHeight="1.2"
            >
              {displayName}
            </Text>
            <Container display="flex">
              <RatingStars size="13px" rating={averageRating} />
              {!!averageRating && (
                <Text
                  fontSize="12px"
                  color="text300"
                  fontWeight="600"
                  marginLeft="3px"
                >
                  {averageRating.toFixed(1)}
                </Text>
              )}
            </Container>
          </Container>
        </Container>
        {!hideShare && (
          <Container marginLeft="auto">
            <EmptyButton
              border="1px solid"
              borderColor="border300"
              borderRadius="50px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="normal"
              fontWeight="600"
              fontSize="14px"
              height="38px"
              width={mobile ? '38px' : 'auto'}
              padding={mobile ? '0' : '0 18px'}
              marginRight="22px"
              onClick={e => {
                onShareShow();
                setShowShare(true);
                e.stopPropagation();
              }}
            >
              <Icon
                className="ls-icon icon-generalshare"
                fontSize="15px"
                marginRight={mobile ? '0' : '7px'}
              />
              {!mobile && 'Share'}
            </EmptyButton>
          </Container>
        )}
      </Container>

      <Container flex="1" width="100%" position="relative" minHeight="0">
        {image ? (
          <Container
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            ref={parentRef}
            overflow={isTouch() ? 'hidden' : 'auto'}
          >
            <Container
              marginTop="auto"
              marginBottom="auto"
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              ref={containerRef}
            >
              <Image
                width="100%"
                borderRadius="20px"
                marginBottom="20px"
                src={imageResizer.resize(image)}
              />
              <Container padding="0 20px 0 20px">
                {!!startDate && !!endDate && (
                  <Container
                    backgroundColor="#eaefff"
                    borderRadius="50px"
                    color="text700"
                    fontSize="14px"
                    padding="8px 25px"
                    fontWeight="600"
                    display="inline-block"
                    margin="20px 0"
                    lineHeight="1.2"
                  >
                    Valid {moment(startDate).format('D MMM YYYY')} -{' '}
                    {moment(endDate).format('D MMM YYYY')}
                  </Container>
                )}
                <StyledText
                  lineHeight="1.2"
                  fontSize={description ? titleSize : '40px'}
                  display="block"
                  fontWeight="bold"
                  marginBottom="20px"
                >
                  {title}
                </StyledText>
                <StyledText
                  fontSize={descriptionSize}
                  display="block"
                  marginBottom="30px"
                >
                  {description}
                </StyledText>
                {!!actionType && (
                  <Container
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="30px"
                  >
                    <Anchor
                      target="_blank"
                      href={`${actionTypes[actionType] || ''}${
                        data[actionType]
                      }`}
                      borderRadius="30px"
                      backgroundColor="white"
                      fontSize="16px"
                      color="#285aff"
                      border="1px solid"
                      borderColor={th => th.primary.primary200}
                      padding="10px 20px"
                      display="flex"
                      alignItems="center"
                      fontWeight="600"
                      lineHeight="1.2"
                    >
                      {text}
                    </Anchor>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
        ) : (
          <Container paddingBottom="20px" height="100%">
            <Container overflow="hidden" height="100%">
              <Container
                borderRadius="20px"
                overflow="hidden"
                width="100%"
                position="absolute"
                left="0"
                top="0"
                right="0"
                bottom="20px"
              >
                <Image
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  src={themes[theme] || themes.lsGradient}
                />
              </Container>
              <Container
                width="100%"
                height="100%"
                position="relative"
                zIndex="2"
                ref={parentRef}
                display="flex"
                overflow={isTouch() ? 'hidden' : 'auto'}
              >
                <Container
                  paddingBottom="20px"
                  padding={mobile ? '25px' : '45px'}
                  marginTop="auto"
                  marginBottom="auto"
                  ref={containerRef}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}
                >
                  {!!startDate && !!endDate && (
                    <Container
                      backgroundColor="#eaefff"
                      borderRadius="50px"
                      color="text700"
                      fontSize="14px"
                      padding="8px 25px"
                      fontWeight="600"
                      display="inline-block"
                      margin="20px 0"
                      lineHeight="1.2"
                    >
                      Valid {moment(startDate).format('D MMM YYYY')} -{' '}
                      {moment(endDate).format('D MMM YYYY')}
                    </Container>
                  )}
                  <StyledText
                    fontSize={description ? titleSize : '40px'}
                    color="white"
                    fontWeight="bold"
                    display="block"
                    lineHeight="1.2"
                    marginBottom="20px"
                  >
                    {title}
                  </StyledText>
                  <StyledText
                    fontSize={descriptionSize}
                    color="white"
                    display="block"
                    marginBottom="30px"
                  >
                    {description}
                  </StyledText>
                  {!!actionType && (
                    <Container
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="30px"
                    >
                      <Anchor
                        target="_blank"
                        href={`${actionTypes[actionType] || ''}${
                          data[actionType]
                        }`}
                        borderRadius="30px"
                        backgroundColor="white"
                        fontSize="16px"
                        color="normal"
                        padding="10px 20px"
                        display="flex"
                        alignItems="center"
                        fontWeight="600"
                        lineHeight="1.2"
                      >
                        {text}
                      </Anchor>
                    </Container>
                  )}
                </Container>
              </Container>
            </Container>
          </Container>
        )}
      </Container>
      <Share
        show={showShare}
        url={`${lsOnlineUrlRoot}/listing-post/${id}`}
        onClose={() => {
          onShareClose();
          setShowShare(false);
        }}
        title={title}
        zIndex="9999999"
      />
    </Container>
  );
};

PostCard.propTypes = {
  data: PropTypes.shape({}),
  onShareShow: PropTypes.func,
  onShareClose: PropTypes.func,
  hideShare: PropTypes.bool,
};

PostCard.defaultProps = {
  data: null,
  onShareShow: () => {},
  onShareClose: () => {},
  hideShare: false,
};

export default PostCard;
