import React, { useContext } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import { Context } from '../context/AppContext';

const useDeleteNotificationSlider = ({ heading } = {}) => {
  const { notificationSlider } = useContext(Context);

  const open = (props = {}) => {
    notificationSlider.open({
      heading: props.heading || heading,
      icon: (
        <Container
          width="90px"
          height="90px"
          borderRadius="100%"
          backgroundColor="#EAEFFF"
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="15px"
        >
          <Icon
            color="text700"
            fontSize="40px"
            className="ls-icon icon-categorysanitarydisposal"
          />
        </Container>
      ),
    });
  };

  return {
    open,
  };
};

export default useDeleteNotificationSlider;
