import React, { useState, useContext, useMemo } from 'react';
import getVideoId from 'get-video-id';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Animation from 'ls-common-client/src/components/Animation';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import AddVideosDialog from './AddVideosDialog';
import MediaPanel from '../../../UI/molecules/MediaPanel';
import Validator from '../../../UI/atoms/Validator';
import { Context } from '../../../../context/AppContext';
import uploading from '../../../../animations/uploading.json';
import mediaBackground from '../../../../images/mediaBackground.svg';
import { MEDIA_TYPES } from '../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';
import useDeleteNotificationSlider from '../../../../hooks/useDeleteNotificationSlider';

const { YOUTUBE, VIMEO } = MEDIA_TYPES;

const getVimeoThumb = async id => {
  const res = await fetch(`https://vimeo.com/api/v2/video/${id}.json`);
  const [{ thumbnail_large: thumbnailLarge }] = await res.json();
  return thumbnailLarge;
};

const getYouTubeThumb = id => `https://img.youtube.com/vi/${id}/0.jpg`;

const getValidUrl = originalUrl => {
  const { id, service } = getVideoId(originalUrl);

  if (!id) {
    return originalUrl;
  }

  let url;

  if (service === 'youtube') {
    url = `https://www.youtube.com/watch?v=${id}`;
  }

  if (service === 'vimeo') {
    url = `https://vimeo.com/${id}`;
  }

  return url;
};

const getImgSrc = async url => {
  const { id, service } = getVideoId(url);

  let thumbnail;

  if (service === 'youtube') {
    thumbnail = getYouTubeThumb(id);
  }

  if (service === 'vimeo') {
    thumbnail = await getVimeoThumb(id);
  }

  return thumbnail;
};

const getOtherMedia = media =>
  media
    .filter(({ type }) => ![YOUTUBE, VIMEO].includes(type))
    .map(({ url, type, background, description }) => ({
      url,
      type,
      background,
      description,
    }));

const Videos = () => {
  const {
    media: { mobile, desktop },
    profile: {
      profile,
      update: { update },
    },
  } = useContext(Context);

  const { media } = profile;

  const successNotificationSlider = useSuccessNotificationSlider();
  const deleteNotificationSlider = useDeleteNotificationSlider();

  const videos = useMemo(
    () =>
      media
        .filter(({ type }) => [YOUTUBE, VIMEO].includes(type))
        .map(({ url, description, type, background }) => ({
          url,
          description,
          type,
          background,
        })),
    [media]
  );

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [showVideoDialog, setShowVideoDialog] = useState();

  const showAddMultipleSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your videos were successfully added.',
    });
  };

  const showAddSingleSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your video was successfully added.',
    });
  };

  const showDescriptionSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your video description was successfully updated!',
    });
  };

  const showDeleteSuccess = () => {
    deleteNotificationSlider.open({
      heading: 'Your video has been deleted.',
    });
  };

  const onAddVideoSubmit = async newMedia => {
    setShowVideoDialog(false);
    setLoading(true);

    const existingMedia = media.map(
      ({ url, type, background, description }) => ({
        url,
        type,
        background,
        description,
      })
    );

    try {
      await update({ media: [...newMedia, ...existingMedia] });
      setError(null);
      if (newMedia.length > 1) {
        showAddMultipleSuccess();
      } else {
        showAddSingleSuccess();
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDescriptionSubmit = async ({ description }, i) => {
    setLoading(true);

    const videoMedia = videos.map((video, index) =>
      i === index ? { ...video, description: description || null } : video
    );

    const otherMedia = getOtherMedia(media);

    try {
      await update({ media: [...videoMedia, ...otherMedia] });
      setError(null);
      showDescriptionSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async i => {
    const videoMedia = videos.filter((video, index) => i !== index);
    const otherMedia = getOtherMedia(media);

    setLoading(true);

    try {
      await update({ media: [...videoMedia, ...otherMedia] });
      setError(null);
      showDeleteSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && !mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {((!videos.length && !mobile) || mobile) && (
        <Container
          flex={mobile ? '0 0 190px' : '1'}
          background={mobile ? `url(${mediaBackground}) no-repeat` : 'none'}
          margin="0 -20px 30px -20px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundSize="cover"
          backgroundPosition="bottom"
          position="relative"
        >
          <Container
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Text
              fontSize="25px"
              color="normal"
              fontWeight="bold"
              marginBottom="15px"
            >
              Add Videos
            </Text>
            <Text
              fontSize="16px"
              color="text400"
              marginBottom="25px"
              maxWidth="280px"
            >
              These videos appear on your profile page
            </Text>
            <EmptyButton
              onClick={() => setShowVideoDialog(true)}
              position={mobile ? 'absolute' : 'relative'}
              bottom={mobile ? '-20px' : 0}
              accept=".pdf"
              fontSize="16px"
              color="text700"
              fontWeight="600"
              boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
              backgroundColor="white"
              borderRadius="30px"
              height="40px"
              padding="0 25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              multiple
            >
              <Icon
                className="ls-icon icon-pluscircle"
                marginRight="10px"
                fontSize="20px"
              />
              Add Videos
            </EmptyButton>
          </Container>
        </Container>
      )}

      {error && mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {!!videos.length && (
        <Container display="flex" margin="0 -10px" flexWrap="wrap">
          {!mobile && (
            <Container flex={desktop ? '0 0 25%' : '0 0 50%'} display="flex">
              <EmptyButton
                onClick={() => setShowVideoDialog(true)}
                flex="1"
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="transparent"
                border={theme => `3px dashed ${theme.border.border300}`}
                borderRadius="20px"
                margin="10px"
              >
                <Container
                  fontSize="16px"
                  color="text700"
                  fontWeight="600"
                  boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
                  backgroundColor="white"
                  borderRadius="30px"
                  height="40px"
                  padding="0 25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position={mobile ? 'absolute' : 'relative'}
                  bottom={mobile ? '-15px' : '0'}
                >
                  <Icon
                    className="ls-icon icon-pluscircle"
                    marginRight="10px"
                    fontSize="20px"
                  />
                  Add Videos
                </Container>
              </EmptyButton>
            </Container>
          )}

          {videos.map((video, i) => (
            <Container key={video.url} flex={desktop ? '0 0 25%' : '0 0 50%'}>
              <MediaPanel
                type="Video"
                data={{ ...video, url: getValidUrl(video.url) }}
                onSubmit={values => onDescriptionSubmit(values, i)}
                onDelete={() => onDelete(i)}
                anchor={mobile && i % 2 === 0 ? 'topLeft' : 'topRight'}
                imgSrc={getImgSrc}
              />
            </Container>
          ))}
        </Container>
      )}

      {loading && (
        <Container
          position="fixed"
          left="0"
          top="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.3)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          padding="0 30px"
        >
          <Animation
            width="450px"
            display="flex"
            alignItems="center"
            data={uploading}
            options={{
              rendererSettings: {
                preserveAspectRatio: 'none',
              },
            }}
          />
        </Container>
      )}
      <AddVideosDialog
        show={showVideoDialog}
        onSubmit={onAddVideoSubmit}
        onClose={() => setShowVideoDialog(false)}
      />
    </>
  );
};

export default Videos;
