import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Anchor from 'ls-common-client/src/components/Anchor';
import Icon from 'ls-common-client/src/components/Icon';
import FormDialogHeader from '../../FormDialogHeader';
import FormDialog from '../../FormDialog';
import HelpSearch from './HelpSearch';

const HelpDialog = ({ onClose, ...props }) => (
  <FormDialog maxWidth="630px" {...props}>
    <FormDialogHeader
      onClose={onClose}
      header="Help"
      subHeader="Discover how to troubleshoot and more ways to optimise your business profile. Still need help? Reach out to our friendly team."
      marginBottom="0"
    />
    <Container padding="15px 0" display="flex" flexWrap="wrap" margin="0 -5px">
      <Anchor
        href="mailto:digitalpresence@localsearch.com.au"
        fontSize="16px"
        color="normal"
        display="flex"
        alignItems="center"
        backgroundColor="background300"
        padding="5px 15px"
        borderRadius="30px"
        margin="5px"
        wordBreak="break-all"
        lineHeight="1"
        _hover={{
          color: 'text700',
        }}
      >
        <Icon
          className="ls-icon icon-categorypostoffices"
          fontSize="20px"
          marginRight="10px"
        />
        digitalpresence@localsearch.com.au
      </Anchor>
      <Anchor
        href="tel:1300 360 867"
        fontSize="16px"
        color="normal"
        display="flex"
        alignItems="center"
        backgroundColor="background300"
        padding="5px 15px"
        borderRadius="30px"
        margin="5px"
        wordBreak="break-all"
        lineHeight="1"
        _hover={{
          color: 'text700',
        }}
      >
        <Icon
          className="ls-icon icon-generalcalllarge"
          fontSize="20px"
          marginRight="10px"
        />
        1300 360 867
      </Anchor>
    </Container>
    <HelpSearch />
  </FormDialog>
);

HelpDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default HelpDialog;
