import { gql } from '@apollo/client';

const removeReviewResponse = gql`
  mutation removeReviewResponse($reviewId: String!) {
    removeReviewResponse(reviewId: $reviewId) {
      success
    }
  }
`;

export default removeReviewResponse;
