import { gql } from '@apollo/client';

const updateReviewResponse = gql`
  mutation updateReviewResponse($input: UpdateReviewResponseInput!) {
    updateReviewResponse(input: $input) {
      reviewResponse {
        id
      }
    }
  }
`;

export default updateReviewResponse;
