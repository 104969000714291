import { useMutation } from '@apollo/client';
import client from '../services/client';
import { addEnquiryResponse as addEnquiryResponseMutation } from '../graphql/mutations';

const useEnquiryResponse = () => {
  const [
    addEnquiryResponse,
    { loading: addEnquiryResponseLoading, error: addEnquiryResponseError },
  ] = useMutation(addEnquiryResponseMutation);

  const add = async input => {
    const { enquiryId, message } = input;

    await addEnquiryResponse({ variables: { input } });

    client.cache.modify({
      id: `Enquiry:${enquiryId}`,
      fields: {
        enquiryResponse: () => ({
          message,
        }),
      },
    });
  };

  return {
    add: {
      add,
      loading: addEnquiryResponseLoading,
      error: addEnquiryResponseError,
    },
  };
};

export default useEnquiryResponse;
