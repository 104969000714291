import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Panel from '../../UI/atoms/Panel';
import { Context } from '../../../context/AppContext';
import { MEDIA_TYPES } from '../../../lib/constants';
import imageResizer from '../../../services/imageResizer';
import PaidFeatureToolTip from '../../UI/molecules/PaidFeatureToolTip';
import noImages from '../../../images/noImages.svg';

const { IMAGE } = MEDIA_TYPES;

const Images = props => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { name: packageName } = profile.package || {};

  const { profileId } = useParams();

  const { media } = profile;

  const images = useMemo(
    () => media.filter(({ type }) => type === IMAGE),
    [media]
  );

  return (
    <Panel display="flex" flexDirection="column" {...props}>
      <Container display="flex" marginBottom="15px" alignItems="center">
        <Text fontSize="16px" fontWeight="600">
          Images
        </Text>
        {!packageName && <PaidFeatureToolTip marginLeft="10px" />}
      </Container>
      {images.length ? (
        <Container
          display="flex"
          justifyContent="center"
          flexDirection="column"
          flex="1"
          marginBottom="10px"
        >
          <Container position="relative" height="80px" marginBottom="10px">
            {images.slice(0, 3).map(({ url }, i) => (
              <Container
                key={url}
                borderRadius="7px"
                border={theme => `1px solid ${theme.border.border300}`}
                padding="2px"
                boxShadow={
                  i === 0 ? 'none' : '-2px 0 4px 0 rgba(0, 0, 0, 0.15)'
                }
                position="absolute"
                top="0"
                left={i === 1 ? '25%' : 'auto'}
                right={i === 2 ? '0' : 'auto'}
                width="50%"
                height="80px"
                background="white"
              >
                <Image
                  src={imageResizer.resize(url, {
                    width: '160px',
                    height: '160px',
                  })}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  borderRadius="7px"
                />
              </Container>
            ))}
          </Container>
          <Text
            fontSize="14px"
            color="text300"
            display="block"
            textAlign="center"
          >
            {images.length} Photo{images.length > 1 && 's'}
          </Text>
        </Container>
      ) : (
        <Container
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          marginBottom="20px"
        >
          <Image src={noImages} />
          <Text
            textAlign="center"
            fontSize="14px"
            color="text300"
            maxWidth="130px"
            fontWeight="600"
            lineHeight="1.2"
          >
            Make your profile stand out by adding images.
          </Text>
        </Container>
      )}
      <Container
        padding="15px 0 0 0"
        display="flex"
        justifyContent="center"
        marginTop="auto"
        borderTop={theme => `1px solid ${theme.border.border300}`}
      >
        <AnchorLink
          to={`/media/${profileId}/images`}
          color="text700"
          fontSize="14px"
          fontWeight="600"
        >
          Add Images
        </AnchorLink>
      </Container>
    </Panel>
  );
};

export default Images;
