import React, { useContext } from 'react';
import { Context } from '../../../context/AppContext';
import ReviewsComponents from './Reviews';
import HideReviews from './HideReviews';

const Reviews = () => {
  const {
    profile: { profile },
  } = useContext(Context);

  const {
    settings: { showReviews },
  } = profile;

  if (showReviews) {
    return <ReviewsComponents />;
  }

  return <HideReviews />;
};

export default Reviews;
