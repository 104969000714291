import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import Form from 'ls-common-client/src/components/Form';
import Container from 'ls-common-client/src/components/Container';
import TextArea from '../../../../UI/atoms/TextArea';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import Validator from '../../../../UI/atoms/Validator';

const schema = Joi.object({
  message: Joi.string().required().messages({
    'string.empty': 'A reply is required',
  }),
});

const QuoteResponseForm = ({
  message,
  onClose,
  onSubmit,
  loading,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      message: message || '',
    },
    resolver: joiResolver(schema),
  });

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      noValidate
      flex="1"
      {...props}
    >
      <Container marginBottom="15px">
        <TextArea
          {...register('message')}
          placeholder="Write something here..."
          height="155px"
          resize="none"
        />
        <Validator>{errors?.message?.message}</Validator>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading}>Post Reply</FormDialogSubmit>
      </Container>
    </Form>
  );
};

QuoteResponseForm.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

QuoteResponseForm.defaultProps = {
  message: null,
  loading: false,
};

export default QuoteResponseForm;
