import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import FancyInput from 'ls-common-client/src/components/FancyInput';
import Button from 'ls-common-client/src/components/Button';
import Text from 'ls-common-client/src/components/Text';
import Form from 'ls-common-client/src/components/Form';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { addressJoin } from '../../../../../lib/util';
import Validator from '../../../../UI/atoms/Validator';
import { createInvitation as createInvitationMutation } from '../../../../../graphql/mutations';
import { profileSuggest } from '../../../../../graphql/queries';
import { Context } from '../../../../../context/AppContext';
import client from '../../../../../services/client';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

let timeout;

const StyledEmptyButton = styled(EmptyButton)`
  &:focus {
    .ProfileSearchResultsButton {
      background: ${props => props.theme.background.background300};
    }
  }
`;

const fetchResults = async query => {
  const { data } = await client.query({
    query: profileSuggest,
    variables: {
      query,
    },
  });

  const {
    profileSuggest: { edges },
  } = data;

  return edges;
};

const schema = Joi.object({
  businessName: Joi.string().messages({
    'string.empty': 'This field is required.',
  }),
  profileId: Joi.string().messages({
    'string.empty': 'You forgot to select a business from the drop down.',
  }),
  name: Joi.string().messages({
    'string.empty': 'This field is required.',
  }),
  email: Joi.string().email({ tlds: false }).messages({
    'string.email': 'Invalid email',
    'string.empty': 'This field is required.',
  }),
});

const InvitationForm = ({ onClose, ...props }) => {
  const {
    profile: { profile = {} },
  } = useContext(Context);

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Invitation sent!',
  });

  const [createInvitation, { loading }] = useMutation(createInvitationMutation);

  const autoSuggestRef = useRef();
  const [autoSuggestLoading, setAutoSuggestLoading] = useState();
  const [results, setResults] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      businessName: profile.displayName || '',
      profileId: profile.id || '',
    },
  });

  const submit = async ({ profileId, name, email }) => {
    await createInvitation({
      variables: {
        input: {
          name,
          email,
          roleNames: [`L_${profileId}`],
          redirect: window.location.origin,
        },
      },
    });
    successNotificationSlider.open();
    onClose();
  };

  const onBusinessNameChange = ({ target }) => {
    clearTimeout(timeout);
    setValue('profileId', '');

    if (target.value) {
      setAutoSuggestLoading(true);
      timeout = setTimeout(async () => {
        setResults(await fetchResults(target.value));
        setAutoSuggestLoading(false);
      }, 1500);
    } else {
      setAutoSuggestLoading(false);
      setResults(null);
    }
  };

  const onSelect = ({ id, displayName }) => {
    setValue('businessName', displayName);
    setValue('profileId', id);
    setResults(null);
  };

  const onKeyDown = e => {
    const { which } = e;

    if (which === 40) {
      e.preventDefault();
      autoSuggestRef?.current?.firstChild.focus();
    }
  };

  return (
    <Form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(submit)}
      {...props}
    >
      <Container marginBottom="5px" position="relative">
        <FancyInput
          placeholder="Business Name *"
          autoComplete="new-password"
          onKeyDown={onKeyDown}
          {...register('businessName', { onChange: onBusinessNameChange })}
        />
        <AutoSuggest
          ref={autoSuggestRef}
          data={results}
          loading={autoSuggestLoading}
          onSelect={({ node }) => onSelect(node)}
          render={({ node, onKeyDown: onKeyDownRender }) => (
            <StyledEmptyButton
              key={node.id}
              onKeyDown={onKeyDownRender}
              onClick={() => onSelect(node)}
              width="100%"
            >
              <Text
                margin="0 10px"
                height="1px"
                backgroundColor="background300"
                display="block"
              />
              <Text padding="3px" display="block">
                <Text
                  className="ProfileSearchResultsButton"
                  key={node.id}
                  textAlign="left"
                  padding="15px"
                  borderRadius="11px"
                  display="block"
                  _hover={{
                    backgroundColor: 'background200',
                  }}
                >
                  <Text
                    display="block"
                    color="normal"
                    fontSize="14px"
                    lineHeight="1"
                    marginBottom="3px"
                  >
                    {node.displayName}
                  </Text>
                  <Text
                    display="block"
                    color="text400"
                    fontSize="10px"
                    lineHeight="1.1"
                  >
                    {addressJoin(
                      node.street,
                      node.suburb,
                      node.stateCode,
                      node.postcode
                    )}
                  </Text>
                </Text>
              </Text>
            </StyledEmptyButton>
          )}
          boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.15)"
          border="none"
          width="100%"
          height="380px"
          zIndex="9"
        />
        <Validator>{errors.profileId?.message}</Validator>
      </Container>
      <Container marginBottom="5px">
        <FancyInput placeholder="Name *" {...register('name')} />
        <Validator>{errors.name?.message}</Validator>
      </Container>
      <Container marginBottom="50px">
        <FancyInput placeholder="Email Address *" {...register('email')} />
        <Validator>{errors.email?.message}</Validator>
      </Container>
      <Button loading={loading} type="submit" primary rounded height="40px">
        Send Invite
      </Button>
    </Form>
  );
};

InvitationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default InvitationForm;
