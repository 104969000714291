import { gql } from '@apollo/client';

const setProfilePaymentTypes = gql`
  mutation setProfilePaymentTypes($input: SetProfilePaymentTypesInput) {
    setProfilePaymentTypes(input: $input) {
      paymentTypes {
        name
      }
    }
  }
`;

export default setProfilePaymentTypes;
