import React, { useEffect, useRef } from 'react';
import CropperJS from 'cropperjs';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import 'cropperjs/dist/cropper.css';

const Cropper = ({
  src,
  imageHeight,
  imageWidth,
  options,
  cropperRef,
  ...props
}) => {
  const imageRef = useRef();

  useEffect(() => {
    const cropper = new CropperJS(imageRef.current, {
      ...options,
    });

    cropperRef(cropper);

    return () => cropper.destroy();
  }, []);

  return (
    <Container {...props}>
      <Image
        ref={imageRef}
        src={src}
        display="block"
        maxWidth="100%"
        height={imageHeight}
        width={imageWidth}
      />
    </Container>
  );
};

Cropper.propTypes = {
  src: PropTypes.string,
  cropperRef: PropTypes.func,
  options: PropTypes.shape({}),
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
};

Cropper.defaultProps = {
  src: null,
  options: {},
  cropperRef: () => null,
  imageHeight: '100%',
  imageWidth: '100%',
};

export default Cropper;
