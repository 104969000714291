import { gql } from '@apollo/client';

const setProfileAmenities = gql`
  mutation setProfileAmenities($input: SetProfileAmenitiesInput) {
    setProfileAmenities(input: $input) {
      amenities {
        id
        name
      }
    }
  }
`;

export default setProfileAmenities;
