import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import { EmptyButton } from 'ls-common-client/src/components';
import Panel from '../../UI/atoms/Panel';
import ToolTip from '../../UI/molecules/ToolTip';
import insightsEmptySearchTerms from '../../../images/insightsEmptySearchTerms.svg';
import { Context } from '../../../context/AppContext';

const SearchTerms = ({ data, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [showToolTip, setShowToolTip] = useState();
  const [showAll, setShowAll] = useState();

  const listData = useMemo(() => {
    if (!data || !data.length) {
      return [];
    }
    return data.filter(({ clicks }) => clicks.value);
  }, [data, showAll]);

  return (
    <Panel display="flex" flexDirection="column" padding="0" {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
        padding="18px 18px 0 18px"
      >
        <Text fontWeight="600" lineHeight="1.2" marginRight="20px">
          Search Terms Used to Find Your Profile
        </Text>
        <ToolTip
          show={showToolTip}
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
          anchor="topRight"
          arrowAnchor="top"
          render={() => (
            <Text
              display="block"
              fontSize="14px"
              lineHeight="1.2"
              color="text400"
            >
              These are the terms users searched for to find your business
              profile.
            </Text>
          )}
          popupProps={{
            right: '-15px',
            width: '210px',
            padding: '15px',
          }}
        >
          <Icon className="ls-icon icon-generalabout" fontSize="20px" />
        </ToolTip>
      </Container>
      {listData.length ? (
        <Container
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          position="relative"
        >
          <Container
            position={mobile ? 'relative' : 'absolute'}
            height="100%"
            overflow="auto"
            padding="0 18px 18px 18px"
            width="100%"
          >
            {listData
              .slice(0, showAll || !mobile ? listData.length : 8)
              .map(({ name, clicks }) => (
                <Container
                  key={name}
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="10px"
                >
                  <Text
                    fontSize="16px"
                    color="text400"
                    marginRight="15px"
                    flex="1"
                  >
                    {name}
                  </Text>
                  <Text textAlign="right" fontSize="16px" color="normal">
                    {clicks.value}
                  </Text>
                </Container>
              ))}
          </Container>
          {listData.length > 8 && mobile && (
            <EmptyButton
              fontSize="14px"
              color="text700"
              fontWeight="600"
              borderTop={theme => `1px solid ${theme.border.border300}`}
              paddingTop="15px"
              onClick={() => setShowAll(!showAll)}
              margin="0 18px"
              paddingBottom="18px"
            >
              See {showAll ? 'less' : 'more'}
            </EmptyButton>
          )}
        </Container>
      ) : (
        <Container
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="20px"
        >
          <Image src={insightsEmptySearchTerms} />
          <Text
            color="#9b9b9b"
            fontSize="14px"
            fontWeight="600"
            maxWidth="140px"
            lineHeight="1.2"
          >
            You have no insights on search terms yet.
          </Text>
        </Container>
      )}
    </Panel>
  );
};

SearchTerms.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchTerms.defaultProps = {
  data: null,
};

export default SearchTerms;
