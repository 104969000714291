import { gql } from '@apollo/client';

const enquiries = gql`
  query enquiries($listingId: String, $first: Int, $after: String) {
    enquiries(listingId: $listingId, first: $first, after: $after) {
      edges {
        node {
          id
          message
          market
          region
          state
          suburbName
          enquiryContact {
            name
            email
            phone
          }
          enquiryAssets {
            edges {
              node {
                id
                url
              }
            }
          }
          user {
            avatar
            displayName
          }
          enquiryResponse {
            message
          }
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default enquiries;
