import React, { useContext } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Image from 'ls-common-client/src/components/Image';
import Icon from 'ls-common-client/src/components/Icon';
import Form from 'ls-common-client/src/components/Form';
import Validator from '../../../UI/atoms/Validator';
import Input from '../../../UI/atoms/Input';
import FormDialogSubmit from '../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../context/AppContext';
import facebookIcon from '../../../../images/facebook.svg';
import linkedInIcon from '../../../../images/linkedIn.svg';
import instagramIcon from '../../../../images/instagram.svg';
import twitterIcon from '../../../../images/twitter.svg';
import pinterestIcon from '../../../../images/pinterest.svg';
import tumblrIcon from '../../../../images/tumblr.svg';
import youtubeIcon from '../../../../images/youtube.svg';
import { SOCIAL_LABELS, CONTACT_TYPES } from '../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';

const { FACEBOOK, LINKEDIN, INSTAGRAM, TWITTER, PINTEREST, TUMBLR, YOUTUBE } =
  SOCIAL_LABELS;

const { SOCIAL } = CONTACT_TYPES;

const socials = [
  {
    label: FACEBOOK,
    name: 'Facebook',
    icon: facebookIcon,
  },
  { label: LINKEDIN, name: 'LinkedIn', icon: linkedInIcon },
  { label: INSTAGRAM, name: 'Instagram', icon: instagramIcon },
  { label: TWITTER, name: 'Twitter', icon: twitterIcon },
  { label: PINTEREST, name: 'Pinterest', icon: pinterestIcon },
  { label: TUMBLR, name: 'Tumblr', icon: tumblrIcon },
  { label: YOUTUBE, name: 'YouTube', icon: youtubeIcon },
];

const getSocialData = label => socials.find(s => s.label === label);

const schema = Joi.object({
  contacts: Joi.array().items(
    Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      value: Joi.string().allow(''),
    }).custom((contact, { message }) => {
      const { type, label, value } = contact;

      if (type === SOCIAL) {
        try {
          Joi.attempt(value, Joi.string().uri());
        } catch (e) {
          const { name } = getSocialData(label) || {};
          return message(`Invalid ${name} URL`);
        }
      }

      return contact;
    })
  ),
});

const parseContactsIn = contacts =>
  socials.reduce((acc, social) => {
    const match = contacts.find(contact => social.label === contact.label);
    const { type, label, value } = match || {};
    return match ? [...acc, { type, label, value }] : acc;
  }, []);

const getOtherContacts = contacts =>
  contacts.reduce((acc, { label, primary, type, value }) => {
    const isSocial = socials.some(social => label === social.label);
    return isSocial ? acc : [...acc, { label, primary, type, value }];
  }, []);

const SocialAccountsForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
    media: { mobile },
  } = useContext(Context);

  const { contacts = [] } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      contacts: parseContactsIn(contacts),
    },
    resolver: joiResolver(schema),
  });

  const submit = async values => {
    const otherContacts = getOtherContacts(contacts);
    await update({ contacts: [...otherContacts, ...values.contacts] });
    successNotificationSlider.open();
    onClose();
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const selectSocial = label => {
    const i = fields.findIndex(field => field.label === label);

    if (i === -1) {
      append({
        label,
        type: SOCIAL,
        value: '',
      });
    } else {
      remove(i);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      noValidate
      display="flex"
      flexDirection="column"
      {...props}
    >
      <Container>
        <Container
          display={mobile ? 'grid' : 'flex'}
          gridTemplateColumns="auto auto auto auto"
          rowGap="10px"
          flexWrap="wrap"
          marginBottom="20px"
          justifyContent="space-between"
        >
          {socials.map(({ label, icon }) => {
            const active = fields.some(field => field.label === label);

            return (
              <Container
                key={label}
                flex={mobile ? '0 0 25%' : '0'}
                position="relative"
              >
                {active && (
                  <Container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="20px"
                    height="20px"
                    borderRadius="100%"
                    backgroundColor="#44d7b6"
                    position="absolute"
                    top="5px"
                    right="5px"
                    border="1px solid white"
                  >
                    <Icon
                      className="ls-icon icon-generalticksmall"
                      color="white"
                      fontSize="12px"
                      marginBottom="-2px"
                    />
                  </Container>
                )}
                <EmptyButton
                  onClick={() => selectSocial(label)}
                  padding="10px"
                  borderRadius="11px"
                  _hover={{
                    backgroundColor: 'background300',
                  }}
                >
                  <Image src={icon} />
                </EmptyButton>
              </Container>
            );
          })}
        </Container>
        <Container marginBottom="20px">
          {fields.map(({ label, id }, i) => {
            const { name, icon } = getSocialData(label) || {};

            return (
              <Container key={id} marginBottom="10px">
                <Container marginBottom="5px" position="relative">
                  <Container
                    position="absolute"
                    left="8px"
                    top="13px"
                    pointerEvents="none"
                  >
                    <Image width="24px" height="24px" src={icon} />
                  </Container>
                  <Input
                    {...register(`contacts.${i}.value`)}
                    placeholder={`Paste ${name} profile URL here...`}
                    paddingLeft="40px"
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                  <Validator>{errors.contacts?.[i]?.message}</Validator>
                </Container>
              </Container>
            );
          })}
        </Container>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

SocialAccountsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SocialAccountsForm;
