import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import PaymentMethodsForm from './PaymentMethodsForm';

const BusinessDetailsDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="750px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Payment Methods"
        subHeader="Let customers know what payment methods you accept."
        onClose={onClose}
      />
      <PaymentMethodsForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

BusinessDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BusinessDetailsDialog;
