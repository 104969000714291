import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../../UI/molecules/FormDialogHeader';
import EnquiryResponseForm from './EnquiryResponseForm';

const EnquiryResponseDialog = ({
  onClose,
  onSubmit,
  message,
  loading,
  ...props
}) => {
  return (
    <FormDialog onClose={onClose} maxWidth="420px" {...props}>
      <FormDialogHeader
        header="Reply as Owner"
        subHeader="Remember, nearly half of consumers expect an enquiry response within 4 hours or less!"
        onClose={onClose}
      />
      <EnquiryResponseForm
        message={message}
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </FormDialog>
  );
};

EnquiryResponseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  loading: PropTypes.bool,
};

EnquiryResponseDialog.defaultProps = {
  message: null,
  loading: false,
};

export default EnquiryResponseDialog;
