import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import Slider from './Slider';
import { Context } from '../../../../context/AppContext';

const PostModal = ({ show, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  return (
    <Dialog
      mode={mobile ? 'mobile' : 'desktop'}
      maxWidth="610px"
      borderRadius="30px"
      overflow="hidden"
      display="flex"
      zIndex="9999"
      flexDirection="column"
      show={show}
      render={({ children }) => (
        <Container
          display="flex"
          flexDirection="column"
          flex={mobile ? 1 : '0 1 890px'}
          overflow="hidden"
        >
          {children}
        </Container>
      )}
    >
      <Slider {...props} />
    </Dialog>
  );
};

PostModal.propTypes = {
  show: PropTypes.bool,
};

PostModal.defaultProps = {
  show: false,
};

export default PostModal;
