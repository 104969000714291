import React from 'react';
import PropTypes from 'prop-types';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import FormDialog from '../../../UI/molecules/FormDialog';
import TradingHoursForm from './TradingHoursForm';

const TradingHoursDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog onClose={onClose} maxWidth="550px" {...props}>
      <FormDialogHeader
        header="Trading Hours"
        subHeader="Select which days you're open and add hours."
        onClose={onClose}
      />
      <TradingHoursForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

TradingHoursDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TradingHoursDialog;
