import { gql } from '@apollo/client';

const addEnquiryResponse = gql`
  mutation addEnquiryResponse($input: AddEnquiryResponseInput!) {
    addEnquiryResponse(input: $input) {
      enquiryResponse {
        id
      }
    }
  }
`;

export default addEnquiryResponse;
