import React from 'react';
import PropTypes from 'prop-types';
import ProductsServicesForm from './ProductsServicesForm';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';

const ProductsServicesDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog onClose={onClose} maxWidth="825px" {...props}>
      <FormDialogHeader
        header="Products, Services &amp; More"
        subHeader="Let potential customers know how you can help them by selecting the best attributes below for your business profile."
        onClose={onClose}
      />
      <ProductsServicesForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

ProductsServicesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ProductsServicesDialog;
