import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useApolloClient } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import Loader from 'ls-common-client/src/components/Loader';
import Validator from '../../../atoms/Validator';
import imageResizer from '../../../../../services/imageResizer';
import uploader from '../../../../../services/uploader';

const moderateAssetQuery = gql`
  query moderateAsset($url: String!) {
    moderateAsset(url: $url)
  }
`;

const FileUploader = ({ value, onRemove, onUploadSuccess, ...props }) => {
  const client = useApolloClient();

  const [uploadError, setUploadError] = useState();
  const [loading, setLoading] = useState();

  const moderateImage = async (url, type) => {
    if (!type.includes('image')) {
      return true;
    }

    const {
      data: { moderateAsset },
    } = await client.query({
      query: moderateAssetQuery,
      variables: { url },
      fetchPolicy: 'no-cache',
    });

    return moderateAsset;
  };

  const checkError = ({ size, type }) => {
    let err;
    const acceptedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    const maxSize = 1024 * 1024 * 10;

    if (size > maxSize) {
      err =
        'Oops, your file size is too big. Please try again ensuring the file is a maximum of 10MB.';
    }

    if (!acceptedTypes.includes(type)) {
      err =
        "Oops, you've selected an invalid file format. You can try again with a jpeg, png or pdf file. ";
    }

    if (err) {
      throw new Error(err);
    }
  };

  const onSelected = async ([file]) => {
    const { type } = file;

    try {
      checkError(file);
    } catch (e) {
      setUploadError(e.message);
      return;
    }

    setLoading(true);

    const url = await uploader.upload(file);
    const moderate = await moderateImage(url, type);

    setLoading(false);

    if (!moderate) {
      setUploadError(
        'Oh my! Your image does not meet our community guidelines. Please try again with a more g-rated image. Need help? Contact 1300 360 867. '
      );
      return;
    }

    setUploadError('');
    onUploadSuccess(url);
  };

  return (
    <Container {...props}>
      <Container display="flex">
        {value ? (
          <Container position="relative">
            <Container
              width="60px"
              height="60px"
              border="1px solid"
              borderColor="border300"
              borderRadius="6px"
              overflow="hidden"
            >
              <Image
                objectFit="cover"
                src={imageResizer.resize(value, { width: 120, height: 120 })}
                width="100%"
                height="100%"
              />
            </Container>
            <EmptyButton
              position="absolute"
              right="-16px"
              top="-16px"
              padding="10px"
              onClick={onRemove}
            >
              <Container
                backgroundColor="rgba(0,0,0,.4)"
                borderRadius="100%"
                width="21px"
                height="21px"
                border="1px solid white"
              >
                <Icon
                  iconColor="white"
                  fontSize="9px"
                  className="ls-icon icon-generalxlarge"
                />
              </Container>
            </EmptyButton>
          </Container>
        ) : (
          <FileUpload
            onSelected={onSelected}
            maxUploads={1}
            name="fileUploader"
            accept=".jpg,.jpeg,.png,.pdf"
            position="relative"
          >
            <Container
              borderRadius="30px"
              width="auto"
              display="flex"
              alignItems="center"
              border="1px solid"
              borderColor={theme => theme.primary.primary200}
              fontSize="14px"
              height="40px"
              color="text700"
              padding="0 20px"
              fontWeight="normal"
            >
              <Icon className="ls-icon icon-uploadIcon" fontSize="18px" />
              <Text marginLeft="10px">Upload File</Text>
            </Container>
            {loading && (
              <Container
                position="absolute"
                left="0"
                top="0"
                width="100%"
                height="100%"
                backgroundColor="rgba(255,255,255,0.5)"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Loader width="150px" />
              </Container>
            )}
          </FileUpload>
        )}
      </Container>
      <Container marginTop="10px">
        <Validator visible={uploadError}>{uploadError}</Validator>
      </Container>
    </Container>
  );
};

FileUploader.propTypes = {
  value: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
};

FileUploader.defaultProps = {
  value: null,
};

export default FileUploader;
