import React from 'react';
import Text from 'ls-common-client/src/components/Text';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import dragDropBackground from '../../../images/dragDropBackground.svg';

const DragDropScreen = props => (
  <FileUpload
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding="20px"
    {...props}
  >
    <Container
      position="absolute"
      width="100%"
      height="100%"
      left="0"
      top="0"
      zIndex="-1"
    >
      <Image
        width="100%"
        height="100%"
        objectFit="cover"
        src={dragDropBackground}
      />
    </Container>
    <Container
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Text
        fontSize="50px"
        fontWeight="600"
        color="white"
        lineHeight="1.1"
        marginBottom="30px"
      >
        Drag and Drop
      </Text>
      <Text fontSize="18px" fontWeight="600" color="white" lineHeight="1.2">
        Upload files by dropping them into the window
      </Text>
    </Container>
  </FileUpload>
);

export default DragDropScreen;
