import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Doughnut from 'ls-common-client/src/components/charts/Doughnut';
import Panel from '../../UI/atoms/Panel';
import ToolTip from '../../UI/molecules/ToolTip';

const gradients = [
  {
    from: '#ff7f00',
    to: '#ffbe00',
  },
  {
    from: '#98b0fd',
    to: '#2d60fb',
  },
  {
    from: '#40a499',
    to: '#61e7d9',
  },
  { from: '#ee7aeb', to: '#849ae8' },
  {
    from: '#fad961',
    to: '#faec45',
  },
  {
    from: '#65d3e4',
    to: '#78a1ef',
  },
  {
    from: '#0071A6',
    to: '#00A699',
  },
  {
    from: '#FFE144',
    to: '#F04CFF',
  },
];

const getFontSize = length => {
  let fontSize = '60px';

  if (length > 5) {
    fontSize = '30px';
  }

  if (length === 5) {
    fontSize = '40px';
  }

  if (length === 4) {
    fontSize = '45px';
  }

  return fontSize;
};

const setBackgroundColor = ({ chart: { ctx } }, i) => {
  const { from, to } = gradients[i] || gradients[0];
  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
  gradient.addColorStop(0, from);
  gradient.addColorStop(1, to);

  return gradient;
};

const Referrer = ({ data, ...props }) => {
  const [showToolTip, setShowToolTip] = useState();

  const total = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.reduce((acc, { count }) => acc + count, 0);
  }, [data]);

  const chartData = useMemo(() => {
    if (!data || !data.length) {
      return {
        datasets: [
          {
            data: [1],
            backgroundColor: '#ececec',
          },
        ],
      };
    }

    const labels = data.map(({ referrer }) => referrer);

    const datasets = [
      {
        data: data.map(({ count }) => count),
        backgroundColor: context =>
          data.map((item, i) => setBackgroundColor(context, i)),
      },
    ];

    return {
      datasets,
      labels,
    };
  }, [data]);

  const legendData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map(({ count, referrer }, i) => {
      const { from, to } = gradients[i] || gradients[0];

      return {
        background: `linear-gradient(90deg, ${from} 0%, ${to} 100%)`,
        label: referrer,
        value: `${Math.round((count / total) * 100)}%`,
      };
    });
  }, [data, total]);

  return (
    <Panel display="flex" flexDirection="column" {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Text fontWeight="600" lineHeight="1.2" marginRight="30px">
          Where Users Found Your Profile
        </Text>
        <ToolTip
          show={showToolTip}
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
          anchor="topRight"
          arrowAnchor="top"
          render={() => (
            <Text
              display="block"
              fontSize="14px"
              lineHeight="1.2"
              color="text400"
            >
              Discover where online users were searching to find your business
              profile.
            </Text>
          )}
          popupProps={{
            right: '-15px',
            width: '210px',
            padding: '15px',
          }}
        >
          <Icon className="ls-icon icon-generalabout" fontSize="20px" />
        </ToolTip>
      </Container>
      <Container
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="20px"
        >
          <Doughnut
            width="220px"
            height="220px"
            data={chartData}
            options={{
              cutout: '70%',
              borderWidth: 0,
            }}
          />

          <Container
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Text
              fontSize={getFontSize(total.toString().length)}
              fontWeight="bold"
              color="#505d6f"
              lineHeight="1"
            >
              {total.toLocaleString()}
            </Text>
            <Text color="text300" fontSize="16px" fontWeight="600">
              Total
            </Text>
          </Container>
        </Container>
        <Container
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          flexDirection="column"
          marginLeft="15px"
        >
          {legendData.map(({ background, label, value }) => (
            <Container
              key={label}
              display="flex"
              alignItems="center"
              margin="8px 0"
            >
              <Container
                width="14px"
                height="14px"
                borderRadius="100%"
                marginRight="8px"
                background={background}
              />
              <Text
                fontSize="14px"
                color="text500"
                fontWeight="bold"
                marginRight="5px"
              >
                {value}
              </Text>
              <Text color="text400" fontSize="14px">
                {label}
              </Text>
            </Container>
          ))}
        </Container>
      </Container>
    </Panel>
  );
};

Referrer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

Referrer.defaultProps = {
  data: null,
};

export default Referrer;
