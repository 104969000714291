import { gql } from '@apollo/client';

const reviewAggregates = gql`
  query reviewAggregates($listingId: String) {
    reviewAggregates(listingId: $listingId) {
      averageRating
      count
    }
  }
`;

export default reviewAggregates;
