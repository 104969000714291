import React from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';

const Checkbox = ({ active, activeColor, ...props }) => (
  <Text
    width="18px"
    height="18px"
    position="relative"
    display="block"
    borderRadius="3px"
    border={theme => (active ? 'none' : `1px solid ${theme.border.border400}`)}
    backgroundColor={active ? '#44d7b6' : 'white'}
    {...props}
  >
    {active && (
      <Icon
        className="ls-icon icon-generalticksmall"
        position="absolute"
        color="white"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        fontSize="10px"
      />
    )}
  </Text>
);

Checkbox.propTypes = {
  active: PropTypes.bool,
  activeColor: PropTypes.string,
};

Checkbox.defaultProps = {
  active: false,
  activeColor: '#4bc0c0',
};

export default Checkbox;
