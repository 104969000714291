import { gql } from '@apollo/client';

const createInvitation = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`;

export default createInvitation;
