import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import StatusForm from './StatusForm';

const StatusDialog = ({ onClose, statusValues, ...props }) => {
  return (
    <FormDialog maxWidth="420px" onClose={onClose} {...props}>
      <FormDialogHeader header="Profile Status" onClose={onClose} />
      <StatusForm onClose={onClose} flex="1" statusValues={statusValues} />
    </FormDialog>
  );
};

StatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  statusValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StatusDialog;
