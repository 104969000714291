import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Anchor from 'ls-common-client/src/components/Anchor';
import { isTouch } from 'ls-common-client/src/util';
import Panel from '../../UI/atoms/Panel';
import { Context } from '../../../context/AppContext';
import { createProfileUrl } from '../../../lib/util';

const StyledEmptyButton = styled(EmptyButton)`
  &:hover {
    i {
      opacity: 1;
    }
    > div {
      background-color: ${props => props.theme.background.background200};
    }
  }
`;

const Menu = ({ items, onClick, ...props }) => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { vanityUrlPath } = profile;

  return (
    <Panel padding="0 20px" display="flex" flexDirection="column" {...props}>
      {items.map((item, i) => (
        <Container key={item.key} onClick={() => onClick(i)}>
          <StyledEmptyButton
            fontSize="16px"
            color="normal"
            fontWeight="600"
            padding="5px 0"
            width="100%"
            textAlign="left"
            borderBottom={theme => `1px solid ${theme.border.border300}`}
          >
            <Container
              display="flex"
              alignItems="center"
              padding="12px 10px"
              borderRadius="11px"
              margin="0 -10px"
              transition="all 0.3s ease"
            >
              <Text
                height="17px"
                flex="0 0 17px"
                backgroundColor={
                  item.isDataCompleted ? '#44d7b6' : 'background500'
                }
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginRight="8px"
              >
                <Icon
                  className="ls-icon icon-generalticksmall"
                  color="white"
                  fontSize="10px"
                />
              </Text>
              <Text
                marginRight="10px"
                lineHeight="1.2"
                display="flex"
                alignItems="center"
              >
                {item.text}
              </Text>
              <Icon
                transition="all 0.3s ease"
                opacity={isTouch() ? 1 : 0}
                className="ls-icon icon-pencilcircle"
                fontSize="17px"
                color="text200"
                marginLeft="auto"
              />
            </Container>
          </StyledEmptyButton>
        </Container>
      ))}
      <Anchor
        href={createProfileUrl(vanityUrlPath)}
        target="_blank"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        color="text700"
        fontWeight="600"
        fontSize="14px"
        marginTop="auto"
        height="58px"
      >
        Preview Profile{' '}
        <Icon
          className="ls-icon icon-external-link"
          marginLeft="7px"
          fontSize="13px"
        />
      </Anchor>
    </Panel>
  );
};

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      isDataCompleted: PropTypes.bool,
      key: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Menu;
