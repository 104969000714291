import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Popup from 'ls-common-client/src/components/Popup';
import Menu from 'ls-common-client/src/components/Menu';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import { fromFile, toFile } from 'ls-common-client/src/image';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import Loader from 'ls-common-client/src/components/Loader';
import Validator from '../../../atoms/Validator';
import { Context } from '../../../../../context/AppContext';
import uploader from '../../../../../services/uploader';
import CropperDialog from '../../CropperDialog';

const moderateAssetQuery = gql`
  query moderateAsset($url: String!) {
    moderateAsset(url: $url)
  }
`;

const StyledUploadContainer = styled(Container)`
  cursor: pointer;
`;

const StyledDragOverContainer = styled(Container)`
  pointer-events: none;
`;

const ImageUploader = ({ value, onUploadSuccess, onRemove, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  const fileUploadRef = useRef();
  const [showImageMenu, setShowImageMenu] = useState();
  const [showCropperDialog, setCropperDialog] = useState();
  const [originalImage, setOriginalImage] = useState(value);
  const [uploadError, setUploadError] = useState();
  const [isOver, setIsOver] = useState();
  const [loading, setLoading] = useState();

  const client = useApolloClient();

  const checkError = ({ size, type }) => {
    let err;
    const acceptedTypes = ['image/png', 'image/jpeg'];
    const maxSize = 1024 * 1024 * 10;

    if (size > maxSize) {
      err =
        'Oops, your file size is too big. Please try again ensuring the file is a maximum of 10MB.';
    }
    if (!acceptedTypes.includes(type)) {
      err =
        "Oops, you've selected an invalid file format. You can try again with a jpeg or png file. ";
    }

    if (err) {
      throw new Error(err);
    }
  };

  const onFileSelected = async ([file]) => {
    try {
      checkError(file);
      const img = await fromFile(file);
      setOriginalImage(img.src);
      setCropperDialog(true);
      setUploadError(null);
    } catch (e) {
      setUploadError(e.message);
    }
  };

  const onUplodClick = () => {
    if (value) {
      setShowImageMenu(true);
    } else {
      fileUploadRef.current.click();
    }
  };

  const moderateImage = async url => {
    const res = await client.query({
      query: moderateAssetQuery,
      variables: { url },
      fetchPolicy: 'no-cache',
    });
    return res;
  };

  const onCrop = async img => {
    setLoading(true);
    setCropperDialog(false);
    setShowImageMenu(false);

    const file = await toFile(img);
    const url = await uploader.upload(file);

    const {
      data: { moderateAsset },
    } = await moderateImage(url);

    setLoading(false);

    if (!moderateAsset) {
      setUploadError(
        'Oh my! Your image does not meet our community guidelines. Please try again with a more g-rated image. Need help? Contact 1300 360 867. '
      );
      return;
    }

    onUploadSuccess(url);
  };

  const onFileDrop = e => {
    e.preventDefault();
    setIsOver(false);
    onFileSelected(e.dataTransfer.files);
  };

  const onEditImage = () => {
    setCropperDialog(true);
    setShowImageMenu(false);
  };

  const onUploadNewImage = () => {
    fileUploadRef.current.click();
    setShowImageMenu(false);
  };

  const onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOver(true);
  };

  const onDragLeave = () => {
    setIsOver(false);
  };

  return (
    <Container {...props}>
      <FileUpload
        ref={fileUploadRef}
        onSelected={onFileSelected}
        display="none"
        accept=".jpg,.jpeg,.png"
      />
      <StyledUploadContainer
        type="button"
        position="relative"
        onClick={onUplodClick}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onFileDrop}
      >
        {loading && (
          <Container
            position="absolute"
            left="0"
            top="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(255,255,255, 0.5)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="2"
          >
            <Loader width="200px" />
          </Container>
        )}
        {isOver && (
          <StyledDragOverContainer
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right="0"
            padding="20px"
            backgroundColor="rgba(255,255,255,0.7)"
            zIndex="5"
          >
            <Container
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              border="2px dashed"
              borderColor="#97aeff"
            >
              <Icon fontSize="30px" className="ls-icon icon-uploadIcon" />
            </Container>
          </StyledDragOverContainer>
        )}
        {value ? (
          <>
            <ClickOutside
              onClickOutside={() => setShowImageMenu(false)}
              width="100%"
              paddingBottom="49.2%"
            >
              <Container
                width="100%"
                height="100%"
                borderRadius="11px"
                overflow="hidden"
                position="absolute"
                left="0"
                top="0"
              >
                <Image
                  objectFit="cover"
                  width="100%"
                  height="100%"
                  src={value}
                />
              </Container>
              <Container
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%, -50%)"
              >
                <Container
                  backgroundColor="rgba(0,0,0,0.4)"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  borderRadius="5px"
                  fontSize="18px"
                  padding="3px 15px"
                  height="45px"
                >
                  <Icon
                    iconSize="22px"
                    marginRight="10px"
                    className="ls-icon icon-categoryphotography"
                  />
                  Change
                </Container>
                <Popup
                  show={showImageMenu}
                  onClose={() => setShowImageMenu(false)}
                  width="240px"
                  padding="0 10px"
                  zIndex="3"
                >
                  <Menu
                    data={[
                      {
                        text: 'Edit Image',
                        onClick: onEditImage,
                        icon: (
                          <Icon
                            fontSize="20px"
                            className="ls-icon icon-generaledit"
                            marginRight="15px"
                          />
                        ),
                        key: 'editImage',
                      },
                      {
                        splitter: true,
                        key: 'splitter',
                      },
                      {
                        text: 'Upload New Image',
                        onClick: onUploadNewImage,
                        icon: (
                          <Icon
                            fontSize="20px"
                            className="ls-icon icon-generalshare"
                            marginRight="15px"
                          />
                        ),
                        key: 'uploadNewImage',
                      },
                    ]}
                  />
                </Popup>
              </Container>
            </ClickOutside>
            <EmptyButton
              borderRadius="100px"
              width="26px"
              height="26px"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              right="15px"
              top="15px"
              onClick={onRemove}
            >
              <Icon
                color="white"
                iconSize="12px"
                className="ls-icon icon-generalxlarge"
              />
            </EmptyButton>
          </>
        ) : (
          <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            padding={mobile ? '20px' : '20px 40px'}
            height="220px"
            border="1px solid"
            borderColor="border300"
            borderRadius="11px"
          >
            <Text
              display="block"
              textAlign="center"
              fontWeight="600"
              fontSize="16px"
              marginBottom="12px"
              lineHeight="1.1"
            >
              Drag &amp; Drop an Image Here
            </Text>
            <Text
              display="block"
              textAlign="center"
              fontSize="14px"
              color="text300"
              marginBottom="20px"
              maxWidth="400px"
            >
              Really stand out from the crowd with a photo or graphic for your
              post! Simply click the Upload Button or drag and drop your images
              into this box!
            </Text>
            <Container
              width="auto"
              display="flex"
              alignItems="center"
              border="1px solid"
              borderColor={theme => theme.primary.primary200}
              borderRadius="30px"
              fontSize="14px"
              height="40px"
              color="text700"
              padding="0 15px"
            >
              <Icon
                className="ls-icon icon-categoryphotography"
                fontSize="19px"
                marginRight="8px"
              />
              Upload Image
            </Container>
          </Container>
        )}
      </StyledUploadContainer>
      <Validator marginTop="5px" display="block" visible={uploadError}>
        {uploadError}
      </Validator>
      <CropperDialog
        show={showCropperDialog}
        onClose={() => setCropperDialog(false)}
        src={originalImage}
        options={{
          aspectRatio: 570 / 280,
          autoCropArea: 1,
          dragMode: 'move',
          toggleDragModeOnDblclick: false,
          cropBoxMovable: false,
          cropBoxResizable: false,
          wheelZoomRatio: 0.05,
        }}
        onCrop={onCrop}
      />
    </Container>
  );
};

ImageUploader.propTypes = {
  value: PropTypes.string,
  onUploadSuccess: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ImageUploader.defaultProps = {
  value: null,
};

export default ImageUploader;
