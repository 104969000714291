import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Anchor from 'ls-common-client/src/components/Anchor';
import Container from 'ls-common-client/src/components/Container';
import Ribbon from '../../../../UI/atoms/Ribbon';
import ToolTip from '../../../../UI/molecules/ToolTip';
import { Context } from '../../../../../context/AppContext';
import config from '../../../../../config';

const { b2bRoot } = config;

const HighlightCheckbox = ({ children, onClose, ...props }) => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { name: packageName } = profile.package || {};

  return (
    <ToolTip
      anchor="topLeft"
      arrowAnchor="top"
      render={() => (
        <>
          {packageName ? (
            <Text
              display="block"
              fontSize="14px"
              lineHeight="1.3"
              color="text400"
            >
              This will appear as a business highlight on your business card in
              the search results.
            </Text>
          ) : (
            <>
              <Container display="flex" alignItems="center" marginBottom="5px">
                <Ribbon />
                <Text
                  marginLeft="10px"
                  fontWeight="600"
                  lineHeight="1.3"
                  fontSize="14px"
                >
                  This is a paid feature.
                </Text>
              </Container>
              <Container>
                <Text
                  display="block"
                  color="text300"
                  lineHeight="1.3"
                  fontSize="14px"
                >
                  <Anchor
                    href={`${b2bRoot}/contact-us`}
                    target="_blank"
                    type="button"
                    color="text700"
                    cursor="pointer"
                  >
                    Upgrade
                  </Anchor>{' '}
                  from as little as $2 a day to show business highlights on your
                  profile.
                </Text>
              </Container>
            </>
          )}
          <Container marginTop="5px">
            <EmptyButton
              onClick={onClose}
              color="text700"
              fontWeight="600"
              fontSize="14px"
            >
              Got it
            </EmptyButton>
          </Container>
        </>
      )}
      popupProps={{
        width: '210px',
        left: '-10px',
        padding: '15px',
      }}
      display="flex"
      alignItems="center"
      {...props}
    >
      {children}
    </ToolTip>
  );
};

HighlightCheckbox.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

HighlightCheckbox.defaultProps = {
  children: null,
  onClose: () => {},
};

export default HighlightCheckbox;
