import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import AppMenu from 'ls-common-client/src/components/AppMenu';
import UserMenu from 'ls-common-client/src/components/UserMenu';
import auth from '../../../../services/auth';
import imageResizer from '../../../../services/imageResizer';
import { Context } from '../../../../context/AppContext';
import ToolTip from '../ToolTip';
import HelpDialog from './HelpDialog';

const PageHeader = ({ children, ...props }) => {
  const {
    user: { user, isStaff, isPersoniv },
    media: { desktop },
    sideBar,
    profile: { profile },
  } = useContext(Context);

  const [showHelpDialog, setShowHelpDialog] = useState();

  return (
    <>
      <Container minHeight={desktop ? 'auto' : '62px'} {...props}>
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          top="0"
          zIndex="4"
          border={desktop ? 'none' : '1px solid'}
          borderColor="border300"
          position={desktop ? 'relative' : 'fixed'}
          padding={desktop ? '25px 20px' : '15px 20px'}
          backgroundColor={desktop ? 'none' : 'white'}
          borderRadius={desktop ? '0' : '0 0 42px 42px'}
        >
          <Container display="flex" alignItems="center">
            {!desktop && (
              <ToolTip
                anchor="left"
                render={() => (
                  <Text
                    fontSize="12px"
                    fontWeight="600"
                    display="flex"
                    alignItems="center"
                  >
                    <Icon
                      color={theme => theme.error.normal}
                      fontSize="18px"
                      className="ls-icon icon-warning"
                      marginRight="5px"
                    />
                    Search a business to start
                  </Text>
                )}
                show={!profile && !desktop}
                popupProps={{
                  width: '200px',
                }}
              >
                <EmptyButton
                  display="flex"
                  alignItems="center"
                  onClick={sideBar.open}
                  fontSize="30px"
                  marginRight="15px"
                  color="normal"
                >
                  <Icon className="ls-icon icon-generalmenu" />
                </EmptyButton>
              </ToolTip>
            )}
            <Text
              color="normal"
              fontSize="22px"
              fontWeight="600"
              lineHeight="1"
            >
              {children}
            </Text>
          </Container>
          <Container display="flex" alignItems="center">
            <EmptyButton
              onClick={() => setShowHelpDialog(true)}
              display="flex"
              alignItems="center"
              marginRight={desktop ? '15px' : '10px'}
              color="normal"
            >
              <Icon fontSize="18px" className="ls-icon icon-generalabout" />
              {desktop && (
                <Text color="normal" fontSize="14px" marginLeft="5px">
                  Help
                </Text>
              )}
            </EmptyButton>
            <AppMenu
              width={desktop ? '34px' : '30px'}
              height={desktop ? '34px' : '30px'}
              iconFill="#222e35"
              isStaff={isStaff}
              isPersoniv={isPersoniv}
            />
            <UserMenu
              width={desktop ? '34px' : '30px'}
              height={desktop ? '34px' : '30px'}
              user={user}
              auth={auth}
              imageResizer={imageResizer}
              iconFill="#222e35"
              marginLeft={desktop ? '15px' : '10px'}
            />
          </Container>
        </Container>
      </Container>
      <HelpDialog
        show={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
      />
    </>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node,
};

PageHeader.defaultProps = {
  children: null,
};

export default PageHeader;
