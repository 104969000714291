import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Form from 'ls-common-client/src/components/Form';
import { Context } from '../../../../../context/AppContext';
import Validator from '../../../../UI/atoms/Validator';
import Checkbox from '../../../../UI/atoms/Checkbox';
import Label from '../../../../UI/atoms/Label';
import Input from '../../../../UI/atoms/Input';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import RemoveButton from '../../../../UI/molecules/RemoveButton';
import { PRODUCT_TYPES } from '../../../../../lib/constants';
import HighlightToolTip from './HighlightToolTip';
import AlertDialog from '../../../../UI/molecules/AlertDialog';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

const { PRODUCT, SERVICE } = PRODUCT_TYPES;

const schema = Joi.object({
  services: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required().messages({
          'string.empty': 'You forgot to add your service',
        }),
        type: Joi.string(),
        highlight: Joi.bool(),
      })
    )
    .unique('name')
    .messages({
      'array.unique': 'You have duplicate services',
    }),
  licences: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required().messages({
          'string.empty': 'You forgot to add your licence',
        }),
      })
    )
    .unique('name')
    .messages({
      'array.unique': 'You have duplicate licences',
    }),
  products: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required().messages({
          'string.empty': 'You forgot to add your product',
        }),
        type: Joi.string(),
        highlight: Joi.bool(),
      })
    )
    .unique('name')
    .messages({
      'array.unique': 'You have duplicate products',
    }),
});

const productTypes = [
  {
    type: SERVICE,
    plural: 'services',
  },
  {
    type: PRODUCT,
    plural: 'products',
  },
];

const filterType = (products, filter) =>
  products.reduce((acc, { type, name, highlight }) => {
    if (filter === type) {
      acc.push({
        type,
        name,
        highlight: !!highlight,
      });
    }
    return acc;
  }, []);

const parseProductsIn = products =>
  productTypes.reduce(
    (acc, { type, plural }) => ({
      ...acc,
      [plural]: filterType(products, type),
    }),
    {}
  );

const parseProductsOut = values =>
  productTypes.reduce((acc, { plural }) => [...acc, ...values[plural]], []);

const ProductsServicesForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
    media: { mobile },
  } = useContext(Context);

  const [showHighlightAlert, setShowHighlightAlert] = useState();
  const [showHighlightToolTip, setShowHighlightToolTip] = useState(true);

  const { licences, products } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      licences: licences.map(({ name }) => ({
        name,
      })),
      ...parseProductsIn(products),
    },
    resolver: joiResolver(schema),
  });

  const {
    fields: fieldsServices,
    append: appendService,
    remove: removeService,
  } = useFieldArray({
    control,
    name: 'services',
  });

  const {
    fields: fieldsLicences,
    append: appendLicence,
    remove: removeLicence,
  } = useFieldArray({
    control,
    name: 'licences',
  });

  const {
    fields: fieldsProducts,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: 'products',
  });

  const submit = async values => {
    await update({
      licences: values.licences,
      products: parseProductsOut(values),
    });
    successNotificationSlider.open();
    onClose();
  };

  const highlightCount = () => {
    const values = [...getValues('services'), ...getValues('products')];
    const count = values.reduce(
      (acc, { highlight }) => (highlight ? acc + 1 : acc),
      0
    );

    return count;
  };

  const onCheckboxClick = (value, onChange) => {
    const count = highlightCount() + (value ? -1 : 1);

    if (count <= 4) {
      onChange(!value);
    } else {
      setShowHighlightAlert(true);
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(submit)}
        noValidate
        display="flex"
        flexDirection="column"
        {...props}
      >
        <Container
          display="flex"
          flexDirection={mobile ? 'column' : 'row'}
          margin="0 -15px 20px -15px"
        >
          <Container flex="1" padding="0 15px">
            <Container
              paddingBottom="20px"
              marginBottom="20px"
              borderBottom={theme => `1px solid ${theme.border.border300}`}
            >
              <Label>Services</Label>
              {fieldsServices.map(({ id }, i) => (
                <Container key={id} marginBottom="10px">
                  <Container
                    marginBottom="5px"
                    position="relative"
                    display="flex"
                    alignItems="center"
                  >
                    <Controller
                      name={`services.${i}.highlight`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <HighlightToolTip
                          show={i === 0 && showHighlightToolTip}
                          onClose={() => setShowHighlightToolTip(false)}
                          marginRight="8px"
                        >
                          <EmptyButton
                            onClick={() => onCheckboxClick(value, onChange)}
                          >
                            <Checkbox active={value} />
                          </EmptyButton>
                        </HighlightToolTip>
                      )}
                    />
                    <RemoveButton
                      onClick={() => removeService(i)}
                      position="absolute"
                      right="-8px"
                      top="16px"
                    />
                    <Input
                      {...register(`services.${i}.name`)}
                      placeholder="Add a service"
                    />
                  </Container>
                  <Validator>{errors.services?.[i]?.name?.message}</Validator>
                  <Validator>{errors.services?.[i]?.message}</Validator>
                </Container>
              ))}
              <Container>
                <EmptyButton
                  onClick={() =>
                    appendService({ name: '', type: SERVICE, highlight: false })
                  }
                  color="text700"
                  fontWeight="600"
                  fontSize="14px"
                >
                  {fieldsServices.length ? 'Add additional' : 'Add Service'}
                </EmptyButton>
              </Container>
            </Container>

            <Container
              paddingBottom={mobile ? '20px' : '0'}
              marginBottom={mobile ? '20px' : '0'}
              borderBottom={
                mobile ? theme => `1px solid ${theme.border.border300}` : null
              }
            >
              <Label>Licences</Label>
              {fieldsLicences.map(({ id }, i) => (
                <Container key={id} marginBottom="10px">
                  <Container marginBottom="5px" position="relative">
                    <RemoveButton
                      position="absolute"
                      right="-8px"
                      top="16px"
                      onClick={() => removeLicence(i)}
                    />
                    <Input
                      {...register(`licences.${i}.name`)}
                      placeholder="Add a licence"
                    />
                  </Container>
                  <Validator>{errors.licences?.[i]?.name?.message}</Validator>
                  <Validator>{errors.licences?.[i]?.message}</Validator>
                </Container>
              ))}
              <Container>
                <EmptyButton
                  onClick={() => appendLicence({ name: '' })}
                  color="text700"
                  fontWeight="600"
                  fontSize="14px"
                >
                  {fieldsLicences.length ? 'Add additional' : 'Add Licence'}
                </EmptyButton>
              </Container>
            </Container>
          </Container>

          <Container flex="1" padding="0 15px">
            <Container
              paddingBottom="20px"
              marginBottom="20px"
              borderBottom={theme => `1px solid ${theme.border.border300}`}
            >
              <Label>Products</Label>
              {fieldsProducts.map(({ id }, i) => (
                <Container key={id} marginBottom="10px">
                  <Container marginBottom="5px" flex="1" marginRight="20px">
                    <Container
                      position="relative"
                      marginBottom="5px"
                      display="flex"
                      alignItems="center"
                    >
                      <Controller
                        name={`products.${i}.highlight`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <HighlightToolTip
                            show={
                              i === 0 &&
                              showHighlightToolTip &&
                              !fieldsServices.length
                            }
                            onClose={() => setShowHighlightToolTip(false)}
                            marginRight="8px"
                          >
                            <EmptyButton
                              onClick={() => onCheckboxClick(value, onChange)}
                            >
                              <Checkbox active={value} />
                            </EmptyButton>
                          </HighlightToolTip>
                        )}
                      />
                      <RemoveButton
                        onClick={() => removeProduct(i)}
                        position="absolute"
                        right="-8px"
                        top="16px"
                      />
                      <Input
                        {...register(`products.${i}.name`)}
                        placeholder="Add a product"
                      />
                    </Container>
                    <Validator>{errors.products?.[i]?.name?.message}</Validator>
                    <Validator>{errors.products?.[i]?.message}</Validator>
                  </Container>
                </Container>
              ))}
              <Container>
                <EmptyButton
                  onClick={() =>
                    appendProduct({ name: '', type: PRODUCT, highlight: false })
                  }
                  color="text700"
                  fontWeight="600"
                  fontSize="14px"
                >
                  {fieldsProducts.length ? 'Add additional' : 'Add Product'}
                </EmptyButton>
              </Container>
            </Container>
          </Container>
        </Container>
        <Container
          display="flex"
          justifyContent="flex-end"
          padding="5px"
          marginTop="auto"
        >
          <FormDialogSubmit loading={loading} disabled={!isDirty} />
        </Container>
      </Form>
      <AlertDialog
        show={showHighlightAlert}
        heading="Maximum reached"
        text="You're allowed a maximum of 4 business highlights."
        buttons={[
          {
            children: 'OK',
            props: {
              primary: true,
              rounded: true,
              onClick: () => setShowHighlightAlert(false),
            },
          },
        ]}
      />
    </>
  );
};

ProductsServicesForm.propTypes = {
  onClose: PropTypes.func,
};

ProductsServicesForm.defaultProps = {
  onClose: () => {},
};

export default ProductsServicesForm;
