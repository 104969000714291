import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import UpgradeForm from './UpgradeForm';
import UpgradeSlide from './UpgradeSlide';

const UpgradeDialogMobile = ({ show, onClose, user }) => (
  <Dialog show={show} mode="mobile">
    <Container display="flex" flexDirection="column">
      <Container
        flex="0 0 50%"
        backgroundColor="background200"
        borderRadius="0% 0% 54% 54% / 0% 0% 16% 16%"
      >
        <Container display="flex" justifyContent="flex-end" marginBottom="20px">
          <EmptyButton
            onClick={onClose}
            width="43px"
            height="43px"
            backgroundColor="white"
            boxShadow="0 0 4px 0 rgba(0, 0, 0, 0.2)"
            borderRadius="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            marginRight="20px"
            marginTop="15px"
          >
            <Icon fontSize="14px" className="ls-icon icon-generalxlarge" />
          </EmptyButton>
        </Container>
        <Container padding="0 30px 30px 30px">
          <Container>
            <Text
              fontSize="25px"
              fontWeight="600"
              display="block"
              marginBottom="18px"
              lineHeight="1.1"
              textAlign="center"
            >
              Why you should upgrade
            </Text>
            <UpgradeSlide />
          </Container>
        </Container>
      </Container>
      <Container flex="0 0 50%" padding="50px 30px 30px 30px">
        <Text
          fontSize="25px"
          lineHeight="1"
          fontWeight="600"
          display="block"
          marginBottom="15px"
          textAlign="center"
        >
          Let&apos;s get you started!
        </Text>
        <Text
          fontSize="16px"
          color="text400"
          lineHeight="1.2"
          display="block"
          marginBottom="30px"
          textAlign="center"
        >
          Please confirm the below information are the best details for our team
          to reach you on.
        </Text>
        <UpgradeForm user={user} />
      </Container>
    </Container>
  </Dialog>
);

UpgradeDialogMobile.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

UpgradeDialogMobile.defaultProps = {
  show: false,
  user: undefined,
};

export default UpgradeDialogMobile;
