import { gql } from '@apollo/client';

const user = gql`
  {
    currentUser {
      id
      accountId
      displayName
      avatar
      suburbId
      userRoles {
        edges {
          node {
            roleName
          }
        }
      }
      account {
        firstName
        lastName
        phoneNumber
        email
        isEmailUpdatable
        isPasswordUpdatable
        identities {
          id
          connection
        }
      }
      suburb {
        name
        postcode
        state
      }
    }
  }
`;

export default user;
