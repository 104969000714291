import React from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Anchor from 'ls-common-client/src/components/Anchor';
import postsUnpaid from '../../../images/postsUnpaid.png';
import PageHeader from '../../UI/molecules/PageHeader';
import config from '../../../config';

const { b2bRoot } = config;

const PostsUnpaid = () => (
  <>
    <PageHeader>Posts</PageHeader>
    <Container
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="20px"
    >
      <Container
        maxWidth="450px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Image
          src={postsUnpaid}
          width="100%"
          maxWidth="390px"
          marginBottom="20px"
        />
        <Text
          fontSize="25px"
          lineHeight="1.3"
          fontWeight="bold"
          marginBottom="20px"
          maxWidth="400px"
        >
          Ready to unlock posts to help grow your business?
        </Text>
        <Text
          fontSize="16px"
          color="text400"
          lineHeight="1.4"
          marginBottom="35px"
        >
          Posts help you stand out from the crowd and really snag new customers,
          but they are exclusive to upgraded profiles. Upgrade for less than a
          cup of coffee a day!
        </Text>
        <Anchor
          href={`${b2bRoot}/contact-us`}
          target="_blank"
          color="text700"
          fontSize="16px"
          fontWeight="600"
          backgroundColor="white"
          borderRadius="30px"
          padding="10px 48px"
          width="auto"
          boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
        >
          Upgrade
        </Anchor>
      </Container>
    </Container>
  </>
);

export default PostsUnpaid;
