import { gql } from '@apollo/client';

const addReviewResponse = gql`
  mutation addReviewResponse($input: AddReviewResponseInput!) {
    addReviewResponse(input: $input) {
      reviewResponse {
        id
      }
    }
  }
`;

export default addReviewResponse;
