import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { reviewRatingAggregates as reviewRatingAggregatesQuery } from '../graphql/queries';

const useReviewAggregates = () => {
  const { profileId } = useParams();

  const {
    data: { reviewRatingAggregates } = {},
    loading,
    error,
  } = useQuery(reviewRatingAggregatesQuery, {
    variables: { listingId: profileId },
  });

  return {
    reviewRatingAggregates,
    loading,
    error,
  };
};

export default useReviewAggregates;
