import { gql } from '@apollo/client';

const listingPosts = gql`
  query listingPosts(
    $listingId: String
    $state: ListingPostStateRegex
    $startDate: DateTime
    $endDate: DateTime
    $first: Int
    $after: String
  ) {
    listingPosts(
      listingId: $listingId
      state: $state
      startDate: $startDate
      endDate: $endDate
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          image
          theme
          title
          description
          url
          email
          phone
          file
          text
          startDate
          endDate
          state
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default listingPosts;
