import React, { useState, useContext, useMemo } from 'react';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Menu from './Menu';
import CategoriesLocation from './CategoriesLocation';
import ProfileDetailsDialog from './ProfileDetailsDialog';
import TradingHoursDialog from './TradingHoursDialog';
import ContactInformationDialog from './ContactInformationDialog';
import ProductsServicesDialog from './ProductsServicesDialog';
import PaymentMethodsDialog from './PaymentMethodsDialog';
import SocialAccountsDialog from './SocialAccountsDialog';
import AmenitiesDialog from './AmenitiesDialog';
import { Context } from '../../../context/AppContext';
import ProfileProgress from './ProfileProgress';
import PaidFeatureToolTip from '../../UI/molecules/PaidFeatureToolTip';
import PageHeader from '../../UI/molecules/PageHeader';
import {
  PROFILE_STATUSES,
  CONTACT_TYPES,
  PRODUCT_TYPES,
  SOCIAL_LABELS,
} from '../../../lib/constants';
import StatusDialog from './StatusDialog';
import ReviewStatusFlag from './ReviewStatusFlag';

const { PENDING, PUBLISHED, DISABLED } = PROFILE_STATUSES;
const { PHONE, EMAIL, URL, SOCIAL } = CONTACT_TYPES;
const { PRODUCT, SERVICE } = PRODUCT_TYPES;
const socialLabels = Object.values(SOCIAL_LABELS);

const statusValues = [
  {
    color: '#4bc0c0',
    text: 'Published',
    value: PUBLISHED,
  },
  {
    color: '#ffbe00',
    text: 'Pending',
    value: PENDING,
  },
  {
    color: '#f16159',
    text: 'Disabled',
    value: DISABLED,
  },
];

const isDataCompleted = (keys, data) => keys.every(key => data[key]);

const Info = props => {
  const {
    media: { mobile },
    profile: { profile },
    media: { desktop },
    user: { isStaff, isPersoniv },
  } = useContext(Context);

  const {
    displayName,
    establishedAt,
    frequentlyAskedQuestions,
    about,
    tradingHours,
    contacts,
    products,
    licences,
    paymentTypes,
    amenities,
    status,
  } = profile;

  const { name: packageName } = profile.package || {};

  const dataCompleted = useMemo(() => {
    const values = {
      displayName,
      establishedAt,
      frequentlyAskedQuestions,
      about,
      tradingHours,
      contacts: contacts.filter(({ type }) =>
        [PHONE, EMAIL, URL].includes(type)
      ),
      products: products.filter(({ type }) => type === PRODUCT),
      services: products.filter(({ type }) => type === SERVICE),
      licences,
      paymentTypes,
      socials: contacts.filter(
        ({ type, label }) => type === SOCIAL && socialLabels.includes(label)
      ),
      amenities,
    };

    return Object.keys(values).reduce((acc, key) => {
      if (Array.isArray(values[key])) {
        return {
          ...acc,
          [key]: !!values[key].length,
        };
      }

      if (values[key] && typeof value === 'object') {
        return {
          ...acc,
          [key]: !!Object.keys(values[key]).length,
        };
      }

      return {
        ...acc,
        [key]: !!values[key],
      };
    }, {});
  }, [profile]);

  const menuItems = useMemo(
    () => [
      {
        text: 'Profile Details',
        MenuComponent: ProfileDetailsDialog,
        key: 'profileDetails',
        isDataCompleted: isDataCompleted(
          ['displayName', 'establishedAt', 'about', 'frequentlyAskedQuestions'],
          dataCompleted
        ),
      },
      {
        text: 'Trading Hours',
        MenuComponent: TradingHoursDialog,
        key: 'tradingHours',
        isDataCompleted: isDataCompleted(['tradingHours'], dataCompleted),
      },
      {
        text: 'Contact Information',
        MenuComponent: ContactInformationDialog,
        key: 'contactInformation',
        isDataCompleted: isDataCompleted(['contacts'], dataCompleted),
      },
      {
        text: (
          <>
            Products, Services &amp; more{' '}
            {!packageName && (
              <Text marginLeft="10px" display="flex" alignItems="center">
                <PaidFeatureToolTip
                  heading="Includes a paid feature"
                  height="18px"
                />
              </Text>
            )}
          </>
        ),
        MenuComponent: ProductsServicesDialog,
        key: 'productsServices',
        isDataCompleted: isDataCompleted(
          ['products', 'services', 'licences'],
          dataCompleted
        ),
      },
      {
        text: 'Payment Methods',
        MenuComponent: PaymentMethodsDialog,
        key: 'paymentMethods',
        isDataCompleted: isDataCompleted(['paymentTypes'], dataCompleted),
      },
      {
        text: 'Social Accounts',
        MenuComponent: SocialAccountsDialog,
        key: 'socialAccounts',
        isDataCompleted: isDataCompleted(['socials'], dataCompleted),
      },
      {
        text: (
          <>
            Amenities{' '}
            {!packageName && (
              <Text marginLeft="10px" display="flex" alignItems="center">
                <PaidFeatureToolTip height="18px" />
              </Text>
            )}
          </>
        ),
        MenuComponent: AmenitiesDialog,
        key: 'amenities',
        isDataCompleted: isDataCompleted(['amenities'], dataCompleted),
      },
    ],
    [dataCompleted, packageName]
  );

  const [showIndex, setShowIndex] = useState(null);
  const [showStatusDialog, setShowStatusDialog] = useState();

  const currentStatus = useMemo(
    () => statusValues.find(({ value }) => value === status),
    [status]
  );

  const onMenuClick = i => {
    setShowIndex(i);
  };

  const onClose = () => {
    setShowIndex(null);
  };

  return (
    <>
      <PageHeader>
        {desktop ? (
          <Container display="flex" alignItems="center">
            Profile Information
            {currentStatus && (
              <EmptyButton
                onClick={() => {
                  if (isStaff || (isPersoniv && !packageName)) {
                    setShowStatusDialog(true);
                  }
                }}
                cursor={isStaff || isPersoniv ? 'pointer' : 'default'}
                backgroundColor={currentStatus.color}
                color="white"
                padding="3px 10px"
                borderRadius="30px"
                marginLeft="10px"
                fontSize="12px"
                fontWeight="600"
              >
                {currentStatus.text}
              </EmptyButton>
            )}
            {(isStaff || isPersoniv) && <ReviewStatusFlag marginLeft="8px" />}
          </Container>
        ) : (
          'Profile'
        )}
      </PageHeader>

      {!desktop && (
        <Container
          padding="20px 20px 10px 20px"
          display="flex"
          alignItems="center"
        >
          <Text
            fontSize="22px"
            fontWeight="600"
            lineHeight="1"
            marginRight="10px"
          >
            Profile Information{' '}
          </Text>
          {currentStatus && (
            <EmptyButton
              onClick={() => {
                if (isStaff || isPersoniv) {
                  setShowStatusDialog(true);
                }
              }}
              cursor={isStaff || isPersoniv ? 'pointer' : 'default'}
              backgroundColor={currentStatus.color}
              color="white"
              padding="3px 10px"
              borderRadius="30px"
              fontSize="12px"
              fontWeight="600"
            >
              {currentStatus.text}
            </EmptyButton>
          )}
          {(isStaff || isPersoniv) && <ReviewStatusFlag marginLeft="8px" />}
        </Container>
      )}

      <Container
        padding="0 10px 20px 10px"
        display="flex"
        flexWrap="wrap"
        flexDirection={mobile ? 'column' : 'row'}
        {...props}
      >
        <Container flexGrow="1" display="flex" flexDirection="column">
          <Menu items={menuItems} onClick={onMenuClick} />
          <ProfileProgress flex="1" dataCompleted={dataCompleted} />
        </Container>
        <CategoriesLocation flex="10" minWidth={mobile ? '200px' : '400px'} />
        {menuItems.map(({ MenuComponent, key }, i) => (
          <MenuComponent onClose={onClose} show={showIndex === i} key={key} />
        ))}
      </Container>
      <StatusDialog
        statusValues={statusValues}
        show={showStatusDialog}
        onClose={() => setShowStatusDialog(false)}
      />
    </>
  );
};

export default Info;
