import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import ContactInformationForm from './ContactInformationForm';

const ContactInformationDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="420px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Contact Information"
        subHeader="Enter the best contact details for potential customers to contact you."
        onClose={onClose}
      />
      <ContactInformationForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

ContactInformationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContactInformationDialog;
