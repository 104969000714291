import { gql } from '@apollo/client';

const setProfileCategories = gql`
  mutation setProfileCategories($input: SetProfileCategoriesInput) {
    setProfileCategories(input: $input) {
      categories {
        id
        name
        package {
          id
          name
        }
      }
    }
  }
`;

export default setProfileCategories;
