import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Avatar from 'ls-common-client/src/components/Avatar';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import TextReveal from 'ls-common-client/src/components/TextReveal';
import Anchor from 'ls-common-client/src/components/Anchor';
import { formatGeneric, fromE164 } from 'ls-common-client/src/phone';
import Panel from '../../../UI/atoms/Panel';
import QuoteResponseDialog from './QuoteResponseDialog';
import useQuoteListingResponse from '../../../../hooks/useQuoteListingResponse';
import imageResizer from '../../../../services/imageResizer';
import { QUOTE_SERVICE } from '../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';

const { INSTALLATION, REPAIR, ONGOING, ONE_OFF, COMMERCIAL, OTHER } =
  QUOTE_SERVICE;

const quoteServices = {
  [INSTALLATION]: 'Installation',
  [REPAIR]: 'Repairs',
  [ONGOING]: 'On-going help',
  [ONE_OFF]: 'One-off job',
  [COMMERCIAL]: 'Commercial project',
  [OTHER]: 'Other',
};

const Quote = ({ data, ...props }) => {
  const {
    id,
    quote: {
      message,
      requiredBy,
      suburbName,
      state,
      user,
      quoteQuoteServices,
      quoteContact,
      quoteAssets,
    },
    quoteListingResponse,
    updatedAt,
  } = data;
  const { avatar } = user || {};
  const { edges: services } = quoteQuoteServices;
  const { name, email, phone } = quoteContact || {};
  const { edges: assets } = quoteAssets;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Your reply was sent.',
  });

  const {
    add: { add, loading: addLoading },
  } = useQuoteListingResponse();

  const [showQuoteResponseDialog, setShowQuoteResponseDialog] = useState();

  const onAddSubmit = async values => {
    await add({ quoteListingId: id, ...values });
    setShowQuoteResponseDialog(false);
    successNotificationSlider.open();
  };

  return (
    <>
      <Panel {...props}>
        <Container display="flex" alignItems="center" marginBottom="20px">
          <Container marginRight="10px">
            {avatar ? (
              <Avatar width="38px" height="38px" src={avatar} />
            ) : (
              <Icon fontSize="38px" className="ls-icon icon-avatar" />
            )}
          </Container>
          <Container display="flex" flexDirection="column" marginRight="10px">
            <Text fontSize="18px" fontWeight="bold" lineHeight="1.1">
              {name}
            </Text>
            <Container display="flex" flexWrap="wrap">
              <Text
                fontSize="14px"
                color="text400"
                lineHeight="1"
                whiteSpace="nowrap"
              >
                {moment(updatedAt).fromNow()}
              </Text>
            </Container>
          </Container>
        </Container>
        <Container display="flex" flexWrap="wrap" marginBottom="15px">
          {email && (
            <Anchor
              href={`mailto:${email}`}
              display="flex"
              alignItems="center"
              margin="3px"
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
              _hover={{
                color: 'text700',
              }}
            >
              <Icon
                className="ls-icon icon-categorypostoffices"
                fontSize="16px"
                marginRight="10px"
              />
              {email}
            </Anchor>
          )}
          {phone && (
            <Anchor
              href={`tel:${phone}`}
              display="flex"
              alignItems="center"
              margin="3px"
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
              _hover={{
                color: 'text700',
              }}
            >
              <Icon
                className="ls-icon icon-generalcalllarge"
                fontSize="16px"
                marginRight="10px"
              />
              {formatGeneric(fromE164(phone))}
            </Anchor>
          )}
          {suburbName && (
            <Container
              display="flex"
              alignItems="center"
              margin="3px"
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
            >
              <Icon
                fontSize="16px"
                marginRight="10px"
                className="ls-icon icon-locationpin"
              />
              Located in {suburbName}, {state}
            </Container>
          )}

          {services.map(({ node }) => (
            <Container
              key={node.id}
              display="flex"
              alignItems="center"
              margin="3px"
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
            >
              <Icon
                fontSize="16px"
                marginRight="10px"
                className="ls-icon icon-categoryprofessionalservices"
              />
              {quoteServices[node.quoteServiceName]}
            </Container>
          ))}
          {requiredBy && (
            <Container
              display="flex"
              alignItems="center"
              margin="3px"
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
            >
              <Icon
                className="ls-icon icon-categoryeventsandweddings"
                fontSize="19px"
                marginRight="10px"
              />

              {moment(requiredBy).format('DD/MM/YYYY')}
            </Container>
          )}
        </Container>

        {message && (
          <Container marginBottom="15px">
            <TextReveal
              fontSize="16px"
              lineHeight="1.5"
              marginBottom="10px"
              lines={5}
              whiteSpace="pre-wrap"
              wordBreak="break-word"
              renderShowMore={({ onClick, show }) => (
                <EmptyButton onClick={onClick} fontSize="16px" color="text300">
                  See {show ? 'less' : 'more'}
                  <Icon
                    className={`ls-icon icon-${
                      show ? 'generalcaretupmedium' : 'generalcaretdownmedium'
                    }`}
                    fontSize="10px"
                    marginLeft="8px"
                  />
                </EmptyButton>
              )}
            >
              {message}
            </TextReveal>
          </Container>
        )}

        {!!assets.length && (
          <Container marginBottom="15px">
            <Container
              color="normal"
              borderRadius="30px"
              backgroundColor="background300"
              padding="5px 15px"
              wordBreak="break-word"
              display="inline-block"
            >
              <Icon
                className="ls-icon icon-categoryphotography"
                marginRight="10px"
                fontSize="17px"
              />
              Image{assets.length > 1 && 's'} attached
            </Container>
            <Container display="flex" flexWrap="wrap" margin="0 -8px">
              {assets.map(({ node }) => (
                <Anchor
                  href={node.url}
                  key={node.id}
                  target="_blank"
                  width="106px"
                  height="106px"
                  margin="8px"
                  borderRadius="14px"
                  overflow="hidden"
                >
                  <Image
                    src={imageResizer.resize(node.url, {
                      width: 212,
                      height: 212,
                    })}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                  />
                </Anchor>
              ))}
            </Container>
          </Container>
        )}
        {quoteListingResponse?.message ? (
          <Container
            marginBottom="10px"
            padding="20px"
            backgroundColor="#eaefff"
            borderRadius="15px"
          >
            <TextReveal
              lines={3}
              fontSize="16px"
              lineHeight="1.5"
              marginBottom="10px"
              whiteSpace="pre-wrap"
              wordBreak="break-word"
              renderShowMore={({ onClick, show }) => (
                <EmptyButton onClick={onClick} fontSize="16px" color="text300">
                  See {show ? 'less' : 'more'}
                  <Icon
                    className={`ls-icon icon-${
                      show ? 'generalcaretupmedium' : 'generalcaretdownmedium'
                    }`}
                    fontSize="10px"
                    marginLeft="8px"
                  />
                </EmptyButton>
              )}
            >
              {quoteListingResponse?.message}
            </TextReveal>
          </Container>
        ) : (
          <Container>
            <EmptyButton
              onClick={() => setShowQuoteResponseDialog(true)}
              color="text700"
              borderRadius="30px"
              fontSize="14px"
              fontWeight="600"
              padding="5px 10px"
              marginLeft="-10px"
              _hover={{
                backgroundColor: 'background200',
              }}
            >
              Reply as owner
            </EmptyButton>
          </Container>
        )}
      </Panel>
      <QuoteResponseDialog
        message={quoteListingResponse?.message}
        show={showQuoteResponseDialog}
        onClose={() => setShowQuoteResponseDialog(false)}
        onSubmit={onAddSubmit}
        loading={addLoading}
      />
    </>
  );
};

Quote.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    quote: PropTypes.shape({
      requiredBy: PropTypes.string,
      suburbName: PropTypes.string,
      state: PropTypes.string,
      message: PropTypes.string,
      quoteQuoteServices: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              quoteServiceName: PropTypes.string,
            }),
          })
        ),
      }),
      user: PropTypes.shape({
        avatar: PropTypes.string,
      }),
      quoteContact: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      quoteAssets: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              url: PropTypes.string,
            }),
          })
        ),
      }),
    }),
    quoteListingResponse: PropTypes.shape({
      message: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
  }),
};

Quote.defaultProps = {
  data: null,
};

export default Quote;
