import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'ls-common-client/src/components/Button';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import { Context } from '../../../context/AppContext';

const FormDialogSubmit = ({ children, loading, disabled, ...props }) => {
  const {
    media: { desktop, mobile },
  } = useContext(Context);

  return desktop ? (
    <EmptyButton
      type="submit"
      color="text700"
      fontSize="14px"
      fontWeight="600"
      position="relative"
      padding="10px"
      overflow="hidden"
      disabled={loading || disabled}
      _disabled={{
        color: 'text400',
      }}
      {...props}
    >
      {loading && (
        <Container
          height="100%"
          width="100%"
          backgroundColor="white"
          left="0"
          top="3px"
          position="absolute"
        >
          <Loader
            position="absolute"
            transform="translate(-50%,-50%)"
            top="50%"
            left="50%"
            width="130px"
          />
        </Container>
      )}
      {children}
    </EmptyButton>
  ) : (
    <Button
      type="submit"
      rounded
      primary
      height="40px"
      width={mobile ? '100%' : 'auto'}
      loading={loading}
      disabled={loading || disabled}
      _disabled={{
        color: 'white',
        backgroundColor: 'background500',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

FormDialogSubmit.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormDialogSubmit.defaultProps = {
  children: 'Update',
  loading: false,
  disabled: false,
};

export default FormDialogSubmit;
