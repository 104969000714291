import React, { forwardRef } from 'react';
import CommonTextArea from 'ls-common-client/src/components/TextArea';

const TextArea = forwardRef((props, ref) => (
  <CommonTextArea
    ref={ref}
    backgroundColor="#f8f8f8"
    border="none"
    borderRadius="7px"
    fontSize="16px"
    fontFamily="Circular"
    boxShadow="none"
    _placeholder={{
      color: 'text300',
    }}
    _focus={{
      border: '1px solid #97AEFF',
    }}
    {...props}
  />
));

export default TextArea;
