import { gql } from '@apollo/client';

const reviewRatingAggregates = gql`
  query reviewRatingAggregates($listingId: String) {
    reviewRatingAggregates(listingId: $listingId) {
      rating
      count
    }
  }
`;

export default reviewRatingAggregates;
