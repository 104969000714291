import React, { useContext } from 'react';
import PostsPaid from './PostsPaid';
import PostsUnpaid from './PostsUnpaid';
import { Context } from '../../../context/AppContext';

const Posts = props => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { name: packageName } = profile.package || {};

  return packageName ? <PostsPaid {...props} /> : <PostsUnpaid {...props} />;
};

export default Posts;
