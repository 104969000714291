import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isTouch } from 'ls-common-client/src/util';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Anchor from 'ls-common-client/src/components/Anchor';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Loader from 'ls-common-client/src/components/Loader';
import Image from 'ls-common-client/src/components/Image';
import Panel from '../../UI/atoms/Panel';
import noPostsBackground from '../../../images/noPostsBackground.svg';
import { LISTING_POST_STATES } from '../../../lib/constants';
import { Context } from '../../../context/AppContext';
import useListingPosts from '../../../hooks/useListingPosts';
import themes from '../../../images/listingPostThemes';
import PostModal from '../../UI/molecules/PostModal';
import CreatePostModal from '../../UI/molecules/CreatePostModal';
import PaidFeatureToolTip from '../../UI/molecules/PaidFeatureToolTip';
import useSuccessNotificationSlider from '../../../hooks/useSuccessNotificationSlider';
import config from '../../../config';

const { b2bRoot } = config;

const { ENABLED } = LISTING_POST_STATES;

const LatestPosts = props => {
  const {
    media: { mobile },
    user: { user },
    profile: { profile },
  } = useContext(Context);

  const { name: packageName } = profile.package || {};

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Your post was successfully created!',
  });

  const [showPostModal, setShowPostModal] = useState();
  const [initialSlide, setInitialSlide] = useState();
  const [showCreatePostModal, setShowCreatePostModal] = useState();
  const [postModalPaused, setPostModalPaused] = useState();

  const { profileId } = useParams();

  const variables = useMemo(
    () => ({
      state: ENABLED,
    }),
    []
  );

  const {
    listingPosts,
    loading,
    create: { create },
  } = useListingPosts({
    variables,
  });

  const { edges = [] } = listingPosts || {};

  const onCreateSubmit = async values => {
    await create({
      listingId: profileId,
      userId: user.id,
      state: ENABLED,
      ...values,
    });
    setShowCreatePostModal(false);
    successNotificationSlider.open();
  };

  const onPostClick = i => {
    setShowPostModal(true);
    setInitialSlide(i);
  };

  return (
    <>
      <Panel display="flex" flexDirection="column" {...props}>
        <Container display="flex" marginBottom="15px" alignItems="center">
          <Container display="flex" alignItems="center">
            <Text fontSize="16px" fontWeight="600">
              Latest Posts
            </Text>
            {!packageName && <PaidFeatureToolTip marginLeft="10px" />}
          </Container>
          {packageName && (
            <EmptyButton
              fontSize="14px"
              fontWeight="600"
              marginLeft="auto"
              color="text700"
              onClick={() => setShowCreatePostModal(true)}
            >
              Create Post
            </EmptyButton>
          )}
        </Container>
        {edges.length && packageName ? (
          <Container margin="0 -18px" position="relative" flex="0 0 147px">
            <Container
              display="flex"
              overflowX="auto"
              position="absolute"
              width="100%"
              height="100%"
              paddingBottom="10px"
            >
              {edges.map(({ node: { id, image, title, theme } }, i) => (
                <Container
                  key={id}
                  margin={mobile ? '0 8px' : '0 15px'}
                  minWidth="0"
                  width={mobile ? '97px' : '108px'}
                  flex={mobile ? '0 0 97px' : '0 0 108px'}
                >
                  <Container
                    width={mobile ? '97px' : '108px'}
                    height={mobile ? '97px' : '108px'}
                    overflow="hidden"
                    borderRadius="100%"
                    display="flex"
                    background="linear-gradient(211deg, rgba(185,198,240,1) 0%, rgba(45,96,251,1) 100%)"
                    padding="3px"
                    marginBottom="4px"
                  >
                    <EmptyButton
                      onClick={() => onPostClick(i)}
                      width="100%"
                      height="100%"
                      border="3px solid white"
                      borderRadius="100%"
                      overflow="hidden"
                    >
                      <Image
                        objectFit="cover"
                        width="100%"
                        height="100%"
                        src={image || themes[theme] || themes.lsGradient}
                      />
                    </EmptyButton>
                  </Container>
                  <Text
                    whiteSpace="nowrap"
                    fontSize={mobile ? '12px' : '14px'}
                    fontWeight="600"
                    display="block"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    textAlign="center"
                  >
                    {title}
                  </Text>
                </Container>
              ))}
            </Container>
          </Container>
        ) : (
          <Container
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="0 0 147px"
            flexDirection="column"
            background={`url(${noPostsBackground})`}
            backgroundPosition="0 20px"
            backgroundRepeat="repeat-x"
          >
            {loading && packageName && <Loader width="170px" />}

            {!loading && packageName && (
              <>
                <Text
                  maxWidth="170px"
                  fontSize="14px"
                  fontWeight="600"
                  color="text300"
                  textAlign="center"
                  lineHeight="1.2"
                  marginBottom="20px"
                >
                  Get the word out by creating your first post.
                </Text>
                <EmptyButton
                  onClick={() => setShowCreatePostModal(true)}
                  color="text700"
                  fontSize="14px"
                  fontWeight="600"
                  backgroundColor="background300"
                  borderRadius="30px"
                  padding="7px 23px"
                >
                  Create Post
                </EmptyButton>
              </>
            )}

            {!packageName && (
              <>
                <Text
                  maxWidth="170px"
                  fontSize="14px"
                  fontWeight="600"
                  color="text300"
                  textAlign="center"
                  lineHeight="1.2"
                  marginBottom="20px"
                >
                  Unlock this feature by upgrading
                </Text>
                <Anchor
                  href={`${b2bRoot}/contact-us`}
                  target="_blank"
                  color="normal"
                  fontSize="14px"
                  fontWeight="600"
                  backgroundColor="background300"
                  borderRadius="30px"
                  padding="7px 23px"
                >
                  Upgrade
                </Anchor>
              </>
            )}
          </Container>
        )}
        <Container
          paddingTop="15px"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          borderTop={theme => `1px solid ${theme.border.border300}`}
          marginTop="auto"
        >
          {!edges.length && (
            <Text
              fontWeight="600"
              fontSize="14px"
              color="text300"
              textAlign="left"
              marginTop="3px"
              marginRight="10px"
            >
              No active posts
            </Text>
          )}

          {packageName && (
            <AnchorLink
              to={`/posts/${profileId}`}
              color="text700"
              fontSize="14px"
              fontWeight="600"
              marginTop="3px"
              marginLeft="auto"
            >
              View All Posts
            </AnchorLink>
          )}
        </Container>
      </Panel>
      <PostModal
        show={showPostModal}
        onClose={() => setShowPostModal(false)}
        onClick={() => {
          if (!isTouch()) {
            setPostModalPaused(!postModalPaused);
          }
        }}
        paused={postModalPaused}
        data={edges}
        initialSlide={initialSlide}
      />
      <CreatePostModal
        title="Create a Post"
        submitText="Create"
        show={showCreatePostModal}
        onClose={() => setShowCreatePostModal(false)}
        onSubmit={onCreateSubmit}
      />
    </>
  );
};

export default LatestPosts;
