import { useState } from 'react';
import { getPosts } from '../services/b2b';

const useB2bPosts = (defaultParams = {}) => {
  const [b2bPosts, setB2bPosts] = useState();
  const [loading, setLoading] = useState();

  const fetch = async (params = {}) => {
    setLoading(true);
    try {
      const posts = await getPosts({
        category: 115,
        size: 5,
        ...defaultParams,
        ...params,
      });
      setB2bPosts(posts);
    } catch (e) {
      setB2bPosts(undefined);
    }
    setLoading(false);
  };

  return {
    b2bPosts,
    loading,
    fetch,
  };
};

export default useB2bPosts;
