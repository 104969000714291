import React, { useContext } from 'react';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import Anchor from 'ls-common-client/src/components/Anchor';
import AppMenu from 'ls-common-client/src/components/AppMenu';
import UserMenu from 'ls-common-client/src/components/UserMenu';
import { Context } from '../../../context/AppContext';
import unauthorisedBackground from '../../../images/unauthorisedBackground.svg';
import auth from '../../../services/auth';
import imageResizer from '../../../services/imageResizer';

const Unauthorised = props => {
  const {
    user: { isStaff, isPersoniv, user },
    media: { desktop, mobile },
  } = useContext(Context);

  return (
    <Container
      display="flex"
      flexDirection="column"
      width="100%"
      backgroundColor="white"
      {...props}
    >
      <Container
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={desktop ? '25px 20px' : '15px 20px'}
      >
        <AppMenu
          width={desktop ? '34px' : '30px'}
          height={desktop ? '34px' : '30px'}
          display="flex"
          iconFill="#222e35"
          isStaff={isStaff}
          isPersoniv={isPersoniv}
        />
        <UserMenu
          width={desktop ? '34px' : '30px'}
          height={desktop ? '34px' : '30px'}
          user={user}
          auth={auth}
          imageResizer={imageResizer}
          iconFill="#222e35"
          marginLeft={desktop ? '15px' : '10px'}
        />
      </Container>
      <Container
        backgroundImage={`url(${unauthorisedBackground})`}
        backgroundRepeat="no-repeat"
        backgroundSize="100%"
        backgroundPosition="bottom"
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container
          maxWidth="422px"
          borderRadius="11px"
          backgroundColor="white"
          padding={mobile ? '20px' : '40px'}
          boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.2)"
          margin="20px"
        >
          <Text
            fontSize="25px"
            fontWeight="600"
            display="block"
            lineHeight="1.1"
            marginBottom="20px"
          >
            Hmm we can&apos;t connect you to an account, let&apos;s try to
            resolve this.
          </Text>
          <Text
            color="text400"
            lineHeight="1.25"
            display="block"
            fontSize="16px"
            marginBottom="35px"
          >
            There are no business profiles connected to this account. Send a
            request for a customer expert to get in touch.
          </Text>

          <Anchor
            href="mailto:digitalpresence@localsearch.com.au?subject=Connect Account to Profile&body=Please help me to connect my account.%0D%0A%0D%0ABusiness Name: %0D%0ARequested By:"
            height="40px"
            borderRadius="30px"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="600"
            fontSize="16px"
            marginBottom="25px"
            backgroundColor={theme => theme.primary.normal}
          >
            Send Request
          </Anchor>
          <Text textAlign="center" display="block" lineHeight="1.2">
            Contact us on <Anchor href="tel:1300360867">1300 360 867</Anchor>
          </Text>
        </Container>
      </Container>
    </Container>
  );
};

export default Unauthorised;
