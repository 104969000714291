import { useState } from 'react';
import cuid from 'cuid';

const useNotificationSlider = () => {
  const [notifications, setNotifications] = useState([]);

  const onClose = key => {
    setNotifications(current =>
      current.map(props =>
        key === props.key ? { ...props, show: false } : props
      )
    );
  };

  const onHasClosed = key => {
    setNotifications(current => current.filter(props => key !== props.key));
  };

  const open = props => {
    setNotifications(current => {
      const key = cuid();
      return [
        {
          show: true,
          key,
          onClose: () => onClose(key),
          onHasClosed: () => onHasClosed(key),
          ...props,
        },
        ...current,
      ];
    });
  };

  return {
    open,
    notifications,
  };
};

export default useNotificationSlider;
