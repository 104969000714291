import React, { useMemo, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { isTouch } from 'ls-common-client/src/util';
import Container from 'ls-common-client/src/components/Container';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Loader from 'ls-common-client/src/components/Loader';
import Panel from '../../../UI/atoms/Panel';
import useReviewAggregates from '../../../../hooks/useReviewAggregates';
import useReviewRatingAggregates from '../../../../hooks/useReviewRatingAggregates';
import ShareDialog from './ShareDialog';
import { Context } from '../../../../context/AppContext';
import ToolTip from '../../../UI/molecules/ToolTip';

const round = num => (num ? Math.round(num * 10) / 10 : 0);

const AverageRating = props => {
  const {
    media: { desktop },
  } = useContext(Context);

  const { profileId } = useParams();
  const [showShareDialog, setShowShareDialog] = useState();
  const [showToolTip, setShowToolTip] = useState();

  const {
    reviewAggregates: { averageRating, count } = {},
    loading: reviewAggregatesLoading,
  } = useReviewAggregates();

  const {
    reviewRatingAggregates = [],
    loading: reviewRatingAggregatesLoading,
  } = useReviewRatingAggregates();

  const ratings = useMemo(() => {
    const values = [5, 4, 3, 2, 1];

    const findCount = value => {
      const match = reviewRatingAggregates.find(
        ({ rating }) => rating === value
      );
      return match ? match.count : 0;
    };

    return values.map(value => ({
      rating: value,
      count: findCount(value),
    }));
  }, [reviewRatingAggregates]);

  const percentage = ratingCount =>
    ratingCount ? round(ratingCount / count) * 100 : 0;

  const loading = reviewAggregatesLoading || reviewRatingAggregatesLoading;

  return (
    <>
      <Panel
        display="flex"
        flexDirection="column"
        padding="18px 18px 0 18px"
        {...props}
      >
        <Container flex="1">
          <Text fontSize="16px" fontWeight="600">
            Average Rating
          </Text>
          {loading ? (
            <Container
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight="130px"
            >
              <Loader width="170px" position="absolute" />
            </Container>
          ) : (
            <Container flex="0 0 107px" display="flex" flexWrap="wrap">
              <Container
                display="flex"
                alignItems="center"
                paddingTop="15px"
                flex={desktop ? '0 0 200px' : '0 0 170px'}
              >
                <Icon
                  color={count ? '#f9c70a' : '#ececec'}
                  fontSize="70px"
                  className="ls-icon icon-generalstarlarge"
                />
                <Text fontSize="60px" color={count ? 'text500' : '#ececec'}>
                  {round(averageRating)}
                </Text>
              </Container>
              <Container
                flex="1"
                marginTop="-12px"
                minWidth="100px"
                padding="10px 0"
              >
                {ratings.map(rating => (
                  <Container
                    key={rating.rating}
                    display="flex"
                    alignItems="center"
                    marginBottom="3px"
                  >
                    <Text width="8px" fontSize="12.5px">
                      {rating.rating}
                    </Text>
                    <Icon
                      className="ls-icon icon-generalstarlarge"
                      color="text200"
                      fontSize="12px"
                      marginRight="5px"
                      marginBottom="-2px"
                    />
                    <Container
                      flex="1"
                      display="flex"
                      alignItems="center"
                      position="relative"
                    >
                      <Container
                        width={`${percentage(rating.count)}%`}
                        height="9px"
                        backgroundColor={theme => theme.secondary.normal}
                        position="absolute"
                        left="0"
                        top="0"
                        borderRadius="10px"
                      />
                      <Container
                        backgroundColor="#ececec"
                        height="9px"
                        borderRadius="10px"
                        flex="1"
                      />
                    </Container>
                    <Text
                      paddingLeft="10px"
                      fontSize="11px"
                      color="text300"
                      width="45px"
                    >
                      {percentage(rating.count)}%
                    </Text>
                  </Container>
                ))}
              </Container>
            </Container>
          )}
        </Container>
        <Container
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          height="53px"
          borderTop={theme => `1px solid ${theme.border.border300}`}
          marginTop="auto"
        >
          {count ? (
            <Container
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Text
                fontWeight="600"
                fontSize="14px"
                color="text300"
                textAlign="left"
                marginTop="3px"
                marginRight="3px"
              >
                {count} Reviews
              </Text>
              <ToolTip
                show={!isTouch() && showToolTip}
                onMouseEnter={() => setShowToolTip(true)}
                onMouseLeave={() => setShowToolTip(false)}
                render={() => (
                  <Text
                    display="block"
                    fontSize="14px"
                    color="text400"
                    lineHeight="1.2"
                  >
                    Share your custom link to encourage clients to review your
                    business.
                  </Text>
                )}
                anchor="bottom"
                popupProps={{
                  width: '230px',
                  padding: '15px',
                }}
              >
                <EmptyButton
                  padding="5px"
                  borderRadius="100%"
                  width="35px"
                  height="35px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    backgroundColor: 'background300',
                    color: 'text700',
                  }}
                  onClick={() => setShowShareDialog(true)}
                >
                  <Icon className="ls-icon icon-share" fontSize="15px" />
                </EmptyButton>
              </ToolTip>
            </Container>
          ) : (
            <Text
              fontWeight="600"
              fontSize="14px"
              color="text300"
              textAlign="left"
              marginTop="3px"
              marginRight="10px"
            >
              No reviews yet
            </Text>
          )}
          <AnchorLink
            to={`/reviews/${profileId}`}
            color="text700"
            fontSize="14px"
            fontWeight="600"
            marginTop="3px"
            marginLeft="auto"
          >
            View All Reviews
          </AnchorLink>
        </Container>
      </Panel>
      <ShareDialog
        show={showShareDialog}
        onClose={() => setShowShareDialog(false)}
      />
    </>
  );
};

export default AverageRating;
