import lsGradient from './lsGradient.svg';
import intoTheDeep from './intoTheDeep.svg';
import summerPineapple from './summerPineapple.svg';
import magicMint from './magicMint.svg';
import sweetJasmine from './sweetJasmine.svg';
import aquaSpectacular from './aquaSpectacular.svg';
import { LISTING_POST_THEMES } from '../../lib/constants';

const {
  LS_GRADIENT,
  INTO_THE_DEEP,
  SUMMER_PINEAPPLE,
  MAGIC_MINT,
  SWEET_JASMINE,
  AQUA_SPECTACULAR,
} = LISTING_POST_THEMES;

export default {
  [LS_GRADIENT]: lsGradient,
  [INTO_THE_DEEP]: intoTheDeep,
  [SUMMER_PINEAPPLE]: summerPineapple,
  [MAGIC_MINT]: magicMint,
  [SWEET_JASMINE]: sweetJasmine,
  [AQUA_SPECTACULAR]: aquaSpectacular,
};
