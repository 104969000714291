import React, { useContext, useEffect, useState, useRef } from 'react';
import ClipboardJS from 'clipboard/dist/clipboard.min';
import Container from 'ls-common-client/src/components/Container';
import Anchor from 'ls-common-client/src/components/Anchor';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import { Context } from '../../../../../context/AppContext';
import { createProfileUrl } from '../../../../../lib/util';

const ShareDialogContents = () => {
  const {
    profile: { profile },
  } = useContext(Context);

  const clipboardButtonRef = useRef();
  const [copied, setCopied] = useState();

  useEffect(() => {
    const clipboard = new ClipboardJS(clipboardButtonRef.current);
    clipboard.on('success', () => setCopied(true));
  }, []);

  const { vanityUrlPath, displayName } = profile;

  const reviewLink = `${createProfileUrl(vanityUrlPath)}?review=true`;

  return (
    <>
      <Container
        border={theme => `2px dashed ${theme.primary.primary200}`}
        borderRadius="11px"
        padding="20px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="5px"
      >
        <Anchor
          href={reviewLink}
          target="_blank"
          fontSize="16px"
          lineHeight="1.2"
          wordBreak="break-word"
          paddingRight="15px"
          color="normal"
          _hover={{
            color: 'text700',
          }}
        >
          {reviewLink}
        </Anchor>
        <EmptyButton
          ref={clipboardButtonRef}
          data-clipboard-text={reviewLink}
          color="normal"
          _hover={{
            color: theme => theme.primary.normal,
          }}
        >
          <Icon className="ls-icon icon-documents" fontSize="16px" />
        </EmptyButton>
      </Container>

      <Container
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="20px"
        marginBottom="10px"
      >
        {copied && (
          <>
            <Text
              color={theme => theme.secondary.normal}
              fontWeight="600"
              fontSize="14px"
            >
              Link Copied
            </Text>
            <Container
              width="15px"
              height="15px"
              backgroundColor={theme => theme.secondary.normal}
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginLeft="10px"
            >
              <Icon
                color="white"
                fontSize="10px"
                className="ls-icon icon-generalticksmall"
              />
            </Container>
          </>
        )}
      </Container>
      <Container display="flex" alignItems="center" flexWrap="wrap">
        <Anchor
          href={`https://www.facebook.com/sharer/sharer.php?t=${displayName}&u=${reviewLink}`}
          target="_blank"
          display="flex"
          alignItems="center"
          fontSize="14px"
          color="normal"
          fontWeight="600"
          padding="6px 10px"
          borderRadius="30px"
          whiteSpace="nowrap"
          height="34px"
          _hover={{
            color: theme => theme.primary.normal,
            backgroundColor: 'background300',
          }}
        >
          <Icon
            color="#1877f2"
            fontSize="22px"
            className="ls-icon icon-facebook"
            marginRight="7px"
          />
          Share via Facebook
        </Anchor>
        <Anchor
          href={`mailto:?body=${reviewLink}`}
          display="flex"
          alignItems="center"
          fontSize="14px"
          color="normal"
          fontWeight="600"
          padding="6px 10px"
          borderRadius="30px"
          whiteSpace="nowrap"
          height="34px"
          _hover={{
            color: theme => theme.primary.normal,
            backgroundColor: 'background300',
          }}
        >
          <Icon
            className="ls-icon icon-categorypostoffices"
            marginRight="7px"
          />
          Share via email
        </Anchor>
      </Container>
    </>
  );
};

export default ShareDialogContents;
