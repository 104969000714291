import React from 'react';
import PropTypes from 'prop-types';
import UpgradeDialogDesktop from './UpgradeDialogDesktop';
import UpgradeDialogMobile from './UpgradeDialogMobile';

const UpgradeDialog = ({ media, ...props }) => {
  const { desktop } = media;

  return desktop ? (
    <UpgradeDialogDesktop {...props} />
  ) : (
    <UpgradeDialogMobile {...props} />
  );
};

UpgradeDialog.propTypes = {
  media: PropTypes.shape({
    desktop: PropTypes.bool,
  }),
};

UpgradeDialog.defaultProps = {
  media: {
    desktop: false,
  },
};

export default UpgradeDialog;
