import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import AmenitiesForm from './AmenitiesForm';
import { Context } from '../../../../context/AppContext';
import PaidFeatureToolTip from '../../../UI/molecules/PaidFeatureToolTip';

const AmenitiesDialog = ({ onClose, ...props }) => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { name: packageName } = profile.package || {};

  return (
    <FormDialog maxWidth="750px" onClose={onClose} {...props}>
      <FormDialogHeader
        header={
          <Container display="flex" alignItems="center">
            Amenities
            {!packageName && <PaidFeatureToolTip marginLeft="10px" />}
          </Container>
        }
        subHeader="Let customers know what payment methods you accept."
        onClose={onClose}
      />
      <AmenitiesForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

AmenitiesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AmenitiesDialog;
