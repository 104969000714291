import React, { useState, useContext } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import FeedbackForm from 'ls-common-client/src/components/FeedbackForm';
import { Context } from '../../../../context/AppContext';

const Footer = props => {
  const [show, setShow] = useState();
  const {
    media: { mobile },
  } = useContext(Context);
  return (
    <>
      <Container padding="20px" fontSize="13px" {...props}>
        <Text color="text300" marginRight="10px">
          © Business Profile Manager.
        </Text>{' '}
        <EmptyButton
          onClick={() => setShow(true)}
          color="normal"
          fontSize="13px"
        >
          Send Feedback
        </EmptyButton>
      </Container>
      <FeedbackForm
        show={show}
        onClose={() => setShow(false)}
        mode={mobile ? 'mobile' : 'desktop'}
        application="Business Profile Manager"
      />
    </>
  );
};

export default Footer;
