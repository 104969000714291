import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Line from 'ls-common-client/src/components/charts/Line';
import moment from 'moment';
import Panel from '../../UI/atoms/Panel';
import ToolTip from '../../UI/molecules/ToolTip';

const setBackgroundColor = ({ chart: { ctx } }) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 260);
  gradient.addColorStop(0, '#ee7aeb');
  gradient.addColorStop(1, '#849ae8');
  return gradient;
};

const getStartOfMonth = (data, i) => {
  const { pointDate, count } = data[i];
  const { pointDate: prevPointDate, count: prevCount } = data[i - 1] || {};

  const month = pointDate ? moment(pointDate).format('MMM') : null;
  const prevMonth = prevPointDate ? moment(prevPointDate).format('MMM') : null;
  const isStartOfMonth = month && prevMonth && prevMonth !== month;

  const average = (count + prevCount) / 2;

  if (isStartOfMonth) {
    return {
      count: average,
      pointDate,
    };
  }

  return null;
};

const addPointsBetweenMonths = data =>
  data.reduce((acc, item, i) => {
    const isLast = i === data.length - 1;
    const isFirst = i === 0;

    if (isFirst) {
      acc.push({ ...item, hidePoint: true });
    }

    const startOfMonth = getStartOfMonth(data, i);

    if (startOfMonth) {
      acc.push({ ...startOfMonth, hidePoint: true });
    }

    acc.push(item);

    if (isLast) {
      acc.push({ ...item, hidePoint: true });
    }

    return acc;
  }, []);

const SearchesOverTime = ({ data, selectedSegment, ...props }) => {
  const [showToolTip, setShowToolTip] = useState();

  const chartData = useMemo(() => {
    if (!data || !data.length) {
      return undefined;
    }

    const newData = addPointsBetweenMonths(data);

    const labels = newData.map(({ pointDate }) => pointDate);
    const pointRadius = newData.map(({ hidePoint }) => (hidePoint ? 0 : 5));

    const datasets = [
      {
        data: newData.map(({ count }) => count),
        borderWidth: 3,
        borderColor: setBackgroundColor,
        pointRadius,
        pointHoverRadius: pointRadius,
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        pointBackgroundColor: '#5369f9',
        pointHoverBackgroundColor: '#5369f9',
        labels,
      },
    ];

    return {
      datasets,
      labels,
      hidePointIndexes: newData.reduce(
        (acc, { hidePoint }, i) => (hidePoint ? [...acc, i] : acc),
        []
      ),
    };
  }, [data]);

  const xTicksCallback = i => {
    const { labels, hidePointIndexes } = chartData;

    const hidePoint = hidePointIndexes.includes(i);

    if (hidePoint) {
      return moment(labels[i]).format('MMM');
    }

    return null;
  };

  return (
    <Panel display="flex" flexDirection="column" padding="0" {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="18px"
        borderBottom={theme => `1px solid ${theme.border.border300}`}
      >
        <Text fontWeight="600" lineHeight="1.2" marginRight="30px">
          Searches Over Time
        </Text>
        <ToolTip
          show={showToolTip}
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
          anchor="topRight"
          arrowAnchor="top"
          render={() => (
            <Text
              display="block"
              fontSize="14px"
              lineHeight="1.2"
              color="text400"
            >
              Explore how many people have viewed your profile over the past{' '}
              {selectedSegment}.
            </Text>
          )}
          popupProps={{
            right: '-15px',
            width: '210px',
            padding: '15px',
          }}
        >
          <Icon className="ls-icon icon-generalabout" fontSize="20px" />
        </ToolTip>
      </Container>
      {chartData ? (
        <Container display="flex" overflow="auto">
          <Container
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="1 0 600px"
          >
            <Line
              data={chartData}
              plugins={[
                {
                  afterDraw: chart => {
                    const {
                      scales: {
                        x: { _gridLineItems, ticks },
                      },
                      ctx,
                    } = chart;

                    const y = chart.height - 20;

                    ticks.forEach(({ label }, index) => {
                      const { x1 } = _gridLineItems[index];
                      const { x1: nextX1 } = _gridLineItems[index + 1] || {};

                      const tickDistance = nextX1 - x1;
                      const offset = 10;
                      const x = x1 + tickDistance / 2 - offset;

                      ctx.save();
                      ctx.font = '14px Circular';
                      ctx.fillStyle = '#9b9b9b';
                      ctx.fillText(label, x, y);
                      ctx.restore();
                    });
                  },
                },
              ]}
              options={{
                layout: {
                  padding: {
                    bottom: 40,
                  },
                },
                scales: {
                  y: {
                    offset: true,
                    display: false,
                  },
                  x: {
                    offset: true,
                    ticks: {
                      callback: i => xTicksCallback(i),
                      font: {
                        size: 14,
                      },
                      display: false,
                    },
                    grid: {
                      borderDash: [10],
                      drawBorder: false,
                      tickBorderDash: [10],
                      tickLength: 30,
                      drawTicks: true,
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    padding: 15,
                    titleFont: {
                      size: 14,
                    },
                    bodyFont: {
                      size: 14,
                    },
                    filter: tooltipItem => {
                      const { dataIndex, label } = tooltipItem;
                      const nextLabel = chartData.labels[dataIndex + 1];
                      const isSecondLast =
                        chartData.labels.length - 2 === dataIndex;
                      const isLast = chartData.labels.length - 1 === dataIndex;

                      if (isLast || (nextLabel === label && !isSecondLast)) {
                        return null;
                      }

                      return tooltipItem;
                    },
                    callbacks: {
                      label: context => {
                        const { formattedValue } = context;
                        return `${formattedValue} Searches`;
                      },
                    },
                  },
                },
              }}
              height="280px"
            />
          </Container>
        </Container>
      ) : (
        <Container
          height="330px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom="20px"
        >
          <Text
            width="170px"
            color="text300"
            fontSize="14px"
            fontWeight="600"
            lineHeight="1.2"
            textAlign="center"
          >
            You have no insights on searches yet.
          </Text>
        </Container>
      )}
    </Panel>
  );
};

SearchesOverTime.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSegment: PropTypes.string,
};

SearchesOverTime.defaultProps = {
  data: null,
  selectedSegment: '',
};

export default SearchesOverTime;
