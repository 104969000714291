import React, { useContext, useMemo } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import ProfileInfo from './ProfileInfo';
import Images from './Images';
import TradingHours from './TradingHours';
import LatestPosts from './LatestPosts';
import ProfileTips from './ProfileTips';
import AverageRating from './AverageRating';
import ProfileSearches from './ProfileSearches';
import ProfileViews from './ProfileViews';
import useSearchLocalmetrics from '../../../hooks/useSearchLocalmetrics';
import { Context } from '../../../context/AppContext';
import PageHeader from '../../UI/molecules/PageHeader';

const Home = props => {
  const {
    media: { desktop, mobile },
    user: { user },
  } = useContext(Context);

  const { displayName, account } = user || {};
  const { firstName } = account || {};
  const userName = displayName || firstName;

  const { searchLocalmetrics, loading } = useSearchLocalmetrics();
  const { segments } = searchLocalmetrics || {};

  const { impressionCount, pageViewCount } = useMemo(() => {
    if (!segments) {
      return {};
    }

    const { data } =
      segments.find(({ segment }) => segment === '365days') || {};

    return {
      impressionCount: data?.impressionCount?.count,
      pageViewCount: data?.pageViewCount?.count,
    };
  }, [segments]);

  return (
    <>
      <PageHeader>
        {desktop ? (
          <>
            Welcome back
            {!!userName && <Text fontWeight="normal">, {userName}</Text>}
          </>
        ) : (
          'Home'
        )}
      </PageHeader>
      {!desktop && (
        <Container padding="20px 20px 10px 20px">
          <Text color="normal" fontSize="22px" fontWeight="600" lineHeight="1">
            Welcome back
          </Text>
        </Container>
      )}
      <Container
        padding="0 12px 20px 12px"
        display="flex"
        flexWrap="wrap"
        {...props}
      >
        <Container flex="3">
          <Container display="flex" flexWrap="wrap">
            <ProfileInfo flex="3" minWidth="280px" />
            <Images minWidth="200px" flex="1" />
            <TradingHours minWidth="220px" flex="1" />
          </Container>
          <LatestPosts />
          <Container display="flex" flexWrap="wrap">
            <Container
              minWidth={desktop ? '350px' : '275px'}
              display="flex"
              flex="1"
            >
              <AverageRating flex="1" />
            </Container>
            <Container
              display="flex"
              flex="1"
              flexWrap={mobile ? 'wrap' : 'nowrap'}
            >
              <Container flex="1" display="flex">
                <ProfileSearches
                  loading={loading}
                  count={impressionCount}
                  flex="1"
                />
              </Container>
              <Container flex="1" display="flex">
                <ProfileViews
                  loading={loading}
                  count={pageViewCount}
                  flex="1"
                />
              </Container>
            </Container>
          </Container>
        </Container>
        <ProfileTips flex="1" minWidth="275px" />
      </Container>
    </>
  );
};

export default Home;
