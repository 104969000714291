import { gql } from '@apollo/client';

const setProfileTradingHours = gql`
  mutation setProfileTradingHours($input: SetProfileTradingHoursInput) {
    setProfileTradingHours(input: $input) {
      tradingHours {
        day
        start
        interval
        type
      }
    }
  }
`;

export default setProfileTradingHours;
