import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import Animation from 'ls-common-client/src/components/Animation';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { toFile, fromFile } from 'ls-common-client/src/image';
import Image from 'ls-common-client/src/components/Image';
import imageResizer from '../../../services/imageResizer';
import AlertDialog from '../../UI/molecules/AlertDialog';
import DragDropScreen from '../../UI/molecules/DragDropScreen';
import MediaPanel from '../../UI/molecules/MediaPanel';
import ToolTip from '../../UI/molecules/ToolTip';
import Validator from '../../UI/atoms/Validator';
import uploader from '../../../services/uploader';
import { Context } from '../../../context/AppContext';
import { MEDIA_TYPES } from '../../../lib/constants';
import uploading from '../../../animations/uploading.json';
import mediaBackground from '../../../images/mediaBackground.svg';
import CropperDialog from '../../UI/molecules/CropperDialog';
import useSuccessNotificationSlider from '../../../hooks/useSuccessNotificationSlider';
import useDeleteNotificationSlider from '../../../hooks/useDeleteNotificationSlider';
import toolTipLocalStorage from '../../../lib/toolTipLocalStorage';

const { IMAGE } = MEDIA_TYPES;

const maxImages = 50;
const maxFeatureImages = 4;

const getOtherMedia = media =>
  media
    .filter(({ type }) => type !== IMAGE)
    .map(({ url, type, background, description }) => ({
      url,
      type,
      background,
      description,
    }));

const uploadFiles = files =>
  Promise.all(Array.from(files).map(file => uploader.upload(file)));

const Images = () => {
  const {
    media: { mobile, desktop },
    profile: {
      profile,
      update: { update },
    },
    user: { isPersoniv },
  } = useContext(Context);

  const { media } = profile;

  const successNotificationSlider = useSuccessNotificationSlider();
  const deleteNotificationSlider = useDeleteNotificationSlider();

  const images = useMemo(
    () =>
      media
        .filter(({ type }) => type === IMAGE)
        .map(({ url, description, type, background }) => ({
          url,
          description,
          type,
          background,
        })),
    [media]
  );

  const [showDragDrop, setShowDragDrop] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [showUploadMaximumAlert, setShowUploadMaximumAlert] = useState();
  const [replaceImageIndex, setReplaceImageIndex] = useState();
  const [showFeatureMaximumAlert, setShowFeatureMaximumAlert] = useState();
  const [showFeatureMinimumAlert, setShowFeatureMinimumAlert] = useState();
  const [showFeatureToolTip, setShowFeatureToolTip] = useState(
    !toolTipLocalStorage.get('featureImage')
  );
  const [showCropperDialog, setShowCropperDialog] = useState();
  const [cropperSrc, setCropperSrc] = useState();
  const [uploadQueue, setUploadQueue] = useState();
  const [errorModalData, setErrorModalData] = useState();

  const fileUploadRef = useRef();

  const validateFile = async file => {
    const minHeight = isPersoniv ? 600 : 800;
    const minWidth = isPersoniv ? 1000 : 1400;
    const maxSize = 1024 * 1024 * 10;

    const { type: fileType, size, name } = file;
    const isImage = ['image/png', 'image/jpeg'].includes(fileType);
    const img = isImage ? await fromFile(file) : {};
    const { width, height, src } = img;

    let heading;
    let message;
    let valid = true;
    let allowBypass;

    if (width < minWidth || height < minHeight) {
      heading = 'Do you still want to continue?';
      message = `Hm, this image is below our recommended size of ${minWidth} x ${minHeight} px, so it may not look as good as it could.`;
      allowBypass = true;
      valid = false;
    }

    if (size > maxSize) {
      message =
        'Oops, your file size is too big. Please try again ensuring the file is a maximum of 10MB.';
      valid = false;
    }

    if (!isImage) {
      message =
        "Oops, you've selected an invalid file format. You can try again with a jpeg or png file. ";
      valid = false;
    }

    return {
      valid,
      error: { heading, message, src, name, allowBypass },
      file,
    };
  };

  const validateFiles = async files => {
    const validity = await Promise.all(
      Array.from(files).map(async file => validateFile(file))
    );
    return validity;
  };

  const showUploadSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your image upload was successful!',
    });
  };

  const showDescriptionSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your image description was successfully updated!',
    });
  };

  const showEditSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your image edit was successful!',
    });
  };

  const showFeatureImageAddSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your image was added as a feature image.',
    });
  };

  const showFeatureImageRemoveSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your image was removed as a feature image.',
    });
  };

  const showDeleteSuccess = () => {
    deleteNotificationSlider.open({
      heading: 'Your image has been deleted.',
    });
  };

  const addNewImages = async file => {
    const files = Array.isArray(file) ? file : [file];

    if (!files.length) {
      return false;
    }

    try {
      const urls = await uploadFiles(files);
      const newMedia = urls.map((url, i) => ({
        url,
        type: IMAGE,
        background: !images.length && i < 4,
        description: null,
      }));
      const existingMedia = media.map(
        ({ url, type, background, description }) => ({
          url,
          type,
          background,
          description,
        })
      );
      await update({
        media: [...newMedia, ...existingMedia],
      });
      setError(null);
      return true;
    } catch (e) {
      setError(e);
      window.scroll(0, 0);
      return false;
    }
  };

  const replaceImage = async file => {
    try {
      const url = await uploader.upload(file);

      const imageMedia = images.map((image, index) =>
        replaceImageIndex === index
          ? {
              url,
              description: image.description,
              background: image.background,
              type: image.type,
            }
          : image
      );

      const otherMedia = getOtherMedia(media);

      await update({ media: [...imageMedia, ...otherMedia] });
      setError(null);
      return true;
    } catch (e) {
      setError(e);
      window.scroll(0, 0);
      return false;
    }
  };

  const onDragEnter = () => {
    setShowDragDrop(true);
  };

  const onDragLeave = () => {
    setShowDragDrop(false);
  };

  const removeFromUploadQueue = () => {
    setUploadQueue(uploadQueue.slice(1, uploadQueue.length));
  };

  const addToUploadQueue = (files, bypassType, onBypassSuccess) => {
    setUploadQueue(
      Array.isArray(files)
        ? files.map(file => ({ ...file, bypassType, onBypassSuccess }))
        : [{ ...files, bypassType, onBypassSuccess }]
    );
  };

  const onFileErrorBypass = async () => {
    const [{ bypassType, file, onBypassSuccess }] = uploadQueue;

    setLoading(true);
    let success;

    if (bypassType === 'add') {
      success = await addNewImages(file);
    }

    if (bypassType === 'replace') {
      success = await replaceImage(file);
    }

    if (success) {
      onBypassSuccess();
    }

    setLoading(false);
    removeFromUploadQueue();
  };

  useEffect(() => {
    const getNextInQueue = () => {
      const [next] = uploadQueue || [];
      setErrorModalData(next ? next.error : null);
    };

    getNextInQueue();
  }, [uploadQueue]);

  const onSelectedNew = async files => {
    setLoading(true);
    setShowDragDrop(false);

    const isUploadMax = files.length + images.length > maxImages;

    if (isUploadMax) {
      setShowUploadMaximumAlert(true);
      setLoading(false);
      return;
    }

    const validity = await validateFiles(files);
    const validFiles = validity.filter(({ valid }) => valid);
    const invalidFiles = validity.filter(({ valid }) => !valid);

    const success = await addNewImages(validFiles.map(({ file }) => file));

    addToUploadQueue(invalidFiles, 'add', showUploadSuccess);

    if (!invalidFiles.length && success) {
      showUploadSuccess();
    }

    setLoading(false);
  };

  const onEdit = (src, i) => {
    setReplaceImageIndex(i);
    setShowCropperDialog(true);
    setCropperSrc(src);
  };

  const onUploadNewImage = i => {
    setReplaceImageIndex(i);
    fileUploadRef.current.click();
  };

  const onSelectedReplace = async ([file]) => {
    setLoading(true);

    const validity = await validateFile(file);

    if (!validity.valid) {
      addToUploadQueue(validity, 'replace', showUploadSuccess);
      setLoading(false);
      return;
    }

    const success = await replaceImage(file);

    if (success) {
      showUploadSuccess();
    }

    setLoading(false);
  };

  const onCrop = async base64 => {
    setLoading(true);

    const file = await toFile(base64);
    const validity = await validateFile(file);

    if (!validity.valid) {
      addToUploadQueue(validity, 'replace', showEditSuccess);
      setLoading(false);
      return;
    }

    const success = await replaceImage(file);

    if (success) {
      showEditSuccess();
    }

    setLoading(false);
  };

  const onSubmit = async ({ description }, i) => {
    setLoading(true);

    const imageMedia = images.map((image, index) =>
      i === index ? { ...image, description: description || null } : image
    );

    const otherMedia = getOtherMedia(media);

    try {
      await update({ media: [...imageMedia, ...otherMedia] });
      setError(null);
      showDescriptionSuccess();
    } catch (e) {
      setError(e);
      window.scroll(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async i => {
    const imageMedia = images.filter((image, index) => i !== index);

    const featureImageCount = imageMedia.reduce(
      (acc, { background }) => (background ? acc + 1 : acc),
      0
    );

    if (!featureImageCount && imageMedia.length) {
      imageMedia[0] = {
        ...imageMedia[0],
        background: true,
      };
    }

    const otherMedia = getOtherMedia(media);

    setLoading(true);

    try {
      await update({ media: [...imageMedia, ...otherMedia] });
      setError(null);
      showDeleteSuccess();
    } catch (e) {
      setError(e);
      window.scroll(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const onFeatureClick = async (value, i) => {
    const count = images.reduce(
      (acc, { background }) => (background ? acc + 1 : acc),
      value ? 1 : 0
    );

    if (count === 1 && !value) {
      setShowFeatureMinimumAlert(true);
      return;
    }

    if (count > maxFeatureImages) {
      setShowFeatureMaximumAlert(true);
      return;
    }

    setLoading(true);

    try {
      const imageMedia = images.map((image, index) =>
        i === index ? { ...image, background: value } : image
      );

      const otherMedia = getOtherMedia(media);

      await update({ media: [...imageMedia, ...otherMedia] });
      setError(null);
      if (value) {
        showFeatureImageAddSuccess();
      } else {
        showFeatureImageRemoveSuccess();
      }
    } catch (e) {
      setError(e);
      window.scroll(0, 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
    };
  }, []);

  const onFeatureToolTipClick = () => {
    setShowFeatureToolTip(false);
    toolTipLocalStorage.set('featureImage', true);
  };

  return (
    <>
      <FileUpload
        accept=".jpg,.jpeg,.png"
        ref={fileUploadRef}
        onSelected={onSelectedReplace}
        display="none"
      />

      {error && !mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {((!images.length && !mobile) || mobile) && (
        <Container
          flex={mobile ? '0 0 190px' : '1'}
          background={mobile ? `url(${mediaBackground}) no-repeat` : 'none'}
          margin="0 -20px 30px -20px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundSize="cover"
          backgroundPosition="bottom"
          position="relative"
        >
          <Container
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Text
              fontSize="25px"
              color="normal"
              fontWeight="bold"
              marginBottom="15px"
            >
              Upload images
            </Text>
            <Text
              fontSize="16px"
              color="text400"
              marginBottom="25px"
              maxWidth="280px"
            >
              These images appear at the top of your profile page
            </Text>
            <FileUpload
              onSelected={onSelectedNew}
              position={mobile ? 'absolute' : 'relative'}
              bottom={mobile ? '-20px' : 0}
              accept=".jpg,.jpeg,.png"
              fontSize="16px"
              color="text700"
              fontWeight="600"
              boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
              backgroundColor="white"
              borderRadius="30px"
              height="40px"
              padding="0 25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              multiple
            >
              <Icon
                className="ls-icon icon-pluscircle"
                marginRight="10px"
                fontSize="20px"
              />
              Upload File
            </FileUpload>
          </Container>
        </Container>
      )}

      {error && mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {!!images.length && (
        <Container display="flex" margin="0 -10px" flexWrap="wrap">
          {!mobile && (
            <Container flex={desktop ? '0 0 25%' : '0 0 50%'} display="flex">
              <FileUpload
                onSelected={onSelectedNew}
                accept=".jpg,.jpeg,.png"
                flex="1"
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="transparent"
                border={theme => `3px dashed ${theme.border.border300}`}
                borderRadius="20px"
                margin="10px"
                multiple
              >
                <Container
                  fontSize="16px"
                  color="text700"
                  fontWeight="600"
                  boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
                  backgroundColor="white"
                  borderRadius="30px"
                  height="40px"
                  padding="0 25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position={mobile ? 'absolute' : 'relative'}
                  bottom={mobile ? '-15px' : '0'}
                >
                  <Icon
                    className="ls-icon icon-pluscircle"
                    marginRight="10px"
                    fontSize="20px"
                  />
                  Upload File
                </Container>
              </FileUpload>
            </Container>
          )}

          {images.map((image, i) => (
            <Container
              key={image.url}
              position="relative"
              flex={desktop ? '0 0 25%' : '0 0 50%'}
            >
              <MediaPanel
                type="Image"
                data={image}
                onEdit={() => onEdit(image.url, i)}
                onUpload={() => onUploadNewImage(i)}
                onSubmit={values => onSubmit(values, i)}
                onDelete={() => onDelete(i)}
                imgSrc={url =>
                  imageResizer.resize(url, {
                    width: '420px',
                    height: '420px',
                  })
                }
                anchor={mobile && i % 2 === 0 ? 'topLeft' : 'topRight'}
              />
              <ToolTip
                show={showFeatureToolTip && i === 0}
                render={() => (
                  <>
                    <Text
                      display="block"
                      fontSize="14px"
                      lineHeight="1.2"
                      color="normal"
                      fontWeight="600"
                      marginBottom="5px"
                    >
                      Feature Image
                    </Text>
                    <Text
                      display="block"
                      fontSize="14px"
                      lineHeight="1.2"
                      color="text400"
                      marginBottom="5px"
                    >
                      Select four images to feature at the top of your
                      localsearch.com.au profile.
                    </Text>
                    <EmptyButton
                      onClick={onFeatureToolTipClick}
                      color="text700"
                      fontSize="14px"
                    >
                      Got it
                    </EmptyButton>
                  </>
                )}
                anchor="topLeft"
                arrowAnchor="top"
                position="absolute"
                left={mobile ? '20px' : '35px'}
                top={mobile ? '20px' : '35px'}
                popupProps={{
                  padding: '15px',
                  width: '210px',
                }}
              >
                <EmptyButton
                  onClick={() => {
                    onFeatureClick(!image.background, i);
                    setShowFeatureToolTip(false);
                  }}
                >
                  <Icon
                    color={image.background ? '#f9c70a' : 'white'}
                    className="ls-icon icon-generalstarlarge"
                    fontSize="35px"
                    textShadow="0 0 3px rgba(0,0,0,0.3)"
                  />
                </EmptyButton>
              </ToolTip>
            </Container>
          ))}
        </Container>
      )}

      <DragDropScreen
        onSelected={onSelectedNew}
        onDragLeave={onDragLeave}
        accept=".jpg,.jpeg,.png"
        pointerEvents={showDragDrop ? 'default' : 'none'}
        opacity={showDragDrop ? 1 : 0}
        transition="opacity 0.3s ease"
        position="fixed"
        left="0"
        top="0"
        width="100%"
        height="100%"
        zIndex="999"
      />

      <CropperDialog
        show={showCropperDialog}
        onClose={() => setShowCropperDialog(false)}
        src={cropperSrc}
        options={{
          movable: false,
          scalable: false,
          zoomable: false,
        }}
        onCrop={onCrop}
      />

      {loading && (
        <Container
          position="fixed"
          left="0"
          top="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.5)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          padding="0 30px"
        >
          <Animation
            width="450px"
            display="flex"
            alignItems="center"
            data={uploading}
            options={{
              rendererSettings: {
                preserveAspectRatio: 'none',
              },
            }}
          />
        </Container>
      )}
      <AlertDialog
        heading="Minimum reached"
        text="You must have at least one feature image."
        show={showFeatureMinimumAlert}
        buttons={[
          {
            props: {
              primary: true,
              rounded: true,
              onClick: () => setShowFeatureMinimumAlert(false),
            },
            children: 'OK',
          },
        ]}
      />
      <AlertDialog
        heading="Maximum reached"
        text={`You're allowed a maximum of ${maxFeatureImages} feature images.`}
        show={showFeatureMaximumAlert}
        buttons={[
          {
            props: {
              primary: true,
              rounded: true,
              onClick: () => setShowFeatureMaximumAlert(false),
            },
            children: 'OK',
          },
        ]}
      />
      <AlertDialog
        heading="Image maximum reached"
        text={`You're allowed a maximum of ${maxImages} images.`}
        show={showUploadMaximumAlert}
        buttons={[
          {
            props: {
              primary: true,
              rounded: true,
              onClick: () => setShowUploadMaximumAlert(false),
            },
            children: 'OK',
          },
        ]}
      />

      <AlertDialog
        show={!!errorModalData}
        heading={errorModalData?.heading}
        text={
          <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Text marginBottom="20px">{errorModalData?.message}</Text>
            {errorModalData?.src && (
              <Container width="100px">
                <Image src={errorModalData.src} width="100%" />
              </Container>
            )}
            <Text fontSize="12px" fontWeight="bold" color="text500">
              {errorModalData?.name}
            </Text>
          </Container>
        }
        buttons={
          errorModalData?.allowBypass
            ? [
                {
                  props: {
                    primary: true,
                    rounded: true,
                    onClick: onFileErrorBypass,
                  },
                  children: 'Yes',
                },
                {
                  props: {
                    onClick: removeFromUploadQueue,
                    rounded: true,
                    color: theme => theme.error.normal,
                    backgroundColor: 'background300',
                    marginLeft: '10px',
                  },
                  children: 'Remove',
                },
              ]
            : [
                {
                  props: {
                    primary: true,
                    rounded: true,
                    onClick: removeFromUploadQueue,
                  },
                  children: 'OK',
                },
              ]
        }
      />
    </>
  );
};

export default Images;
