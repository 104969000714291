import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Form from 'ls-common-client/src/components/Form';
import Validator from '../../../../UI/atoms/Validator';
import Label from '../../../../UI/atoms/Label';
import Input from '../../../../UI/atoms/Input';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../../context/AppContext';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

const schema = Joi.object({
  reviewEmail: Joi.string().email({ tlds: false }).allow('').messages({
    'string.email': 'Invalid email',
  }),
  quoteEmail: Joi.string().email({ tlds: false }).allow('').messages({
    'string.email': 'Invalid email',
  }),
  reportEmail: Joi.string().email({ tlds: false }).allow('').messages({
    'string.email': 'Invalid email',
  }),
});

const SettingsForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading, error },
    },
  } = useContext(Context);

  const { settings: { reviewEmail, quoteEmail, reportEmail } = {} } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Your update to Settings was successful!',
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    reset({
      reviewEmail: reviewEmail || '',
      quoteEmail: quoteEmail || '',
      reportEmail: reportEmail || '',
    });
  }, [profile]);

  const submit = async values => {
    await update({
      settings: {
        reviewEmail: values.reviewEmail || null,
        quoteEmail: values.quoteEmail || null,
        reportEmail: values.reportEmail || null,
      },
    });
    successNotificationSlider.open();
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container>
        <Container marginBottom="10px">
          <Label>Review Email</Label>
          <Input
            {...register('reviewEmail')}
            marginBottom="5px"
            placeholder="Email"
          />
          <Validator>{errors.reviewEmail?.message}</Validator>
        </Container>
        <Container marginBottom="10px">
          <Label>Quote Email</Label>
          <Input
            {...register('quoteEmail')}
            marginBottom="5px"
            placeholder="Email"
          />
          <Validator>{errors.quoteEmail?.message}</Validator>
        </Container>
        <Container marginBottom="10px">
          <Label>Report Email</Label>
          <Input
            {...register('reportEmail')}
            marginBottom="5px"
            placeholder="Email"
          />
          <Validator>{errors.reportEmail?.message}</Validator>
        </Container>
      </Container>
      <Validator>{error?.message}</Validator>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

SettingsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SettingsForm;
