import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import {
  Carousel,
  CarouselItem,
} from 'ls-common-client/src/components/Carousel';
import visualTour1 from '../../../../images/visualTour1.png';
import visualTour2 from '../../../../images/visualTour2.png';
import visualTour3 from '../../../../images/visualTour3.png';
import visualTour4 from '../../../../images/visualTour4.png';
import visualTour5 from '../../../../images/visualTour5.png';

const duration = 10000;

const slides = [
  {
    image: visualTour1,
    heading: 'Custom Call-to-Actions on Mobile',
    text: 'Prefer phone calls over email? Whatever your goal, custom call-to-action buttons can help you direct people to how you want them to engage with your business.',
  },
  {
    image: visualTour2,
    heading: 'Book Now Button',
    text: 'Boost your bookings with the new Book Now button! This button makes it easy for people to book an appointment with your business, wherever they are on your profile',
  },
  {
    image: visualTour3,
    heading: "Share Your 'Write a Review' Link",
    text: "Studies show you need at least 20 to 50 reviews to win over customers. Get more reviews with just the click of a button with your 'Write a Review' Link.",
  },
  {
    image: visualTour4,
    heading: 'Profile Health Score',
    text: 'The higher your rating, the higher your potential of increasing your enquiries from users visiting your Localsearch Business Profile.',
  },
  {
    image: visualTour5,
    heading: 'Posts',
    text: 'Now you can show off your specials, offers, new products, tips and more with Posts! Posting regularly will help entice more people to contact your business.',
  },
];

const DialogContent = ({ onClose }) => {
  const carouselRef = useRef();
  const { prev, next, goTo } = carouselRef.current || {};

  const progressBarRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);

  const stopProgress = () => {
    const { style } = progressBarRef.current;
    style.transition = `none`;
    style.width = '0';
  };

  const startProgress = () => {
    const { style } = progressBarRef.current;
    style.transition = `all linear ${duration}ms`;
    style.width = '100%';
  };

  const onSlideStart = () => {
    startProgress();
  };

  const onSlideEnd = i => {
    setActiveSlide(i + 1);
    stopProgress();
  };

  const onInit = () => {
    startProgress();
  };

  return (
    <Container display="flex" flexDirection="column" height="100%">
      <EmptyButton
        onClick={onClose}
        padding="20px"
        position="absolute"
        right="13px"
        top="28px"
        width="42px"
        height="42px"
        backgroundColor="white"
        boxShadow="0 0 4px 0 rgba(0, 0, 0, 0.2)"
        borderRadius="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex="2"
      >
        <Icon
          className="ls-icon icon-generalxlarge"
          color="normal"
          fontSize="14px"
        />
      </EmptyButton>
      <Container padding="12px 20px 20px 20px">
        <Container position="relative">
          <Container
            height="7px"
            backgroundColor="background400"
            borderRadius="10px"
          />
          <Container
            ref={progressBarRef}
            height="7px"
            position="absolute"
            left="0"
            top="0"
            width="0"
            borderRadius="10px"
            backgroundImage="linear-gradient(to left, #2d60fb, #98b0fd)"
          />
        </Container>
      </Container>
      <Container flex="1" display="flex" alignItems="center">
        <Carousel
          ref={carouselRef}
          duration={duration}
          onSlideStart={onSlideStart}
          onSlideEnd={onSlideEnd}
          onInit={onInit}
        >
          {slides.map(({ image, heading, text }) => (
            <CarouselItem
              key={heading}
              display="flex"
              flexDirection="column"
              padding="0 20px 5px 20px"
            >
              <Container marginBottom="15px">
                <Image width="100%" src={image} />
              </Container>
              <Text
                fontSize="20px"
                fontWeight="600"
                lineHeight="1.2"
                textAlign="center"
                marginBottom="10px"
              >
                {heading}
              </Text>
              <Text
                fontSize="16px"
                lineHeight="1.2"
                color="text400"
                textAlign="center"
              >
                {text}
              </Text>
            </CarouselItem>
          ))}
        </Carousel>
      </Container>
      <Container
        display="flex"
        justifyContent="center"
        padding="20px 0"
        marginBottom="10px"
      >
        {slides.map(({ heading }, i) => (
          <EmptyButton
            key={heading}
            onClick={() => goTo(i)}
            width="9px"
            height="9px"
            backgroundColor={theme =>
              activeSlide === i
                ? theme.primary.normal
                : theme.background.background400
            }
            borderRadius="100%"
            margin="0 2px"
          />
        ))}
      </Container>
      <Container display="flex" justifyContent="center" marginBottom="25px">
        <EmptyButton
          onClick={prev}
          fontSize="14px"
          margin="0 10px"
          color={activeSlide === 0 ? 'text200' : 'text700'}
          fontWeight="600"
        >
          Prev
        </EmptyButton>
        <EmptyButton
          onClick={next}
          fontSize="14px"
          color={activeSlide === slides.length - 1 ? 'text200' : 'text700'}
          margin="0 10px"
          fontWeight="600"
        >
          Next
        </EmptyButton>
      </Container>
    </Container>
  );
};

DialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DialogContent;
