import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { reviewAggregates as reviewAggregatesQuery } from '../graphql/queries';

const useReviewAggregates = () => {
  const { profileId } = useParams();

  const {
    data: { reviewAggregates } = {},
    loading,
    error,
  } = useQuery(reviewAggregatesQuery, {
    variables: { listingId: profileId },
  });

  return {
    reviewAggregates,
    loading,
    error,
  };
};

export default useReviewAggregates;
