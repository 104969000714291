import { gql } from '@apollo/client';

const deleteListingPost = gql`
  mutation deleteListingPost($id: String!) {
    deleteListingPost(id: $id) {
      success
    }
  }
`;

export default deleteListingPost;
