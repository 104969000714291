import React, { useEffect, useState, useContext, useRef } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import Animation from 'ls-common-client/src/components/Animation';
import Image from 'ls-common-client/src/components/Image';
import Anchor from 'ls-common-client/src/components/Anchor';
import { fromFile, toFile } from 'ls-common-client/src/image';
import imageResizer from '../../../services/imageResizer';
import DragDropScreen from '../../UI/molecules/DragDropScreen';
import MoreMenu from '../../UI/molecules/MoreMenu';
import Panel from '../../UI/atoms/Panel';
import Validator from '../../UI/atoms/Validator';
import uploader from '../../../services/uploader';
import { Context } from '../../../context/AppContext';
import uploading from '../../../animations/uploading.json';
import mediaBackground from '../../../images/mediaBackground.svg';
import CropperDialog from '../../UI/molecules/CropperDialog';
import useSuccessNotificationSlider from '../../../hooks/useSuccessNotificationSlider';

const validateFile = ({ type, size }) => {
  const maxSize = 1024 * 1024 * 10;
  let message;

  if (size > maxSize) {
    message =
      'Oops, your file size is too big. Please try again ensuring the file is a maximum of 10MB.';
  }

  if (!['image/png', 'image/jpeg'].includes(type)) {
    message =
      "Oops, you've selected an invalid file format. You can try again with a jpeg or png file. ";
  }

  if (message) {
    throw new Error(message);
  }
};

const Logo = () => {
  const {
    media: { mobile, desktop },
    profile: {
      profile,
      update: { update },
    },
  } = useContext(Context);

  const { logo } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Your image upload was successful!',
  });

  const [showDragDrop, setShowDragDrop] = useState();
  const [cropperSrc, setCropperSrc] = useState();
  const [showCropperDialog, setShowCropperDialog] = useState();
  const [showMoreMenu, setShowMoreMenu] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const fileUploadRef = useRef();

  const onDragEnter = () => {
    setShowDragDrop(true);
  };

  const onDragLeave = () => {
    setShowDragDrop(false);
  };

  const onSelected = async files => {
    const [file] = files;
    setShowDragDrop(false);

    try {
      validateFile(file);
      const img = await fromFile(file);
      setCropperSrc(img.src);
      setShowCropperDialog(true);
    } catch (e) {
      setError(e);
    }
  };

  const onCrop = async base64 => {
    setShowCropperDialog(false);
    setLoading(true);
    try {
      const file = await toFile(base64);
      const path = await uploader.upload(file);
      await update({ logo: path });
      setError(null);
      successNotificationSlider.open();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onEditImage = () => {
    setShowCropperDialog(true);
    setCropperSrc(logo);
  };

  const onUploadNewImage = () => {
    fileUploadRef.current.click();
  };

  const onCropReady = cropperRef => {
    const { width: containerWidth, height: containerHeight } =
      cropperRef.current.getContainerData();

    const smallestContainerDimension =
      (containerWidth < containerHeight ? containerWidth : containerHeight) -
      100;

    cropperRef.current.setCropBoxData({
      width: smallestContainerDimension,
      height: smallestContainerDimension,
      left: (containerWidth - smallestContainerDimension) / 2,
      top: (containerHeight - smallestContainerDimension) / 2,
    });
    cropperRef.current.setCanvasData({
      width: smallestContainerDimension,
      height: smallestContainerDimension,
    });

    const { width: canvasWidth, height: canvasHeight } =
      cropperRef.current.getCanvasData();

    cropperRef.current.setCanvasData({
      left: (containerWidth - canvasWidth) / 2,
      top: (containerHeight - canvasHeight) / 2,
    });
  };

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
    };
  }, []);

  return (
    <>
      <FileUpload
        accept=".jpg,.jpeg,.png"
        display="none"
        ref={fileUploadRef}
        onSelected={onSelected}
      />

      {error && !mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {((!logo && !mobile) || mobile) && (
        <Container
          flex={mobile ? '0 0 190px' : '1'}
          background={mobile ? `url(${mediaBackground}) no-repeat` : 'none'}
          margin="0 -20px 30px -20px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundSize="cover"
          backgroundPosition="bottom"
          position="relative"
        >
          <Container
            display="flex"
            flexDirection="column"
            alignItems="center"
            maxWidth="280px"
            textAlign="center"
          >
            <Text
              fontSize="25px"
              color="normal"
              fontWeight="bold"
              marginBottom="15px"
            >
              Upload a logo
            </Text>
            <Text fontSize="16px" color="text400" marginBottom="25px">
              Make sure you upload a good quality logo that doesn&apos;t exceed
              10MB
            </Text>
            <FileUpload
              onSelected={onSelected}
              position={mobile ? 'absolute' : 'relative'}
              bottom={mobile ? '-20px' : 0}
              accept=".jpg,.jpeg,.png"
              fontSize="16px"
              color="text700"
              fontWeight="600"
              boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
              backgroundColor="white"
              borderRadius="30px"
              height="40px"
              padding="0 25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                className="ls-icon icon-pluscircle"
                marginRight="10px"
                fontSize="20px"
              />
              Upload File
            </FileUpload>
          </Container>
        </Container>
      )}

      {error && mobile && (
        <Validator fontSize="14px">
          <Icon
            fontSize="18px"
            className="ls-icon icon-generalalert"
            marginRight="5px"
          />
          {error.message}
        </Validator>
      )}

      {logo && (
        <Container
          display="flex"
          margin="0 -10px"
          flexDirection={mobile ? 'column' : 'row'}
        >
          {!mobile && (
            <Container display="flex" flex={desktop ? '0 0 25%' : '1 0 50%'}>
              <FileUpload
                onSelected={onSelected}
                accept=".jpg,.jpeg,.png"
                position="relative"
                display="flex"
                flex="1"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="transparent"
                border={theme => `3px dashed ${theme.border.border300}`}
                borderRadius="20px"
                margin="10px"
              >
                <Container
                  fontSize="16px"
                  color="text700"
                  fontWeight="600"
                  boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
                  backgroundColor="white"
                  borderRadius="30px"
                  height="40px"
                  padding="0 25px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position={mobile ? 'absolute' : 'relative'}
                  bottom={mobile ? '-15px' : '0'}
                >
                  <Icon
                    className="ls-icon icon-pluscircle"
                    marginRight="10px"
                    fontSize="20px"
                  />
                  Upload File
                </Container>
              </FileUpload>
            </Container>
          )}

          <Container flex={desktop ? '0 0 25%' : '1 0 50%'} position="relative">
            <Panel padding={mobile ? '0' : '15px'}>
              <MoreMenu
                show={showMoreMenu}
                onClick={() => setShowMoreMenu(true)}
                onClose={() => setShowMoreMenu(false)}
                menuItems={[
                  {
                    text: 'Edit Image',
                    onClick: onEditImage,
                    icon: 'icon-generaledit',
                  },
                  {
                    text: 'Upload New Image',
                    onClick: onUploadNewImage,
                    icon: 'icon-generalshare',
                  },
                ]}
                anchor="topRight"
                position="absolute"
                right="36px"
                top="36px"
                popupWidth="190px"
                zIndex="2"
              />
              <Anchor
                href={logo}
                target="_blank"
                height="210px"
                display="block"
                width="100%"
                border={theme => `1px solid ${theme.border.border300}`}
                borderRadius="20px"
                overflow="hidden"
                position="relative"
              >
                <Image
                  height="100%"
                  width="100%"
                  objectFit="contain"
                  src={imageResizer.resize(logo, {
                    width: '420px',
                    height: '420px',
                  })}
                />
              </Anchor>
            </Panel>
          </Container>
        </Container>
      )}

      <DragDropScreen
        onSelected={onSelected}
        onDragLeave={onDragLeave}
        accept=".jpg,.jpeg,.png"
        pointerEvents={showDragDrop ? 'default' : 'none'}
        opacity={showDragDrop ? 1 : 0}
        transition="opacity 0.3s ease"
        position="fixed"
        left="0"
        top="0"
        width="100%"
        height="100%"
        zIndex="999"
      />
      <CropperDialog
        show={showCropperDialog}
        onClose={() => setShowCropperDialog(false)}
        src={cropperSrc}
        options={{
          aspectRatio: 1 / 1,
          autoCropArea: 1,
          dragMode: 'move',
          toggleDragModeOnDblclick: false,
          cropBoxMovable: false,
          cropBoxResizable: false,
          wheelZoomRatio: 0.05,
        }}
        onCrop={onCrop}
        onReady={onCropReady}
      />
      {loading && (
        <Container
          position="fixed"
          left="0"
          top="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.3)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="999"
          padding="0 30px"
        >
          <Animation
            width="450px"
            display="flex"
            alignItems="center"
            data={uploading}
            options={{
              rendererSettings: {
                preserveAspectRatio: 'none',
              },
            }}
          />
        </Container>
      )}
    </>
  );
};

export default Logo;
