import { gql } from '@apollo/client';

const addQuoteListingResponse = gql`
  mutation addQuoteListingResponse($input: AddQuoteListingResponseInput!) {
    addQuoteListingResponse(input: $input) {
      quoteListingResponse {
        id
      }
    }
  }
`;

export default addQuoteListingResponse;
