import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { LISTING_POST_STATES } from '../../../lib/constants';
import { Context } from '../../../context/AppContext';

const { ENABLED, DISABLED } = LISTING_POST_STATES;

const StateSelector = ({ onSelect, state, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  return (
    <Container display="flex" {...props}>
      <EmptyButton
        onClick={() => onSelect(ENABLED)}
        borderRadius="22px"
        padding="7px 22px"
        color="normal"
        fontSize="14px"
        fontWeight="600"
        backgroundColor={state === ENABLED ? 'background400' : 'transparent'}
        whiteSpace="nowrap"
        flex={mobile ? '1' : '0'}
      >
        Active Posts
      </EmptyButton>
      <EmptyButton
        onClick={() => onSelect(DISABLED)}
        borderRadius="22px"
        padding="7px 22px"
        color="normal"
        fontSize="14px"
        fontWeight="600"
        backgroundColor={state === DISABLED ? 'background400' : 'transparent'}
        whiteSpace="nowrap"
        flex={mobile ? '1' : '0'}
      >
        Inactive Posts
      </EmptyButton>
    </Container>
  );
};

StateSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
};

export default StateSelector;
