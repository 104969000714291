import React, { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Container from 'ls-common-client/src/components/Container';
import Animation from 'ls-common-client/src/components/Animation';
import { Context } from '../../../context/AppContext';
import { REVIEW_STATUSES } from '../../../lib/constants';
import uploading from '../../../animations/uploading.json';
import useSuccessNotificationSlider from '../../../hooks/useSuccessNotificationSlider';

const { PENDING, APPROVED } = REVIEW_STATUSES;

const ReviewStatusFlag = props => {
  const {
    profile: {
      profile,
      update: { update },
    },
  } = useContext(Context);

  const { reviewStatus } = profile;

  const [loading, setLoading] = useState();

  const successNotificationSlider = useSuccessNotificationSlider();

  const onClick = async () => {
    setLoading(true);
    await update({
      reviewStatus: reviewStatus === PENDING ? APPROVED : PENDING,
    });
    setLoading(false);
    successNotificationSlider.open({
      heading: `This profile has successfully been ${
        reviewStatus === PENDING ? 'approved' : 'flagged for review.'
      }`,
    });
  };

  return (
    <>
      <EmptyButton onClick={onClick} height="24px" {...props}>
        <Icon
          color={reviewStatus === PENDING ? 'text700' : 'text200'}
          fontSize="24px"
          className="ls-icon icon-flag"
        />
      </EmptyButton>
      {loading &&
        createPortal(
          <Container
            position="fixed"
            left="0"
            top="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(0,0,0,0.5)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="999"
            padding="0 30px"
          >
            <Animation
              width="450px"
              display="flex"
              alignItems="center"
              data={uploading}
              options={{
                rendererSettings: {
                  preserveAspectRatio: 'none',
                },
              }}
            />
          </Container>,
          document.body
        )}
    </>
  );
};

export default ReviewStatusFlag;
