import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Popup from 'ls-common-client/src/components/Popup';

const fadeDuration = 300;
const delayDuration = 300;

const anchorArrowStyles = {
  topLeft: {
    top: '100%',
    left: '10px',
  },
  top: {
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
  },
  topRight: {
    top: '100%',
    right: '10px',
  },
  rightTop: { right: '100%', top: '12px' },
  right: {
    right: '100%',
    top: '50%',
    transform: 'translateY(-50%) rotate(45deg)',
  },
  rightBottom: { right: '100%', bottom: '12px' },
  bottomLeft: { bottom: '100%', left: '10px' },
  bottom: {
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
  },
  bottomRight: { bottom: '100%', right: '10px' },
  leftTop: { left: '100%', top: '12px' },
  left: {
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%) rotate(45deg)',
  },
  leftBottom: { left: '100%', bottom: '12px' },
};

const ToolTip = ({
  children,
  anchor,
  arrowAnchor,
  render,
  show,
  popupProps,
  arrowProps,
  ...props
}) => {
  const popupRef = useRef();
  const fadeTimeout = useRef();
  const delayTimeout = useRef();
  const [outOfViewStyles, setOutOfViewStyles] = useState({});
  const [isReady, setIsReady] = useState();
  const [renderPopup, setRenderPopup] = useState();

  const checkOutOfView = () => {
    const { innerWidth } = window;
    const { left, right } = popupRef.current.getBoundingClientRect();

    if (left < 0) {
      setOutOfViewStyles({
        marginRight: `${left - 10}px`,
      });
    }

    if (right > innerWidth) {
      const overflowRight = right - innerWidth;
      setOutOfViewStyles({
        marginLeft: `-${overflowRight + 10}px`,
      });
    }
  };

  const awaitDelay = () =>
    new Promise(resolve => {
      delayTimeout.current = setTimeout(() => {
        resolve();
      }, delayDuration);
    });

  const awaitFade = () =>
    new Promise(resolve => {
      fadeTimeout.current = setTimeout(() => {
        resolve();
      }, fadeDuration);
    });

  useEffect(() => {
    if (show) {
      clearTimeout(delayTimeout.current);
      clearTimeout(fadeTimeout.current);
      setRenderPopup(true);
    } else {
      (async () => {
        await awaitDelay();
        setIsReady(false);
        await awaitFade();
        setRenderPopup(false);
      })();
    }
  }, [show]);

  useEffect(() => {
    if (renderPopup) {
      checkOutOfView();
      setIsReady(true);
    }
  }, [renderPopup]);

  return (
    <Container position="relative" {...props}>
      {children}
      {renderPopup && (
        <Container
          position="absolute"
          height="10px"
          width="10px"
          transform="rotate(45deg)"
          zIndex="3"
          transition={`opacity ${fadeDuration}ms ease`}
          opacity={isReady ? 1 : 0}
          backgroundColor={theme => theme.background.background100}
          {...anchorArrowStyles[arrowAnchor || anchor || 'top']}
          {...arrowProps}
        />
      )}
      <Popup
        ref={popupRef}
        show={renderPopup}
        anchor={anchor}
        padding="5px 12px"
        margin="0"
        borderRadius="7px"
        transition="opacity 0.5s ease"
        opacity={isReady ? 1 : 0}
        zIndex="2"
        {...popupProps}
        {...outOfViewStyles}
      >
        {render()}
      </Popup>
    </Container>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node,
  arrowAnchor: PropTypes.string,
  anchor: PropTypes.string,
  render: PropTypes.func,
  show: PropTypes.bool,
  popupProps: PropTypes.shape({}),
  arrowProps: PropTypes.shape({}),
};

ToolTip.defaultProps = {
  children: null,
  arrowAnchor: null,
  anchor: 'top',
  render: () => null,
  show: false,
  popupProps: {},
  arrowProps: {},
};

export default ToolTip;
