import React, { useEffect, useState } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import upgradeLogo from '../../../../images/upgradeLogo.png';
import upgradePost from '../../../../images/upgradePost.svg';
import upgradeReview from '../../../../images/upgradeReview.svg';
import upgradeReport from '../../../../images/upgradeReport.svg';

let timeout;
let prevPageX;
let direction;
const transitionDuration = 300;
const duration = 4000;

const salesPoints = [
  {
    text: 'Add logos, photos, videos and more.',
    image: (
      <Container
        width="190px"
        height="120px"
        display="flex"
        alignItems="center"
      >
        <Image width="100%" src={upgradeLogo} />
      </Container>
    ),
  },
  {
    text: 'Share posts to stand out from the crowd.',
    image: (
      <Container
        width="150px"
        height="120px"
        display="flex"
        alignItems="center"
      >
        <Image width="100%" src={upgradePost} />
      </Container>
    ),
  },
  {
    text: 'Automate review and enquiry responses.',
    image: (
      <Container
        width="115px"
        height="120px"
        display="flex"
        alignItems="center"
      >
        <Image width="100%" src={upgradeReview} />
      </Container>
    ),
  },
  {
    text: 'Receive monthly reports to your email.',
    image: (
      <Container
        width="150px"
        height="120px"
        display="flex"
        alignItems="center"
      >
        <Image width="100%" src={upgradeReport} />
      </Container>
    ),
  },
];

const UpgradeSlide = props => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const fadeOut = () =>
    new Promise(resolve => {
      setOpacity(0);
      setTimeout(() => {
        resolve();
      }, transitionDuration);
    });

  const fadeIn = () =>
    new Promise(resolve => {
      setOpacity(1);
      setTimeout(() => {
        resolve();
      }, transitionDuration);
    });

  const slideNext = async () => {
    const slideLength = salesPoints.length;
    await fadeOut();
    setSlideIndex(i => {
      if (i < slideLength - 1) {
        return i + 1;
      }
      return 0;
    });
    await fadeIn();
  };

  const slidePrev = async () => {
    const slideLength = salesPoints.length;
    await fadeOut();
    setSlideIndex(i => {
      if (i) {
        return i - 1;
      }
      return slideLength - 1;
    });
    await fadeIn();
  };

  const initSlide = () => {
    timeout = setTimeout(async () => {
      slideNext();
      initSlide();
    }, duration);
  };

  const onNavClick = async i => {
    clearTimeout(timeout);

    if (i !== slideIndex) {
      await fadeOut();
      setSlideIndex(i);
      await fadeIn();
    }

    initSlide();
  };

  const onTouchMove = e => {
    const {
      touches: [touch],
    } = e;

    const { pageX } = touch;

    if (prevPageX) {
      const distanceX = pageX - prevPageX;
      direction = distanceX < 0 ? 'right' : 'left';
    }

    prevPageX = pageX;
  };

  const onTouchEnd = () => {
    clearTimeout(timeout);

    if (direction === 'right') {
      slideNext();
    }
    if (direction === 'left') {
      slidePrev();
    }

    initSlide();
  };

  useEffect(() => {
    initSlide();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const slide = salesPoints[slideIndex];

  return (
    <Container {...props}>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        opacity={opacity}
        transition={`all ${transitionDuration}ms ease`}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {slide.image}
        <Container display="flex" alignItems="center" marginBottom="10px">
          <Text
            fontSize="16px"
            color="text400"
            fontWeight="600"
            lineHeight="1.2"
            marginTop="15px"
            width="180px"
            textAlign="center"
          >
            {slide.text}
          </Text>
        </Container>
      </Container>
      <Container display="flex" justifyContent="center">
        <Container>
          {salesPoints.map(({ text }, i) => (
            <EmptyButton
              key={text}
              width="8px"
              height="8px"
              borderRadius="100%"
              margin="3px"
              backgroundColor={slideIndex === i ? '#9b9b9b' : '#d8d8d8'}
              onClick={() => onNavClick(i)}
            />
          ))}
        </Container>
      </Container>
    </Container>
  );
};

export default UpgradeSlide;
