import React, { useEffect } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import Anchor from 'ls-common-client/src/components/Anchor';
import Panel from '../../UI/atoms/Panel';
import useB2bPosts from '../../../hooks/useB2bPosts';

const ProfileTips = props => {
  const { fetch, b2bPosts = [], loading } = useB2bPosts({ size: 6 });

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Panel display="flex" flexDirection="column" {...props}>
      <Text
        display="block"
        fontSize="16px"
        fontWeight="600"
        marginBottom="10px"
      >
        Profile Tips
      </Text>
      {loading && (
        <Container
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="center"
        >
          <Loader width="200px" />
        </Container>
      )}
      {b2bPosts.map(({ id, url, image, title }) => (
        <Container key={id}>
          <Anchor
            href={url}
            target="_blank"
            display="flex"
            margin="0 -10px"
            alignItems="center"
          >
            <Text
              borderRadius="11px"
              overflow="hidden"
              height="80px"
              flex="0 0 80px"
              margin="10px"
            >
              <Image src={image} width="100%" height="100%" objectFit="cover" />
            </Text>
            <Text
              margin="10px"
              flex="1"
              fontSize="16px"
              lineHeight="1.2"
              fontWeight="600"
              color="normal"
            >
              {title}
            </Text>
          </Anchor>
          <Container padding="10px 0">
            <Container
              width="100%"
              borderBottom="1px solid"
              borderColor="border300"
            />
          </Container>
        </Container>
      ))}
    </Panel>
  );
};

export default ProfileTips;
