import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'ls-common-client/src/components/Form';
import Container from 'ls-common-client/src/components/Container';
import FormDialogSubmit from '../../../UI/molecules/FormDialogSubmit';
import SelectButton from '../../../UI/atoms/SelectButton';
import useSuccessNotificationSlider from '../../../../hooks/useSuccessNotificationSlider';
import { Context } from '../../../../context/AppContext';

const allAmenities = [
  {
    id: 'cl28b4i1f0000c5mn4aodaklp',
    label: 'Free parking',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0001c5mnc136el86',
    label: 'Free WiFi',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0002c5mn3i3l6cru',
    label: 'After-hours emergency service',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0003c5mn39rxbqmb',
    label: 'Public toilets available',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0004c5mna0waebkg',
    label: 'Undercover parking',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0005c5mn46zb91cv',
    label: 'Child friendly',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0006c5mngzhj0p20',
    label: 'Pet friendly',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0007c5mn26186w9a',
    label: 'Wheelchair accessible',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0008c5mnfkmf53th',
    label: 'Loyalty program',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f0009c5mn1ccu1u88',
    label: 'No appointment needed',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000ac5mn490h25b8',
    label: 'ATM',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000bc5mn4go135yb',
    label: 'Mobile service',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000cc5mnhuxk5emo',
    label: 'Parents room',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000dc5mn1kxlhybb',
    label: 'Electric car charging',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000ec5mnge7d03bj',
    label: 'Playground',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000fc5mn8bo0e3nk',
    label: 'Baggage storage',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
  {
    id: 'cl28b4i1f000gc5mn1sd5ebba',
    label: 'Loan car available',
    description: null,
    enabled: 1,
    created_at: '2022-04-21 11:10:25.875000000',
    updated_at: '2022-04-21 11:10:25.875000000',
  },
];

const AmenitiesForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
  } = useContext(Context);

  const { amenities } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const [isDirty, setIsDirty] = useState();
  const [selectedAmenities, setSelectedAmenities] = useState(
    amenities.map(({ id }) => ({ id }))
  );

  const submit = async e => {
    e.preventDefault();
    await update({ amenities: selectedAmenities });
    successNotificationSlider.open();
    onClose();
  };

  const selected = id => selectedAmenities.some(item => item.id === id);

  const onClick = id => {
    setIsDirty(true);
    if (selected(id)) {
      setSelectedAmenities(selectedAmenities.filter(item => item.id !== id));
    } else {
      setSelectedAmenities([...selectedAmenities, { id }]);
    }
  };

  return (
    <Form
      onSubmit={submit}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container>
        <Container display="flex" flexWrap="wrap" margin="0 -5px 30px -5px">
          {allAmenities.map(({ label, id }) => (
            <SelectButton
              key={id}
              onClick={() => onClick(id)}
              selected={selected(id)}
              margin="5px"
            >
              {label}
            </SelectButton>
          ))}
        </Container>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

AmenitiesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AmenitiesForm;
