import { gql } from '@apollo/client';

const profileSuggest = gql`
  query profileSuggest($query: String!) {
    profileSuggest(query: $query) {
      edges {
        node {
          id
          displayName
          street
          suburb
          stateCode
          postcode
        }
      }
    }
  }
`;

export default profileSuggest;
