import React, { useMemo, useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import cuid from 'cuid';
import { isTouch } from 'ls-common-client/src/util';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Button from 'ls-common-client/src/components/Button';
import useListingPosts from '../../../hooks/useListingPosts';
import Post from './Post';
import { Context } from '../../../context/AppContext';
import noPosts from '../../../images/noPosts.svg';
import StateSelector from './StateSelector';
import { LISTING_POST_STATES } from '../../../lib/constants';
import PostModal from '../../UI/molecules/PostModal';
import CreatePostModal from '../../UI/molecules/CreatePostModal';
import { listingPostDisabled } from '../../../lib/util';
import PageHeader from '../../UI/molecules/PageHeader';
import useSuccessNotificationSlider from '../../../hooks/useSuccessNotificationSlider';
import useDeleteNotificationSlider from '../../../hooks/useDeleteNotificationSlider';

const { ENABLED, DISABLED } = LISTING_POST_STATES;

const PostsPaid = props => {
  const {
    media: { device, desktop, mobile },
    user: { user },
  } = useContext(Context);

  const successNotificationSlider = useSuccessNotificationSlider();
  const deleteNotificationSlider = useDeleteNotificationSlider();

  const { profileId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get('state') || ENABLED;

  const [fetchMoreLoading, setFetchMoreLoading] = useState();
  const [editData, setEditData] = useState();
  const [showPostModal, setShowPostModal] = useState();
  const [showEditModal, setShowEditModal] = useState();
  const [showCreateModal, setShowCreateModal] = useState();
  const [initialSlide, setInitialSlide] = useState();
  const [deactivateLoading, setDeactivateLoading] = useState();
  const [activateLoading, setActivateLoading] = useState();
  const [deleteLoading, setDeleteLoading] = useState();
  const [postModalPaused, setPostModalPaused] = useState();

  const variables = useMemo(
    () => ({
      state,
    }),
    [state]
  );

  const {
    listingPosts,
    previousListingPosts,
    loading,
    fetchMore,
    create: { create },
    update: { update },
    remove: { remove },
  } = useListingPosts({
    variables,
  });

  const { edges = [], pageInfo: { endCursor, hasNextPage } = {} } =
    listingPosts || {};

  const columns = useMemo(() => {
    if (!edges.length) {
      return null;
    }

    const numberOfColumns = {
      mobile: 1,
      tablet: 3,
      desktop: 4,
    }[device];

    const cols = [...Array(numberOfColumns)].map(() => ({
      id: cuid(),
      column: [],
    }));

    edges.forEach((edge, i) => {
      const {
        node: { id },
      } = edge;

      const iteration = i % numberOfColumns;

      if (cols[iteration].column.length) {
        cols[iteration].column.push({ index: i, ...edge });
      } else {
        cols[iteration] = { id, column: [{ index: i, ...edge }] };
      }
    });

    return cols;
  }, [edges, device]);

  const showCreateSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your post was successfully created!',
    });
  };

  const showEditSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your post was successfully edited!',
    });
  };

  const showDeactivateSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your post was successfully deactivated!',
    });
  };

  const showActivateSuccess = () => {
    successNotificationSlider.open({
      heading: 'Your post was successfully activated!',
    });
  };

  const showDeleteSuccess = () => {
    deleteNotificationSlider.open({
      heading: 'Your post has been deleted.',
    });
  };

  const onLoadMoreClick = async () => {
    setFetchMoreLoading(true);
    await fetchMore({ variables: { after: endCursor } }, state);
    setFetchMoreLoading(false);
  };

  const onEditClick = data => {
    setShowEditModal(true);
    setEditData(data);
  };

  const onCreateSubmit = async values => {
    await create({
      state: ENABLED,
      listingId: profileId,
      userId: user.id,
      ...values,
    });
    setSearchParams({
      state: listingPostDisabled(values) ? DISABLED : ENABLED,
    });
    setShowCreateModal(false);
    showCreateSuccess();
  };

  const onEditSubmit = async values => {
    const { id } = editData;
    await update({ id, ...values });
    setSearchParams({
      state: listingPostDisabled(values) ? DISABLED : ENABLED,
    });
    setShowEditModal(false);
    showEditSuccess();
  };

  const onDeactivateClick = async id => {
    setDeactivateLoading(true);
    await update({ id, state: DISABLED, startDate: null, endDate: null });
    setDeactivateLoading(false);
    showDeactivateSuccess();
  };

  const onActivateClick = async id => {
    setActivateLoading(true);
    await update({ id, state: ENABLED, startDate: null, endDate: null });
    setActivateLoading(false);
    showActivateSuccess();
  };

  const onDeleteClick = async data => {
    setDeleteLoading(true);
    await remove(data);
    showDeleteSuccess();
    setDeleteLoading(false);
  };

  const menuItems = data => {
    const { id } = data;
    const isDisabled = listingPostDisabled(data);

    return [
      {
        text: 'Edit',
        onClick: e => {
          e.stopPropagation();
          onEditClick(data);
        },
        icon: 'icon-generaledit',
      },
      ...(isDisabled
        ? [
            {
              text: 'Activate',
              onClick: e => {
                e.stopPropagation();
                onActivateClick(id);
              },
              loading: activateLoading,
              icon: 'icon-generalrefresh',
            },
          ]
        : [
            {
              text: 'Deactivate',
              onClick: e => {
                e.stopPropagation();
                onDeactivateClick(id);
              },
              loading: deactivateLoading,
              icon: 'icon-generalxlarge',
            },
          ]),
      {
        text: 'Delete',
        loading: deleteLoading,
        onClick: e => {
          e.stopPropagation();
          onDeleteClick(data);
        },
        icon: 'icon-categorysanitarydisposal',
      },
    ];
  };

  if (loading && !previousListingPosts) {
    return (
      <>
        <PageHeader>Posts</PageHeader>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
          width="100%"
          height="100%"
          {...props}
        >
          <Loader width="200px" />
        </Container>
      </>
    );
  }

  return (
    <>
      <PageHeader>Posts</PageHeader>
      {!columns && !loading ? (
        <Container
          padding={desktop ? '0 20px' : '20px'}
          height="100%"
          display="flex"
          flexDirection="column"
          {...props}
        >
          <StateSelector
            onSelect={value => setSearchParams({ state: value })}
            state={state}
          />
          <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            flex="1"
          >
            <Container marginBottom="20px">
              <Image width="100%" src={noPosts} />
            </Container>
            <Text
              fontSize="25px"
              fontWeight="bold"
              lineHeight="1"
              textAlign="center"
              marginBottom="25px"
            >
              You have no {state === DISABLED ? 'inactive' : 'active'} posts
            </Text>
            <EmptyButton
              onClick={() => setShowCreateModal(true)}
              fontSize="16px"
              color="text700"
              padding="10px 33px"
              background="white"
              fontWeight="600"
              borderRadius="30px"
              boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.1)"
            >
              Create Post
            </EmptyButton>
          </Container>
        </Container>
      ) : (
        <Container
          padding={desktop ? '0 20px 20px 20px' : '20px'}
          height="100%"
          {...props}
        >
          <Container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <StateSelector
              onSelect={value => setSearchParams({ state: value })}
              state={state}
              flex={mobile ? '0 0 100%' : '1'}
              marginBottom="20px"
            />
            {!loading && (
              <Button
                onClick={() => setShowCreateModal(true)}
                primary
                rounded
                height="32px"
                fontSize="14px"
                width="auto"
                marginLeft="auto"
              >
                Create Post
              </Button>
            )}
          </Container>
          {loading ? (
            <Container
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="20px"
              width="100%"
              height="100%"
              {...props}
            >
              <Loader width="200px" />
            </Container>
          ) : (
            <Container
              display="flex"
              flexWrap="wrap"
              margin="0 -10px 30px -10px"
            >
              {columns.map(({ id, column }) => (
                <Container
                  key={id}
                  display="flex"
                  flexDirection="column"
                  flex="1"
                >
                  {column.map(({ node, index }) => (
                    <Post
                      menuItems={menuItems(node)}
                      key={node.id}
                      data={node}
                      type="button"
                      onClick={() => {
                        setShowPostModal(true);
                        setInitialSlide(index);
                      }}
                      cursor="pointer"
                    />
                  ))}
                </Container>
              ))}
            </Container>
          )}
          {hasNextPage && (
            <Container
              display="flex"
              justifyContent="center"
              position="relative"
              minHeight="50px"
            >
              {fetchMoreLoading ? (
                <Loader
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%,-50%)"
                  width="150px"
                />
              ) : (
                <EmptyButton
                  backgroundColor="white"
                  border={theme => `1px solid ${theme.border.border300}`}
                  height="35px"
                  padding="0 18px"
                  borderRadius="30px"
                  fontSize="14px"
                  fontWeight="600"
                  color="normal"
                  onClick={onLoadMoreClick}
                >
                  Load More
                </EmptyButton>
              )}
            </Container>
          )}
        </Container>
      )}

      <PostModal
        show={showPostModal}
        onClose={() => setShowPostModal(false)}
        data={edges}
        initialSlide={initialSlide}
        onClick={() => {
          if (!isTouch()) {
            setPostModalPaused(!postModalPaused);
          }
        }}
        paused={postModalPaused}
      />
      <CreatePostModal
        title="Create a Post"
        submitText="Create"
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={onCreateSubmit}
      />
      <CreatePostModal
        title="Edit Post"
        submitText="Update"
        data={editData}
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSubmit={onEditSubmit}
      />
    </>
  );
};

export default PostsPaid;
