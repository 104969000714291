import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { wktToGeoJSON } from '@terraformer/wkt';
import mapboxgl from 'mapbox-gl';
import mapConfig from 'ls-map/src/map/config';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Panel from '../../../UI/atoms/Panel';
import { Context } from '../../../../context/AppContext';
import CategoriesDialog from '../CategoriesDialog';
import LocationDialog from '../LocationDialog';
import {
  addressJoin,
  sortCategories,
  getPackageGradient,
} from '../../../../lib/util';

const { style } = mapConfig;

const CategoriesLocation = props => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { street, suburb, stateCode, postcode, coordinates } = profile;

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const markerRef = useRef();
  const [showCategories, setShowCategories] = useState();
  const [showLocationDialog, setShowLocationDialog] = useState();
  const [showCategoriesDialog, setShowCategoriesDialog] = useState();

  const address = useMemo(
    () => addressJoin(street, suburb, stateCode, postcode),
    [street, suburb, stateCode, postcode]
  );

  const categories = useMemo(
    () => sortCategories(profile.categories),
    [profile]
  );

  const { lat, lng } = useMemo(() => {
    if (!coordinates) {
      return {};
    }

    const {
      coordinates: [lg, lt],
    } = wktToGeoJSON(coordinates);

    return { lat: lt, lng: lg };
  }, [coordinates]);

  const showMap = lat && lng;

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setCenter([lng, lat]);
      markerRef.current.setLngLat([lng, lat]);
    }
  }, [lat, lng]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style,
      zoom: 15,
      center: [lng, lat],
    });

    map.once('load', () => {
      map.setLayoutProperty('Selected', 'visibility', 'none');
    });

    const marker = new mapboxgl.Marker({ color: '#F16159' })
      .setLngLat([lng, lat])
      .addTo(map);

    mapRef.current = map;
    markerRef.current = marker;

    return () => map.remove();
  }, []);

  return (
    <>
      <Panel {...props}>
        <Container
          paddingBottom="15px"
          borderBottom={theme => `1px solid ${theme.border.border300}`}
          marginBottom="15px"
        >
          <Container display="flex" alignItems="center" marginBottom="15px">
            <Text fontSize="16px" fontWeight="600">
              Categories
            </Text>
            <EmptyButton
              onClick={() => setShowCategoriesDialog(true)}
              marginLeft="10px"
            >
              <Icon
                fontSize="18px"
                color="text200"
                className="ls-icon icon-pencilcircle"
                lineHeight="2"
              />
            </EmptyButton>
          </Container>

          {categories.length ? (
            <Container display="flex" flexWrap="wrap">
              {categories
                .slice(0, showCategories ? categories.length : 4)
                .map(category => {
                  const { name } = category;
                  const { id: packageId } = category.package || {};
                  const gradient = getPackageGradient(packageId);

                  return (
                    <EmptyButton
                      key={name}
                      onClick={() => setShowCategoriesDialog(true)}
                      border={theme => `1px solid ${theme.border.border300}`}
                      borderRadius="50px"
                      fontSize="14px"
                      color="normal"
                      fontWeight="600"
                      padding="7px 12px"
                      margin="5px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {gradient && (
                        <Icon
                          marginRight="8px"
                          fontSize="18px"
                          className="ls-icon icon-ribbonoutline"
                          _before={{
                            backgroundImage: `linear-gradient(${gradient.from}, ${gradient.to})`,
                            backgroundClip: 'text',
                            color: 'transparent',
                          }}
                        />
                      )}
                      {name}
                    </EmptyButton>
                  );
                })}
              {categories.length > 4 && (
                <EmptyButton
                  color="text300"
                  fontWeight="600"
                  marginLeft="auto"
                  fontSize="14px"
                  onClick={() => setShowCategories(!showCategories)}
                >
                  Show {showCategories ? 'Less' : 'All'}
                </EmptyButton>
              )}
            </Container>
          ) : (
            <Container marginBottom="5px">
              <EmptyButton
                onClick={() => setShowCategoriesDialog(true)}
                color="text700"
                fontWeight="600"
                fontSize="14px"
              >
                Add Categories
              </EmptyButton>
            </Container>
          )}
        </Container>
        <Container>
          <Text
            fontSize="16px"
            fontWeight="600"
            display="block"
            marginBottom="15px"
          >
            Location
          </Text>
          <Container position="relative">
            <EmptyButton
              onClick={() => setShowLocationDialog(true)}
              border={theme => `1px solid ${theme.border.border300}`}
              borderRadius="50px"
              backgroundColor="white"
              fontSize="14px"
              color="text500"
              padding="7px 10px"
              display="flex"
              alignItems="center"
              position="absolute"
              textAlign="left"
              lineHeight="1.2"
              left={showMap ? '10px' : '0'}
              top={showMap ? '10px' : '0'}
              zIndex="1"
            >
              <Icon
                color="#f16159"
                fontSize="15px"
                marginRight="8px"
                className="ls-icon icon-generalpindropmini"
              />
              {address || 'Add Address'}
              <Icon
                color="text200"
                fontSize="18px"
                className="ls-icon icon-pencilcircle"
                marginLeft="10px"
              />
            </EmptyButton>
            {showMap && (
              <Container ref={mapContainerRef} width="100%" height="530px" />
            )}
          </Container>
        </Container>
      </Panel>
      <CategoriesDialog
        show={showCategoriesDialog}
        onClose={() => setShowCategoriesDialog(false)}
      />
      <LocationDialog
        show={showLocationDialog}
        onClose={() => setShowLocationDialog(false)}
      />
    </>
  );
};

export default CategoriesLocation;
