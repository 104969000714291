import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Menu from './Menu';

const MoreMenuMobile = ({ onClose, ...props }) => (
  <Dialog
    height="calc(100vh - 100px)"
    maxWidth="unset"
    position="fixed"
    bottom="0"
    left="0"
    clickOutside
    borderRadius="15px 15px 0 0"
    render={({ children }) => children}
    onClose={onClose}
    allowTouchMoveQuerySelectors={['.moreMenuScrollContainer']}
    {...props}
  >
    <Menu onClose={onClose} />
  </Dialog>
);

MoreMenuMobile.propTypes = {
  onClose: PropTypes.func,
};

MoreMenuMobile.defaultProps = {
  onClose: () => {},
};

export default MoreMenuMobile;
