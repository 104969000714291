import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import { Context } from '../../../context/AppContext';

const DialogHeader = ({ header, subHeader, onClose, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  return (
    <Container marginBottom="30px" {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap-reverse"
        position="relative"
      >
        <Text
          fontSize={mobile ? '30px' : '25px'}
          fontWeight="600"
          lineHeight="1"
          flex={mobile ? '0 0 100%' : '1'}
          marginBottom="12px"
        >
          {header}
        </Text>
        <EmptyButton
          onClick={onClose}
          padding="10px"
          marginRight="-20px"
          marginTop="-25px"
          marginLeft="auto"
        >
          <Icon
            fontSize="15px"
            color="normal"
            className="ls-icon icon-generalxlarge"
          />
        </EmptyButton>
      </Container>
      {subHeader && (
        <Text fontSize={mobile ? '18px' : '16px'} color="text400">
          {subHeader}
        </Text>
      )}
    </Container>
  );
};

DialogHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
};

DialogHeader.defaultProps = {
  header: '',
  subHeader: '',
  onClose: () => {},
};

export default DialogHeader;
