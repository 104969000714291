import React, { createContext, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import useMedia from 'ls-common-client/src/hooks/useMedia';
import useUser from './hooks/useUser';
import useProfile from './hooks/useProfile';
import useGoogleMaps from './hooks/useGoogleMaps';
import useSideBar from './hooks/useSideBar';
import useUpgradeDialog from './hooks/useUpgradeDialog';
import useNotificationSlider from './hooks/useNotificationSlider';
import UpgradeDialog from '../../components/UI/molecules/UpgradeDialog';

const Context = createContext();

const Provider = ({ children }) => {
  const portalRef = useRef();
  const theme = useContext(ThemeContext);
  const media = useMedia(theme.mediaQueries);
  const user = useUser();
  const profile = useProfile();
  const googleMaps = useGoogleMaps();
  const sideBar = useSideBar();
  const upgradeDialog = useUpgradeDialog();
  const notificationSlider = useNotificationSlider();

  const value = useMemo(
    () => ({
      theme,
      media,
      user,
      profile,
      googleMaps,
      sideBar,
      upgradeDialog,
      notificationSlider,
    }),
    [
      theme,
      media,
      user,
      profile,
      googleMaps,
      sideBar,
      upgradeDialog,
      notificationSlider,
    ]
  );

  return (
    <Context.Provider value={value}>
      {children}
      <UpgradeDialog
        media={media}
        user={user}
        show={upgradeDialog.show}
        onClose={upgradeDialog.close}
      />
      {notificationSlider.notifications.map(props => (
        <NotificationSlider
          mode={media.mobile ? 'mobile' : 'desktop'}
          zIndex="10"
          portalElement={portalRef.current}
          position="relative"
          width={media.mobile ? '100%' : '465px'}
          {...props}
        />
      ))}
      <Container
        ref={portalRef}
        position="fixed"
        zIndex="101"
        bottom="0"
        left="0"
        right="0"
      />
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export { Provider, Context };
