import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import upgradeDesktop from '../../../../images/upgradeDesktop.png';
import UpgradeForm from './UpgradeForm';

const salesPoints = [
  'Add logos, photos, videos and more.',
  'Share posts to stand out from the crowd.',
  'Receive monthly reports to your email.',
  'Utilise our 24/7 reporting dashboard.',
  'Automate review and enquiry responses.',
];

const UpgradeDialogDesktop = ({ show, onClose, user }) => (
  <Dialog
    show={show}
    width="calc(100vw - 50px)"
    maxWidth="833px"
    mode="desktop"
  >
    <Container display="flex">
      <Container flex="0 0 50%" padding="30px">
        <Text
          fontSize="25px"
          lineHeight="1"
          fontWeight="600"
          display="block"
          marginBottom="15px"
        >
          Let&apos;s get you started!
        </Text>
        <Text
          fontSize="16px"
          color="text400"
          lineHeight="1.2"
          display="block"
          marginBottom="30px"
        >
          Please confirm the below information are the best details for our team
          to reach you on.
        </Text>
        <UpgradeForm user={user} />
      </Container>
      <Container
        flex="0 0 50%"
        backgroundColor="background300"
        borderRadius="20px"
      >
        <Container display="flex" justifyContent="flex-end">
          <EmptyButton onClick={onClose} padding="15px">
            <Icon fontSize="15px" className="ls-icon icon-generalxlarge" />
          </EmptyButton>
        </Container>
        <Container padding="0 60px 60px 60px">
          <Container display="flex" justifyContent="center">
            <Container width="220px">
              <Image width="100%" src={upgradeDesktop} />
            </Container>
          </Container>
          <Container>
            <Text
              fontSize="25px"
              fontWeight="600"
              display="block"
              marginBottom="18px"
              lineHeight="1.1"
            >
              Why you should upgrade
            </Text>
            {salesPoints.map(text => (
              <Container
                key={text}
                display="flex"
                alignItems="center"
                marginBottom="10px"
              >
                <Container
                  height="15px"
                  flex="0 0 15px"
                  borderRadius="100%"
                  backgroundColor={theme => theme.secondary.secondary200}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginRight="8px"
                  marginTop="3px"
                >
                  <Icon
                    color="white"
                    className="ls-icon icon-generalticksmall"
                    fontSize="9px"
                    marginTop="2px"
                    marginLeft="1px"
                  />
                </Container>

                <Text
                  fontSize="14px"
                  color="text300"
                  fontWeight="600"
                  lineHeight="1.2"
                >
                  {text}
                </Text>
              </Container>
            ))}
          </Container>
        </Container>
      </Container>
    </Container>
  </Dialog>
);

UpgradeDialogDesktop.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

UpgradeDialogDesktop.defaultProps = {
  show: false,
  user: undefined,
};

export default UpgradeDialogDesktop;
