import React, { useContext, useMemo, useState } from 'react';
import cuid from 'cuid';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Form from 'ls-common-client/src/components/Form';
import {
  toE164,
  validate as validatePhone,
  formatLS,
} from 'ls-common-client/src/phone';
import Validator from '../../../../UI/atoms/Validator';
import Label from '../../../../UI/atoms/Label';
import Input from '../../../../UI/atoms/Input';
import Radio from '../../../../UI/atoms/Radio';
import RemoveButton from '../../../../UI/molecules/RemoveButton';
import PrimaryToolTip from './PrimaryToolTip';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import { Context } from '../../../../../context/AppContext';
import { CONTACT_TYPES, URL_LABELS } from '../../../../../lib/constants';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

const { PHONE, EMAIL, URL } = CONTACT_TYPES;
const { WEBSITE, BOOKING } = URL_LABELS;

const schema = Joi.object({
  contacts: Joi.array().items(
    Joi.object({
      type: Joi.string().optional(),
      label: Joi.string().optional(),
      value: Joi.string().optional().allow(''),
      primary: Joi.bool(),
      key: Joi.string(),
    }).custom((contact, { message }) => {
      const { type, value } = contact;

      if (type === PHONE) {
        if (validatePhone(value)) {
          return { ...contact, value: toE164(value) };
        }
        return message('Invalid phone number');
      }

      if (type === EMAIL && value) {
        try {
          Joi.attempt(value, Joi.string().email({ tlds: false }));
        } catch (e) {
          return message('Invalid email');
        }
      }

      if (type === URL && value) {
        try {
          Joi.attempt(value, Joi.string().uri());
        } catch (e) {
          return message('Invalid URL');
        }
      }

      return contact;
    })
  ),
});

const parseContactsIn = contacts => {
  const values = contacts.map(({ type, label, primary, value }) => ({
    type,
    label,
    value: type === PHONE ? formatLS(value) : value,
    primary,
    key: cuid(),
  }));

  const hasWebsite = contacts.some(
    ({ type, label }) => type === URL && label === WEBSITE
  );
  const hasEmail = contacts.some(({ type }) => type === EMAIL);
  const hasBooking = contacts.some(
    ({ type, label }) => type === URL && label === BOOKING
  );

  if (!hasEmail) {
    values.push({
      type: EMAIL,
      label: EMAIL,
      value: '',
    });
  }

  if (!hasWebsite) {
    values.push({
      type: URL,
      label: WEBSITE,
      value: '',
    });
  }

  if (!hasBooking) {
    values.push({
      type: URL,
      label: BOOKING,
      value: '',
    });
  }

  return values;
};

const parseContactsOut = contacts =>
  contacts
    .filter(({ value }) => value)
    .map(({ label, type, value, primary }) => ({
      label,
      type,
      value,
      primary,
    }));

const ContactInformationForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { update, loading },
    },
  } = useContext(Context);

  const { contacts } = profile;

  const [showToolTip, setShowToolTip] = useState(true);

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      contacts: parseContactsIn(contacts),
    },
    resolver: joiResolver(schema),
  });

  const submit = async values => {
    await update({
      contacts: parseContactsOut(values.contacts),
    });
    successNotificationSlider.open();
    onClose();
  };

  const {
    fields,
    append,
    remove,
    update: updateField,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const phoneIndex = useMemo(
    () => fields.findIndex(({ type }) => type === PHONE),
    [fields]
  );

  const emailIndex = useMemo(
    () => fields.findIndex(({ type }) => type === EMAIL),
    [fields]
  );

  const websiteIndex = useMemo(
    () =>
      fields.findIndex(({ type, label }) => type === URL && label !== BOOKING),
    [fields]
  );

  const bookingIndex = useMemo(
    () =>
      fields.findIndex(({ type, label }) => type === URL && label === BOOKING),
    [fields]
  );

  const phoneCount = useMemo(
    () => fields.reduce((acc, { type }) => (type === PHONE ? acc + 1 : acc), 0),
    [fields]
  );

  const onPrimaryClick = index => {
    const values = getValues('contacts');
    values.forEach((value, i) => {
      updateField(i, {
        ...value,
        primary: i === index,
      });
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      noValidate
      display="flex"
      flexDirection="column"
      {...props}
    >
      <Container>
        <Container marginBottom="20px">
          {fields.map(({ type, key, primary }, i) => {
            if (type !== PHONE) return null;

            return (
              <Container key={key} marginBottom="15px">
                <PrimaryToolTip
                  show={showToolTip && i === phoneIndex}
                  onClose={() => setShowToolTip(false)}
                >
                  <EmptyButton
                    onClick={() => {
                      onPrimaryClick(i);
                    }}
                    display="flex"
                    alignItems="center"
                    marginBottom="10px"
                  >
                    <Radio active={primary} marginRight="8px" />
                    <Label marginBottom="0">
                      {phoneIndex === i ? 'Phone' : 'Additional Phone'}
                    </Label>
                  </EmptyButton>
                </PrimaryToolTip>
                <Container marginBottom="5px" position="relative">
                  <RemoveButton
                    onClick={() => remove(i)}
                    position="absolute"
                    right="-8px"
                    top="16px"
                  />
                  <Input
                    {...register(`contacts.${i}.value`)}
                    placeholder="Add phone"
                  />
                  <Validator>{errors.contacts?.[i]?.message}</Validator>
                </Container>
              </Container>
            );
          })}
          {phoneCount < 5 && (
            <Container>
              <EmptyButton
                onClick={() =>
                  append({
                    value: '',
                    type: PHONE,
                    label: PHONE,
                    key: cuid(),
                    primary: false,
                  })
                }
                color="text700"
                fontWeight="600"
                fontSize="14px"
              >
                {phoneCount ? 'Add additional' : 'Add Phone'}
              </EmptyButton>
            </Container>
          )}
        </Container>

        <Container marginBottom="20px">
          <PrimaryToolTip
            show={!phoneCount && showToolTip}
            onClose={() => setShowToolTip(false)}
          >
            <EmptyButton
              onClick={() => {
                onPrimaryClick(emailIndex);
              }}
              display="flex"
              alignItems="center"
              marginBottom="10px"
            >
              <Radio active={fields[emailIndex].primary} marginRight="8px" />
              <Label marginBottom="0">Email</Label>
            </EmptyButton>
          </PrimaryToolTip>
          <Input
            {...register(`contacts.${emailIndex}.value`)}
            placeholder="Email"
            autoCorrect="off"
            autoCapitalize="none"
          />
          <Validator>{errors.contacts?.[emailIndex]?.message}</Validator>
        </Container>

        <Container marginBottom="20px">
          <EmptyButton
            onClick={() => {
              onPrimaryClick(websiteIndex);
            }}
            display="flex"
            alignItems="center"
            marginBottom="10px"
          >
            <Radio active={fields[websiteIndex].primary} marginRight="8px" />
            <Label marginBottom="0">Website</Label>
          </EmptyButton>
          <Input
            {...register(`contacts.${websiteIndex}.value`)}
            placeholder="Website"
            autoCorrect="off"
            autoCapitalize="none"
          />
          <Validator>{errors.contacts?.[websiteIndex]?.message}</Validator>
        </Container>

        <Container marginBottom="20px">
          <EmptyButton
            onClick={() => {
              onPrimaryClick(bookingIndex);
            }}
            display="flex"
            alignItems="center"
            marginBottom="10px"
          >
            <Radio active={fields[bookingIndex].primary} marginRight="8px" />
            <Label marginBottom="0">Booking</Label>
          </EmptyButton>
          <Input
            {...register(`contacts.${bookingIndex}.value`)}
            placeholder="Booking URL"
            autoCorrect="off"
            autoCapitalize="none"
          />
          <Validator>{errors.contacts?.[bookingIndex]?.message}</Validator>
        </Container>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

ContactInformationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContactInformationForm;
