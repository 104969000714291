import React, { useState, useContext } from 'react';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import Selector from '../../../../UI/molecules/Selector';
import { Context } from '../../../../../context/AppContext';

const TypeSelector = props => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [show, setShow] = useState();

  return (
    <Selector
      show={show}
      onClose={() => setShow(false)}
      anchor="topRight"
      {...props}
    >
      {({ ref, label, color, borderColor, focusBorderColor }) =>
        mobile ? (
          <EmptyButton
            ref={ref}
            onClick={() => setShow(!show)}
            borderRadius="100%"
            width="34px"
            height="34px"
            border="1px solid rgba(0, 0, 0, 0.2)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            _focus={{
              border: '1px solid #a5a5a5',
            }}
          >
            <Text
              display="block"
              backgroundColor="#9b9b9b"
              width="4px"
              height="4px"
              borderRadius="100%"
              marginBottom="2px"
            />
            <Text
              display="block"
              backgroundColor="#9b9b9b"
              width="4px"
              height="4px"
              borderRadius="100%"
              marginBottom="2px"
            />
            <Text
              display="block"
              backgroundColor="#9b9b9b"
              width="4px"
              height="4px"
              borderRadius="100%"
            />
          </EmptyButton>
        ) : (
          <EmptyButton
            ref={ref}
            onClick={() => setShow(!show)}
            borderRadius="50px"
            height="32px"
            padding="0 12px"
            fontWeight="600"
            display="flex"
            alignItems="center"
            width="100%"
            color={color}
            border={`1px solid ${borderColor}`}
            _focus={{
              border: `1px solid ${focusBorderColor}`,
            }}
          >
            <Text
              width="100%"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontSize="14px"
            >
              {label}
            </Text>
            <Icon
              fontSize="10px"
              className={`ls-icon ${
                show
                  ? 'icon-generalcaretupmedium'
                  : 'icon-generalcaretdownmedium'
              }`}
              marginLeft="5px"
            />
          </EmptyButton>
        )
      }
    </Selector>
  );
};

export default TypeSelector;
