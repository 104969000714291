import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import { Context } from '../../../../context/AppContext';
import AlertDialog from '../AlertDialog';
import CreatePostForm from './CreatePostForm';

const CreatePostModal = ({ show, onClose, title, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [showQuitDialog, setShowQuitDialog] = useState();
  const [isDirty, setIsDirty] = useState();

  return (
    <>
      <Dialog
        show={show}
        maxWidth="520px"
        mode={mobile ? 'mobile' : 'desktop'}
        border="none"
        height={mobile ? '100%' : 'auto'}
      >
        <Container
          backgroundColor={theme => theme.primary.normal}
          padding="20px"
          color="white"
          fontSize="24px"
          fontWeight="600"
          borderTopLeftRadius="11px"
          borderTopRightRadius="11px"
          display="flex"
          justifyContent="space-between"
        >
          <Text>{title}</Text>
          <EmptyButton
            display="flex"
            alignItems="center"
            onClick={() => (isDirty ? setShowQuitDialog(true) : onClose())}
          >
            <Icon
              color="white"
              fontSize="18px"
              className="ls-icon icon-generalxlarge"
              lineHeight="0.5"
              height="0.4em"
              width="0.7em"
            />
          </EmptyButton>
        </Container>
        <Container padding="20px 20px 40px 20px">
          <CreatePostForm onIsDirty={() => setIsDirty(true)} {...props} />
        </Container>
      </Dialog>
      <AlertDialog
        show={showQuitDialog}
        heading="Are you sure you don't want to continue creating this post?"
        text="If not, your progress won't be saved."
        buttons={[
          {
            children: 'Exit',
            props: {
              backgroundColor: 'white',
              rounded: true,
              marginRight: '10px',
              onClick: () => {
                setShowQuitDialog(false);
                onClose();
              },
            },
          },
          {
            children: 'Continue',
            props: {
              primary: true,
              rounded: true,
              onClick: () => setShowQuitDialog(false),
            },
          },
        ]}
      />
    </>
  );
};

CreatePostModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};

CreatePostModal.defaultProps = {
  show: false,
  onClose: () => {},
};

export default CreatePostModal;
