import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Panel from '../../UI/atoms/Panel';
import TradingHoursDialog from '../Info/TradingHoursDialog';
import { Context } from '../../../context/AppContext';
import { DAYS_OF_THE_WEEK } from '../../../lib/constants';

const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } =
  DAYS_OF_THE_WEEK;

const days = [
  { value: MONDAY, label: 'Mon' },
  { value: TUESDAY, label: 'Tue' },
  { value: WEDNESDAY, label: 'Wed' },
  { value: THURSDAY, label: 'Thu' },
  { value: FRIDAY, label: 'Fri' },
  { value: SATURDAY, label: 'Sat' },
  { value: SUNDAY, label: 'Sun' },
];

const minutesInDay = 1440;

const parseTime = time => {
  if (typeof time !== 'number') return null;
  const milliseconds = time * 60 * 1000;
  return moment.utc(milliseconds).format('h:mm a');
};

const parseIntervals = (intervals, dayLabel) =>
  intervals.map((interval, i) => {
    const { start, interval: duration } = interval;
    const is24Hours = !start && duration === minutesInDay;

    return {
      day: i === 0 ? dayLabel : null,
      startTime: is24Hours ? '24 hrs' : parseTime(start),
      endTime: is24Hours ? null : parseTime(start + duration),
    };
  });

const TradingHours = props => {
  const {
    profile: { profile },
  } = useContext(Context);

  const { tradingHours } = profile;

  const [showTradingHoursDialog, setShowTradingHoursDialog] = useState();

  const hours = useMemo(
    () =>
      days.reduce((acc, { value, label: dayLabel }) => {
        const intervals = tradingHours.filter(({ day }) => day === value);

        if (intervals.length) {
          return [...acc, ...parseIntervals(intervals, dayLabel)];
        }

        return [...acc, { day: dayLabel, startTime: 'Closed' }];
      }, []),
    [tradingHours]
  );

  return (
    <>
      <Panel display="flex" flexDirection="column" {...props}>
        <Container display="flex" justifyContent="center" marginBottom="25px">
          <Icon
            fontSize="30px"
            color="text200"
            className="ls-icon icon-clock"
          />
        </Container>

        {hours.length ? (
          <Container
            flex="1"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            marginBottom="20px"
          >
            <Container
              display="flex"
              justifyContent="center"
              marginBottom="10px"
            >
              <Container>
                {hours
                  .map(({ day, startTime, endTime }) => {
                    return (
                      <Container
                        key={`${day}${startTime}${endTime}`}
                        fontSize="14px"
                      >
                        <Text display="inline-block" width="40px">
                          {day}
                        </Text>
                        <Text whiteSpace="nowrap">
                          {' '}
                          {startTime}
                          {endTime ? ` - ${endTime}` : null}
                        </Text>
                      </Container>
                    );
                  })
                  .slice(0, 7)}
              </Container>
            </Container>
            {hours.length > 7 && (
              <Container display="flex" justifyContent="center">
                <Container
                  width="5px"
                  height="5px"
                  borderRadius="100%"
                  backgroundColor="#bbbbbb"
                  margin="1px"
                />
                <Container
                  width="5px"
                  height="5px"
                  borderRadius="100%"
                  backgroundColor="#bbbbbb"
                  margin="1px"
                />
                <Container
                  width="5px"
                  height="5px"
                  borderRadius="100%"
                  backgroundColor="#bbbbbb"
                  margin="1px"
                />
              </Container>
            )}
          </Container>
        ) : (
          <Text flex="1">Let people know when you&apos;re open</Text>
        )}

        <Container
          padding="15px 0 0 0"
          display="flex"
          justifyContent="center"
          marginTop="auto"
          borderTop={theme => `1px solid ${theme.border.border300}`}
        >
          <EmptyButton
            onClick={() => setShowTradingHoursDialog(true)}
            color="text700"
            fontSize="14px"
            fontWeight="600"
          >
            Update Hours
          </EmptyButton>
        </Container>
      </Panel>
      <TradingHoursDialog
        show={showTradingHoursDialog}
        onClose={() => setShowTradingHoursDialog(false)}
      />
    </>
  );
};

export default TradingHours;
