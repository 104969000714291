const create = config => {
  const { baseUrl } = config || {};

  const fetchApi = async (url = '', options = {}) => {
    const { query } = options;

    const params = query ? `?${new URLSearchParams(query)}` : '';

    const res = await fetch(baseUrl + url + params, options);
    const json = await res.json();
    const { message, statusText, status } = json;

    if (res.ok) {
      return json;
    }

    const error = new Error(message || statusText);

    throw Object.assign(
      error,
      {
        status,
      },
      json
    );
  };

  return {
    fetchApi,
  };
};

export { create };
