import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import client from '../../../services/client';
import { profile as profileQuery } from '../../../graphql/queries';
import {
  updateProfile,
  setProfileFrequentlyAskedQuestions,
  setProfileContacts,
  setProfileTradingHours,
  setProfilePaymentTypes,
  setProfileAmenities,
  setProfileProducts,
  setProfileLicences,
  setProfileMedia,
  setProfileSettings,
  setProfileCategories,
} from '../../../graphql/mutations';

const useProfile = () => {
  const [updateLoading, setUpdateLoading] = useState();
  const [updateError, setUpdateError] = useState();

  const { profileId: id } = useParams();

  const [getProfile, { data: { profile } = {}, loading, error }] = useLazyQuery(
    profileQuery,
    {
      variables: {
        id,
      },
    }
  );

  useEffect(() => {
    if (id) {
      getProfile();
    }
  }, [id]);

  const writeToCache = responses => {
    const responseData = responses.reduce((acc, { data }) => {
      const mutation = Object.keys(data)[0];
      const key = Object.keys(data[mutation])[0];

      return { ...acc, [key]: data[mutation][key] };
    }, {});

    const { profile: profileUpdates = {}, ...otherUpdates } = responseData;

    client.writeQuery({
      query: profileQuery,
      data: {
        profile: {
          ...profile,
          ...profileUpdates,
          ...otherUpdates,
        },
      },
      variables: { id },
    });
  };

  const update = async inputs => {
    const {
      frequentlyAskedQuestions,
      contacts,
      tradingHours,
      paymentTypes,
      amenities,
      products,
      licences,
      media,
      settings,
      categories,
      ...updateProfileInput
    } = inputs;

    const mutations = [
      {
        frequentlyAskedQuestions,
        mutation: setProfileFrequentlyAskedQuestions,
      },
      { contacts, mutation: setProfileContacts },
      { tradingHours, mutation: setProfileTradingHours },
      { paymentTypes, mutation: setProfilePaymentTypes },
      { amenities, mutation: setProfileAmenities },
      { products, mutation: setProfileProducts },
      { licences, mutation: setProfileLicences },
      { media, mutation: setProfileMedia },
      { settings, mutation: setProfileSettings },
      { categories, mutation: setProfileCategories },
    ];

    const updates = [];

    if (Object.keys(updateProfileInput).length) {
      updates.push(
        client.mutate({
          mutation: updateProfile,
          variables: {
            id,
            input: { id, ...updateProfileInput },
          },
        })
      );
    }

    mutations.forEach(({ mutation, ...input }) => {
      const key = Object.keys(input)[0];
      if (input[key]) {
        updates.push(
          client.mutate({
            mutation,
            variables: { input: { profileId: id, [key]: input[key] } },
          })
        );
      }
    });

    setUpdateLoading(true);
    try {
      const responses = await Promise.all(updates);
      writeToCache(responses);
    } catch (e) {
      setUpdateError(e);
      throw e;
    } finally {
      setUpdateLoading(false);
    }
  };

  return {
    profile,
    loading,
    error,
    update: {
      loading: updateLoading,
      error: updateError,
      update,
    },
  };
};

export default useProfile;
