import React, { useContext } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Form from 'ls-common-client/src/components/Form';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import SearchInput from './SearchInput';
import FormDialogSubmit from '../../../../UI/molecules/FormDialogSubmit';
import Validator from '../../../../UI/atoms/Validator';
import { Context } from '../../../../../context/AppContext';
import { sortCategories } from '../../../../../lib/util';
import useSuccessNotificationSlider from '../../../../../hooks/useSuccessNotificationSlider';

const schema = Joi.object({
  categories: Joi.array()
    .items(
      Joi.object({
        id: Joi.string(),
        name: Joi.string().messages({
          'string.empty':
            'You forgot to select your category from the drop down.',
        }),
        packageId: Joi.string().allow(null),
      })
    )
    .unique('name')
    .messages({
      'array.unique': 'You have duplicate categories',
    }),
});

const parseCategoriesIn = categories => {
  if (!categories.length) {
    return [{ name: '', packageId: null }];
  }

  return sortCategories(categories).map(category => {
    const { id, name } = category;
    const { id: packageId = null } = category.package || {};
    return {
      id,
      name,
      packageId,
    };
  });
};

const parseCategoriesOut = categories => categories.map(({ id }) => ({ id }));

const CategoriesForm = ({ onClose, ...props }) => {
  const {
    profile: {
      profile = {},
      update: { loading, update },
    },
  } = useContext(Context);

  const { categories } = profile;

  const successNotificationSlider = useSuccessNotificationSlider({
    heading: 'Thanks for your update!',
    text: 'A heads up, your new information will take up to 10 minutes to show online.',
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      categories: parseCategoriesIn(categories),
    },
    resolver: joiResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'categories',
  });

  const submit = async values => {
    await update({ categories: parseCategoriesOut(values.categories) });
    successNotificationSlider.open();
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      display="flex"
      flexDirection="column"
      noValidate
      {...props}
    >
      <Container>
        {fields.map(({ id, packageId }, i) => (
          <Container key={id}>
            <Controller
              control={control}
              name={`categories.${i}.name`}
              render={({ field: { onChange, value, ref } }) => (
                <SearchInput
                  ref={ref}
                  packageId={packageId}
                  onSelect={selected => {
                    onChange(selected.name);
                    setValue(`categories.${i}.id`, selected.id);
                  }}
                  onChange={() => onChange('')}
                  onRemove={() => remove(i)}
                  value={value}
                  index={i}
                  marginBottom="10px"
                />
              )}
            />
            <Validator display="block" marginBottom="15px">
              {errors.categories?.[i]?.name?.message}
            </Validator>
            <Validator display="block" marginBottom="15px">
              {errors.categories?.[i]?.message}
            </Validator>
          </Container>
        ))}
      </Container>
      <Container>
        <EmptyButton
          color="text700"
          fontSize="14px"
          fontWeight="600"
          onClick={() => append({ name: '', packageId: null })}
        >
          Add another
        </EmptyButton>
      </Container>
      <Container display="flex" justifyContent="flex-end" marginTop="auto">
        <FormDialogSubmit loading={loading} disabled={!isDirty} />
      </Container>
    </Form>
  );
};

CategoriesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CategoriesForm;
