import { gql } from '@apollo/client';

const setProfileLicences = gql`
  mutation setProfileLicences($input: SetProfileLicencesInput) {
    setProfileLicences(input: $input) {
      licences {
        name
      }
    }
  }
`;

export default setProfileLicences;
