import { gql } from '@apollo/client';

const setProfileFrequentlyAskedQuestions = gql`
  mutation setProfileFrequentlyAskedQuestions(
    $input: SetProfileFrequentlyAskedQuestionsInput
  ) {
    setProfileFrequentlyAskedQuestions(input: $input) {
      frequentlyAskedQuestions {
        question
        answer
      }
    }
  }
`;

export default setProfileFrequentlyAskedQuestions;
