import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from '../../../UI/molecules/FormDialog';
import FormDialogHeader from '../../../UI/molecules/FormDialogHeader';
import CategoriesForm from './CategoriesForm';

const CategoriesDialog = ({ onClose, ...props }) => {
  return (
    <FormDialog maxWidth="420px" onClose={onClose} {...props}>
      <FormDialogHeader
        header="Categories"
        subHeader="Select the best categories to describe what you do."
        onClose={onClose}
      />
      <CategoriesForm onClose={onClose} flex="1" />
    </FormDialog>
  );
};

CategoriesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CategoriesDialog;
