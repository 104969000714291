import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Anchor from 'ls-common-client/src/components/Anchor';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { Context } from '../../../../../context/AppContext';

const menuItems = [
  {
    title: 'Nucleus',
    text: 'Learn more about the Nucleus all-in-one digital marketing dashboard, free with a Localsearch digital marketing service.',
    href: 'https://business.localsearch.com.au/nucleus',
    gradient:
      'linear-gradient(180deg, rgba(1,54,227,1) 0%, rgba(34,46,53,1) 72%)',
  },
  {
    title: 'Social Media Marketing',
    text: 'Grow using the power of social media in confidence with included ad spend, monthly reporting and a dedicated acount manager.',
    href: 'https://business.localsearch.com.au/social-media-marketing',
    gradient: 'linear-gradient(90deg, #849ae8 0%, #ee7aeb 100%)',
  },
  {
    title: 'Affordable Websites',
    text: 'Smart, affordable websites starting from only $29 a week with pay-as-you go or flat-fee options available.',
    href: 'https://business.localsearch.com.au/affordable-websites',
    gradient: 'linear-gradient(90deg, #ff7f00 0%, #ffbe00 100%)',
  },
  {
    title: 'Google My Business',
    text: 'Put your business where the locals find products and services on Google with our Google My Business Optimisation services.',
    href: 'https://business.localsearch.com.au/google-my-business',
    gradient: 'linear-gradient(200deg, #3868fb 0%, #a7c7f6 80%, #ffcc00 100%)',
  },
  {
    title: 'SEO Services',
    text: "Increase your online presence on organic search results with one of Australia's top-rated search engine optimisation services.",
    href: 'https://business.localsearch.com.au/seo-services',
    gradient: 'linear-gradient(54deg, #40a499 0%, #61e7d9 100%)',
  },
  {
    title: 'Search Engine Marketing',
    text: 'Boost your leads and website traffic with Google Ads! Track every conversion, phone call and more.',
    href: 'https://business.localsearch.com.au/search-engine-marketing',
    gradient: 'linear-gradient(218deg, #0071a6 0%, #00a699 100%)',
  },
];

const Menu = ({ onClose, ...props }) => {
  const {
    media: { desktop },
  } = useContext(Context);

  return (
    <Container display="flex" flexDirection="column" height="100%" {...props}>
      <Container
        borderBottom="1px solid #eaefff"
        paddingLeft="20px"
        height="44px"
        display="flex"
        alignItems="center"
      >
        <Text fontWeight="600" fontSize="16px">
          Localsearch Products
        </Text>
        {!desktop && (
          <EmptyButton
            color="normal"
            onClick={onClose}
            marginLeft="auto"
            padding="10px 15px"
          >
            <Icon className="ls-icon icon-generalxlarge" />
          </EmptyButton>
        )}
      </Container>
      <Container
        display="flex"
        flexWrap="wrap"
        padding="10px 15px"
        minHeight="0"
        overflow="auto"
        className="moreMenuScrollContainer"
      >
        {menuItems.map(({ title, text, gradient, href }) => (
          <Container key={title} flex="1 0 50%" padding="15px" minWidth="300px">
            <Anchor
              href={href}
              target="_blank"
              display="flex"
              fontSize="16px"
              color="normal"
              alignItems="center"
              fontWeight="600"
              lineHeight="1"
              marginBottom="5px"
            >
              <Container
                maxWidth="24px"
                maxHeight="24px"
                minWidth="24px"
                minHeight="24px"
                borderRadius="100%"
                backgroundImage={gradient}
                marginRight="12px"
              />
              {title}
            </Anchor>
            <Text lineHeight="1.1" color="text300" fontSize="14px">
              {text}
            </Text>
          </Container>
        ))}
      </Container>
      <Container
        borderTop="1px solid #eaefff"
        padding="12px 15px"
        display="flex"
        justifyContent="center"
        marginTop="auto"
      >
        <Anchor
          fontSize="14px"
          fontWeight="600"
          display="flex"
          alignItems="center"
          href="https://business.localsearch.com.au/digital-marketing-for-small-businesses"
          target="_blank"
        >
          Discover more products
          <Icon
            marginLeft="10px"
            marginBottom="-2px"
            className="ls-icon icon-generalarrowelarge"
          />
        </Anchor>
      </Container>
    </Container>
  );
};

Menu.propTypes = {
  onClose: PropTypes.func,
};

Menu.defaultProps = {
  onClose: () => {},
};

export default Menu;
