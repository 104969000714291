import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import Icon from 'ls-common-client/src/components/Icon';
import businessProfileLogo from '../../../images/businessProfileLogo.svg';
import { Context } from '../../../context/AppContext';
import SideBar from './SideBar';
import Unauthorised from './Unauthorised';
import NoProfile from './NoProfile';
import RoleAccessFilter from './RoleAccessFilter';
import Footer from './Footer';
import VisualTour from './VisualTour';

const Layout = () => {
  const {
    user: { loading: userLoading, isAuthorised, hasNoRouteAccess },
    profile: { loading: profileLoading, profile },
  } = useContext(Context);

  const { profileId } = useParams();

  const [showLoader, setShowLoader] = useState(true);

  const menuItems = useMemo(() => {
    const id = profileId || '';
    const items = [
      {
        text: 'Home',
        icon: <Icon fontSize="15px" className="ls-icon icon-windows" />,
        path: id ? `/${id}` : '/',
        match: profileId ? '/:profileId' : '/',
      },
      {
        text: 'Info',
        icon: <Icon fontSize="13px" className="ls-icon icon-house" />,
        path: `/info/${id}`,
        match: '/info/*',
      },
      {
        text: 'Posts',
        icon: <Icon fontSize="10px" className="ls-icon icon-megaphone" />,
        path: `/posts/${id}`,
        match: '/posts/*',
      },
      {
        text: 'Reviews',
        icon: (
          <Icon fontSize="17px" className="ls-icon icon-generalstarlarge" />
        ),
        path: `/reviews/${id}`,
        match: '/reviews/*',
      },
      {
        text: 'Enquiries',
        icon: <Icon fontSize="11px" className="ls-icon icon-envelope" />,
        path: `/enquiries/${id}`,
        match: '/enquiries/*',
      },
      {
        text: 'Quotes',
        icon: <Icon fontSize="14px" className="ls-icon icon-document" />,
        path: `/quotes/${id}`,
        match: '/quotes/*',
      },
      {
        text: 'Media',
        icon: <Icon fontSize="12px" className="ls-icon icon-camera" />,
        path: `/media/${id}`,
        match: '/media/*',
      },
      {
        text: 'Insights',
        icon: <Icon fontSize="11px" className="ls-icon icon-bargraph" />,
        path: `/insights/${id}`,
        match: '/insights/*',
      },
    ];
    return items.filter(({ match }) => !hasNoRouteAccess(match));
  }, [profileId, hasNoRouteAccess]);

  const loading = useMemo(
    () => userLoading || profileLoading,
    [userLoading, profileLoading]
  );

  useEffect(() => {
    if (loading) {
      setShowLoader(true);
    } else {
      setTimeout(() => {
        setShowLoader(false);
      }, 600);
    }
  }, [loading]);

  return (
    <Container display="flex" minHeight="100vh">
      {showLoader && (
        <Container
          position="fixed"
          left="0"
          top="0"
          width="100%"
          height="100%"
          backgroundColor="white"
          opacity={loading ? 1 : 0}
          transition="all 0.6s ease"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          zIndex="15"
        >
          <Image width="200px" src={businessProfileLogo} />
          <Loader width="200px" />
        </Container>
      )}

      {isAuthorised ? (
        <>
          <SideBar menuItems={menuItems} />
          <Container display="flex" flex="1" flexDirection="column">
            {profile ? (
              <RoleAccessFilter menuItems={menuItems}>
                <Outlet />
                <VisualTour />
              </RoleAccessFilter>
            ) : (
              <NoProfile />
            )}
            <Footer marginTop="auto" />
          </Container>
        </>
      ) : (
        <Unauthorised />
      )}
    </Container>
  );
};

export default Layout;
