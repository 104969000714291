import React, { useContext, useState, useMemo } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Loader from 'ls-common-client/src/components/Loader';
import { Context } from '../../../context/AppContext';
import insightsComingSoon from '../../../images/insightsComingSoon.svg';
import Selector from '../../UI/molecules/Selector';
import useSearchLocalmetrics from '../../../hooks/useSearchLocalmetrics';
import Devices from './Devices';
import SearchTerms from './SearchTerms';
import Referrer from './Referrer';
import SearchesOverTime from './SearchesOverTime';
import PageHeader from '../../UI/molecules/PageHeader';

const addSpaceToSegment = str => str.replace('d', ' d');
const getNumberFromString = str => str.replace(/[^0-9]/g, '');

const Insights = props => {
  const {
    media: { desktop, mobile },
  } = useContext(Context);

  const { searchLocalmetrics, loading } = useSearchLocalmetrics();
  const { segments } = searchLocalmetrics || {};

  const [tab, setTab] = useState('overview');
  const [selectedSegment, setSelectedSegment] = useState('365days');
  const [showDateRange, setShowDateRange] = useState();

  const dateRanges = useMemo(() => {
    if (!segments) {
      return [];
    }

    return segments
      .map(({ label, segment }) => ({
        label,
        value: segment,
      }))
      .sort(
        (a, b) => getNumberFromString(a.label) - getNumberFromString(b.label)
      );
  }, [segments]);

  const { data } = useMemo(() => {
    if (!segments) {
      return {};
    }

    return segments.find(report => report.segment === selectedSegment);
  }, [selectedSegment, segments]);

  const {
    hitsByDevice,
    googleSearchTerms,
    hitsByReferrer,
    impressionsOverTime,
  } = data || {};

  if (loading) {
    return (
      <>
        <PageHeader>Insights</PageHeader>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
          width="100%"
          height="100%"
          {...props}
        >
          <Loader width="200px" />
        </Container>
      </>
    );
  }

  return (
    <>
      <PageHeader>Insights</PageHeader>
      <Container
        padding={desktop ? '0 20px 20px 20px' : '20px'}
        height="100%"
        display="flex"
        flexDirection="column"
        {...props}
      >
        <Container
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Container flex="1" display="flex" marginBottom="20px">
            <EmptyButton
              onClick={() => setTab('overview')}
              borderRadius="22px"
              padding="7px 22px"
              color="normal"
              fontSize="14px"
              fontWeight="600"
              flex={mobile ? '1' : '0'}
              backgroundColor={
                tab === 'overview' ? 'background400' : 'transparent'
              }
              whiteSpace="nowrap"
            >
              Overview
            </EmptyButton>
            <EmptyButton
              onClick={() => setTab('customerActions')}
              borderRadius="22px"
              padding="7px 22px"
              color="normal"
              fontSize="14px"
              fontWeight="600"
              flex={mobile ? '1' : '0'}
              backgroundColor={
                tab === 'customerActions' ? 'background400' : 'transparent'
              }
              whiteSpace="nowrap"
            >
              Customer Actions
            </EmptyButton>
          </Container>

          <Selector
            data={dateRanges}
            show={showDateRange}
            onClose={() => setShowDateRange(false)}
            onChange={value => setSelectedSegment(value)}
            value={selectedSegment}
            anchor="topRight"
            popupProps={{
              width: '230px',
            }}
            marginLeft="auto"
            visibility={tab === 'overview' ? 'visible' : 'hidden'}
          >
            {({ ref }) => (
              <EmptyButton
                ref={ref}
                onClick={() => setShowDateRange(!showDateRange)}
                backgroundColor="white"
                border={theme => `1px solid ${theme.border.border300}`}
                height="32px"
                padding="0 13px 0 20px"
                borderRadius="30px"
                fontSize="14px"
                fontWeight="600"
                whiteSpace="nowrap"
                color="normal"
                width="120px"
                _hover={{
                  color: 'text700',
                }}
              >
                {addSpaceToSegment(selectedSegment)}
                <Icon
                  className="ls-icon icon-generalcaretdownmedium"
                  fontSize="10px"
                  marginLeft="15px"
                />
              </EmptyButton>
            )}
          </Selector>
        </Container>

        {tab === 'customerActions' && (
          <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Container
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Image src={insightsComingSoon} />
              <Container
                fontSize="12px"
                color="normal"
                padding="5px 10px"
                backgroundColor="background400"
                fontWeight="600"
                borderRadius="30px"
                margin="30px 0 10px 0"
              >
                <Text marginRight="5px">🔥</Text> New Feature
              </Container>
              <Text fontSize="25px" color="nomal" fontWeight="bold">
                Coming soon!
              </Text>
            </Container>
          </Container>
        )}

        {tab === 'overview' && (
          <>
            <Container
              display="flex"
              margin="0 -10px"
              flexWrap="wrap"
              flexDirection={mobile ? 'column' : 'row'}
            >
              <Devices data={hitsByDevice} flex="1" minWidth="280px" />
              <SearchTerms data={googleSearchTerms} flex="1" minWidth="210px" />
              <Referrer data={hitsByReferrer} flex="1" minWidth="280px" />
            </Container>
            <Container margin="0 -10px">
              <SearchesOverTime
                data={impressionsOverTime}
                selectedSegment={addSpaceToSegment(selectedSegment)}
              />
            </Container>
          </>
        )}
      </Container>
    </>
  );
};

export default Insights;
