import { gql } from '@apollo/client';

const categorySuggest = gql`
  query categorySuggest($query: String!) {
    categorySuggest(query: $query) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default categorySuggest;
