import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Dialog from 'ls-common-client/src/components/Dialog';
import InvitationForm from './InvitationForm';
import { Context } from '../../../../../context/AppContext';
import invitationIllustration from '../../../../../images/invitationIllustration.svg';

const points = [
  'The invite may take up to 1 hour to send to the business.',
  'If the business does not receive the invite, have them check their junk/spam.',
  'The link in the invite can only be used once and expires after 5 days.',
];

const InvitationDialog = ({ onClose, ...props }) => {
  const {
    media: { desktop },
  } = useContext(Context);

  return (
    <Dialog
      mode={desktop ? 'desktop' : 'mobile'}
      onClose={onClose}
      maxWidth="830px"
      {...props}
    >
      <Container
        display="flex"
        height="100%"
        flexDirection="row"
        flexWrap="wrap-reverse"
      >
        <Container flex="1" padding="30px" minWidth="300px">
          <Text
            fontSize="25px"
            lineHeight="1.1"
            fontWeight="600"
            display="block"
            marginBottom="10px"
          >
            Invite a User to Access Their Business Profile Manager
          </Text>
          <Text
            fontSize="16px"
            color="text400"
            lineHeight="1.2"
            display="block"
            marginBottom="30px"
          >
            Please ensure the below details are correct.
          </Text>
          <InvitationForm onClose={onClose} />
        </Container>
        <Container
          flex="1"
          minWidth="300px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          backgroundColor="background300"
          borderTopRightRadius="11px"
          borderBottomRightRadius="11px"
          overflow="hidden"
        >
          <Container display="flex" justifyContent="flex-end" width="100%">
            <EmptyButton onClick={onClose} padding="20px">
              <Icon className="ls-icon icon-generalxlarge" />
            </EmptyButton>
          </Container>

          <Container
            maxWidth="320px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0 30px 30px 30px"
          >
            <Container marginBottom="20px">
              <Image src={invitationIllustration} />
            </Container>
            <Container>
              <Text
                display="block"
                fontWeight="600"
                fontSize="25px"
                lineHeight="1.1"
                marginBottom="20px"
              >
                What Happens Now?
              </Text>

              {points.map(text => (
                <Container key={text} display="flex" marginBottom="10px">
                  <Container
                    height="15px"
                    flex="0 0 15px"
                    borderRadius="100%"
                    backgroundColor={theme => theme.secondary.secondary200}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginRight="8px"
                    marginTop="3px"
                  >
                    <Icon
                      color="white"
                      className="ls-icon icon-generalticksmall"
                      fontSize="9px"
                      marginTop="2px"
                      marginLeft="1px"
                    />
                  </Container>

                  <Text
                    fontSize="14px"
                    color="text300"
                    fontWeight="600"
                    lineHeight="1.2"
                  >
                    {text}
                  </Text>
                </Container>
              ))}
            </Container>
          </Container>
        </Container>
      </Container>
    </Dialog>
  );
};

InvitationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default InvitationDialog;
