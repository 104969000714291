const PHONE_REGEX = /^[0-9 +]{4,12}$/;
const PRICE_REGEX = /^\d+(\.\d{1,2})?$/;
const VANITY_URL_PATH_REGEX = /^[a-z\d\-_]+$/i;

const DAYS_OF_THE_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

const TRADING_HOURS_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed',
  TWENTY_FOUR_HOURS: '24hrs',
  OPEN_BY_APPOINTMENT: 'openByAppointment',
};

const CONTACT_TYPES = {
  PHONE: 'phone',
  SOCIAL: 'social',
  EMAIL: 'email',
  URL: 'url',
};

const SOCIAL_LABELS = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedIn',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  PINTEREST: 'pinterest',
  TUMBLR: 'tumblr',
  YOUTUBE: 'youTube',
};

const URL_LABELS = {
  WEBSITE: 'website',
  BOOKING: 'booking',
};

const PAYMENT_TYPES = {
  ACCOUNT: 'account',
  AFTERPAY: 'afterPay',
  AMEX: 'amex',
  APPLE_PAY: 'applePay',
  BARTERCARD: 'bartercard',
  BITCOIN: 'bitcoin',
  BPAY: 'bpay',
  BULK_BILLING: 'bulkBilling',
  CASH: 'cash',
  CHEQUE: 'cheque',
  DIRECT_DEBIT: 'directDebit',
  EFT: 'eft',
  EFTPOS: 'eftpos',
  FULLY_INSURED: 'fullyInsured',
  GIFT_VOUCHER: 'giftVoucher',
  GOOGLE_PAY: 'googlePay',
  HICAPS: 'hicaps',
  HUMM: 'humm',
  INTEREST_FREE_PLANS: 'interestFreePlans',
  MASTERCARD: 'mastercard',
  OPENPAY: 'openpay',
  PAYPAL: 'payPal',
  SENIORS_DISCOUNT: 'seniorsDiscount',
  VISA: 'visa',
  ZIP_PAY: 'zipPay',
};

const PRODUCT_TYPES = {
  PRODUCT: 'product',
  SERVICE: 'service',
};

const STATES = {
  NSW: 'NSW',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
  NT: 'NT',
  ACT: 'ACT',
};

const MEDIA_TYPES = {
  IMAGE: 'image',
  YOUTUBE: 'youTube',
  VIMEO: 'vimeo',
  DOCUMENT: 'document',
};

const LISTING_POST_THEMES = {
  LS_GRADIENT: 'lsGradient',
  INTO_THE_DEEP: 'intoTheDeep',
  SUMMER_PINEAPPLE: 'summerPineapple',
  MAGIC_MINT: 'magicMint',
  SWEET_JASMINE: 'sweetJasmine',
  AQUA_SPECTACULAR: 'aquaSpectacular',
};

const LISTING_POST_STATES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

const QUOTE_SERVICE = {
  INSTALLATION: 'installation',
  REPAIR: 'repair',
  ONGOING: 'ongoing',
  ONE_OFF: 'oneOff',
  COMMERCIAL: 'commercial',
  OTHER: 'other',
};

const PROFILE_STATUSES = {
  PENDING: 'pending',
  PUBLISHED: 'published',
  DISABLED: 'disabled',
};

const REVIEW_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
};

export {
  DAYS_OF_THE_WEEK,
  TRADING_HOURS_TYPES,
  CONTACT_TYPES,
  SOCIAL_LABELS,
  URL_LABELS,
  PHONE_REGEX,
  PRICE_REGEX,
  VANITY_URL_PATH_REGEX,
  PAYMENT_TYPES,
  PRODUCT_TYPES,
  STATES,
  MEDIA_TYPES,
  LISTING_POST_THEMES,
  LISTING_POST_STATES,
  QUOTE_SERVICE,
  PROFILE_STATUSES,
  REVIEW_STATUSES,
};
