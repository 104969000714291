import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Image from 'ls-common-client/src/components/Image';
import Anchor from 'ls-common-client/src/components/Anchor';
import Container from 'ls-common-client/src/components/Container';
import Form from 'ls-common-client/src/components/Form';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Panel from '../atoms/Panel';
import Input from '../atoms/Input';
import Label from '../atoms/Label';
import MoreMenu from './MoreMenu';
import { Context } from '../../../context/AppContext';

const MediaPanel = ({
  data,
  onUpload,
  onDelete,
  onSubmit,
  onEdit,
  type,
  anchor,
  imgSrc,
  ...props
}) => {
  const { url, description } = data;

  const {
    media: { mobile },
  } = useContext(Context);

  const [src, setSrc] = useState();
  const [showMoreMenu, setShowMoreMenu] = useState();

  useEffect(() => {
    (async () => {
      setSrc(await imgSrc(url));
    })();
  }, [url]);

  const { register, handleSubmit, watch } = useForm({
    defaultValues: { description },
  });

  const hasChanged = description !== (watch('description') || null);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} display="flex" {...props}>
      <Panel padding={mobile ? '0' : '15px'} flex="1" position="relative">
        <Anchor
          href={url}
          target="_blank"
          height={mobile ? '160px' : '210px'}
          width="100%"
          border={theme => `1px solid ${theme.border.border300}`}
          borderRadius="20px"
          overflow="hidden"
          position="relative"
          marginBottom="15px"
          display="block"
        >
          <Image height="100%" width="100%" objectFit="cover" src={src} />
        </Anchor>
        <MoreMenu
          show={showMoreMenu}
          onClick={() => setShowMoreMenu(!showMoreMenu)}
          onClose={() => setShowMoreMenu(false)}
          popupWidth="220px"
          anchor={anchor}
          menuItems={[
            ...(onEdit
              ? [
                  {
                    text: `Edit ${type}`,
                    onClick: () => {
                      onEdit();
                      setShowMoreMenu(false);
                    },
                    icon: 'icon-generaledit',
                  },
                ]
              : []),
            ...(onUpload
              ? [
                  {
                    text: `Upload New ${type}`,
                    onClick: () => {
                      onUpload();
                      setShowMoreMenu(false);
                    },
                    icon: 'icon-generalshare',
                  },
                ]
              : []),
            {
              text: 'Delete',
              onClick: () => {
                onDelete();
                setShowMoreMenu(false);
              },
              icon: 'icon-categorysanitarydisposal',
            },
          ]}
          position="absolute"
          right={mobile ? '10px' : '25px'}
          top={mobile ? '10px' : '25px'}
        />
        <Container padding={mobile ? '0 10px' : '0'}>
          <Label fontSize={mobile ? '14px' : '16px'}>{type} description</Label>
          <Input
            {...register('description')}
            maxLength="50"
            placeholder={`${type} description`}
            marginBottom="10px"
            height={mobile ? '43px' : '48px'}
            fontSize={mobile ? '14px' : '16px'}
          />
          {hasChanged && (
            <Container
              display="flex"
              justifyContent="flex-end"
              marginBottom={mobile ? '15px' : '0'}
            >
              <EmptyButton
                color="text700"
                fontWeight="600"
                fontSize="14px"
                type="submit"
              >
                Submit
              </EmptyButton>
            </Container>
          )}
        </Container>
      </Panel>
    </Form>
  );
};

MediaPanel.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  anchor: PropTypes.string,
  imgSrc: PropTypes.func.isRequired,
};

MediaPanel.defaultProps = {
  onEdit: null,
  onUpload: null,
  onDelete: null,
  onSubmit: null,
  type: 'Image',
  anchor: 'right',
};

export default MediaPanel;
